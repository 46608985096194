import React from 'react';
import axios from 'axios';

export default function APIV2({
    hobby,
    life,
    char,
    art,
    cat,
    priceFrom,
    priceTo,
    age,
    version,
    onClick,
    issue,
    type,
    nextStep,
    email,
    onApiResponse,
    newsletterSelected,
    handleApiResponse,
    disabled,
    errorMessage,
    setErrorMessage
}) {
    const maxRetries = 1; // Maximale Anzahl an Wiederholungsversuchen
    let retryCount = 0; // Zähler für die Anzahl der Versuche

    const sendRequest = () => {
        axios.post('https://product-scraper-ggbm.onrender.com', {
            age: `${age}`,
            hobby: `${hobby}`,
            life: `${life}`,
            char: `${char}`,
            art: `${art}`,
            cat: `${cat}`,
            priceFrom: `${priceFrom}`,
            priceTo: `${priceTo}`,
            type: `${type}`,
            issue: `${issue}`,
            email: `${email}`,
            version: `${version}`,
            name: `hello`,
            newsletter: `${newsletterSelected}`
        })
        .then(function (response) {
            console.log(response.data);
            handleApiResponse(response);
        })
        .catch(function (error) {
            console.log(error);
            if (retryCount < maxRetries) {
                retryCount++;
                console.log(`Wiederholungsversuch ${retryCount}: erneuter Versuch der Anfrage`);
                sendRequest(); // Versuchen Sie, die Anfrage erneut zu senden
            } else {
                setErrorMessage('Leider ist ein Fehler aufgetreten. Bitte versuche es erneut.');
            }
        });
    }

    const sendMessage = (e) => {
        e.preventDefault();
        nextStep();
        onClick();
        sendRequest();
    };

    return (
        <button disabled={disabled} className='api-button' onClick={sendMessage}>
            <span className='btn-span-container'>
                <span>Jetzt, <br/>Geschenkideen & -produkte suchen!<span className='duration-btn'></span></span>
            </span>
        </button>
    );
}
