import React, { useState } from "react";
import StepOne from "./page-components/StepOne";
import StepTwo from "./page-components/StepTwo";
import StepThree from "./page-components/StepThree";
import StepFour from "./page-components/StepFour";
import StepFive from "./page-components/StepFive";
import API from "./page-components/API";
import APIV2 from "./page-components/APIV2";
import ProductList from "./page-components/ProductList";

export default function Page(props) {
  const { state, step } = props;
  const [message, setMessage] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [mobileUsage, setMobileUsage] = useState();
  const [softwareUntil, setSoftwareUntil] = useState("");
  const [transferData, setTransferData] = useState();
  const [reasonUntil, setReasonUntil] = useState("");
  const [amountPeopleValue, setAmountPeopleValue] = useState(30);
  const [softwareSelection, setSoftwareSelection] = useState("");
  const [time, setTime] = useState("");
  const [appleUsage, setAppleUsage] = useState();
  const [email, setEmail] = useState("");
  const [emailNew, setEmailNew] = useState("");
  const [phone, setPhone] = useState("");
  const [partner, setPartner] = useState("");
  const [checkOne, setCheckOne] = useState(false);
  const [checkTwo, setCheckTwo] = useState(false);
  const [checkThree, setCheckThree] = useState(false);
  const [checkFour, setCheckFour] = useState(false);
  const [hobby, setHobby] = useState("");
  const [life, setLife] = useState("");
  const [char, setChar] = useState("");
  const [art, setArt] = useState("");
  const [cat, setCat] = useState("");
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [type, setType] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [apiResponseTwoNew, setApiResponseTwoNew] = useState("");
  const [errorForPage, setErrorForPage] = useState("");
//   const [apiResponse, setApiResponse] = useState({
//       "data": [
//         {
//             "idea": "Kochbuch",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "„Perfektion – die Wissenschaft des guten Kochens“ - Gesamtausgabe mit über 300 Rezepten – Genuss trifft Wissenschaft",
//                     "asin": "3868514899",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2MzcyNzgxNTYxMDQyMTc2OjE2ODQwNzQzNzQ6c3BfYXRmOjIwMDM4MTIxNjcyNjA4OjowOjo&url=%2FPerfektion-Gesamtausgabe-Wissenschaft-Stiftung-Warentest%2Fdp%2F3868514899%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DKochbuch%26qid%3D1684074374%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91yzSUu7iIL._AC_UY218_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 87,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.9,
//                             "raw": "€69,90",
//                             "name": "Gebundene Ausgabe",
//                             "asin": "3868514899",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2MzcyNzgxNTYxMDQyMTc2OjE2ODQwNzQzNzQ6c3BfYXRmOjIwMDM4MTIxNjcyNjA4OjowOjo&url=%2FPerfektion-Gesamtausgabe-Wissenschaft-Stiftung-Warentest%2Fdp%2F3868514899%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DKochbuch%26qid%3D1684074374%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.9,
//                         "raw": "€69,90",
//                         "name": "Gebundene Ausgabe",
//                         "asin": "3868514899",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2MzcyNzgxNTYxMDQyMTc2OjE2ODQwNzQzNzQ6c3BfYXRmOjIwMDM4MTIxNjcyNjA4OjowOjo&url=%2FPerfektion-Gesamtausgabe-Wissenschaft-Stiftung-Warentest%2Fdp%2F3868514899%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DKochbuch%26qid%3D1684074374%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "Der goldene Plachutta: Alle 1500 Rezepte",
//                     "asin": "385033676X",
//                     "link": "https://www.amazon.de/goldene-Plachutta-Ewald/dp/385033676X/ref=sr_1_2?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-2",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/511Y0sKOpUL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Ewald Plachutta",
//                             "link": "https://www.amazon.de/Ewald-Plachutta/e/B0043BX6H6?ref=sr_ntt_srch_lnk_2&qid=1684074374&sr=8-2"
//                         },
//                         {
//                             "name": "Mario Plachutta",
//                             "link": "https://www.amazon.de/Mario-Plachutta/e/B00457YQX6?ref=sr_ntt_srch_lnk_2&qid=1684074374&sr=8-2"
//                         }
//                     ],
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 418,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50,
//                             "raw": "€50,00",
//                             "name": "Gebundenes Buch",
//                             "asin": "385033676X",
//                             "link": "https://www.amazon.de/goldene-Plachutta-Ewald/dp/385033676X/ref=sr_1_2?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-2"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50,
//                         "raw": "€50,00",
//                         "name": "Gebundenes Buch",
//                         "asin": "385033676X",
//                         "link": "https://www.amazon.de/goldene-Plachutta-Ewald/dp/385033676X/ref=sr_1_2?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-2"
//                     }
//                 },
//                 {
//                     "position": 3,
//                     "title": "Der Silberlöffel: Die Bibel der italienischen Küche",
//                     "asin": "3947426100",
//                     "link": "https://www.amazon.de/Silberl%C3%B6ffel-Die-Bibel-italienischen-K%C3%BCche/dp/3947426100/ref=sr_1_3?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-3",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71qj0HjF5iL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Phaidon"
//                         }
//                     ],
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 843,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50,
//                             "raw": "€50,00",
//                             "name": "Gebundenes Buch",
//                             "asin": "3947426100",
//                             "link": "https://www.amazon.de/Silberl%C3%B6ffel-Die-Bibel-italienischen-K%C3%BCche/dp/3947426100/ref=sr_1_3?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-3"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50,
//                         "raw": "€50,00",
//                         "name": "Gebundenes Buch",
//                         "asin": "3947426100",
//                         "link": "https://www.amazon.de/Silberl%C3%B6ffel-Die-Bibel-italienischen-K%C3%BCche/dp/3947426100/ref=sr_1_3?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-3"
//                     }
//                 },
//                 {
//                     "position": 4,
//                     "title": "„Perfektion – die Wissenschaft des guten Kochens“ - Gesamtausgabe mit über 300 Rezepten – Genuss trifft Wissenschaft",
//                     "asin": "3868514899",
//                     "link": "https://www.amazon.de/Perfektion-Gesamtausgabe-Wissenschaft-Stiftung-Warentest/dp/3868514899/ref=sr_1_4?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-4",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91yzSUu7iIL._AC_UY218_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 87,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.9,
//                             "raw": "€69,90",
//                             "name": "Gebundenes Buch",
//                             "asin": "3868514899",
//                             "link": "https://www.amazon.de/Perfektion-Gesamtausgabe-Wissenschaft-Stiftung-Warentest/dp/3868514899/ref=sr_1_4?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-4"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 58.99,
//                             "raw": "€58,99",
//                             "name": "Kindle",
//                             "asin": "B07KRJKTJL",
//                             "link": "https://www.amazon.de/Perfektion-Gesamtausgabe-Wissenschaft-guten-Kochens-ebook/dp/B07KRJKTJL/ref=sr_1_4?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-4"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.9,
//                         "raw": "€69,90",
//                         "name": "Gebundenes Buch",
//                         "asin": "3868514899",
//                         "link": "https://www.amazon.de/Perfektion-Gesamtausgabe-Wissenschaft-Stiftung-Warentest/dp/3868514899/ref=sr_1_4?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-4"
//                     }
//                 },
//                 {
//                     "position": 5,
//                     "title": "Die Gourmet-Bibel Frankreich: Das monumentale Standardwerk zur französischen Küche. Mit 375 Rezepten auf 400 Seiten und 1000 Bildern ein ... Absolut ... Ein Frankreich-Kochbuch der Extraklasse.",
//                     "asin": "3959614004",
//                     "link": "https://www.amazon.de/Die-Gourmet-Bibel-Frankreich-Absolut-franz%C3%B6sische/dp/3959614004/ref=sr_1_5?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-5",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81hH0INZ9ZL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "François-Régis Gaudry",
//                             "link": "https://www.amazon.de/Fran%25C3%25A7ois-R%25C3%25A9gis-Gaudry/e/B004MQ6H6E?ref=sr_ntt_srch_lnk_5&qid=1684074374&sr=8-5"
//                         }
//                     ],
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 177,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 70,
//                             "raw": "€70,00",
//                             "name": "Gebundenes Buch",
//                             "asin": "3959614004",
//                             "link": "https://www.amazon.de/Die-Gourmet-Bibel-Frankreich-Absolut-franz%C3%B6sische/dp/3959614004/ref=sr_1_5?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-5"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 70,
//                         "raw": "€70,00",
//                         "name": "Gebundenes Buch",
//                         "asin": "3959614004",
//                         "link": "https://www.amazon.de/Die-Gourmet-Bibel-Frankreich-Absolut-franz%C3%B6sische/dp/3959614004/ref=sr_1_5?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-5"
//                     }
//                 },
//                 {
//                     "position": 6,
//                     "title": "Kochbuch Frankreich – Die klassische französische Küche: 800 Rezepte vom Koch des Jahrhunderts Joël Robuchon. Perfekt französisch kochen mit diesem Standardwerk.",
//                     "asin": "3959615302",
//                     "link": "https://www.amazon.de/Kochbuch-Frankreich-franz%C3%B6sische-Jahrhunderts-Standardwerk/dp/3959615302/ref=sr_1_6?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-6",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71AjJani7qL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Joël Robuchon"
//                         }
//                     ],
//                     "is_prime": true,
//                     "rating": 4.1,
//                     "ratings_total": 14,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69,
//                             "raw": "€69,00",
//                             "name": "Gebundene Ausgabe",
//                             "asin": "3959615302",
//                             "link": "https://www.amazon.de/Kochbuch-Frankreich-franz%C3%B6sische-Jahrhunderts-Standardwerk/dp/3959615302/ref=sr_1_6?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-6"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69,
//                         "raw": "€69,00",
//                         "name": "Gebundene Ausgabe",
//                         "asin": "3959615302",
//                         "link": "https://www.amazon.de/Kochbuch-Frankreich-franz%C3%B6sische-Jahrhunderts-Standardwerk/dp/3959615302/ref=sr_1_6?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-6"
//                     }
//                 },
//                 {
//                     "position": 7,
//                     "title": "Leaf to Root: Gemüse essen vom Blatt bis zur Wurzel",
//                     "asin": "3038009040",
//                     "link": "https://www.amazon.de/Leaf-Root-Gem%C3%BCse-essen-Wurzel/dp/3038009040/ref=sr_1_7?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-7",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51S2XJLq-YL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Esther Kern",
//                             "link": "https://www.amazon.de/Esther-Kern/e/B01MQXNEMC?ref=sr_ntt_srch_lnk_7&qid=1684074374&sr=8-7"
//                         },
//                         {
//                             "name": "Pascal Haag",
//                             "link": "https://www.amazon.de/Pascal-Haag/e/B01N0SHDVC?ref=sr_ntt_srch_lnk_7&qid=1684074374&sr=8-7"
//                         }
//                     ],
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 106,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 58,
//                             "raw": "€58,00",
//                             "name": "Gebundenes Buch",
//                             "asin": "3038009040",
//                             "link": "https://www.amazon.de/Leaf-Root-Gem%C3%BCse-essen-Wurzel/dp/3038009040/ref=sr_1_7?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-7"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 58,
//                         "raw": "€58,00",
//                         "name": "Gebundenes Buch",
//                         "asin": "3038009040",
//                         "link": "https://www.amazon.de/Leaf-Root-Gem%C3%BCse-essen-Wurzel/dp/3038009040/ref=sr_1_7?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-7"
//                     }
//                 },
//                 {
//                     "position": 8,
//                     "title": "Magnolia Table, Volume 3: A Collection of Recipes for Gathering",
//                     "asin": "0062820176",
//                     "link": "https://www.amazon.de/Untitled-Anon9780062820174/dp/0062820176/ref=sr_1_8?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-8",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91Ge3UG7GkL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Joanna Gaines",
//                             "link": "https://www.amazon.de/Joanna-Gaines/e/B01D2GZ184?ref=sr_ntt_srch_lnk_8&qid=1684074374&sr=8-8"
//                         }
//                     ],
//                     "rating": 4.7,
//                     "ratings_total": 92,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 34.44,
//                             "raw": "€34,44",
//                             "name": "Gebundenes Buch",
//                             "asin": "0062820176",
//                             "link": "https://www.amazon.de/Untitled-Anon9780062820174/dp/0062820176/ref=sr_1_8?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-8"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 29.86,
//                             "raw": "€29,86",
//                             "name": "Kindle",
//                             "asin": "B0BN1WHTFV",
//                             "link": "https://www.amazon.de/Magnolia-Table-Collection-Recipes-Gathering-ebook/dp/B0BN1WHTFV/ref=sr_1_8?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-8"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 34.44,
//                         "raw": "€34,44",
//                         "name": "Gebundenes Buch",
//                         "asin": "0062820176",
//                         "link": "https://www.amazon.de/Untitled-Anon9780062820174/dp/0062820176/ref=sr_1_8?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-8"
//                     }
//                 },
//                 {
//                     "position": 9,
//                     "title": "Kochbuch – Sushi: Perfekt. Zuhause. Zubereiten. Mit vielen Step-by-Step Fotos.",
//                     "asin": "395961649X",
//                     "link": "https://www.amazon.de/Kochbuch-Perfekt-Zubereiten-Step-Step/dp/395961649X/ref=sr_1_9?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-9",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61-Ovjb1SaL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Oof Verschuren"
//                         }
//                     ],
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 6,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "Gebundenes Buch",
//                             "asin": "395961649X",
//                             "link": "https://www.amazon.de/Kochbuch-Perfekt-Zubereiten-Step-Step/dp/395961649X/ref=sr_1_9?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-9"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 29.99,
//                             "raw": "€29,99",
//                             "name": "Kindle",
//                             "asin": "B0BP2TX929",
//                             "link": "https://www.amazon.de/Sushi-Perfekt-Zubereiten-Oof-Verschuren-ebook/dp/B0BP2TX929/ref=sr_1_9?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-9"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "Gebundenes Buch",
//                         "asin": "395961649X",
//                         "link": "https://www.amazon.de/Kochbuch-Perfekt-Zubereiten-Step-Step/dp/395961649X/ref=sr_1_9?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-9"
//                     }
//                 },
//                 {
//                     "position": 10,
//                     "title": "Kochbuch Italien – Die Gourmet-Bibel Italien: Das Standardwerk der italienischen Küche. Gesammeltes Gourmetwissen: Italienisch kochen mithilfe 1.272 Produktporträts, 265 Rezepten, 1.000 Abbildungen.",
//                     "asin": "3959615787",
//                     "link": "https://www.amazon.de/Kochbuch-Italien-Gourmet-Bibel-italienischen-Produktportr%C3%A4ts/dp/3959615787/ref=sr_1_10?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-10",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/711O3LsAcWL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "François-Régis Gaudry"
//                         }
//                     ],
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 67,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 70,
//                             "raw": "€70,00",
//                             "name": "Gebundene Ausgabe",
//                             "asin": "3959615787",
//                             "link": "https://www.amazon.de/Kochbuch-Italien-Gourmet-Bibel-italienischen-Produktportr%C3%A4ts/dp/3959615787/ref=sr_1_10?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-10"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 70,
//                         "raw": "€70,00",
//                         "name": "Gebundene Ausgabe",
//                         "asin": "3959615787",
//                         "link": "https://www.amazon.de/Kochbuch-Italien-Gourmet-Bibel-italienischen-Produktportr%C3%A4ts/dp/3959615787/ref=sr_1_10?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-10"
//                     }
//                 },
//                 {
//                     "position": 11,
//                     "title": "Harald Wohlfahrt: Seine Signature Dishes. Die limitierte Premiumausgabe",
//                     "asin": "3960331495",
//                     "link": "https://www.amazon.de/Harald-Wohlfahrt-Signature-limitierte-Premiumausgabe/dp/3960331495/ref=sr_1_11?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-11",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61BBd1yX7xL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Ralf Frenzel"
//                         }
//                     ],
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 2,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.9,
//                             "raw": "€79,90",
//                             "name": "Gebundene Ausgabe",
//                             "asin": "3960331495",
//                             "link": "https://www.amazon.de/Harald-Wohlfahrt-Signature-limitierte-Premiumausgabe/dp/3960331495/ref=sr_1_11?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-11"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.9,
//                         "raw": "€79,90",
//                         "name": "Gebundene Ausgabe",
//                         "asin": "3960331495",
//                         "link": "https://www.amazon.de/Harald-Wohlfahrt-Signature-limitierte-Premiumausgabe/dp/3960331495/ref=sr_1_11?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-11"
//                     }
//                 },
//                 {
//                     "position": 12,
//                     "title": "Chinesisches Kochbuch – Die Küche Sichuans: Über 250 Rezepte, 56 Garmethoden, 23 Aromen. Das Standardwerk zur chinesischen Küche.",
//                     "asin": "3959616511",
//                     "link": "https://www.amazon.de/Chinesisches-Kochbuch-Garmethoden-Standardwerk-chinesischen/dp/3959616511/ref=sr_1_12?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-12",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81LDrgsTYuL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Fuchsia Dunlop"
//                         }
//                     ],
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 15,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 60,
//                             "raw": "€60,00",
//                             "name": "Gebundene Ausgabe",
//                             "asin": "3959616511",
//                             "link": "https://www.amazon.de/Chinesisches-Kochbuch-Garmethoden-Standardwerk-chinesischen/dp/3959616511/ref=sr_1_12?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-12"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 60,
//                         "raw": "€60,00",
//                         "name": "Gebundene Ausgabe",
//                         "asin": "3959616511",
//                         "link": "https://www.amazon.de/Chinesisches-Kochbuch-Garmethoden-Standardwerk-chinesischen/dp/3959616511/ref=sr_1_12?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-12"
//                     }
//                 },
//                 {
//                     "position": 13,
//                     "title": "Gordon Ramsay's Healthy, Lean & Fit: Mouthwatering Recipes to Fuel You for Life",
//                     "asin": "1538714663",
//                     "link": "https://www.amazon.de/Gordon-Ramsays-Healthy-Lean-Mouthwatering/dp/1538714663/ref=sr_1_13?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-13",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61YjLGc7rJL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Gordon Ramsay",
//                             "link": "https://www.amazon.de/Gordon-Ramsay/e/B001H6ETXK?ref=sr_ntt_srch_lnk_13&qid=1684074374&sr=8-13"
//                         }
//                     ],
//                     "coupon": {
//                         "badge_text": "Spare 1,95 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 2305,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 29.99,
//                             "raw": "€29,99",
//                             "name": "Gebundenes Buch",
//                             "asin": "1538714663",
//                             "link": "https://www.amazon.de/Gordon-Ramsays-Healthy-Lean-Mouthwatering/dp/1538714663/ref=sr_1_13?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-13"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 29.99,
//                         "raw": "€29,99",
//                         "name": "Gebundenes Buch",
//                         "asin": "1538714663",
//                         "link": "https://www.amazon.de/Gordon-Ramsays-Healthy-Lean-Mouthwatering/dp/1538714663/ref=sr_1_13?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-13"
//                     }
//                 },
//                 {
//                     "position": 14,
//                     "title": "NOPI: The Cookbook: Ausgezeichnet: James Beard Awards 2016",
//                     "asin": "0091957168",
//                     "link": "https://www.amazon.de/NOPI-Cookbook-Yotam-Ottolenghi/dp/0091957168/ref=sr_1_14?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-14",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71+5lzxvBoL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Yotam Ottolenghi",
//                             "link": "https://www.amazon.de/Yotam-Ottolenghi/e/B002A0CKME?ref=sr_ntt_srch_lnk_14&qid=1684074374&sr=8-14"
//                         }
//                     ],
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 472,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 28.99,
//                             "raw": "€28,99",
//                             "name": "Gebundenes Buch",
//                             "asin": "0091957168",
//                             "link": "https://www.amazon.de/NOPI-Cookbook-Yotam-Ottolenghi/dp/0091957168/ref=sr_1_14?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-14"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 19.99,
//                             "raw": "€19,99",
//                             "name": "Kindle",
//                             "asin": "B014VOCW4M",
//                             "link": "https://www.amazon.de/NOPI-Cookbook-English-Yotam-Ottolenghi-ebook/dp/B014VOCW4M/ref=sr_1_14?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-14"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 28.99,
//                         "raw": "€28,99",
//                         "name": "Gebundenes Buch",
//                         "asin": "0091957168",
//                         "link": "https://www.amazon.de/NOPI-Cookbook-Yotam-Ottolenghi/dp/0091957168/ref=sr_1_14?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-14"
//                     }
//                 },
//                 {
//                     "position": 15,
//                     "title": "Hensslers schnelle Nummer 2: Das geilste Kochbuch der Welt (Gräfe und Unzer Einzeltitel) + Hensslers schnelle Nummer: 100 neue Rezepte zum Erfolgsformat (Promi- und Fernsehköch*innen)",
//                     "asin": "B0B88H8MFJ",
//                     "link": "https://www.amazon.de/Hensslers-schnelle-Nummer-Einzeltitel-Erfolgsformat/dp/B0B88H8MFJ/ref=sr_1_15?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-15",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61YPcQOi7RL._AC_UY218_.jpg",
//                     "is_prime": true,
//                     "rating": 4.9,
//                     "ratings_total": 25,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 52.9,
//                             "raw": "€52,90",
//                             "name": "Product Bundle",
//                             "asin": "B0B88H8MFJ",
//                             "link": "https://www.amazon.de/Hensslers-schnelle-Nummer-Einzeltitel-Erfolgsformat/dp/B0B88H8MFJ/ref=sr_1_15?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-15"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 52.9,
//                         "raw": "€52,90",
//                         "name": "Product Bundle",
//                         "asin": "B0B88H8MFJ",
//                         "link": "https://www.amazon.de/Hensslers-schnelle-Nummer-Einzeltitel-Erfolgsformat/dp/B0B88H8MFJ/ref=sr_1_15?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-15"
//                     }
//                 },
//                 {
//                     "position": 16,
//                     "title": "Die Weltköche zu Gast im Ikarus: Außergewöhnliche Rezepte und wegweisende Chefs im Porträt: Band 8",
//                     "asin": "3710500605",
//                     "link": "https://www.amazon.de/Die-Weltk%C3%B6che-Gast-Ikarus-Au%C3%9Fergew%C3%B6hnliche/dp/3710500605/ref=sr_1_16?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-16",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71NPnelNqSL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Martin Klein"
//                         }
//                     ],
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 16,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.95,
//                             "raw": "€69,95",
//                             "name": "Gebundene Ausgabe",
//                             "asin": "3710500605",
//                             "link": "https://www.amazon.de/Die-Weltk%C3%B6che-Gast-Ikarus-Au%C3%9Fergew%C3%B6hnliche/dp/3710500605/ref=sr_1_16?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-16"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.95,
//                         "raw": "€69,95",
//                         "name": "Gebundene Ausgabe",
//                         "asin": "3710500605",
//                         "link": "https://www.amazon.de/Die-Weltk%C3%B6che-Gast-Ikarus-Au%C3%9Fergew%C3%B6hnliche/dp/3710500605/ref=sr_1_16?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-16"
//                     }
//                 },
//                 {
//                     "position": 17,
//                     "title": "Die Weltköche zu Gast im Ikarus: Außergewöhnliche Rezepte und wegweisende Chefs im Porträt: Band 9",
//                     "asin": "3710500680",
//                     "link": "https://www.amazon.de/Die-Weltk%C3%B6che-Gast-Ikarus-Au%C3%9Fergew%C3%B6hnliche/dp/3710500680/ref=sr_1_17?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-17",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71vH99qc2hL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Martin Klein"
//                         }
//                     ],
//                     "is_prime": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.95,
//                             "raw": "€69,95",
//                             "name": "Gebundene Ausgabe",
//                             "asin": "3710500680",
//                             "link": "https://www.amazon.de/Die-Weltk%C3%B6che-Gast-Ikarus-Au%C3%9Fergew%C3%B6hnliche/dp/3710500680/ref=sr_1_17?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-17"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.95,
//                         "raw": "€69,95",
//                         "name": "Gebundene Ausgabe",
//                         "asin": "3710500680",
//                         "link": "https://www.amazon.de/Die-Weltk%C3%B6che-Gast-Ikarus-Au%C3%9Fergew%C3%B6hnliche/dp/3710500680/ref=sr_1_17?keywords=Kochbuch&qid=1684074374&refinements=p_36%3A5000-10000&sr=8-17"
//                     }
//                 }
//             ]
//         },
//         {
//             "idea": "Küchenmesser",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "MITSUMOTO SAKARI 22 cm Gyuto Japanisches Messer, Handgeschmiedetes Küchenmesser Profi Fleischmesser, Hochkohlenstoffstahl Stahl Kochmesser Profi Messer (Rosenholzgriff & Geschenkbox)",
//                     "asin": "B09W5MJDGZ",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfYXRmOjIwMDY0OTAzOTA1OTk4OjowOjo&url=%2FMITSUMOTO-SAKARI-Handgeschmiedetes-Hochkohlenstoffstahl-Rosenholzgriff%2Fdp%2FB09W5MJDGZ%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71SrWCd8TnL._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 384,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 63.99,
//                             "raw": "€63,99",
//                             "name": "63,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 81.99,
//                             "raw": "€81,99",
//                             "name": "63,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 63.99,
//                         "raw": "€63,99",
//                         "name": "63,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "SHAN ZU Damastmesser Kochmesser 67 Schichten Damaststahl Küchenmesser mit G10 Griff 20CM - PRO Series",
//                     "asin": "B071JV1GDP",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfYXRmOjIwMDYwNDMwMTYyOTk4OjowOjo&url=%2FSHAN-ZU-Damastmesser-Damaststahl-K%25C3%25BCchenmesser%2Fdp%2FB071JV1GDP%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/811Sw6bCNxL._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 4802,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 119.99,
//                             "raw": "€119,99",
//                             "name": "69,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 3,
//                     "title": "SCHNEIDWERK Chef-Messer Damastmesser, Küchenmesser 25,5 cm Klingenlänge , 67 Lagen Edelstahl-Damast, Kochmesser Damaststahl, sehr scharf, Damaszener-Stahl Rostfrei DI-Serie",
//                     "asin": "B07QBQY1XR",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfYXRmOjIwMDI4MDc0NTMzNDAzOjowOjo&url=%2FSCHNEIDWERK-Damastmesser-K%25C3%25BCchenmesser-Edelstahl-Damast-Damaszener-Stahl%2Fdp%2FB07QBQY1XR%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81rpO5SkwJL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 61,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.95,
//                             "raw": "€79,95",
//                             "name": "79,95 €",
//                             "asin": "B07QBQY1XR",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfYXRmOjIwMDI4MDc0NTMzNDAzOjowOjo&url=%2FSCHNEIDWERK-Damastmesser-K%25C3%25BCchenmesser-Edelstahl-Damast-Damaszener-Stahl%2Fdp%2FB07QBQY1XR%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.95,
//                         "raw": "€79,95",
//                         "name": "79,95 €",
//                         "asin": "B07QBQY1XR",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfYXRmOjIwMDI4MDc0NTMzNDAzOjowOjo&url=%2FSCHNEIDWERK-Damastmesser-K%25C3%25BCchenmesser-Edelstahl-Damast-Damaszener-Stahl%2Fdp%2FB07QBQY1XR%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 4,
//                     "title": "EKVALLY KÄPY Santoku Messer 17,5 cm Klinge, Damast Küchenmesser mit oktagonalem Palisander-Holzgriff - Damastmesser",
//                     "asin": "B0BLFP3DRV",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfYXRmOjIwMTUzMDExMjczNDk4OjowOjo&url=%2FEKVALLY-K%25C3%2584PY-Santoku-K%25C3%25BCchenmesser-Palisander-Holzgriff%2Fdp%2FB0BLFP3DRV%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81gZKZFUCNL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 124,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 82.99,
//                             "raw": "€82,99",
//                             "name": "82,99 €",
//                             "asin": "B0BLFP3DRV",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfYXRmOjIwMTUzMDExMjczNDk4OjowOjo&url=%2FEKVALLY-K%25C3%2584PY-Santoku-K%25C3%25BCchenmesser-Palisander-Holzgriff%2Fdp%2FB0BLFP3DRV%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 82.99,
//                         "raw": "€82,99",
//                         "name": "82,99 €",
//                         "asin": "B0BLFP3DRV",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfYXRmOjIwMTUzMDExMjczNDk4OjowOjo&url=%2FEKVALLY-K%25C3%2584PY-Santoku-K%25C3%25BCchenmesser-Palisander-Holzgriff%2Fdp%2FB0BLFP3DRV%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 5,
//                     "title": "SHAN ZU Damastmesser Kochmesser 67 Schichten Damaststahl Küchenmesser mit G10 Griff 20CM - PRO Series",
//                     "asin": "B071JV1GDP",
//                     "link": "https://www.amazon.de/SHAN-ZU-Damastmesser-Damaststahl-K%C3%BCchenmesser/dp/B071JV1GDP/ref=sr_1_5?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-5",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/811Sw6bCNxL._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 4802,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 119.99,
//                             "raw": "€119,99",
//                             "name": "69,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 6,
//                     "title": "SHAN ZU Küchenmesserset 3 tlg, Messerset Profi Küchenmesser Kochmesser Deutscher Edelstahl Extra Scharfe Messer, 20cm Chefmesser & 15cm Allzweckmesser & 9.5cm Schälmesser",
//                     "asin": "B07CLYNWQ8",
//                     "link": "https://www.amazon.de/SHAN-ZU-K%C3%BCchen-Kochmesser-Sch%C3%A4lmesser/dp/B07CLYNWQ8/ref=sr_1_6?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-6",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61ll4m62jAL._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 2224,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 43.99,
//                             "raw": "€43,99",
//                             "name": "43,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 63.99,
//                             "raw": "€63,99",
//                             "name": "43,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 43.99,
//                         "raw": "€43,99",
//                         "name": "43,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 7,
//                     "title": "Wolfblood Damastmesser-Set 2er (24cm) Profi-Damast-Küchenmesser Set aus 67 Lagen Damaststahl I Küchenmesser-Damastmesser Set mit Chefmesser Santoku Messer Holzgriff Geschenkbox und Klingenschutz",
//                     "asin": "B09XJBGKYJ",
//                     "link": "https://www.amazon.de/Wolfblood-Damastmesser-Set-Profi-Damast-K%C3%BCchenmesser-K%C3%BCchenmesser-Damastmesser-Klingenschutz/dp/B09XJBGKYJ/ref=sr_1_7?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-7",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71J6dZBZjDL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.9,
//                     "ratings_total": 203,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.99,
//                             "raw": "€79,99",
//                             "name": "69,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 8,
//                     "title": "Wakoli Edib 3er Damastmesser-Set mit Klingenlängen von 8,50cm bis 17,00cm extrem scharf aus 67 Lagen I Profi Küchenmesser Set aus echtem japanischen Damaststahl mit Griffen aus Pakkaholz und Holzbox",
//                     "asin": "B00KGDVXE8",
//                     "link": "https://www.amazon.de/Wakoli-Damastmesser-hochwertige-Kochmesser-K%C3%BCchenmesser/dp/B00KGDVXE8/ref=sr_1_8?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-8",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61bIlgnZMZL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 2883,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 94.99,
//                             "raw": "€94,99",
//                             "name": "94,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 98.99,
//                             "raw": "€98,99",
//                             "name": "94,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 94.99,
//                         "raw": "€94,99",
//                         "name": "94,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 9,
//                     "title": "Aoresac Küchenmesser-Set, 15 pcs Profi-Messerblock mit Holzblock Edelstahl, Kochmesser-Set, ABS-Griff, Vollerl, Premium-Set",
//                     "asin": "B0B99HGSVL",
//                     "link": "https://www.amazon.de/Aoresac-K%C3%BCchenmesser-Set-Profi-Messerblock-Kochmesser-Set-Premium-Set/dp/B0B99HGSVL/ref=sr_1_9?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-9",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61V8-qBlIZL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 20,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 34,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 61.98,
//                             "raw": "€61,98",
//                             "name": "61,98 €",
//                             "asin": "B0B99HGSVL",
//                             "link": "https://www.amazon.de/Aoresac-K%C3%BCchenmesser-Set-Profi-Messerblock-Kochmesser-Set-Premium-Set/dp/B0B99HGSVL/ref=sr_1_9?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-9"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 61.98,
//                         "raw": "€61,98",
//                         "name": "61,98 €",
//                         "asin": "B0B99HGSVL",
//                         "link": "https://www.amazon.de/Aoresac-K%C3%BCchenmesser-Set-Profi-Messerblock-Kochmesser-Set-Premium-Set/dp/B0B99HGSVL/ref=sr_1_9?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-9"
//                     }
//                 },
//                 {
//                     "position": 10,
//                     "title": "D.Perlla Messerblock | Messerset | 16-TLG Messer mit Holzgriff | Edelstahl Kochmesser Set mit Holzblock | Profi Küchenmesser mit Wetzstahl",
//                     "asin": "B09GVTHMZB",
//                     "link": "https://www.amazon.de/D-Perlla-Messerblock-Messerset-Kochmesser-K%C3%BCchenmesser/dp/B09GVTHMZB/ref=sr_1_10?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-10",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81V58sCa77L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 272,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.99,
//                             "raw": "€89,99",
//                             "name": "89,99 €",
//                             "asin": "B09GVTHMZB",
//                             "link": "https://www.amazon.de/D-Perlla-Messerblock-Messerset-Kochmesser-K%C3%BCchenmesser/dp/B09GVTHMZB/ref=sr_1_10?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-10"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.99,
//                         "raw": "€89,99",
//                         "name": "89,99 €",
//                         "asin": "B09GVTHMZB",
//                         "link": "https://www.amazon.de/D-Perlla-Messerblock-Messerset-Kochmesser-K%C3%BCchenmesser/dp/B09GVTHMZB/ref=sr_1_10?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-10"
//                     }
//                 },
//                 {
//                     "position": 11,
//                     "title": "Feuerstein Küchenmesser set 5-Tlg, extrem scharfe Kochmesser aus hochwertigem Edelstahl, Profi Messer set mit ergonomischen Harzgriff in Geschenkbox,",
//                     "asin": "B09W1PQGZP",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTUyMzk2MDk1Mjk4OjowOjo&url=%2FFEUERSTEIN-K%25C3%25BCchenmesser-set-hochwertigem-ergonomischen-Geschenkbox%2Fdp%2FB09W1PQGZP%2Fref%3Dsr_1_11_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-11-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Y44T2fbdL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 3,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "asin": "B09W1PQGZP",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTUyMzk2MDk1Mjk4OjowOjo&url=%2FFEUERSTEIN-K%25C3%25BCchenmesser-set-hochwertigem-ergonomischen-Geschenkbox%2Fdp%2FB09W1PQGZP%2Fref%3Dsr_1_11_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-11-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "asin": "B09W1PQGZP",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTUyMzk2MDk1Mjk4OjowOjo&url=%2FFEUERSTEIN-K%25C3%25BCchenmesser-set-hochwertigem-ergonomischen-Geschenkbox%2Fdp%2FB09W1PQGZP%2Fref%3Dsr_1_11_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-11-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 12,
//                     "title": "FUJUNI Kochmesser Damastmesser Mit Extrem Scharf 20cm Klinge Aus 67 Lagen Vg-10 Damaststahl Küchenmesser,Holzgriff, Geschenkbox",
//                     "asin": "B09ZKHDRKC",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMDg2ODE0MDE2ODk4OjowOjo&url=%2FFUJUNI-Damastmesser-Damaststahl-K%25C3%25BCchenmesser-Geschenkbox%2Fdp%2FB09ZKHDRKC%2Fref%3Dsr_1_12_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-12-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71dgU6rPDmL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 20 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 276,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99,
//                             "raw": "€99,00",
//                             "name": "99,00 €",
//                             "asin": "B09ZKHDRKC",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMDg2ODE0MDE2ODk4OjowOjo&url=%2FFUJUNI-Damastmesser-Damaststahl-K%25C3%25BCchenmesser-Geschenkbox%2Fdp%2FB09ZKHDRKC%2Fref%3Dsr_1_12_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-12-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99,
//                         "raw": "€99,00",
//                         "name": "99,00 €",
//                         "asin": "B09ZKHDRKC",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMDg2ODE0MDE2ODk4OjowOjo&url=%2FFUJUNI-Damastmesser-Damaststahl-K%25C3%25BCchenmesser-Geschenkbox%2Fdp%2FB09ZKHDRKC%2Fref%3Dsr_1_12_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-12-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 13,
//                     "title": "FELIX SOLINGEN 222020 MIU Kochmesser - 20 cm Schneide aus Klingen-Stahl mit Walnuss-Griff - Made in Germany",
//                     "asin": "B09NMHC78J",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTMwOTUwMDAwMjk4OjowOjo&url=%2FFelix-SOLINGEN-222020-Kochmesser-Klingen-Stahl%2Fdp%2FB09NMHC78J%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA1OYX27FZHJBTC",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51Ktl-WQ4lL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 15 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 95,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.9,
//                             "raw": "€54,90",
//                             "name": "54,90 €",
//                             "asin": "B09NMHC78J",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTMwOTUwMDAwMjk4OjowOjo&url=%2FFelix-SOLINGEN-222020-Kochmesser-Klingen-Stahl%2Fdp%2FB09NMHC78J%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA1OYX27FZHJBTC"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.9,
//                         "raw": "€54,90",
//                         "name": "54,90 €",
//                         "asin": "B09NMHC78J",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTMwOTUwMDAwMjk4OjowOjo&url=%2FFelix-SOLINGEN-222020-Kochmesser-Klingen-Stahl%2Fdp%2FB09NMHC78J%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA1OYX27FZHJBTC"
//                     }
//                 },
//                 {
//                     "position": 14,
//                     "title": "FEUERSTEIN Küchenmesser Set 6-Tlg / Extrem Scharfe Kochmesser, Profi Messer aus Hochwertigem Edelstahl / Premium Messerset, Schwarz",
//                     "asin": "B09KT9V229",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTM4Mjg4Njc4Mzk4OjowOjo&url=%2FFEUERSTEIN-K%25C3%25BCchenmesser-Japanische-Kochmesser-hochwertigem%2Fdp%2FB09KT9V229%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61a6VwiFOBL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 20,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.98,
//                             "raw": "€54,98",
//                             "name": "54,98 €",
//                             "asin": "B09KT9V229",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTM4Mjg4Njc4Mzk4OjowOjo&url=%2FFEUERSTEIN-K%25C3%25BCchenmesser-Japanische-Kochmesser-hochwertigem%2Fdp%2FB09KT9V229%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.98,
//                         "raw": "€54,98",
//                         "name": "54,98 €",
//                         "asin": "B09KT9V229",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTM4Mjg4Njc4Mzk4OjowOjo&url=%2FFEUERSTEIN-K%25C3%25BCchenmesser-Japanische-Kochmesser-hochwertigem%2Fdp%2FB09KT9V229%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 15,
//                     "title": "Fissler PROFI Messerset 4 tlg, Spezialklingen-Edelstahl geschmiedet - scharf, Rostfrei, Küchenmesser Set aus 1 Kochmesser / 2 Santokumesser / 1 Brotmesser, Messer Küche",
//                     "asin": "B0B3JMV4CZ",
//                     "link": "https://www.amazon.de/Fissler-PROFI-Messerset-Spezialklingen-Edelstahl-geschmiedet/dp/B0B3JMV4CZ/ref=sr_1_15?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-15",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51QmJ947kwL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 123,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.12,
//                             "raw": "€89,12",
//                             "name": "89,12 €",
//                             "asin": "B0B3JMV4CZ",
//                             "link": "https://www.amazon.de/Fissler-PROFI-Messerset-Spezialklingen-Edelstahl-geschmiedet/dp/B0B3JMV4CZ/ref=sr_1_15?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-15"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.12,
//                         "raw": "€89,12",
//                         "name": "89,12 €",
//                         "asin": "B0B3JMV4CZ",
//                         "link": "https://www.amazon.de/Fissler-PROFI-Messerset-Spezialklingen-Edelstahl-geschmiedet/dp/B0B3JMV4CZ/ref=sr_1_15?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-15"
//                     }
//                 },
//                 {
//                     "position": 16,
//                     "title": "Victorinox Swiss Classic Küchengarnitur 5-teilig, Wellenschliff und gerader Schliff, schwarz, One Size",
//                     "asin": "B001KR9AYO",
//                     "link": "https://www.amazon.de/Victorinox-K%C3%BCchenmesser-Set-Gem%C3%BCsemesser-Tranchiermesser-Sp%C3%BClmaschinengeeignet/dp/B001KR9AYO/ref=sr_1_16?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-16",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61hpTMDLvPL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 503,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 68,
//                             "raw": "€68,00",
//                             "name": "68,00 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 76,
//                             "raw": "€76,00",
//                             "name": "68,00 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 68,
//                         "raw": "€68,00",
//                         "name": "68,00 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 17,
//                     "title": "FEUERSTEIN Küchenmesser Set 6-Tlg / Extrem Scharfe Kochmesser, Profi Messer aus Hochwertigem Edelstahl / Premium Messerset, Schwarz",
//                     "asin": "B09KT9V229",
//                     "link": "https://www.amazon.de/FEUERSTEIN-K%C3%BCchenmesser-Japanische-Kochmesser-hochwertigem/dp/B09KT9V229/ref=sr_1_17?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-17",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61a6VwiFOBL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 20,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.98,
//                             "raw": "€54,98",
//                             "name": "54,98 €",
//                             "asin": "B09KT9V229",
//                             "link": "https://www.amazon.de/FEUERSTEIN-K%C3%BCchenmesser-Japanische-Kochmesser-hochwertigem/dp/B09KT9V229/ref=sr_1_17?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-17"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.98,
//                         "raw": "€54,98",
//                         "name": "54,98 €",
//                         "asin": "B09KT9V229",
//                         "link": "https://www.amazon.de/FEUERSTEIN-K%C3%BCchenmesser-Japanische-Kochmesser-hochwertigem/dp/B09KT9V229/ref=sr_1_17?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-17"
//                     }
//                 },
//                 {
//                     "position": 18,
//                     "title": "ZWILLING Twin Chef Messer-Set, 3-teilig (Spick-/Garniermesser 10 cm, Kochmesser 20 cm, Fleischmesser 16 cm), Rostfreier Spezialstahl/Kunststoff-Griff im Nietendesign, Silber, Schwarz",
//                     "asin": "B0090K65QE",
//                     "link": "https://www.amazon.de/ZWILLING-34930-006-0-Messerset-Profik%C3%B6che-3-teilig/dp/B0090K65QE/ref=sr_1_18?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-18",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71gPq3jhexL._AC_UL400_.jpg",
//                     "unit_price": "20,00 €/stück",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 819,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.95,
//                             "raw": "€89,95",
//                             "name": "59,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 19,
//                     "title": "PAUDIN Messerblock mit Messer,Messerset mit Holzblock, 7-tlg Küchenmesser Set aus hochwertigem Carbon Edelstahl, 5 Profi Ultra scharfe Kochmesser, Rot Schere und Holzblock, Ergonomische Griffe",
//                     "asin": "B09W2GKLTN",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTMyODg1MjYwMjk4OjowOjo&url=%2FMesserblock-Hammerschlag-hochwertigem-K%25C3%25BCchenmesser-Ergonomische%2Fdp%2FB09W2GKLTN%2Fref%3Dsr_1_19_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-19-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71vb4bI2ntL._AC_UL400_.jpg",
//                     "bestseller": {
//                         "link": "https://www.amazon.de/gp/bestsellers/kitchen/3177942031/ref=sr_bs_18_3177942031_1",
//                         "category": "Tranchiermesser"
//                     },
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "coupon": {
//                         "badge_text": "Spare 10,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 328,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.99,
//                             "raw": "€99,99",
//                             "name": "99,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 179.99,
//                             "raw": "€179,99",
//                             "name": "99,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99.99,
//                         "raw": "€99,99",
//                         "name": "99,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 20,
//                     "title": "Sunnecko Messerset 2-TLG Küchenmesser set 20cm Kochmesser +13cm Allzweckmesser Scharf Klinge mit Hammerschlag Profi Messer Set aus Deutscher Edelstahl Küchenmesser Holzgriff inkl. edle Holzkiste",
//                     "asin": "B08MZX97L4",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTQ1ODg0MTM5Nzk4OjowOjo&url=%2FSunnecko-Messersets-K%25C3%25BCchenmesser-Allzweckmesser-Hammerschlag%2Fdp%2FB08MZX97L4%2Fref%3Dsr_1_20_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-20-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71bx51NlUqL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 237,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 55.99,
//                             "raw": "€55,99",
//                             "name": "55,99 €",
//                             "asin": "B08MZX97L4",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTQ1ODg0MTM5Nzk4OjowOjo&url=%2FSunnecko-Messersets-K%25C3%25BCchenmesser-Allzweckmesser-Hammerschlag%2Fdp%2FB08MZX97L4%2Fref%3Dsr_1_20_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-20-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 55.99,
//                         "raw": "€55,99",
//                         "name": "55,99 €",
//                         "asin": "B08MZX97L4",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTQ1ODg0MTM5Nzk4OjowOjo&url=%2FSunnecko-Messersets-K%25C3%25BCchenmesser-Allzweckmesser-Hammerschlag%2Fdp%2FB08MZX97L4%2Fref%3Dsr_1_20_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-20-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 21,
//                     "title": "HEZHEN 18,7CM Santoku Messer, Kochmesser aus Damaszenerstahl mit Hohem Kohlenstoffgehalt, Messer Japanisch Stil Elfenbeinweiß G10 Griff",
//                     "asin": "B0BN1CYZCL",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTAzNTU3MzUyNTk4OjowOjo&url=%2FHEZHEN-Santoku-Damaszenerstahl-Kohlenstoffgehalt-Elfenbeinwei%25C3%259F%2Fdp%2FB0BN1CYZCL%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71TB0BZvfIL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 3,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.99,
//                             "raw": "€99,99",
//                             "name": "99,99 €",
//                             "asin": "B0BN1CYZCL",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTAzNTU3MzUyNTk4OjowOjo&url=%2FHEZHEN-Santoku-Damaszenerstahl-Kohlenstoffgehalt-Elfenbeinwei%25C3%259F%2Fdp%2FB0BN1CYZCL%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99.99,
//                         "raw": "€99,99",
//                         "name": "99,99 €",
//                         "asin": "B0BN1CYZCL",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTAzNTU3MzUyNTk4OjowOjo&url=%2FHEZHEN-Santoku-Damaszenerstahl-Kohlenstoffgehalt-Elfenbeinwei%25C3%259F%2Fdp%2FB0BN1CYZCL%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 22,
//                     "title": "Sunnecko Nakiri messer, damast Kochmesser 18cm Chinesisches Kochmesser, damast Küchenmesser, japanisches messer, VG10 Cored & 73-Schichten Damaststahl scharf Klinge & G10 Griff - Elite Series",
//                     "asin": "B07FF1B8KB",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4NTg3MDExOTc0MzA2NjEzOjE2ODQwNzQzODc6c3BfbXRmOjIwMTI1OTg4MTQzNjk4OjowOjo&url=%2FSunnecko-Chinesisches-K%25C3%25BCchenmesser-Damastmesser-Ergonomischer%2Fdp%2FB07FF1B8KB%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DK%25C3%25BCchenmesser%26qid%3D1684074387%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71HTThM8V1L._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 1155,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 53.81,
//                             "raw": "€53,81",
//                             "name": "53,81 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 75.49,
//                             "raw": "€75,49",
//                             "name": "53,81 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 53.81,
//                         "raw": "€53,81",
//                         "name": "53,81 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 23,
//                     "title": "ZWILLING Professional S Messer-Set, 3-teilig (Spick-/Garniermesser 10 cm, Fleischmesser 16 cm, Kochmesser 20 cm), Rostfreier Spezialstahl/Kunststoff-Griff mit Nieten, Schwarz",
//                     "asin": "B0000ACOV0",
//                     "link": "https://www.amazon.de/ZWILLING-35602-000-0-Professional-Messerset-3-teilig/dp/B0000ACOV0/ref=sr_1_23?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-23",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51qQ5uuxsJL._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Top Angebot"
//                     },
//                     "unit_price": "26,66 €/stück",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 1930,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.99,
//                             "raw": "€79,99",
//                             "name": "79,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 199,
//                             "raw": "€199,00",
//                             "name": "79,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.99,
//                         "raw": "€79,99",
//                         "name": "79,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 24,
//                     "title": "ZEUß Damastmesser-Set 2er (32 cm und 24 cm) Profi-Damast-Küchenmesser Set aus japanischem 67 Lagen Damaststahl I Damastmesser Set mit Chefmesser, Santoku Messer, Pakka Holzgriff und Schleifstein",
//                     "asin": "B0838P4LKJ",
//                     "link": "https://www.amazon.de/Zeu%C3%9F-Set-K%C3%BCchenmesser-Damastmesser-Allzweckmesser/dp/B0838P4LKJ/ref=sr_1_24?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-24",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71ULwHvC8wL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 926,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.99,
//                             "raw": "€79,99",
//                             "name": "79,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.99,
//                             "raw": "€99,99",
//                             "name": "79,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.99,
//                         "raw": "€79,99",
//                         "name": "79,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 25,
//                     "title": "Wakoli EDIB Damastmesser Santoku Messer 17cm Klinge extrem scharf aus 67 Lagen I Damast Küchenmesser und Profi Kochmesser aus echtem japanischen Damaststahl mit Pakkaholz Griff",
//                     "asin": "B004LNMC30",
//                     "link": "https://www.amazon.de/Wakoli-1DM-SAN-EDB-Edip-Damastmesser-K%C3%BCchenmesser/dp/B004LNMC30/ref=sr_1_25?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-25",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71ppnZlVbTL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 1205,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "asin": "B004LNMC30",
//                             "link": "https://www.amazon.de/Wakoli-1DM-SAN-EDB-Edip-Damastmesser-K%C3%BCchenmesser/dp/B004LNMC30/ref=sr_1_25?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-25"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "asin": "B004LNMC30",
//                         "link": "https://www.amazon.de/Wakoli-1DM-SAN-EDB-Edip-Damastmesser-K%C3%BCchenmesser/dp/B004LNMC30/ref=sr_1_25?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-25"
//                     }
//                 },
//                 {
//                     "position": 26,
//                     "title": "PRUCINA® Damastmesser Model. I - Asiatisches Küchenmesser aus 67-lagigem Damaszener Stahl - Profi Kochmesser [20cm Klinge + G10 Griff + 60±2 HRC] + 1 Jahr kostenfreie Schleifung",
//                     "asin": "B08NYWV24S",
//                     "link": "https://www.amazon.de/PRUCINA%C2%AE-Damastmesser-Model-Japanisches-K%C3%BCchenmesser/dp/B08NYWV24S/ref=sr_1_26?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-26",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Oapb7j0tL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 30,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 266,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.99,
//                             "raw": "€99,99",
//                             "name": "99,99 €",
//                             "asin": "B08NYWV24S",
//                             "link": "https://www.amazon.de/PRUCINA%C2%AE-Damastmesser-Model-Japanisches-K%C3%BCchenmesser/dp/B08NYWV24S/ref=sr_1_26?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-26"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99.99,
//                         "raw": "€99,99",
//                         "name": "99,99 €",
//                         "asin": "B08NYWV24S",
//                         "link": "https://www.amazon.de/PRUCINA%C2%AE-Damastmesser-Model-Japanisches-K%C3%BCchenmesser/dp/B08NYWV24S/ref=sr_1_26?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-26"
//                     }
//                 },
//                 {
//                     "position": 27,
//                     "title": "Home Hero 17-tlg Messerset mit Messerblock Acryl - Küchenmesser Set Scharf Edelstahl - Kochmesser Profi Messer Set mit 6 Steakmesser Set, Pizza und Käse Messer, Messerschärfer, Schäler, Schere",
//                     "asin": "B075MD55N1",
//                     "link": "https://www.amazon.de/Edelstahl-Scharfes-Messerset-Messer-Block/dp/B075MD55N1/ref=sr_1_27?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-27",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91d9CweMc6L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 61747,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.99,
//                             "raw": "€56,99",
//                             "name": "56,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.99,
//                             "raw": "€64,99",
//                             "name": "56,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 56.99,
//                         "raw": "€56,99",
//                         "name": "56,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 28,
//                     "title": "TURWHO Fleischmesser Damaststahl 20cm Küchenmesser Profi Messer Scharf Damastmesser für chinesisches kochmesser Japanisches VG-10 & ergonomischer G10 Griff",
//                     "asin": "B0851JHDK7",
//                     "link": "https://www.amazon.de/TURWHO-Fleischerbeil-Japanisches-Damaststahl-Chinesisches-Kochmesser-ergonomischer/dp/B0851JHDK7/ref=sr_1_28?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-28",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71o0OiymARL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 46,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 94.99,
//                             "raw": "€94,99",
//                             "name": "94,99 €",
//                             "asin": "B0851JHDK7",
//                             "link": "https://www.amazon.de/TURWHO-Fleischerbeil-Japanisches-Damaststahl-Chinesisches-Kochmesser-ergonomischer/dp/B0851JHDK7/ref=sr_1_28?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-28"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 94.99,
//                         "raw": "€94,99",
//                         "name": "94,99 €",
//                         "asin": "B0851JHDK7",
//                         "link": "https://www.amazon.de/TURWHO-Fleischerbeil-Japanisches-Damaststahl-Chinesisches-Kochmesser-ergonomischer/dp/B0851JHDK7/ref=sr_1_28?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-28"
//                     }
//                 },
//                 {
//                     "position": 29,
//                     "title": "Wolfblood Damastmesser-Set 3er (32cm, 26cm und 20cm) Profi-Damast-Küchenmesser Set aus 67 Lagen Damaststahl I Damastmesser Set mit Chefmesser, Office Messer, Holzgriff, Geschenkbox & Klingenschutz",
//                     "asin": "B09ZBJCY9D",
//                     "link": "https://www.amazon.de/Wolfblood-Damastmesser-Set-Profi-Damast-K%C3%BCchenmesser-Damastmesser-Klingenschutz/dp/B09ZBJCY9D/ref=sr_1_29?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-29",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Y7PpA1FuL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.9,
//                     "ratings_total": 219,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.99,
//                             "raw": "€99,99",
//                             "name": "99,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 119.99,
//                             "raw": "€119,99",
//                             "name": "99,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99.99,
//                         "raw": "€99,99",
//                         "name": "99,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 30,
//                     "title": "SHAN ZU Handgeschmiedetes Japanisches Messer Küchenmesser 20cm, 7 Schichten Hochwertigem Carbon Stahl Scharf Kochmesser Profi Messer Gyuto Allzweckmesser Fleischmesser mit Ahornholzgriff",
//                     "asin": "B0BVVVZD8X",
//                     "link": "https://www.amazon.de/SHAN-Handgeschmiedetes-Allzweckmesser-Fleischmesser-Ahornholzgriff/dp/B0BVVVZD8X/ref=sr_1_30?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-30",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81QCIZw7XTL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 10 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 22,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.99,
//                             "raw": "€89,99",
//                             "name": "89,99 €",
//                             "asin": "B0BVVVZD8X",
//                             "link": "https://www.amazon.de/SHAN-Handgeschmiedetes-Allzweckmesser-Fleischmesser-Ahornholzgriff/dp/B0BVVVZD8X/ref=sr_1_30?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-30"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.99,
//                         "raw": "€89,99",
//                         "name": "89,99 €",
//                         "asin": "B0BVVVZD8X",
//                         "link": "https://www.amazon.de/SHAN-Handgeschmiedetes-Allzweckmesser-Fleischmesser-Ahornholzgriff/dp/B0BVVVZD8X/ref=sr_1_30?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-30"
//                     }
//                 },
//                 {
//                     "position": 31,
//                     "title": "AIRENA Küchenmesser Set,3-tlg Messerset aus hochwertigem Carbon Edelstahl, Profi Messer set mit 8\" Kochmesser / 7\" Japanisches Stil Messer/ 5\" Allzweckmesser - Damastmuster Klinge und Holzgriff",
//                     "asin": "B09PNFW4VD",
//                     "link": "https://www.amazon.de/AIRENA-K%C3%BCchenmesser-hochwertigem-Japanisches-Allzweckmesser/dp/B09PNFW4VD/ref=sr_1_31?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-31",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71+oN+rhI2L._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 40 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 97,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "asin": "B09PNFW4VD",
//                             "link": "https://www.amazon.de/AIRENA-K%C3%BCchenmesser-hochwertigem-Japanisches-Allzweckmesser/dp/B09PNFW4VD/ref=sr_1_31?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-31"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "asin": "B09PNFW4VD",
//                         "link": "https://www.amazon.de/AIRENA-K%C3%BCchenmesser-hochwertigem-Japanisches-Allzweckmesser/dp/B09PNFW4VD/ref=sr_1_31?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-31"
//                     }
//                 },
//                 {
//                     "position": 32,
//                     "title": "Gustrug Messerset 5-tlg Küchenmesser Set mit Ergonomischen Griff, Profi Messer Set aus Hochwertigem Carbon Edelstahl mit Kochmesser, Brotmesser, Santokumesser, Ausbeinmesser, Gemüsemesser",
//                     "asin": "B0BGFJQ2HG",
//                     "link": "https://www.amazon.de/Gustrug-K%C3%BCchenmesser-Ergonomischen-Santokumesser-Ausbeinmesser/dp/B0BGFJQ2HG/ref=sr_1_32?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-32",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71zxD5TzTgL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 72,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "asin": "B0BGFJQ2HG",
//                             "link": "https://www.amazon.de/Gustrug-K%C3%BCchenmesser-Ergonomischen-Santokumesser-Ausbeinmesser/dp/B0BGFJQ2HG/ref=sr_1_32?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-32"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "asin": "B0BGFJQ2HG",
//                         "link": "https://www.amazon.de/Gustrug-K%C3%BCchenmesser-Ergonomischen-Santokumesser-Ausbeinmesser/dp/B0BGFJQ2HG/ref=sr_1_32?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-32"
//                     }
//                 },
//                 {
//                     "position": 33,
//                     "title": "SCHNEIDWERK Santoku-Messer Damastmesser, Küchenmesser 17,6 cm Klingenlänge, 67 Lagen Edelstahl-Damast, Kochmesser Damaststahl, sehr scharf, Damaszener-Stahl Rostfrei DI-Serie",
//                     "asin": "B07QCQMBQG",
//                     "link": "https://www.amazon.de/SCHNEIDWERK-Santoku-Messer-Damastmesser-Edelstahl-Damast-Damaszener-Stahl/dp/B07QCQMBQG/ref=sr_1_33?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-33",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81sZnw3eRbL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 622,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.95,
//                             "raw": "€69,95",
//                             "name": "69,95 €",
//                             "asin": "B07QCQMBQG",
//                             "link": "https://www.amazon.de/SCHNEIDWERK-Santoku-Messer-Damastmesser-Edelstahl-Damast-Damaszener-Stahl/dp/B07QCQMBQG/ref=sr_1_33?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-33"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.95,
//                         "raw": "€69,95",
//                         "name": "69,95 €",
//                         "asin": "B07QCQMBQG",
//                         "link": "https://www.amazon.de/SCHNEIDWERK-Santoku-Messer-Damastmesser-Edelstahl-Damast-Damaszener-Stahl/dp/B07QCQMBQG/ref=sr_1_33?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-33"
//                     }
//                 },
//                 {
//                     "position": 34,
//                     "title": "Wolfblood Damastmesser-Set 2er (30cm und 24cm) Profi-Damast-Küchenmesser Set aus 67 Lagen Damaststahl & VG10 Santokumesser Klinge I Santoku-Damastmesser Set mit Holzgriff Geschenkbox & Klingenschutz",
//                     "asin": "B0987W884F",
//                     "link": "https://www.amazon.de/Wolfblood-K%C3%BCchenmesser-Santoku-Damastmesser-Allzweckmesser/dp/B0987W884F/ref=sr_1_34?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-34",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71I6TLjTjTL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 287,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.99,
//                             "raw": "€89,99",
//                             "name": "89,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.99,
//                             "raw": "€99,99",
//                             "name": "89,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.99,
//                         "raw": "€89,99",
//                         "name": "89,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 35,
//                     "title": "Wolfblood Damastmesser Santoku Messer XL(30cm) Profi-Kochmesser aus 67 Lagen Damaststahl & VG10 Allzweckmesser Klinge I Damast-Küchenmesser Santokumesser mit Holzgriff Geschenkbox und Klingenschutz",
//                     "asin": "B088HFDLPX",
//                     "link": "https://www.amazon.de/Wolfblood-K%C3%BCchenmesser-Santoku-Damastmesser-Allzweckmesser/dp/B088HFDLPX/ref=sr_1_35?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-35",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61tO5B12vtS._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.9,
//                     "ratings_total": 341,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "59,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 36,
//                     "title": "Salimago Damastmesser VG10 30cm 55 Lagen [ TÜV geprüft ] Küchenmesser scharf-Küchenmesser profi-Kochmesser Profi messer-schärfstes Messer der Welt-Messer groß-Messer Damast-Messer aus Damaststahl",
//                     "asin": "B08HRBQKBP",
//                     "link": "https://www.amazon.de/Salimago-Japanisches-Fleischmesser-K%C3%BCchenmesser-Damastmesser/dp/B08HRBQKBP/ref=sr_1_36?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-36",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/7183DMpZjpL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 212,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.51,
//                             "raw": "€56,51",
//                             "name": "56,51 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.4,
//                             "raw": "€59,40",
//                             "name": "56,51 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 56.51,
//                         "raw": "€56,51",
//                         "name": "56,51 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 37,
//                     "title": "Sunnecko Damastmesser Kochmesser 20cm, Küchenmesser aus Japanischer VG10 Cored&67-Schichten Damaststahl Klinge Küchenmesser mit Ergonomischer Griff -Sonne Serie",
//                     "asin": "B09K3YRNP5",
//                     "link": "https://www.amazon.de/Sunnecko-Damastmesser-Professionell-67-Schichten-Ergonomischer/dp/B09K3YRNP5/ref=sr_1_37?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-37",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71nQx40gKQL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 284,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 63.99,
//                             "raw": "€63,99",
//                             "name": "59,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 38,
//                     "title": "EUNA 5 PCS Küchenmessersets Professionelle scharfe Kochmessersets mit Bezug und Geschenkbox Langlebiges Kochset Set aus Küchenmessern aus Kohlenstoffstahl (grau)…",
//                     "asin": "B0873ZLPKZ",
//                     "link": "https://www.amazon.de/EUNA-K%C3%BCchenmesser-professionelles-Mehrzweckkochen-ergonomischem/dp/B0873ZLPKZ/ref=sr_1_38?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-38",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/8150qvLfUSL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 35 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 4123,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 68.99,
//                             "raw": "€68,99",
//                             "name": "68,99 €",
//                             "asin": "B0873ZLPKZ",
//                             "link": "https://www.amazon.de/EUNA-K%C3%BCchenmesser-professionelles-Mehrzweckkochen-ergonomischem/dp/B0873ZLPKZ/ref=sr_1_38?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-38"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 68.99,
//                         "raw": "€68,99",
//                         "name": "68,99 €",
//                         "asin": "B0873ZLPKZ",
//                         "link": "https://www.amazon.de/EUNA-K%C3%BCchenmesser-professionelles-Mehrzweckkochen-ergonomischem/dp/B0873ZLPKZ/ref=sr_1_38?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-38"
//                     }
//                 },
//                 {
//                     "position": 39,
//                     "title": "DAMASO Damastmesser - Damast Küchenmesser aus 67 Lagen Damaststahl, Japanisches Messer aus japanischem Damaststahl, Scharfes Kochmesser Profi Messer, Küchenmesser Damastmesser (Allzweck- & Kochmesser)",
//                     "asin": "B0BT89XNYY",
//                     "link": "https://www.amazon.de/DAMASO-Damastmesser-K%C3%BCchenmesser-Damaststahl-Japanisches/dp/B0BT89XNYY/ref=sr_1_39?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-39",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81ferHAusLL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 26,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.95,
//                             "raw": "€79,95",
//                             "name": "79,95 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.95,
//                             "raw": "€99,95",
//                             "name": "79,95 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.95,
//                         "raw": "€79,95",
//                         "name": "79,95 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 40,
//                     "title": "KIMZEN® Damastmesser Kochmesser | Black Edition | Japanisches Messer aus 67 Lagen Damaststahl | Scharf & Ausbalanciert | Damast Küchenmesser, Kochmesser Profi Messer, Küchenmesser Damastmesser",
//                     "asin": "B0BFFZPRN2",
//                     "link": "https://www.amazon.de/Damastmesser-Japanisches-Damaststahl-Ausbalanciert-K%C3%BCchenmesser/dp/B0BFFZPRN2/ref=sr_1_40?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-40",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81yklFkmLYL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 95,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "asin": "B0BFFZPRN2",
//                             "link": "https://www.amazon.de/Damastmesser-Japanisches-Damaststahl-Ausbalanciert-K%C3%BCchenmesser/dp/B0BFFZPRN2/ref=sr_1_40?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-40"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "asin": "B0BFFZPRN2",
//                         "link": "https://www.amazon.de/Damastmesser-Japanisches-Damaststahl-Ausbalanciert-K%C3%BCchenmesser/dp/B0BFFZPRN2/ref=sr_1_40?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-40"
//                     }
//                 },
//                 {
//                     "position": 41,
//                     "title": "Fiskars Küchenmesser-Set, 5-teilig, Functional Form, Inklusive Kochmesser (groß), Gemüsemesser, Tomatenmesser, Santoku Kochmesser und Brotmesser, Japanischer Edelstahl/Kunststoff, 1057558",
//                     "asin": "B08QYTJTHJ",
//                     "link": "https://www.amazon.de/Fiskars-K%C3%BCchenmesser-Set-Gem%C3%BCsemesser-Tomatenmesser-1057558/dp/B08QYTJTHJ/ref=sr_1_41_mod_primary_new?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-41",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61G-K5wEtzL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 226,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.64,
//                             "raw": "€50,64",
//                             "name": "50,64 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 61.44,
//                             "raw": "€61,44",
//                             "name": "61,44 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.64,
//                         "raw": "€50,64",
//                         "name": "50,64 €"
//                     }
//                 },
//                 {
//                     "position": 42,
//                     "title": "Wakoli EDIB Damastmesser Nakirimesser 17,50 cm Klinge extrem scharf aus 67 Lagen I Damast Küchenmesser und Profi Kochmesser aus echtem japanischen Damaststahl mit Pakkaholz Griff",
//                     "asin": "B096BLVTV3",
//                     "link": "https://www.amazon.de/Wakoli-Damastmesser-Nakirimesser-K%C3%BCchenmesser-japanischen/dp/B096BLVTV3/ref=sr_1_42?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-42",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71OzLl1bIlL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 276,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "asin": "B096BLVTV3",
//                             "link": "https://www.amazon.de/Wakoli-Damastmesser-Nakirimesser-K%C3%BCchenmesser-japanischen/dp/B096BLVTV3/ref=sr_1_42?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-42"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "asin": "B096BLVTV3",
//                         "link": "https://www.amazon.de/Wakoli-Damastmesser-Nakirimesser-K%C3%BCchenmesser-japanischen/dp/B096BLVTV3/ref=sr_1_42?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-42"
//                     }
//                 },
//                 {
//                     "position": 43,
//                     "title": "Victorinox 6 Stück Tafelmesser - Tomatenmesser Swiss Classic mit Wellenschliff rostfrei - Swiss Made - schwarz",
//                     "asin": "B00BMTWS9E",
//                     "link": "https://www.amazon.de/Victorinox-K%C3%BCchenmesser-Set-Wellenschliff-Ergonomischer-Sp%C3%BClmaschinengeeignet/dp/B00BMTWS9E/ref=sr_1_43?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-43",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71UyxzLROZL._AC_UL400_.jpg",
//                     "bestseller": {
//                         "link": "https://www.amazon.de/gp/bestsellers/kitchen/3098663031/ref=sr_bs_42_3098663031_1",
//                         "category": "Tafelmesser"
//                     },
//                     "unit_price": "4,44 €/stück",
//                     "rating": 4.9,
//                     "ratings_total": 15882,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 26.64,
//                             "raw": "€26,64",
//                             "name": "26,64 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 33,
//                             "raw": "€33,00",
//                             "name": "26,64 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 26.64,
//                         "raw": "€26,64",
//                         "name": "26,64 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 44,
//                     "title": "iPatio Japanisches Messer Küchenmesser Profi Messer, Original Japanisches Kochmesser im Damaststahl, Hackmesser Ausbeinmesser mit Lederscheide, Küchenmesser Outdoor Messer für Küchen Camping BBQ",
//                     "asin": "B09QFH2LW1",
//                     "link": "https://www.amazon.de/Japanisches-K%C3%BCchenmesser-Damaststahl-Ausbeinmesser-Lederscheide/dp/B09QFH2LW1/ref=sr_1_44?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-44",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71TsIjQpjVL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 9,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "rating": 4.4,
//                     "ratings_total": 85,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 32.99,
//                             "raw": "€32,99",
//                             "name": "32,99 €",
//                             "asin": "B09QFH2LW1",
//                             "link": "https://www.amazon.de/Japanisches-K%C3%BCchenmesser-Damaststahl-Ausbeinmesser-Lederscheide/dp/B09QFH2LW1/ref=sr_1_44?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-44"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 32.99,
//                         "raw": "€32,99",
//                         "name": "32,99 €",
//                         "asin": "B09QFH2LW1",
//                         "link": "https://www.amazon.de/Japanisches-K%C3%BCchenmesser-Damaststahl-Ausbeinmesser-Lederscheide/dp/B09QFH2LW1/ref=sr_1_44?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-44"
//                     }
//                 },
//                 {
//                     "position": 45,
//                     "title": "Feuerstein Küchenmesser set 5-Tlg, extrem scharfe Kochmesser aus hochwertigem Edelstahl, Profi Messer set mit ergonomischen Harzgriff in Geschenkbox,",
//                     "asin": "B09W1PQGZP",
//                     "link": "https://www.amazon.de/FEUERSTEIN-K%C3%BCchenmesser-set-hochwertigem-ergonomischen-Geschenkbox/dp/B09W1PQGZP/ref=sr_1_45?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-45",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Y44T2fbdL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 3,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "asin": "B09W1PQGZP",
//                             "link": "https://www.amazon.de/FEUERSTEIN-K%C3%BCchenmesser-set-hochwertigem-ergonomischen-Geschenkbox/dp/B09W1PQGZP/ref=sr_1_45?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-45"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "asin": "B09W1PQGZP",
//                         "link": "https://www.amazon.de/FEUERSTEIN-K%C3%BCchenmesser-set-hochwertigem-ergonomischen-Geschenkbox/dp/B09W1PQGZP/ref=sr_1_45?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-45"
//                     }
//                 },
//                 {
//                     "position": 46,
//                     "title": "ZEUß Damastmesser Küchenmesser XL (32cm) Profi-Kochmesser aus japanischem 67 Lagen Damaststahl und VG 10 Allzweckmesser Klinge I Damast-Küchenmesser und Chefmesser mit Holzgriff und Schleifstein",
//                     "asin": "B07N5GS93W",
//                     "link": "https://www.amazon.de/Zeu%C3%9F-K%C3%BCchenmesser-Damastmesser-32cm-Allzweckmesser/dp/B07N5GS93W/ref=sr_1_46?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-46",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/6145LhrU4WL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 1115,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "59,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 47,
//                     "title": "WMF Spitzenklasse Plus Kochmesser 34 cm, Made in Germany, Messer geschmiedet, Performance Cut, Spezialklingenstahl, Klinge 20 cm",
//                     "asin": "B00K6DQ67W",
//                     "link": "https://www.amazon.de/WMF-Spitzenklasse-Spezialklingenstahl-geschmiedet-Kunststoff-Griff/dp/B00K6DQ67W/ref=sr_1_47?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-47",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41w4DBsTYzL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 6,70 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 468,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 72.08,
//                             "raw": "€72,08",
//                             "name": "72,08 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 84.99,
//                             "raw": "€84,99",
//                             "name": "72,08 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 72.08,
//                         "raw": "€72,08",
//                         "name": "72,08 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 48,
//                     "title": "MOSFiATA Küchenmesser Set, Küchenmessersets 3 Messerset Kochmesser aus Hochwertigem Carbon Edelstahl Hackmesser Nakiri Messer Allzweckmesser mit Fingerschutz Messerschärfer und Geschenkbox",
//                     "asin": "B0BMWT7BL7",
//                     "link": "https://www.amazon.de/MOSFiATA-K%C3%BCchenmesser-K%C3%BCchenmessersets-Allzweckmesser-Messersch%C3%A4rfer/dp/B0BMWT7BL7/ref=sr_1_48?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-48",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71zFm-5hKqL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 20,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "asin": "B0BMWT7BL7",
//                             "link": "https://www.amazon.de/MOSFiATA-K%C3%BCchenmesser-K%C3%BCchenmessersets-Allzweckmesser-Messersch%C3%A4rfer/dp/B0BMWT7BL7/ref=sr_1_48?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-48"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "asin": "B0BMWT7BL7",
//                         "link": "https://www.amazon.de/MOSFiATA-K%C3%BCchenmesser-K%C3%BCchenmessersets-Allzweckmesser-Messersch%C3%A4rfer/dp/B0BMWT7BL7/ref=sr_1_48?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-48"
//                     }
//                 },
//                 {
//                     "position": 49,
//                     "title": "Wolfblood Damastmesser-Set 2er (32cm und 24cm) Profi-Damast-Küchenmesser Set aus 67 Lagen Damaststahl I Küchenmesser-Damastmesser Set mit Chefmesser Kochmesser Holzgriff Geschenkbox & Klingenschutz",
//                     "asin": "B09881NWXZ",
//                     "link": "https://www.amazon.de/Wolfblood-K%C3%BCchenmesser-Damastmesser-Echtholzgriff-Allzweckmesser/dp/B09881NWXZ/ref=sr_1_49?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-49",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61gw+N-ggyL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.9,
//                     "ratings_total": 234,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.99,
//                             "raw": "€89,99",
//                             "name": "89,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.99,
//                             "raw": "€99,99",
//                             "name": "89,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.99,
//                         "raw": "€89,99",
//                         "name": "89,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 50,
//                     "title": "Damaso Damastmesser - Damast Küchenmesser aus 67 Lagen Damaststahl, Japanisches Messer aus japanischem Damaststahl, Scharfes Kochmesser Profi Messer, Küchenmesser Damastmesser",
//                     "asin": "B0BZDQ93PJ",
//                     "link": "https://www.amazon.de/Damaso-Damastmesser-K%C3%BCchenmesser-Damaststahl-Japanisches/dp/B0BZDQ93PJ/ref=sr_1_50?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-50",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61PG+fMvFmL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.95,
//                             "raw": "€99,95",
//                             "name": "99,95 €",
//                             "asin": "B0BZDQ93PJ",
//                             "link": "https://www.amazon.de/Damaso-Damastmesser-K%C3%BCchenmesser-Damaststahl-Japanisches/dp/B0BZDQ93PJ/ref=sr_1_50?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-50"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99.95,
//                         "raw": "€99,95",
//                         "name": "99,95 €",
//                         "asin": "B0BZDQ93PJ",
//                         "link": "https://www.amazon.de/Damaso-Damastmesser-K%C3%BCchenmesser-Damaststahl-Japanisches/dp/B0BZDQ93PJ/ref=sr_1_50?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-50"
//                     }
//                 },
//                 {
//                     "position": 51,
//                     "title": "FANTECK 24cm Kiritsuke Damastmesser Kochmesser Scharf Profi Chefmesser Fleisch Obst Gyuto Allzweckmesser 67 Schichten VG-10 Damaststahl Küchenmesser[Geschenkbox] mit Ergonomischer PakkaholzGriff",
//                     "asin": "B08TBCGK5J",
//                     "link": "https://www.amazon.de/FANTECK-Damastmesser-Allzweckmesser-Ergonomischer-PakkaholzGriff/dp/B08TBCGK5J/ref=sr_1_51?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-51",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71xlOzjMX+L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 338,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.99,
//                             "raw": "€89,99",
//                             "name": "89,99 €",
//                             "asin": "B08TBCGK5J",
//                             "link": "https://www.amazon.de/FANTECK-Damastmesser-Allzweckmesser-Ergonomischer-PakkaholzGriff/dp/B08TBCGK5J/ref=sr_1_51?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-51"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.99,
//                         "raw": "€89,99",
//                         "name": "89,99 €",
//                         "asin": "B08TBCGK5J",
//                         "link": "https://www.amazon.de/FANTECK-Damastmesser-Allzweckmesser-Ergonomischer-PakkaholzGriff/dp/B08TBCGK5J/ref=sr_1_51?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-51"
//                     }
//                 },
//                 {
//                     "position": 52,
//                     "title": "Nimluxe Küchenmesser Set, 8 Teilige Scharfe Küchenmesser Set, Pakkaholzgriff rostfrei Messerset, Rostschutz/Rutschfester Kochmesser",
//                     "asin": "B097SYNWLF",
//                     "link": "https://www.amazon.de/Nimluxe-Messerset-8-teilig-8-teilig/dp/B097SYNWLF/ref=sr_1_52?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-52",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81itPggvY3S._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 20 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 3.9,
//                     "ratings_total": 59,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.99,
//                             "raw": "€89,99",
//                             "name": "89,99 €",
//                             "asin": "B097SYNWLF",
//                             "link": "https://www.amazon.de/Nimluxe-Messerset-8-teilig-8-teilig/dp/B097SYNWLF/ref=sr_1_52?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-52"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.99,
//                         "raw": "€89,99",
//                         "name": "89,99 €",
//                         "asin": "B097SYNWLF",
//                         "link": "https://www.amazon.de/Nimluxe-Messerset-8-teilig-8-teilig/dp/B097SYNWLF/ref=sr_1_52?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-52"
//                     }
//                 },
//                 {
//                     "position": 53,
//                     "title": "ZWILLING Vier Sterne Messer-Set, 3-teilig (Spick/Garniermesser 10 cm, Fleischmesser 16 cm, Kochmesser 20cm), Rostfreier Spezialstahl, Ergonomischer Kunststoff-Griff, Schwarz",
//                     "asin": "B00009QN7G",
//                     "link": "https://www.amazon.de/ZWILLING-35048-000-0-STERNE-Messerset-3-teilig/dp/B00009QN7G/ref=sr_1_53?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-53",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/613fCPpuMsL._AC_UL400_.jpg",
//                     "unit_price": "25,00 €/stück",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 5447,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.99,
//                             "raw": "€74,99",
//                             "name": "74,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 174,
//                             "raw": "€174,00",
//                             "name": "74,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 74.99,
//                         "raw": "€74,99",
//                         "name": "74,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 54,
//                     "title": "PAUDIN Küchenmesserset, 3-tlg Messerset aus hochwertigem Carbon Edelstahl, Ultra Scharfes Messer set mit Chefmesser Hackmesser Allzweckmesser, Chinesisches Kochmesser Profi Messer mit Hozl Griff",
//                     "asin": "B09V19R51H",
//                     "link": "https://www.amazon.de/PAUDIN-K%C3%BCchenmesser-hochwertigem-Allzweckmesser-Chinesisches/dp/B09V19R51H/ref=sr_1_54?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-54",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/819039oN0cL._AC_UL400_.jpg",
//                     "bestseller": {
//                         "link": "https://www.amazon.de/gp/bestsellers/kitchen/3177942031/ref=sr_bs_53_3177942031_1",
//                         "category": "Tranchiermesser"
//                     },
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "coupon": {
//                         "badge_text": "Spare 10 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 328,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.49,
//                             "raw": "€59,49",
//                             "name": "59,49 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 70.99,
//                             "raw": "€70,99",
//                             "name": "59,49 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.49,
//                         "raw": "€59,49",
//                         "name": "59,49 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 55,
//                     "title": "ZWILLING Profile Kochmesser Compact 15 cm - CHEF`S KNIFE COMPACT - Profi Kochmesser - Profi Küchenmesser - FRIODUR Klinge aus hochwertigem Messerstahl - Zwilling Messer - Zwilling Kochmesser",
//                     "asin": "B09PVJQHM7",
//                     "link": "https://www.amazon.de/ZWILLING-Profile-Kochmesser-Compact-K%C3%BCchenmesser/dp/B09PVJQHM7/ref=sr_1_55?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-55",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/11UdWg6vJ1L._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 2,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "asin": "B09PVJQHM7",
//                             "link": "https://www.amazon.de/ZWILLING-Profile-Kochmesser-Compact-K%C3%BCchenmesser/dp/B09PVJQHM7/ref=sr_1_55?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-55"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "asin": "B09PVJQHM7",
//                         "link": "https://www.amazon.de/ZWILLING-Profile-Kochmesser-Compact-K%C3%BCchenmesser/dp/B09PVJQHM7/ref=sr_1_55?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-55"
//                     }
//                 },
//                 {
//                     "position": 56,
//                     "title": "Messer set, HOBO 15 Teiliges Schwarz Küchenmesser set mit Holzblock, Messer block set mit Antihaftbeschichtung, Professionelles Küchenmesserset aus Deutschem Edelstahl mit Hohem Kohlenstoffgehalt",
//                     "asin": "B08S7TZJ7K",
//                     "link": "https://www.amazon.de/HOBO-Antihaftbeschichtung-Professionelles-K%C3%BCchenmesserset-Kohlenstoffgehalt/dp/B08S7TZJ7K/ref=sr_1_56?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-56",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61vHKPjH9OS._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 15 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 189,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 84.99,
//                             "raw": "€84,99",
//                             "name": "84,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.99,
//                             "raw": "€89,99",
//                             "name": "84,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 84.99,
//                         "raw": "€84,99",
//                         "name": "84,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 57,
//                     "title": "KEEMAKE Messerset 6 teilig, Küchenmesser set aus hochwertigem Edelstahl, Kochmesser set mit Pakkaholz Griffen,Ultra Scharfes Messer set mit Kochmesser, Brotmesser, Santokumesser,Gemüsemesser",
//                     "asin": "B09C1KRPBD",
//                     "link": "https://www.amazon.de/KEEMAKE-K%C3%BCchenmesser-Set-Kochmesser-geschmiedet-Kohlenstoffgehalt/dp/B09C1KRPBD/ref=sr_1_57?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-57",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61YKKColqRL._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 261,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 60.44,
//                             "raw": "€60,44",
//                             "name": "60,44 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 90.55,
//                             "raw": "€90,55",
//                             "name": "60,44 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 60.44,
//                         "raw": "€60,44",
//                         "name": "60,44 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 58,
//                     "title": "ZWILLING 1001567 Kochmesser, Klingenlänge: 20 cm, Breites Klingenblatt, Rostfreier Spezialstahl/Kunststoff-Griff, Vier Sterne",
//                     "asin": "B00008S0T3",
//                     "link": "https://www.amazon.de/Zwilling-31071-201-Vier-Sterne-Kochmesser/dp/B00008S0T3/ref=sr_1_58?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-58",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41B8E5rj1iL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 2486,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 49.99,
//                             "raw": "€49,99",
//                             "name": "49,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.95,
//                             "raw": "€89,95",
//                             "name": "49,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 49.99,
//                         "raw": "€49,99",
//                         "name": "49,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 59,
//                     "title": "FUJUNI Damastmesser Allzweckmesser Mit Extrem Scharf 12cm Klinge Aus 67 Lagen VG-10 Damaststahl Küchenmesser Officemesser Damast, Holzgriff, Geschenkbox",
//                     "asin": "B09ZK67GPY",
//                     "link": "https://www.amazon.de/FUJUNI-Damastmesser-Allzweckmesser-K%C3%BCchenmesser-Officemesser/dp/B09ZK67GPY/ref=sr_1_59?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-59",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71pRBVkx07L._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 20 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 276,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.9,
//                             "raw": "€59,90",
//                             "name": "59,90 €",
//                             "asin": "B09ZK67GPY",
//                             "link": "https://www.amazon.de/FUJUNI-Damastmesser-Allzweckmesser-K%C3%BCchenmesser-Officemesser/dp/B09ZK67GPY/ref=sr_1_59?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-59"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.9,
//                         "raw": "€59,90",
//                         "name": "59,90 €",
//                         "asin": "B09ZK67GPY",
//                         "link": "https://www.amazon.de/FUJUNI-Damastmesser-Allzweckmesser-K%C3%BCchenmesser-Officemesser/dp/B09ZK67GPY/ref=sr_1_59?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-59"
//                     }
//                 },
//                 {
//                     "position": 60,
//                     "title": "PAUDIN Messerset Küchenmesserset 3-tlg, Profi Messer Set aus Hochwertigem Edelstahl, Extra Scharfe Küchenmesser Set 20 cm, Hochwertige Chefmesser, Allzweckmesser und Schälmesser, ABS Griff",
//                     "asin": "B09W2LGT61",
//                     "link": "https://www.amazon.de/K%C3%BCchenmesserset-hochwertigem-K%C3%BCchenmesser-Hochwertige-Allzweckmesser/dp/B09W2LGT61/ref=sr_1_60?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-60",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71htlUYefdL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 6,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 441,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 53.99,
//                             "raw": "€53,99",
//                             "name": "53,99 €",
//                             "asin": "B09W2LGT61",
//                             "link": "https://www.amazon.de/K%C3%BCchenmesserset-hochwertigem-K%C3%BCchenmesser-Hochwertige-Allzweckmesser/dp/B09W2LGT61/ref=sr_1_60?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-60"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 53.99,
//                         "raw": "€53,99",
//                         "name": "53,99 €",
//                         "asin": "B09W2LGT61",
//                         "link": "https://www.amazon.de/K%C3%BCchenmesserset-hochwertigem-K%C3%BCchenmesser-Hochwertige-Allzweckmesser/dp/B09W2LGT61/ref=sr_1_60?keywords=K%C3%BCchenmesser&qid=1684074387&refinements=p_36%3A5000-10000&sr=8-60"
//                     }
//                 },
//                 {
//                     "position": 61,
//                     "title": "imarku Messerblock, 16-tlgs japanisches Küchenmesser-Set aus kohlenstoffreichem japanischem Edelstahl mit Spitzer– All-in-One-Messer-Set für Weihnachtsgeschenke, spülmaschinenfest",
//                     "asin": "B0BGRZM4FN",
//                     "link": "https://www.amazon.de/imarku-K%C3%BCchenmesser-Set-kohlenstoffreichem-Weihnachtsgeschenke-sp%C3%BClmaschinenfest/dp/B0BGRZM4FN/ref=sxbs_aspa_sqa?c_c=-1355623354&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B0BGRZM4FN&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-1-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71TSOuzAv3L._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 50,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.9,
//                     "ratings_total": 80,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 4
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 169.99,
//                             "raw": "€169,99",
//                             "name": "169,99 €",
//                             "asin": "B0BGRZM4FN",
//                             "link": "https://www.amazon.de/imarku-K%C3%BCchenmesser-Set-kohlenstoffreichem-Weihnachtsgeschenke-sp%C3%BClmaschinenfest/dp/B0BGRZM4FN/ref=sxbs_aspa_sqa?c_c=-1355623354&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B0BGRZM4FN&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-1-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 169.99,
//                         "raw": "€169,99",
//                         "name": "169,99 €",
//                         "asin": "B0BGRZM4FN",
//                         "link": "https://www.amazon.de/imarku-K%C3%BCchenmesser-Set-kohlenstoffreichem-Weihnachtsgeschenke-sp%C3%BClmaschinenfest/dp/B0BGRZM4FN/ref=sxbs_aspa_sqa?c_c=-1355623354&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B0BGRZM4FN&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-1-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a"
//                     }
//                 },
//                 {
//                     "position": 62,
//                     "title": "Svensbjerg Messer-Set, 5-tlg, Küchenmesser-Set, Kochmesser-Set, Chef-Messer, Scharfe Edelstahl-Messer | Küche, Obst, Gemüse, Fleisch, hochwertiges Profi Set | SB-KS101",
//                     "asin": "B083RGV4R1",
//                     "link": "https://www.amazon.de/SVENSBJERG-K%C3%BCchenmesser-Kochmesser-Messerstahl-Ergonomisch/dp/B083RGV4R1/ref=sxbs_aspa_sqa?c_c=-1355623354&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B083RGV4R1&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-3-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/812xO5VBgCL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 532,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 4
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 44.95,
//                             "raw": "€44,95",
//                             "name": "44,95 €",
//                             "asin": "B083RGV4R1",
//                             "link": "https://www.amazon.de/SVENSBJERG-K%C3%BCchenmesser-Kochmesser-Messerstahl-Ergonomisch/dp/B083RGV4R1/ref=sxbs_aspa_sqa?c_c=-1355623354&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B083RGV4R1&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-3-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 44.95,
//                         "raw": "€44,95",
//                         "name": "44,95 €",
//                         "asin": "B083RGV4R1",
//                         "link": "https://www.amazon.de/SVENSBJERG-K%C3%BCchenmesser-Kochmesser-Messerstahl-Ergonomisch/dp/B083RGV4R1/ref=sxbs_aspa_sqa?c_c=-1355623354&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B083RGV4R1&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-3-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a"
//                     }
//                 },
//                 {
//                     "position": 63,
//                     "title": "HOBO Profi Messer Set 5 teilige, Küchenmesser Set mit Edelstahlklinge aus Edelstahl, Scharfes, Rutschfester, Gefrosteter Griff - Kochmesser/Brotmesser/Santoku/Universalmesser/Gemüsemesser",
//                     "asin": "B088FC9WYS",
//                     "link": "https://www.amazon.de/HOBO-K%C3%BCchenmesser-Edelstahlklinge-Rutschfester-Gefrosteter/dp/B088FC9WYS/ref=sxbs_aspa_sqa?c_c=-1355623354&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B088FC9WYS&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-6-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61UEn--16uS._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 255,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 4
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 23.98,
//                             "raw": "€23,98",
//                             "name": "23,98 €",
//                             "asin": "B088FC9WYS",
//                             "link": "https://www.amazon.de/HOBO-K%C3%BCchenmesser-Edelstahlklinge-Rutschfester-Gefrosteter/dp/B088FC9WYS/ref=sxbs_aspa_sqa?c_c=-1355623354&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B088FC9WYS&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-6-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 23.98,
//                         "raw": "€23,98",
//                         "name": "23,98 €",
//                         "asin": "B088FC9WYS",
//                         "link": "https://www.amazon.de/HOBO-K%C3%BCchenmesser-Edelstahlklinge-Rutschfester-Gefrosteter/dp/B088FC9WYS/ref=sxbs_aspa_sqa?c_c=-1355623354&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B088FC9WYS&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-6-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a"
//                     }
//                 },
//                 {
//                     "position": 64,
//                     "title": "PAUDIN Kochmesser, Klingenlänge 20 cm Profi Küchenmesser Messer Chefmesser Allzweckmesser aus hochwertigem Carbon Edelstahl, Extra Scharfe Messerklinge mit ergonomischer Griff",
//                     "asin": "B09TZQ7GS1",
//                     "link": "https://www.amazon.de/PAUDIN-Klingenl%C3%A4nge-K%C3%BCchenmesser-Allzweckmesser-ergonomischer/dp/B09TZQ7GS1/ref=sxbs_aspa_sqa?c_c=-1355623354&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B09TZQ7GS1&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-8-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71MKRHk7ZxL._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "coupon": {
//                         "badge_text": "Spare 10 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "rating": 4.7,
//                     "ratings_total": 808,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 4
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 22.99,
//                             "raw": "€22,99",
//                             "name": "22,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 28.99,
//                             "raw": "€28,99",
//                             "name": "22,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 22.99,
//                         "raw": "€22,99",
//                         "name": "22,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 65,
//                     "title": "Schleifstein Messer 400 1000 Wetzstein Messer Schleifstein Set Abziehstein Wetzstein für Messer Messerschärfer Whetstone Sharpening Stone Messerschleifsteine Schleifstein Stechbeitel",
//                     "asin": "B098F746FR",
//                     "link": "https://www.amazon.de/Messersch%C3%A4rfer-Schleifstein-Abziehstein-Messerschleifsteine-Stechbeitel/dp/B098F746FR/ref=sxbs_aspa_sqa?c_c=-1148580505&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B098F746FR&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-2-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61Lt8HNP7eL._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "rating": 4.5,
//                     "ratings_total": 638,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 4
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 10.19,
//                             "raw": "€10,19",
//                             "name": "10,19 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 11.99,
//                             "raw": "€11,99",
//                             "name": "10,19 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 10.19,
//                         "raw": "€10,19",
//                         "name": "10,19 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 66,
//                     "title": "DDF IohEF Schleifstein, Körnung 1000/6000 Abziehstein Wetzstein Professionell 2-IN-1 Doppelseitiger Messerschärfer mit Rutschfestem Silikonhalter und Messer-Halter für Messer",
//                     "asin": "B085XR9DDQ",
//                     "link": "https://www.amazon.de/DDF-Professionell-Doppelseitiger-Messersch%C3%A4rfer-Silikonhalter/dp/B085XR9DDQ/ref=sxbs_aspa_sqa?c_c=-1148580505&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B085XR9DDQ&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-4-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61rdrr+LEKL._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "rating": 4.5,
//                     "ratings_total": 10700,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 4
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 17,
//                             "raw": "€17,00",
//                             "name": "17,00 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 23.99,
//                             "raw": "€23,99",
//                             "name": "17,00 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 17,
//                         "raw": "€17,00",
//                         "name": "17,00 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 67,
//                     "title": "Abziehsteine Schleifstein Set für Messer - Professionell Wetzstein Körnung 400/1000 3000/8000 mit Rutschfeste Bambusbasis Flachstein Winkelführung Lederstreifen",
//                     "asin": "B09Z7RDYV2",
//                     "link": "https://www.amazon.de/Abziehsteine-Schleifstein-Messer-Professionell-Winkelf%C3%BChrung/dp/B09Z7RDYV2/ref=sxbs_aspa_sqa?c_c=-1148580505&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B09Z7RDYV2&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-5-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71pcwisVraL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 848,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 4
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 34.99,
//                             "raw": "€34,99",
//                             "name": "34,99 €",
//                             "asin": "B09Z7RDYV2",
//                             "link": "https://www.amazon.de/Abziehsteine-Schleifstein-Messer-Professionell-Winkelf%C3%BChrung/dp/B09Z7RDYV2/ref=sxbs_aspa_sqa?c_c=-1148580505&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B09Z7RDYV2&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-5-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 34.99,
//                         "raw": "€34,99",
//                         "name": "34,99 €",
//                         "asin": "B09Z7RDYV2",
//                         "link": "https://www.amazon.de/Abziehsteine-Schleifstein-Messer-Professionell-Winkelf%C3%BChrung/dp/B09Z7RDYV2/ref=sxbs_aspa_sqa?c_c=-1148580505&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B09Z7RDYV2&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-5-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a"
//                     }
//                 },
//                 {
//                     "position": 68,
//                     "title": "S SATC Schleifstein Körnung 1000/6000 Wetzstein für Messer Professionell Schärfstein 2-IN-1 Messerschärfer Abziehstein Sharpening Stone mit Rutschfeste Bambusbasis und Winkelführung",
//                     "asin": "B08QR8Y4LF",
//                     "link": "https://www.amazon.de/Schleifstein-Abziehstein-Rutschfeste-Bambusbasis-Winkelf%C3%BChrung/dp/B08QR8Y4LF/ref=sxbs_aspa_sqa?c_c=-1148580505&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenmesser&keywords=K%C3%BCchenmesser&pd_rd_i=B08QR8Y4LF&pd_rd_r=d45ef8f4-b127-4f61-b30b-de919a247f90&pd_rd_w=22z2J&pd_rd_wg=ZGrKZ&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=DTNSB5WM4GZS75TPFA9H&qid=1684074387&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-7-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61dvTcDHi2L._AC_UL400_.jpg",
//                     "bestseller": {
//                         "link": "https://www.amazon.de/gp/bestsellers/diy/2077100031/ref=sr_bs_6_2077100031_1",
//                         "category": "Abziehsteine"
//                     },
//                     "rating": 4.5,
//                     "ratings_total": 281,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 4
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 13.99,
//                             "raw": "€13,99",
//                             "name": "13,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 22.99,
//                             "raw": "€22,99",
//                             "name": "13,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 13.99,
//                         "raw": "€13,99",
//                         "name": "13,99 €",
//                         "is_primary": true
//                     }
//                 }
//             ]
//         },
//         {
//             "idea": "Gourmet-Geschenkset",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "Gepp's Feinkost Geschenkkorb mit vielen Delikatessen & Geschirrtuch I Geschenkset mit Gourmet-Pesto, Pasta-Gewürz, Brotbackmischung, Bio-Olivenöl & Kräutersalz I Präsentkorb für jeden Anlass",
//                     "asin": "B0BLPQNRMG",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfYXRmOjIwMDk2MDA0OTQxNzk4OjowOjo&url=%2FGepps-Geschenkkorb-Delikatessen-Gourmet-Pesto-Brotbackmischung%2Fdp%2FB0BLPQNRMG%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/918yCf-VIUL._AC_UL400_.jpg",
//                     "unit_price": "52,99 €/stück",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 5,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 52.99,
//                             "raw": "€52,99",
//                             "name": "52,99 €",
//                             "asin": "B0BLPQNRMG",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfYXRmOjIwMDk2MDA0OTQxNzk4OjowOjo&url=%2FGepps-Geschenkkorb-Delikatessen-Gourmet-Pesto-Brotbackmischung%2Fdp%2FB0BLPQNRMG%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 52.99,
//                         "raw": "€52,99",
//                         "name": "52,99 €",
//                         "asin": "B0BLPQNRMG",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfYXRmOjIwMDk2MDA0OTQxNzk4OjowOjo&url=%2FGepps-Geschenkkorb-Delikatessen-Gourmet-Pesto-Brotbackmischung%2Fdp%2FB0BLPQNRMG%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "Delikatessen Italienisches Feinkost Gourmet Geschenkset Geschenkkorb Buongustaio ohne Alkohol - Bella Italia",
//                     "asin": "B074M88QLN",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfYXRmOjIwMDc5MDYwNTM2MjA1OjowOjo&url=%2FWeihnachten-Spezial-Italienisches-Geschenkset-Buongustaio%2Fdp%2FB074M88QLN%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81rI5aIK9QL._AC_UL400_.jpg",
//                     "unit_price": "94,90 €/stück",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 14,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 94.9,
//                             "raw": "€94,90",
//                             "name": "94,90 €",
//                             "asin": "B074M88QLN",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfYXRmOjIwMDc5MDYwNTM2MjA1OjowOjo&url=%2FWeihnachten-Spezial-Italienisches-Geschenkset-Buongustaio%2Fdp%2FB074M88QLN%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 94.9,
//                         "raw": "€94,90",
//                         "name": "94,90 €",
//                         "asin": "B074M88QLN",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfYXRmOjIwMDc5MDYwNTM2MjA1OjowOjo&url=%2FWeihnachten-Spezial-Italienisches-Geschenkset-Buongustaio%2Fdp%2FB074M88QLN%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 3,
//                     "title": "THE BASQUE Gourmet-Geschenkset - Urlaubs- und Weihnachts-Geschenkkorb mit Wein, Käse, Spargel, Extra natives Olivenöl, Piquillo-Paprika, Foie Pate, ibarra piparras, Chorizo – Deluxe Geschenkbox",
//                     "asin": "B0BNLSZMDL",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfYXRmOjIwMTAxMDM1MTc5OTk4OjowOjo&url=%2FBASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Geschenkbox%2Fdp%2FB0BNLSZMDL%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81oa2IhVZsL._AC_UL400_.jpg",
//                     "unit_price": "84,87 €/stück",
//                     "is_prime": true,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 84.87,
//                             "raw": "€84,87",
//                             "name": "Primary",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 94.87,
//                             "raw": "€94,87",
//                             "name": "Original",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 84.87,
//                         "raw": "€84,87",
//                         "name": "Primary",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 4,
//                     "title": "THE BASQUE Gourmet-Geschenkset - Urlaubs- und Weihnachts-Geschenkkorb mit Wein, Käse, Spargel, Extra natives Olivenöl, Piquillo-Paprika, Foie Pate, Chorizo, Wurst – Deluxe Geschenkbox",
//                     "asin": "B0BNLTK23X",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfYXRmOjIwMTA0MjMwNDYyOTk4OjowOjo&url=%2FBASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Geschenkbox%2Fdp%2FB0BNLTK23X%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81buBeYf7tL._AC_UL400_.jpg",
//                     "unit_price": "69,87 €/stück",
//                     "is_prime": true,
//                     "rating": 3.8,
//                     "ratings_total": 11,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.87,
//                             "raw": "€69,87",
//                             "name": "69,87 €",
//                             "asin": "B0BNLTK23X",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfYXRmOjIwMTA0MjMwNDYyOTk4OjowOjo&url=%2FBASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Geschenkbox%2Fdp%2FB0BNLTK23X%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.87,
//                         "raw": "€69,87",
//                         "name": "69,87 €",
//                         "asin": "B0BNLTK23X",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfYXRmOjIwMTA0MjMwNDYyOTk4OjowOjo&url=%2FBASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Geschenkbox%2Fdp%2FB0BNLTK23X%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 5,
//                     "title": "THE BASQUE Gourmet-Geschenkset - Urlaubs- und Weihnachts-Geschenkkorb mit Wein, Käse, Spargel, Extra natives Olivenöl, Piquillo-Paprika, Foie Pate, ibarra piparras, Chorizo – Deluxe Geschenkbox",
//                     "asin": "B0BNLSZMDL",
//                     "link": "https://www.amazon.de/BASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Geschenkbox/dp/B0BNLSZMDL/ref=sr_1_5?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-5",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81oa2IhVZsL._AC_UL400_.jpg",
//                     "unit_price": "84,87 €/stück",
//                     "is_prime": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 84.87,
//                             "raw": "€84,87",
//                             "name": "Primary",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 94.87,
//                             "raw": "€94,87",
//                             "name": "Original",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 84.87,
//                         "raw": "€84,87",
//                         "name": "Primary",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 6,
//                     "title": "THE BASQUE Gourmet-Geschenkset - Urlaubs- und Weihnachts-Geschenkkorb mit Wein, Käse, Spargel, Extra natives Olivenöl, Piquillo-Paprika, Foie Pate, Mandelgebäck, Chorizo, Wurst – Deluxe Geschenkbox",
//                     "asin": "B0BNLTWFPK",
//                     "link": "https://www.amazon.de/BASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Mandelgeb%C3%A4ck/dp/B0BNLTWFPK/ref=sr_1_6?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-6",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81GCn8W1IYL._AC_UL400_.jpg",
//                     "unit_price": "79,87 €/stück",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 2,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.87,
//                             "raw": "€79,87",
//                             "name": "79,87 €",
//                             "asin": "B0BNLTWFPK",
//                             "link": "https://www.amazon.de/BASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Mandelgeb%C3%A4ck/dp/B0BNLTWFPK/ref=sr_1_6?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-6"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.87,
//                         "raw": "€79,87",
//                         "name": "79,87 €",
//                         "asin": "B0BNLTWFPK",
//                         "link": "https://www.amazon.de/BASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Mandelgeb%C3%A4ck/dp/B0BNLTWFPK/ref=sr_1_6?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-6"
//                     }
//                 },
//                 {
//                     "position": 7,
//                     "title": "THE BASQUE Gourmet-Geschenkset - Urlaubs- und Weihnachts-Geschenkkorb mit Wein, Käse, Spargel, Extra natives Olivenöl, Piquillo-Paprika, Foie Pate, Chorizo, Wurst – Deluxe Geschenkbox",
//                     "asin": "B0BQWNHBF4",
//                     "link": "https://www.amazon.de/BASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Geschenkbox/dp/B0BQWNHBF4/ref=sr_1_7?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-7",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/911qm0+4ZVL._AC_UL400_.jpg",
//                     "unit_price": "88,97 €/stück",
//                     "rating": 4,
//                     "ratings_total": 8,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 88.97,
//                             "raw": "€88,97",
//                             "name": "88,97 €",
//                             "asin": "B0BQWNHBF4",
//                             "link": "https://www.amazon.de/BASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Geschenkbox/dp/B0BQWNHBF4/ref=sr_1_7?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-7"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 88.97,
//                         "raw": "€88,97",
//                         "name": "88,97 €",
//                         "asin": "B0BQWNHBF4",
//                         "link": "https://www.amazon.de/BASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Geschenkbox/dp/B0BQWNHBF4/ref=sr_1_7?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-7"
//                     }
//                 },
//                 {
//                     "position": 8,
//                     "title": "Italienisches Geschenkset „Verona“ | Geschenkkorb gefüllt mit Wein, italienischen Spezialitäten & edler Holzkiste | Präsentkorb für Frauen & Männer zu Geburtstag, Muttertag & Vatertag",
//                     "asin": "B07C9XVZV1",
//                     "link": "https://www.amazon.de/Italienisches-Italienischer-Geschenkkorb-Delikatessen-Spezialit%C3%A4ten/dp/B07C9XVZV1/ref=sr_1_8?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-8",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/A1BzxkbU25L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 216,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.9,
//                             "raw": "€54,90",
//                             "name": "54,90 €",
//                             "asin": "B07C9XVZV1",
//                             "link": "https://www.amazon.de/Italienisches-Italienischer-Geschenkkorb-Delikatessen-Spezialit%C3%A4ten/dp/B07C9XVZV1/ref=sr_1_8?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-8"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.9,
//                         "raw": "€54,90",
//                         "name": "54,90 €",
//                         "asin": "B07C9XVZV1",
//                         "link": "https://www.amazon.de/Italienisches-Italienischer-Geschenkkorb-Delikatessen-Spezialit%C3%A4ten/dp/B07C9XVZV1/ref=sr_1_8?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-8"
//                     }
//                 },
//                 {
//                     "position": 9,
//                     "title": "THE BASQUE Gourmet-Geschenkset - Urlaubs- und Weihnachts-Geschenkkorb mit Wein, Käse, Spargel, Extra natives Olivenöl, Piquillo-Paprika, Foie Pate, Chorizo, Wurst – Deluxe Geschenkbox",
//                     "asin": "B0BNLTK23X",
//                     "link": "https://www.amazon.de/BASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Geschenkbox/dp/B0BNLTK23X/ref=sr_1_9?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-9",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81buBeYf7tL._AC_UL400_.jpg",
//                     "unit_price": "69,87 €/stück",
//                     "is_prime": true,
//                     "rating": 3.8,
//                     "ratings_total": 11,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.87,
//                             "raw": "€69,87",
//                             "name": "69,87 €",
//                             "asin": "B0BNLTK23X",
//                             "link": "https://www.amazon.de/BASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Geschenkbox/dp/B0BNLTK23X/ref=sr_1_9?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-9"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.87,
//                         "raw": "€69,87",
//                         "name": "69,87 €",
//                         "asin": "B0BNLTK23X",
//                         "link": "https://www.amazon.de/BASQUE-Gourmet-Geschenkset-Weihnachts-Geschenkkorb-Piquillo-Paprika-Geschenkbox/dp/B0BNLTK23X/ref=sr_1_9?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-9"
//                     }
//                 },
//                 {
//                     "position": 10,
//                     "title": "Gepp's Feinkost Geschenkkorb mit vielen Delikatessen & Geschirrtuch I Geschenkset mit Gourmet-Pesto, Pasta-Gewürz, Brotbackmischung, Bio-Olivenöl & Kräutersalz I Präsentkorb für jeden Anlass",
//                     "asin": "B0BLPQNRMG",
//                     "link": "https://www.amazon.de/Gepps-Geschenkkorb-Delikatessen-Gourmet-Pesto-Brotbackmischung/dp/B0BLPQNRMG/ref=sr_1_10?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-10",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/918yCf-VIUL._AC_UL400_.jpg",
//                     "unit_price": "52,99 €/stück",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 5,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 52.99,
//                             "raw": "€52,99",
//                             "name": "52,99 €",
//                             "asin": "B0BLPQNRMG",
//                             "link": "https://www.amazon.de/Gepps-Geschenkkorb-Delikatessen-Gourmet-Pesto-Brotbackmischung/dp/B0BLPQNRMG/ref=sr_1_10?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-10"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 52.99,
//                         "raw": "€52,99",
//                         "name": "52,99 €",
//                         "asin": "B0BLPQNRMG",
//                         "link": "https://www.amazon.de/Gepps-Geschenkkorb-Delikatessen-Gourmet-Pesto-Brotbackmischung/dp/B0BLPQNRMG/ref=sr_1_10?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-10"
//                     }
//                 },
//                 {
//                     "position": 11,
//                     "title": "Delikatessen Italienisches Feinkost Gourmet Geschenkset Geschenkkorb Buongustaio ohne Alkohol - Bella Italia",
//                     "asin": "B074M88QLN",
//                     "link": "https://www.amazon.de/Weihnachten-Spezial-Italienisches-Geschenkset-Buongustaio/dp/B074M88QLN/ref=sr_1_11?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-11",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81rI5aIK9QL._AC_UL400_.jpg",
//                     "unit_price": "94,90 €/stück",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 14,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 94.9,
//                             "raw": "€94,90",
//                             "name": "94,90 €",
//                             "asin": "B074M88QLN",
//                             "link": "https://www.amazon.de/Weihnachten-Spezial-Italienisches-Geschenkset-Buongustaio/dp/B074M88QLN/ref=sr_1_11?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-11"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 94.9,
//                         "raw": "€94,90",
//                         "name": "94,90 €",
//                         "asin": "B074M88QLN",
//                         "link": "https://www.amazon.de/Weihnachten-Spezial-Italienisches-Geschenkset-Buongustaio/dp/B074M88QLN/ref=sr_1_11?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-11"
//                     }
//                 },
//                 {
//                     "position": 12,
//                     "title": "Schwarzwald Metzgerei - Geschenkset \"Brotzeit\" mit verschiedenen Wurtsspezialitäten, einem Gourmet-Senf und hervorragendem Williams Schnaps - 9-teilig",
//                     "asin": "B00Q3ETZFI",
//                     "link": "https://www.amazon.de/Schwarzwald-Metzgerei-verschiedenen-Wurtsspezialit%C3%A4ten-hervorragendem/dp/B00Q3ETZFI/ref=sr_1_12?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-12",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91G0JSBcXtL._AC_UL400_.jpg",
//                     "unit_price": "55,90 €/stück",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 282,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 55.9,
//                             "raw": "€55,90",
//                             "name": "55,90 €",
//                             "asin": "B00Q3ETZFI",
//                             "link": "https://www.amazon.de/Schwarzwald-Metzgerei-verschiedenen-Wurtsspezialit%C3%A4ten-hervorragendem/dp/B00Q3ETZFI/ref=sr_1_12?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-12"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 55.9,
//                         "raw": "€55,90",
//                         "name": "55,90 €",
//                         "asin": "B00Q3ETZFI",
//                         "link": "https://www.amazon.de/Schwarzwald-Metzgerei-verschiedenen-Wurtsspezialit%C3%A4ten-hervorragendem/dp/B00Q3ETZFI/ref=sr_1_12?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-12"
//                     }
//                 },
//                 {
//                     "position": 13,
//                     "title": "Bayern Geschenkkorb „München“ für Männer & Frauen | Bier Geschenkset mit bayerischen Wurst Spezialitäten zum Geburtstag, Vatertag",
//                     "asin": "B07V4GRV98",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDMwNTUxMDE2MzA3OjowOjo&url=%2FGeschenkset-Geschenkkorb-Bierglas-bayrischen-Spezialit%25C3%25A4ten%2Fdp%2FB07V4GRV98%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/816DfPGDRtL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 55,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.9,
//                             "raw": "€59,90",
//                             "name": "59,90 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 62.9,
//                             "raw": "€62,90",
//                             "name": "59,90 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.9,
//                         "raw": "€59,90",
//                         "name": "59,90 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 14,
//                     "title": "Gepp’s Feinkost Geschenkset „Filztasche Brotzeit“ I Filztasche mit Spezialitäten wie Brotzeit-Gewürz, Brotbackmischung, Obazda-Gewürz, Grissini & Brotzeitbrett I Geschenkidee für Gourmet Brotzeit",
//                     "asin": "B0B7S9YW9Y",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDcxNjA1MzcyNDk4OjowOjo&url=%2FGepps-Spezialit%25C3%25A4ten-Brotzeit-Gew%25C3%25BCrz-Brotbackmischung-Obazda-Gew%25C3%25BCrz%2Fdp%2FB0B7S9YW9Y%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/918c1H9-AhL._AC_UL400_.jpg",
//                     "unit_price": "54,99 €/stück",
//                     "is_prime": true,
//                     "rating": 3.9,
//                     "ratings_total": 30,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "54,99 €",
//                             "asin": "B0B7S9YW9Y",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDcxNjA1MzcyNDk4OjowOjo&url=%2FGepps-Spezialit%25C3%25A4ten-Brotzeit-Gew%25C3%25BCrz-Brotbackmischung-Obazda-Gew%25C3%25BCrz%2Fdp%2FB0B7S9YW9Y%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.99,
//                         "raw": "€54,99",
//                         "name": "54,99 €",
//                         "asin": "B0B7S9YW9Y",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDcxNjA1MzcyNDk4OjowOjo&url=%2FGepps-Spezialit%25C3%25A4ten-Brotzeit-Gew%25C3%25BCrz-Brotbackmischung-Obazda-Gew%25C3%25BCrz%2Fdp%2FB0B7S9YW9Y%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 15,
//                     "title": "Italienisches Geschenkset „Verona“ | Geschenkkorb gefüllt mit Wein, italienischen Spezialitäten & edler Holzkiste | Präsentkorb für Frauen & Männer zu Geburtstag, Muttertag & Vatertag",
//                     "asin": "B07C9XVZV1",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDE4OTk3MDc0NjA3OjowOjo&url=%2FItalienisches-Italienischer-Geschenkkorb-Delikatessen-Spezialit%25C3%25A4ten%2Fdp%2FB07C9XVZV1%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/A1BzxkbU25L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 216,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.9,
//                             "raw": "€54,90",
//                             "name": "54,90 €",
//                             "asin": "B07C9XVZV1",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDE4OTk3MDc0NjA3OjowOjo&url=%2FItalienisches-Italienischer-Geschenkkorb-Delikatessen-Spezialit%25C3%25A4ten%2Fdp%2FB07C9XVZV1%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.9,
//                         "raw": "€54,90",
//                         "name": "54,90 €",
//                         "asin": "B07C9XVZV1",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDE4OTk3MDc0NjA3OjowOjo&url=%2FItalienisches-Italienischer-Geschenkkorb-Delikatessen-Spezialit%25C3%25A4ten%2Fdp%2FB07C9XVZV1%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 16,
//                     "title": "Italienisches Geschenkset „Florenz“ | Großer Geschenkkorb mit Wein, Olivenöl & vielen Spezialitäten aus Italien | Präsentkorb für Frauen & Männer zum Geburtstag, Muttertag, Vatertag",
//                     "asin": "B07C9X7SV4",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDIwMTkwNTg1MTA3OjowOjo&url=%2FItalienisches-Geschenkkorb-Delikatessen-Italienische-Spezialit%25C3%25A4ten%2Fdp%2FB07C9X7SV4%2Fref%3Dsr_1_16_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-16-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91sPyinNm1L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 90,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 72.9,
//                             "raw": "€72,90",
//                             "name": "72,90 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.9,
//                             "raw": "€74,90",
//                             "name": "72,90 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 72.9,
//                         "raw": "€72,90",
//                         "name": "72,90 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 17,
//                     "title": "Gepp’s Feinkost Geschenkset „Filztasche Brotzeit“ I Filztasche mit Spezialitäten wie Brotzeit-Gewürz, Brotbackmischung, Obazda-Gewürz, Grissini & Brotzeitbrett I Geschenkidee für Gourmet Brotzeit",
//                     "asin": "B0B7S9YW9Y",
//                     "link": "https://www.amazon.de/Gepps-Spezialit%C3%A4ten-Brotzeit-Gew%C3%BCrz-Brotbackmischung-Obazda-Gew%C3%BCrz/dp/B0B7S9YW9Y/ref=sr_1_17?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-17",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/918c1H9-AhL._AC_UL400_.jpg",
//                     "unit_price": "54,99 €/stück",
//                     "is_prime": true,
//                     "rating": 3.9,
//                     "ratings_total": 30,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "54,99 €",
//                             "asin": "B0B7S9YW9Y",
//                             "link": "https://www.amazon.de/Gepps-Spezialit%C3%A4ten-Brotzeit-Gew%C3%BCrz-Brotbackmischung-Obazda-Gew%C3%BCrz/dp/B0B7S9YW9Y/ref=sr_1_17?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-17"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.99,
//                         "raw": "€54,99",
//                         "name": "54,99 €",
//                         "asin": "B0B7S9YW9Y",
//                         "link": "https://www.amazon.de/Gepps-Spezialit%C3%A4ten-Brotzeit-Gew%C3%BCrz-Brotbackmischung-Obazda-Gew%C3%BCrz/dp/B0B7S9YW9Y/ref=sr_1_17?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-17"
//                     }
//                 },
//                 {
//                     "position": 18,
//                     "title": "Hallingers Geschenk-Set mit Tafel Schokolade, Pralinen, Nougat-Mandeln und Tee (290g) - Groß No. 01 (Bundle) - Weihnachten 2023, Für Sie#Für Ihn - Geschenk zu Mutter-/Vatertag 2023",
//                     "asin": "B0742PKRNP",
//                     "link": "https://www.amazon.de/Hallingers-Geschenk-Set-Schokolade-Pralinen-Mandeln/dp/B0742PKRNP/ref=sr_1_18?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-18",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/613LFnuD8sL._AC_UL400_.jpg",
//                     "unit_price": "54,99 €/stück",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 49,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "54,99 €",
//                             "asin": "B0742PKRNP",
//                             "link": "https://www.amazon.de/Hallingers-Geschenk-Set-Schokolade-Pralinen-Mandeln/dp/B0742PKRNP/ref=sr_1_18?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-18"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.99,
//                         "raw": "€54,99",
//                         "name": "54,99 €",
//                         "asin": "B0742PKRNP",
//                         "link": "https://www.amazon.de/Hallingers-Geschenk-Set-Schokolade-Pralinen-Mandeln/dp/B0742PKRNP/ref=sr_1_18?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-18"
//                     }
//                 },
//                 {
//                     "position": 19,
//                     "title": "Hallingers Weihnachts-Geschenk-Set in edler Box (950g) - Stollen & More (Bundle) - Weihnachten 2023, Für Sie#Für Ihn - Geschenk zu Mutter-/Vatertag 2023",
//                     "asin": "B082443HCC",
//                     "link": "https://www.amazon.de/Hallingers-Weihnachts-Geschenk-Set-edler-Box-950g/dp/B082443HCC/ref=sr_1_19?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-19",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/716R86-Q2WL._AC_UL400_.jpg",
//                     "unit_price": "89,46 €/kg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 6,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 84.99,
//                             "raw": "€84,99",
//                             "name": "84,99 €",
//                             "asin": "B082443HCC",
//                             "link": "https://www.amazon.de/Hallingers-Weihnachts-Geschenk-Set-edler-Box-950g/dp/B082443HCC/ref=sr_1_19?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-19"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 84.99,
//                         "raw": "€84,99",
//                         "name": "84,99 €",
//                         "asin": "B082443HCC",
//                         "link": "https://www.amazon.de/Hallingers-Weihnachts-Geschenk-Set-edler-Box-950g/dp/B082443HCC/ref=sr_1_19?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-19"
//                     }
//                 },
//                 {
//                     "position": 20,
//                     "title": "BAVAREGOLA Südtirol Geschenkekiste mit 7 Südtiroler Spezialitäten - Präsentkorb gefüllt mit Speck, Käse, Salami & Schinken von Viktor Kofler/Lana - Genuss Geschenk für Männer & Frauen",
//                     "asin": "B08W8DF734",
//                     "link": "https://www.amazon.de/S%C3%BCdtirol-Geschenkkiste-Genuss-Viktor-Kofler-Speck/dp/B08W8DF734/ref=sr_1_20?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-20",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71bDv1eIh+L._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 10 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "unit_price": "58,99 €/stück",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 313,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 58.99,
//                             "raw": "€58,99",
//                             "name": "58,99 €",
//                             "asin": "B08W8DF734",
//                             "link": "https://www.amazon.de/S%C3%BCdtirol-Geschenkkiste-Genuss-Viktor-Kofler-Speck/dp/B08W8DF734/ref=sr_1_20?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-20"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 58.99,
//                         "raw": "€58,99",
//                         "name": "58,99 €",
//                         "asin": "B08W8DF734",
//                         "link": "https://www.amazon.de/S%C3%BCdtirol-Geschenkkiste-Genuss-Viktor-Kofler-Speck/dp/B08W8DF734/ref=sr_1_20?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-20"
//                     }
//                 },
//                 {
//                     "position": 21,
//                     "title": "Schwarzwald Metzgerei Geschenkkorb „Nostalgie – 6-teiliger Präsentkorb mit leckeren & herzhaften Wurstspezialitäten wie Schwarzwälder Schinken oder Landjäger, Schneidebrett & -messer und Gourmet-Senf",
//                     "asin": "B07KDYLCTY",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDE1MjQzMjUxMzk4OjowOjo&url=%2FSchwarzwald-Metzgerei-verschiedenen-Wurstspezialit%25C3%25A4ten-Schneidebrett%2Fdp%2FB07KDYLCTY%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81ZmBumvuTL._AC_UL400_.jpg",
//                     "unit_price": "54,90 €/stück",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 213,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.9,
//                             "raw": "€54,90",
//                             "name": "54,90 €",
//                             "asin": "B07KDYLCTY",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDE1MjQzMjUxMzk4OjowOjo&url=%2FSchwarzwald-Metzgerei-verschiedenen-Wurstspezialit%25C3%25A4ten-Schneidebrett%2Fdp%2FB07KDYLCTY%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.9,
//                         "raw": "€54,90",
//                         "name": "54,90 €",
//                         "asin": "B07KDYLCTY",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDE1MjQzMjUxMzk4OjowOjo&url=%2FSchwarzwald-Metzgerei-verschiedenen-Wurstspezialit%25C3%25A4ten-Schneidebrett%2Fdp%2FB07KDYLCTY%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 22,
//                     "title": "Schwarzwald Metzgerei - Geschenkset \"Brotzeit\" mit verschiedenen Wurtsspezialitäten, einem Gourmet-Senf und hervorragendem Williams Schnaps - 9-teilig",
//                     "asin": "B00Q3ETZFI",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDE1MjMyODM3Njk4OjowOjo&url=%2FSchwarzwald-Metzgerei-verschiedenen-Wurtsspezialit%25C3%25A4ten-hervorragendem%2Fdp%2FB00Q3ETZFI%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91G0JSBcXtL._AC_UL400_.jpg",
//                     "unit_price": "55,90 €/stück",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 282,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 55.9,
//                             "raw": "€55,90",
//                             "name": "55,90 €",
//                             "asin": "B00Q3ETZFI",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDE1MjMyODM3Njk4OjowOjo&url=%2FSchwarzwald-Metzgerei-verschiedenen-Wurtsspezialit%25C3%25A4ten-hervorragendem%2Fdp%2FB00Q3ETZFI%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 55.9,
//                         "raw": "€55,90",
//                         "name": "55,90 €",
//                         "asin": "B00Q3ETZFI",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDE1MjMyODM3Njk4OjowOjo&url=%2FSchwarzwald-Metzgerei-verschiedenen-Wurtsspezialit%25C3%25A4ten-hervorragendem%2Fdp%2FB00Q3ETZFI%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 23,
//                     "title": "Geschenkset Nizza | Geschenkkorb gefüllt mit Sekt Brut Chardonnay, Lindt Pralinen & Holzkiste | Schokoladen Präsentkorb für Frauen & Männer zur Hochzeit, Geburtstag, Dankeschön",
//                     "asin": "B07QNS5T7G",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDI2NzA3NjA2MzA3OjowOjo&url=%2FGeschenkset-Geschenkkorb-Holzkiste-Chardonnay-Pralinen%2Fdp%2FB07QNS5T7G%2Fref%3Dsr_1_23_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-23-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81-cQRGRJgL._AC_UL400_.jpg",
//                     "unit_price": "54,90 €/stück",
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 78,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.9,
//                             "raw": "€54,90",
//                             "name": "54,90 €",
//                             "asin": "B07QNS5T7G",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDI2NzA3NjA2MzA3OjowOjo&url=%2FGeschenkset-Geschenkkorb-Holzkiste-Chardonnay-Pralinen%2Fdp%2FB07QNS5T7G%2Fref%3Dsr_1_23_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-23-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.9,
//                         "raw": "€54,90",
//                         "name": "54,90 €",
//                         "asin": "B07QNS5T7G",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDI2NzA3NjA2MzA3OjowOjo&url=%2FGeschenkset-Geschenkkorb-Holzkiste-Chardonnay-Pralinen%2Fdp%2FB07QNS5T7G%2Fref%3Dsr_1_23_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-23-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 24,
//                     "title": "PREMIUM GESCHENKSET - DAS BESTE AUS KROATIEN - DINGAC ROTWEIN, KÄSE VON DER INSEL PAG, KONFIN OLIVENÖL UND SCHOKOLADE AUS RAB verpackt in einer edlen Box",
//                     "asin": "B0BLHXZS9R",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDkxOTkyMDY3NDk4OjowOjo&url=%2FPREMIUM-GESCHENKSET-KROATIEN-OLIVEN%25C3%2596L-SCHOKOLADE%2Fdp%2FB0BLHXZS9R%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/719LFIV4eeL._AC_UL400_.jpg",
//                     "unit_price": "79,00 €/stück",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 3,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79,
//                             "raw": "€79,00",
//                             "name": "79,00 €",
//                             "asin": "B0BLHXZS9R",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDkxOTkyMDY3NDk4OjowOjo&url=%2FPREMIUM-GESCHENKSET-KROATIEN-OLIVEN%25C3%2596L-SCHOKOLADE%2Fdp%2FB0BLHXZS9R%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79,
//                         "raw": "€79,00",
//                         "name": "79,00 €",
//                         "asin": "B0BLHXZS9R",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTY1NzY5NzI3MzA3NTg4OjE2ODQwNzQzOTQ6c3BfbXRmOjIwMDkxOTkyMDY3NDk4OjowOjo&url=%2FPREMIUM-GESCHENKSET-KROATIEN-OLIVEN%25C3%2596L-SCHOKOLADE%2Fdp%2FB0BLHXZS9R%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DGourmet-Geschenkset%26qid%3D1684074394%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 25,
//                     "title": "BAVAREGOLA Geschenkekiste mit Südtiroler Spezialitäten die \"6er Marende\" - Präsentkorb gefüllt mit Speck, Käse, Salami & Schinken von Viktor Kofler/Lana - Genuss Geschenk für Männer & Frauen",
//                     "asin": "B08W8PPJFV",
//                     "link": "https://www.amazon.de/Geschenkekiste-mit-S%C3%BCdtiroler-Spezialit%C3%A4ten-Marende/dp/B08W8PPJFV/ref=sr_1_25?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-25",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71uKdka7hCL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 10 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "unit_price": "57,99 €/stück",
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 108,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 57.99,
//                             "raw": "€57,99",
//                             "name": "57,99 €",
//                             "asin": "B08W8PPJFV",
//                             "link": "https://www.amazon.de/Geschenkekiste-mit-S%C3%BCdtiroler-Spezialit%C3%A4ten-Marende/dp/B08W8PPJFV/ref=sr_1_25?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-25"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 57.99,
//                         "raw": "€57,99",
//                         "name": "57,99 €",
//                         "asin": "B08W8PPJFV",
//                         "link": "https://www.amazon.de/Geschenkekiste-mit-S%C3%BCdtiroler-Spezialit%C3%A4ten-Marende/dp/B08W8PPJFV/ref=sr_1_25?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-25"
//                     }
//                 },
//                 {
//                     "position": 26,
//                     "title": "Rivio Premium Foods | Geschenkset \"Trüffel\" | Präsentkorb mit Trüffel-Spezialitäten | in der \"Rivio\"-Geschenkverpackung | für Liebhaber von Trüffelprodukten | ideal für Kochbegeisterte",
//                     "asin": "B0C19JL79P",
//                     "link": "https://www.amazon.de/Rivio-Tr%C3%BCffel-Spezialit%C3%A4ten-Geschenkverpackung-Tr%C3%BCffelprodukten-Kochbegeisterte/dp/B0C19JL79P/ref=sr_1_26?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-26",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61HJm1hBs7L._AC_UL400_.jpg",
//                     "unit_price": "59,90 €/stück",
//                     "is_prime": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.9,
//                             "raw": "€59,90",
//                             "asin": "B0C19JL79P",
//                             "link": "https://www.amazon.de/Rivio-Tr%C3%BCffel-Spezialit%C3%A4ten-Geschenkverpackung-Tr%C3%BCffelprodukten-Kochbegeisterte/dp/B0C19JL79P/ref=sr_1_26?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-26"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.9,
//                         "raw": "€59,90",
//                         "asin": "B0C19JL79P",
//                         "link": "https://www.amazon.de/Rivio-Tr%C3%BCffel-Spezialit%C3%A4ten-Geschenkverpackung-Tr%C3%BCffelprodukten-Kochbegeisterte/dp/B0C19JL79P/ref=sr_1_26?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-26"
//                     }
//                 },
//                 {
//                     "position": 27,
//                     "title": "Feinkost Geschenkkorb SAPORI D'ITALIA DELUXE mit ausgesuchten italienischen Delikatessen Spezialitäten - Geschenkset Italien",
//                     "asin": "B01MRQIOPN",
//                     "link": "https://www.amazon.de/Geschenkkorb-DITALIA-ausgesuchten-italienischen-Spezialit%C3%A4ten/dp/B01MRQIOPN/ref=sr_1_27?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-27",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81TFPXcXNVL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 36,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 93.49,
//                             "raw": "€93,49",
//                             "name": "93,49 €",
//                             "asin": "B01MRQIOPN",
//                             "link": "https://www.amazon.de/Geschenkkorb-DITALIA-ausgesuchten-italienischen-Spezialit%C3%A4ten/dp/B01MRQIOPN/ref=sr_1_27?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-27"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 93.49,
//                         "raw": "€93,49",
//                         "name": "93,49 €",
//                         "asin": "B01MRQIOPN",
//                         "link": "https://www.amazon.de/Geschenkkorb-DITALIA-ausgesuchten-italienischen-Spezialit%C3%A4ten/dp/B01MRQIOPN/ref=sr_1_27?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-27"
//                     }
//                 },
//                 {
//                     "position": 28,
//                     "title": "Schwarzwald Metzgerei: Geschenkkorb \"Holzkiste\" mit ausgewählten Wurst- und Schinken-Delikatessen – Präsentkorb mit Schwarzwälder Schinken, Feigensenf und Weißwein als Geschenk für jeden Anlass",
//                     "asin": "B00NS10EWW",
//                     "link": "https://www.amazon.de/Schwarzwald-Metzgerei-Holzkiste-Pr%C3%A4sentkiste-Schinkenspezialit%C3%A4ten/dp/B00NS10EWW/ref=sr_1_28?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-28",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81HoZQAvEUL._AC_UL400_.jpg",
//                     "unit_price": "63,90 €/stück",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 119,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 63.9,
//                             "raw": "€63,90",
//                             "name": "63,90 €",
//                             "asin": "B00NS10EWW",
//                             "link": "https://www.amazon.de/Schwarzwald-Metzgerei-Holzkiste-Pr%C3%A4sentkiste-Schinkenspezialit%C3%A4ten/dp/B00NS10EWW/ref=sr_1_28?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-28"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 63.9,
//                         "raw": "€63,90",
//                         "name": "63,90 €",
//                         "asin": "B00NS10EWW",
//                         "link": "https://www.amazon.de/Schwarzwald-Metzgerei-Holzkiste-Pr%C3%A4sentkiste-Schinkenspezialit%C3%A4ten/dp/B00NS10EWW/ref=sr_1_28?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-28"
//                     }
//                 },
//                 {
//                     "position": 29,
//                     "title": "PREMIUM GESCHENKSET - DAS BESTE AUS KROATIEN - DINGAC ROTWEIN, KÄSE VON DER INSEL PAG, KONFIN OLIVENÖL UND SCHOKOLADE AUS RAB verpackt in einer edlen Box",
//                     "asin": "B0BLHXZS9R",
//                     "link": "https://www.amazon.de/PREMIUM-GESCHENKSET-KROATIEN-OLIVEN%C3%96L-SCHOKOLADE/dp/B0BLHXZS9R/ref=sr_1_29?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-29",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/719LFIV4eeL._AC_UL400_.jpg",
//                     "unit_price": "79,00 €/stück",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 3,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79,
//                             "raw": "€79,00",
//                             "name": "79,00 €",
//                             "asin": "B0BLHXZS9R",
//                             "link": "https://www.amazon.de/PREMIUM-GESCHENKSET-KROATIEN-OLIVEN%C3%96L-SCHOKOLADE/dp/B0BLHXZS9R/ref=sr_1_29?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-29"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79,
//                         "raw": "€79,00",
//                         "name": "79,00 €",
//                         "asin": "B0BLHXZS9R",
//                         "link": "https://www.amazon.de/PREMIUM-GESCHENKSET-KROATIEN-OLIVEN%C3%96L-SCHOKOLADE/dp/B0BLHXZS9R/ref=sr_1_29?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-29"
//                     }
//                 },
//                 {
//                     "position": 30,
//                     "title": "Gourmet-Tapas-Geschenkkorb aus Spanien - Rebhuhn- und Wildpastete + Räucherkäse + 3 Stücke Chorizo + Natives Olivenöl Extra + Handwerkliches Brot + Käseutensilien-Set",
//                     "asin": "B0BLPXK6XX",
//                     "link": "https://www.amazon.de/Gourmet-Tapas-Geschenkkorb-aus-Spanien-Handwerkliches-K%C3%A4seutensilien-Set/dp/B0BLPXK6XX/ref=sr_1_30?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-30",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71oE6ByPC1L._AC_UL400_.jpg",
//                     "unit_price": "45,00 €/kg",
//                     "is_prime": true,
//                     "rating": 3.6,
//                     "ratings_total": 4,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54,
//                             "raw": "€54,00",
//                             "name": "54,00 €",
//                             "asin": "B0BLPXK6XX",
//                             "link": "https://www.amazon.de/Gourmet-Tapas-Geschenkkorb-aus-Spanien-Handwerkliches-K%C3%A4seutensilien-Set/dp/B0BLPXK6XX/ref=sr_1_30?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-30"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54,
//                         "raw": "€54,00",
//                         "name": "54,00 €",
//                         "asin": "B0BLPXK6XX",
//                         "link": "https://www.amazon.de/Gourmet-Tapas-Geschenkkorb-aus-Spanien-Handwerkliches-K%C3%A4seutensilien-Set/dp/B0BLPXK6XX/ref=sr_1_30?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-30"
//                     }
//                 },
//                 {
//                     "position": 31,
//                     "title": "Feinkost-Präsentkorb Gourmet mit spanischen Delikatessen - Geschenkkorb für Feinschmecker & Freunde der mediterranen Küche",
//                     "asin": "B06WP9VLV7",
//                     "link": "https://www.amazon.de/Feinkost-Pr%C3%A4sentkorb-Gourmet-spanischen-Delikatessen-Feinschmecker/dp/B06WP9VLV7/ref=sr_1_31?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-31",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71UrH2TPXqL._AC_UL400_.jpg",
//                     "unit_price": "89,90 €/stück",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 117,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.9,
//                             "raw": "€89,90",
//                             "name": "89,90 €",
//                             "asin": "B06WP9VLV7",
//                             "link": "https://www.amazon.de/Feinkost-Pr%C3%A4sentkorb-Gourmet-spanischen-Delikatessen-Feinschmecker/dp/B06WP9VLV7/ref=sr_1_31?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-31"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.9,
//                         "raw": "€89,90",
//                         "name": "89,90 €",
//                         "asin": "B06WP9VLV7",
//                         "link": "https://www.amazon.de/Feinkost-Pr%C3%A4sentkorb-Gourmet-spanischen-Delikatessen-Feinschmecker/dp/B06WP9VLV7/ref=sr_1_31?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-31"
//                     }
//                 },
//                 {
//                     "position": 32,
//                     "title": "Italienisches Geschenkset „Pisa“ | Großer Geschenkkorb mit Pasta, Olivenöl & vielen Spezialitäten aus Italien | Präsentkorb für Frauen & Männer zum Geburtstag, Muttertag & Vatertag",
//                     "asin": "B076HXP1YC",
//                     "link": "https://www.amazon.de/Italienisches-Geschenkset-Geschenkkorb-italienischen-Spezialit%C3%A4ten/dp/B076HXP1YC/ref=sr_1_32?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-32",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81y7rvlnhAL._AC_UL400_.jpg",
//                     "unit_price": "72,90 €/stück",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 40,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 72.9,
//                             "raw": "€72,90",
//                             "name": "72,90 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.9,
//                             "raw": "€74,90",
//                             "name": "72,90 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 72.9,
//                         "raw": "€72,90",
//                         "name": "72,90 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 33,
//                     "title": "Präsentkorb \"Viva\" mit spanischen Delikatessen | Dekorative Geschenk-Box mit ausgewählten spanischen Spezialitäten | Feinkost-Geschenk",
//                     "asin": "B01C8HK9Z8",
//                     "link": "https://www.amazon.de/Pr%C3%A4sentkorb-Delikatessen-Geschenk-Box-ausgew%C3%A4hlten-Spezialit%C3%A4ten/dp/B01C8HK9Z8/ref=sr_1_33?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-33",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81aK4qn+DLL._AC_UL400_.jpg",
//                     "unit_price": "57,90 €/stück",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 241,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 57.9,
//                             "raw": "€57,90",
//                             "name": "57,90 €",
//                             "asin": "B01C8HK9Z8",
//                             "link": "https://www.amazon.de/Pr%C3%A4sentkorb-Delikatessen-Geschenk-Box-ausgew%C3%A4hlten-Spezialit%C3%A4ten/dp/B01C8HK9Z8/ref=sr_1_33?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-33"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 57.9,
//                         "raw": "€57,90",
//                         "name": "57,90 €",
//                         "asin": "B01C8HK9Z8",
//                         "link": "https://www.amazon.de/Pr%C3%A4sentkorb-Delikatessen-Geschenk-Box-ausgew%C3%A4hlten-Spezialit%C3%A4ten/dp/B01C8HK9Z8/ref=sr_1_33?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-33"
//                     }
//                 },
//                 {
//                     "position": 34,
//                     "title": "Schwarzwald Metzgerei Geschenkkorb „Nostalgie – 6-teiliger Präsentkorb mit leckeren & herzhaften Wurstspezialitäten wie Schwarzwälder Schinken oder Landjäger, Schneidebrett & -messer und Gourmet-Senf",
//                     "asin": "B07KDYLCTY",
//                     "link": "https://www.amazon.de/Schwarzwald-Metzgerei-verschiedenen-Wurstspezialit%C3%A4ten-Schneidebrett/dp/B07KDYLCTY/ref=sr_1_34?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-34",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81ZmBumvuTL._AC_UL400_.jpg",
//                     "unit_price": "54,90 €/stück",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 213,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.9,
//                             "raw": "€54,90",
//                             "name": "54,90 €",
//                             "asin": "B07KDYLCTY",
//                             "link": "https://www.amazon.de/Schwarzwald-Metzgerei-verschiedenen-Wurstspezialit%C3%A4ten-Schneidebrett/dp/B07KDYLCTY/ref=sr_1_34?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-34"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.9,
//                         "raw": "€54,90",
//                         "name": "54,90 €",
//                         "asin": "B07KDYLCTY",
//                         "link": "https://www.amazon.de/Schwarzwald-Metzgerei-verschiedenen-Wurstspezialit%C3%A4ten-Schneidebrett/dp/B07KDYLCTY/ref=sr_1_34?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-34"
//                     }
//                 },
//                 {
//                     "position": 35,
//                     "title": "Almgourmet - Geschenkset \"Brotzeit\" mit 7 vers. Tiroler Schinken & Käse-Spezialitäten sowie Marillenschnaps - Ansprechend verpackt",
//                     "asin": "B09ZF88PFJ",
//                     "link": "https://www.amazon.de/Almgourmet-Geschenkset-K%C3%A4se-Spezialit%C3%A4ten-sowie-Marillenschnaps-Ansprechend-verpackt/dp/B09ZF88PFJ/ref=sr_1_35?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-35",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61z2QW-G34L._AC_UL400_.jpg",
//                     "unit_price": "87,36 €/stück",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 87.36,
//                             "raw": "€87,36",
//                             "asin": "B09ZF88PFJ",
//                             "link": "https://www.amazon.de/Almgourmet-Geschenkset-K%C3%A4se-Spezialit%C3%A4ten-sowie-Marillenschnaps-Ansprechend-verpackt/dp/B09ZF88PFJ/ref=sr_1_35?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-35"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 87.36,
//                         "raw": "€87,36",
//                         "asin": "B09ZF88PFJ",
//                         "link": "https://www.amazon.de/Almgourmet-Geschenkset-K%C3%A4se-Spezialit%C3%A4ten-sowie-Marillenschnaps-Ansprechend-verpackt/dp/B09ZF88PFJ/ref=sr_1_35?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-35"
//                     }
//                 },
//                 {
//                     "position": 36,
//                     "title": "Schwarzwald Metzgerei - Geschenkset \"Dosenwurst und meehr\" mit verschiedenen Wurtsspezialitäten, Altbadischem Senf, Schinken und Speck - 10-teilig",
//                     "asin": "B01HLH2XHW",
//                     "link": "https://www.amazon.de/Schwarzwald-Metzgerei-verschiedenen-Wurtsspezialit%C3%A4ten-Altbadischem/dp/B01HLH2XHW/ref=sr_1_36?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-36",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91sHo+hRs9L._AC_UL400_.jpg",
//                     "unit_price": "57,90 €/stück",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 268,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 57.9,
//                             "raw": "€57,90",
//                             "name": "57,90 €",
//                             "asin": "B01HLH2XHW",
//                             "link": "https://www.amazon.de/Schwarzwald-Metzgerei-verschiedenen-Wurtsspezialit%C3%A4ten-Altbadischem/dp/B01HLH2XHW/ref=sr_1_36?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-36"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 57.9,
//                         "raw": "€57,90",
//                         "name": "57,90 €",
//                         "asin": "B01HLH2XHW",
//                         "link": "https://www.amazon.de/Schwarzwald-Metzgerei-verschiedenen-Wurtsspezialit%C3%A4ten-Altbadischem/dp/B01HLH2XHW/ref=sr_1_36?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-36"
//                     }
//                 },
//                 {
//                     "position": 37,
//                     "title": "Präsentkorb EXCLUSIVO mit spanischen Spezialitäten - Geschenkkorb gefüllt mit edler Feinkost aus Spanien - ideales Delikatessen Geschenk für Gourmets",
//                     "asin": "B075WV9GCS",
//                     "link": "https://www.amazon.de/Exclusiver-Feinkost-Pr%C3%A4sentkorb-besondere-Momente/dp/B075WV9GCS/ref=sr_1_37?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-37",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81zJEEawYiL._AC_UL400_.jpg",
//                     "unit_price": "74,90 €/stück",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 9,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.9,
//                             "raw": "€74,90",
//                             "name": "74,90 €",
//                             "asin": "B075WV9GCS",
//                             "link": "https://www.amazon.de/Exclusiver-Feinkost-Pr%C3%A4sentkorb-besondere-Momente/dp/B075WV9GCS/ref=sr_1_37?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-37"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 74.9,
//                         "raw": "€74,90",
//                         "name": "74,90 €",
//                         "asin": "B075WV9GCS",
//                         "link": "https://www.amazon.de/Exclusiver-Feinkost-Pr%C3%A4sentkorb-besondere-Momente/dp/B075WV9GCS/ref=sr_1_37?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-37"
//                     }
//                 },
//                 {
//                     "position": 38,
//                     "title": "Hallingers 24er BBQ-Geschenk-Set, Gewürze aus aller Welt (445g) - BBQ Deluxe 24 Männersache (Set) - Weihnachten 2023, Adventskalender - Geschenk zu Mutter-/Vatertag 2023",
//                     "asin": "B012CEN4YK",
//                     "link": "https://www.amazon.de/Hallingers-Gew%C3%BCrz-Set-Deluxe-M%C3%A4nnersache-Miniglas/dp/B012CEN4YK/ref=sr_1_38?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-38",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61h4yX4nnpL._AC_UL400_.jpg",
//                     "unit_price": "54,99 €/stück",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 211,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "54,99 €",
//                             "asin": "B012CEN4YK",
//                             "link": "https://www.amazon.de/Hallingers-Gew%C3%BCrz-Set-Deluxe-M%C3%A4nnersache-Miniglas/dp/B012CEN4YK/ref=sr_1_38?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-38"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.99,
//                         "raw": "€54,99",
//                         "name": "54,99 €",
//                         "asin": "B012CEN4YK",
//                         "link": "https://www.amazon.de/Hallingers-Gew%C3%BCrz-Set-Deluxe-M%C3%A4nnersache-Miniglas/dp/B012CEN4YK/ref=sr_1_38?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-38"
//                     }
//                 },
//                 {
//                     "position": 39,
//                     "title": "Hallingers Oster-Geschenk-Set 60 Trüffel und Ostereier für Ostern in edler Box (960g) - Easter Box Green (Bundle) - Ostern 2023, Für Sie#Für Ihn - Geschenk zu Mutter-/Vatertag 2023",
//                     "asin": "B086DR16CM",
//                     "link": "https://www.amazon.de/Hallingers-Oster-Geschenk-Set-Tr%C3%BCffel-Ostereier-Ostern/dp/B086DR16CM/ref=sr_1_39?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-39",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71qdEnj70gL._AC_UL400_.jpg",
//                     "unit_price": "67,70 €/kg",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 2,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.99,
//                             "raw": "€64,99",
//                             "name": "64,99 €",
//                             "asin": "B086DR16CM",
//                             "link": "https://www.amazon.de/Hallingers-Oster-Geschenk-Set-Tr%C3%BCffel-Ostereier-Ostern/dp/B086DR16CM/ref=sr_1_39?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-39"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.99,
//                         "raw": "€64,99",
//                         "name": "64,99 €",
//                         "asin": "B086DR16CM",
//                         "link": "https://www.amazon.de/Hallingers-Oster-Geschenk-Set-Tr%C3%BCffel-Ostereier-Ostern/dp/B086DR16CM/ref=sr_1_39?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-39"
//                     }
//                 },
//                 {
//                     "position": 40,
//                     "title": "Italienisches Geschenkset „Florenz“ | Großer Geschenkkorb mit Wein, Olivenöl & vielen Spezialitäten aus Italien | Präsentkorb für Frauen & Männer zum Geburtstag, Muttertag, Vatertag",
//                     "asin": "B07C9X7SV4",
//                     "link": "https://www.amazon.de/Italienisches-Geschenkkorb-Delikatessen-Italienische-Spezialit%C3%A4ten/dp/B07C9X7SV4/ref=sr_1_40?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-40",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91sPyinNm1L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 90,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 72.9,
//                             "raw": "€72,90",
//                             "name": "72,90 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.9,
//                             "raw": "€74,90",
//                             "name": "72,90 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 72.9,
//                         "raw": "€72,90",
//                         "name": "72,90 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 41,
//                     "title": "Hallingers Geschenk-Set mit Tafel, Tee, Pralinen & Gewürz (413g) - Best of Christmas (Bundle) - Weihnachten 2023, Für Sie#Für Ihn - Geschenk zu Mutter-/Vatertag 2023",
//                     "asin": "B07LH2RY17",
//                     "link": "https://www.amazon.de/Hallingers-Geschenk-Set-Tafel-Pralinen-Gew%C3%BCrz/dp/B07LH2RY17/ref=sr_1_41?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-41",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/615YAPTi0xL._AC_UL400_.jpg",
//                     "unit_price": "145,25 €/kg",
//                     "is_prime": true,
//                     "rating": 4.9,
//                     "ratings_total": 5,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "asin": "B07LH2RY17",
//                             "link": "https://www.amazon.de/Hallingers-Geschenk-Set-Tafel-Pralinen-Gew%C3%BCrz/dp/B07LH2RY17/ref=sr_1_41?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-41"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "asin": "B07LH2RY17",
//                         "link": "https://www.amazon.de/Hallingers-Geschenk-Set-Tafel-Pralinen-Gew%C3%BCrz/dp/B07LH2RY17/ref=sr_1_41?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-41"
//                     }
//                 },
//                 {
//                     "position": 42,
//                     "title": "Hallingers Oster-Geschenk-Set Schokolade, Kaffee, Ostereier und Osterhasen für Ostern in edler Box (1238g) - Easter Big Box Green (Bundle) - Ostern 2023, Für Sie#Für Ihn - Geschenk zu Mutter-/Vaterta",
//                     "asin": "B086BTGYSZ",
//                     "link": "https://www.amazon.de/Hallingers-Oster-Geschenk-Set-Schokolade-Ostereier-Osterhasen/dp/B086BTGYSZ/ref=sr_1_42?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-42",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71P2o1VncQL._AC_UL400_.jpg",
//                     "unit_price": "609,67 €/kg",
//                     "is_prime": true,
//                     "rating": 4.2,
//                     "ratings_total": 6,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.99,
//                             "raw": "€74,99",
//                             "name": "74,99 €",
//                             "asin": "B086BTGYSZ",
//                             "link": "https://www.amazon.de/Hallingers-Oster-Geschenk-Set-Schokolade-Ostereier-Osterhasen/dp/B086BTGYSZ/ref=sr_1_42?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-42"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 74.99,
//                         "raw": "€74,99",
//                         "name": "74,99 €",
//                         "asin": "B086BTGYSZ",
//                         "link": "https://www.amazon.de/Hallingers-Oster-Geschenk-Set-Schokolade-Ostereier-Osterhasen/dp/B086BTGYSZ/ref=sr_1_42?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-42"
//                     }
//                 },
//                 {
//                     "position": 43,
//                     "title": "Rivio Premium Foods | Geschenkset \"Vegan\" | Präsentkorb mit veganen Spezialitäten | in der \"Rivio\"-Geschenkverpackung | für Liebhaber veganer Produkte | ideal für Kochbegeisterte",
//                     "asin": "B0C4B3CGJQ",
//                     "link": "https://www.amazon.de/Rivio-Geschenkset-Spezialit%C3%A4ten-Geschenkverpackung-Kochbegeisterte/dp/B0C4B3CGJQ/ref=sr_1_43?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-43",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71keOZdUEFL._AC_UL400_.jpg",
//                     "unit_price": "54,90 €/stück",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.9,
//                             "raw": "€54,90",
//                             "asin": "B0C4B3CGJQ",
//                             "link": "https://www.amazon.de/Rivio-Geschenkset-Spezialit%C3%A4ten-Geschenkverpackung-Kochbegeisterte/dp/B0C4B3CGJQ/ref=sr_1_43?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-43"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.9,
//                         "raw": "€54,90",
//                         "asin": "B0C4B3CGJQ",
//                         "link": "https://www.amazon.de/Rivio-Geschenkset-Spezialit%C3%A4ten-Geschenkverpackung-Kochbegeisterte/dp/B0C4B3CGJQ/ref=sr_1_43?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-43"
//                     }
//                 },
//                 {
//                     "position": 44,
//                     "title": "Diem Lamm Paket - Geschenkset, Feinkost, Gourmet- (2 x Lammeintopf / Irish Steh, 2 x Lammragout ,1 x Lammbraten) 5 x 400g Konserve - Dose - Konserve - Langes MhD",
//                     "asin": "B07F7LGMP5",
//                     "link": "https://www.amazon.de/Diem-Lammeintopf-Kartoffeln-Lammragout-mediterraner/dp/B07F7LGMP5/ref=sr_1_44?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-44",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91ANqGoVoVL._AC_UL400_.jpg",
//                     "unit_price": "28,00 €/kg",
//                     "rating": 3.9,
//                     "ratings_total": 21,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 55.99,
//                             "raw": "€55,99",
//                             "name": "55,99 €",
//                             "asin": "B07F7LGMP5",
//                             "link": "https://www.amazon.de/Diem-Lammeintopf-Kartoffeln-Lammragout-mediterraner/dp/B07F7LGMP5/ref=sr_1_44?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-44"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 55.99,
//                         "raw": "€55,99",
//                         "name": "55,99 €",
//                         "asin": "B07F7LGMP5",
//                         "link": "https://www.amazon.de/Diem-Lammeintopf-Kartoffeln-Lammragout-mediterraner/dp/B07F7LGMP5/ref=sr_1_44?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-44"
//                     }
//                 },
//                 {
//                     "position": 45,
//                     "title": "Geschenkset \"Venedig\" | Italienischer Geschenkkorb gefüllt mit Grappa, Gläser, italienischer Schokolade & Holzkiste | Spirituosen Geschenk für Männer & Frauen zum Geburtstag",
//                     "asin": "B07SD88455",
//                     "link": "https://www.amazon.de/Geschenkset-Geschenkkorb-Holzkiste-italienischen-Spezialit%C3%A4ten/dp/B07SD88455/ref=sr_1_45?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-45",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81eEqxcLTFL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 47,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.9,
//                             "raw": "€59,90",
//                             "name": "59,90 €",
//                             "asin": "B07SD88455",
//                             "link": "https://www.amazon.de/Geschenkset-Geschenkkorb-Holzkiste-italienischen-Spezialit%C3%A4ten/dp/B07SD88455/ref=sr_1_45?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-45"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.9,
//                         "raw": "€59,90",
//                         "name": "59,90 €",
//                         "asin": "B07SD88455",
//                         "link": "https://www.amazon.de/Geschenkset-Geschenkkorb-Holzkiste-italienischen-Spezialit%C3%A4ten/dp/B07SD88455/ref=sr_1_45?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-45"
//                     }
//                 },
//                 {
//                     "position": 46,
//                     "title": "Hallingers Geschenk-Set in edler Box (1123g) - Happy Birthday Blue (Bundle) - Geburtstag & Glückwunsch 2023, Für Sie#Für Ihn - Geschenk zu Mutter-/Vatertag 2023",
//                     "asin": "B08244HX9K",
//                     "link": "https://www.amazon.de/Hallingers-Geschenk-Set-edler-Box-1-123g/dp/B08244HX9K/ref=sr_1_46?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-46",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/710VJy1HkUL._AC_UL400_.jpg",
//                     "unit_price": "669,55 €/kg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 5,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.99,
//                             "raw": "€74,99",
//                             "name": "74,99 €",
//                             "asin": "B08244HX9K",
//                             "link": "https://www.amazon.de/Hallingers-Geschenk-Set-edler-Box-1-123g/dp/B08244HX9K/ref=sr_1_46?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-46"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 74.99,
//                         "raw": "€74,99",
//                         "name": "74,99 €",
//                         "asin": "B08244HX9K",
//                         "link": "https://www.amazon.de/Hallingers-Geschenk-Set-edler-Box-1-123g/dp/B08244HX9K/ref=sr_1_46?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-46"
//                     }
//                 },
//                 {
//                     "position": 47,
//                     "title": "Geschenkpaket Flavors of Greece Originale Griechische Delikatessen Gemischte Geschenkbox Präsentpaket Köstliche Lebensmittel Präsentkorb Geschmackvoll Geschenk für Feinschmecker Feinkost Geschenk set.",
//                     "asin": "B07MRF3MTY",
//                     "link": "https://www.amazon.de/Greece-Griechische-Delikatessen-Pr%C3%A4sentpaket-Lebensmittel-einzigartigem/dp/B07MRF3MTY/ref=sr_1_47?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-47",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81AS3d5WZ7L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 23,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.75,
//                             "raw": "€59,75",
//                             "name": "59,75 €",
//                             "asin": "B07MRF3MTY",
//                             "link": "https://www.amazon.de/Greece-Griechische-Delikatessen-Pr%C3%A4sentpaket-Lebensmittel-einzigartigem/dp/B07MRF3MTY/ref=sr_1_47?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-47"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.75,
//                         "raw": "€59,75",
//                         "name": "59,75 €",
//                         "asin": "B07MRF3MTY",
//                         "link": "https://www.amazon.de/Greece-Griechische-Delikatessen-Pr%C3%A4sentpaket-Lebensmittel-einzigartigem/dp/B07MRF3MTY/ref=sr_1_47?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-47"
//                     }
//                 },
//                 {
//                     "position": 48,
//                     "title": "Almgourmet 8-tlg. Geschenkset - Tiroler Spezialitäten für Frühstück - Inkl. Almdudler, Konfitüre, Wurst- & Käse Delikatessen",
//                     "asin": "B09TB79296",
//                     "link": "https://www.amazon.de/Almgourmet-8-tlg-Geschenkset-Spezialit%C3%A4ten-Delikatessen/dp/B09TB79296/ref=sr_1_48?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-48",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61UPbvauPyL._AC_UL400_.jpg",
//                     "unit_price": "80,64 €/stück",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 80.64,
//                             "raw": "€80,64",
//                             "name": "Primary",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 85.12,
//                             "raw": "€85,12",
//                             "name": "Original",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 80.64,
//                         "raw": "€80,64",
//                         "name": "Primary",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 49,
//                     "title": "Geschenkbox mit Wein und Pager Käse in einem Feinschmecker Geschenkset in Weinkisten Holz (Einweg)",
//                     "asin": "B077KJZ78B",
//                     "link": "https://www.amazon.de/Wein-K%C3%A4se-Geschenk-PA%C5%A0KI-Schafsk%C3%A4se/dp/B077KJZ78B/ref=sr_1_49?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-49",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/A1p01DrMxJL._AC_UL400_.jpg",
//                     "unit_price": "77,90 €/stück",
//                     "rating": 4.5,
//                     "ratings_total": 13,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 77.9,
//                             "raw": "€77,90",
//                             "name": "77,90 €",
//                             "asin": "B077KJZ78B",
//                             "link": "https://www.amazon.de/Wein-K%C3%A4se-Geschenk-PA%C5%A0KI-Schafsk%C3%A4se/dp/B077KJZ78B/ref=sr_1_49?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-49"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 77.9,
//                         "raw": "€77,90",
//                         "name": "77,90 €",
//                         "asin": "B077KJZ78B",
//                         "link": "https://www.amazon.de/Wein-K%C3%A4se-Geschenk-PA%C5%A0KI-Schafsk%C3%A4se/dp/B077KJZ78B/ref=sr_1_49?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-49"
//                     }
//                 },
//                 {
//                     "position": 50,
//                     "title": "VEGETARISCHE Feinkost Geschenkkorb GUSTOSO mit ausgesuchten italienischen Delikatessen Spezialitäten ohne Alkohol",
//                     "asin": "B01MRYA7JC",
//                     "link": "https://www.amazon.de/Vegetarischer-Geschenkkorb-ausgesuchten-italienischen-Spezialit%C3%A4ten/dp/B01MRYA7JC/ref=sr_1_50?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-50",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81hLocg+HpL._AC_UL400_.jpg",
//                     "unit_price": "64,90 €/stück",
//                     "rating": 4.8,
//                     "ratings_total": 8,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.9,
//                             "raw": "€64,90",
//                             "name": "64,90 €",
//                             "asin": "B01MRYA7JC",
//                             "link": "https://www.amazon.de/Vegetarischer-Geschenkkorb-ausgesuchten-italienischen-Spezialit%C3%A4ten/dp/B01MRYA7JC/ref=sr_1_50?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-50"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.9,
//                         "raw": "€64,90",
//                         "name": "64,90 €",
//                         "asin": "B01MRYA7JC",
//                         "link": "https://www.amazon.de/Vegetarischer-Geschenkkorb-ausgesuchten-italienischen-Spezialit%C3%A4ten/dp/B01MRYA7JC/ref=sr_1_50?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-50"
//                     }
//                 },
//                 {
//                     "position": 51,
//                     "title": "Römer Präsente Geschenkset Mediterrane Vielfalt: 8-teiliges Set; mediterraner Korb + Pizza Gebäck, Sugo, Pasta, Oliven, Terrine + 2 Flaschen Wein",
//                     "asin": "B006SWHTFI",
//                     "link": "https://www.amazon.de/R%C3%B6mer-Geschenkkorb-Mediterrane-Vielfalt/dp/B006SWHTFI/ref=sr_1_51?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-51",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71GXFPETLXL._AC_UL400_.jpg",
//                     "unit_price": "69,50 €/stück",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 87,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.5,
//                             "raw": "€69,50",
//                             "name": "69,50 €",
//                             "asin": "B006SWHTFI",
//                             "link": "https://www.amazon.de/R%C3%B6mer-Geschenkkorb-Mediterrane-Vielfalt/dp/B006SWHTFI/ref=sr_1_51?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-51"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.5,
//                         "raw": "€69,50",
//                         "name": "69,50 €",
//                         "asin": "B006SWHTFI",
//                         "link": "https://www.amazon.de/R%C3%B6mer-Geschenkkorb-Mediterrane-Vielfalt/dp/B006SWHTFI/ref=sr_1_51?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-51"
//                     }
//                 },
//                 {
//                     "position": 52,
//                     "title": "Hallingers Geschenk-Set in edler Box (1690g) - Pasta Time (Bundle) - Passt immer 2023, Für Sie#Für Ihn - Geschenk zu Mutter-/Vatertag 2023",
//                     "asin": "B0824453SM",
//                     "link": "https://www.amazon.de/Hallingers-Geschenk-Set-edler-Box-1-690g/dp/B0824453SM/ref=sr_1_52?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-52",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71ZVEFeTWSL._AC_UL400_.jpg",
//                     "unit_price": "384,56 €/kg",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 2,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.99,
//                             "raw": "€64,99",
//                             "name": "64,99 €",
//                             "asin": "B0824453SM",
//                             "link": "https://www.amazon.de/Hallingers-Geschenk-Set-edler-Box-1-690g/dp/B0824453SM/ref=sr_1_52?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-52"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.99,
//                         "raw": "€64,99",
//                         "name": "64,99 €",
//                         "asin": "B0824453SM",
//                         "link": "https://www.amazon.de/Hallingers-Geschenk-Set-edler-Box-1-690g/dp/B0824453SM/ref=sr_1_52?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-52"
//                     }
//                 },
//                 {
//                     "position": 53,
//                     "title": "Geschenkset Siena | Italien Geschenkkorb mit Wein, Olivenöl & Holzkiste | Feinkost Präsentkorb mit Rotwein & italienischen Spezialitäten aus der Toskana",
//                     "asin": "B08N3GV5M4",
//                     "link": "https://www.amazon.de/Geschenkset-Geschenkkorb-Pr%C3%A4sentkorb-italienischen-Spezialit%C3%A4ten/dp/B08N3GV5M4/ref=sr_1_53?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-53",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91SqMXQSO5L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 3,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.9,
//                             "raw": "€79,90",
//                             "name": "79,90 €",
//                             "asin": "B08N3GV5M4",
//                             "link": "https://www.amazon.de/Geschenkset-Geschenkkorb-Pr%C3%A4sentkorb-italienischen-Spezialit%C3%A4ten/dp/B08N3GV5M4/ref=sr_1_53?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-53"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.9,
//                         "raw": "€79,90",
//                         "name": "79,90 €",
//                         "asin": "B08N3GV5M4",
//                         "link": "https://www.amazon.de/Geschenkset-Geschenkkorb-Pr%C3%A4sentkorb-italienischen-Spezialit%C3%A4ten/dp/B08N3GV5M4/ref=sr_1_53?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-53"
//                     }
//                 },
//                 {
//                     "position": 54,
//                     "title": "Spanische Gourmet-Tapas-Korbbox - iberische Chorizo und iberischer Schinken + Iberische Trüffelpasteten und süßer Wein + 3 Stück Fuets + natives Olivenöl + Gourmet-Brot +Tapas aus Holz als Geschenk",
//                     "asin": "B0BLQJFC7W",
//                     "link": "https://www.amazon.de/Spanische-Gourmet-Tapas-Korbbox-iberischer-Tr%C3%BCffelpasteten-Gourmet-Brot/dp/B0BLQJFC7W/ref=sr_1_54?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-54",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91t5v3m+0UL._AC_UL400_.jpg",
//                     "unit_price": "54,00 €/stück",
//                     "is_prime": true,
//                     "rating": 3,
//                     "ratings_total": 4,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54,
//                             "raw": "€54,00",
//                             "name": "54,00 €",
//                             "asin": "B0BLQJFC7W",
//                             "link": "https://www.amazon.de/Spanische-Gourmet-Tapas-Korbbox-iberischer-Tr%C3%BCffelpasteten-Gourmet-Brot/dp/B0BLQJFC7W/ref=sr_1_54?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-54"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54,
//                         "raw": "€54,00",
//                         "name": "54,00 €",
//                         "asin": "B0BLQJFC7W",
//                         "link": "https://www.amazon.de/Spanische-Gourmet-Tapas-Korbbox-iberischer-Tr%C3%BCffelpasteten-Gourmet-Brot/dp/B0BLQJFC7W/ref=sr_1_54?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-54"
//                     }
//                 },
//                 {
//                     "position": 55,
//                     "title": "Hallingers Oster-Geschenk-Set 4 Schokolade, 60 Trüffel/Ostereier für Ostern in edler Box (1320g) - Easter Box Yellow (Bundle) - Ostern 2023, Für Sie#Für Ihn - Geschenk zu Mutter-/Vatertag 2023",
//                     "asin": "B086DQJPP6",
//                     "link": "https://www.amazon.de/Hallingers-Oster-Geschenk-Set-Schokolade-Tr%C3%BCffel-Ostereier/dp/B086DQJPP6/ref=sr_1_55?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-55",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71YtTyFQoJL._AC_UL400_.jpg",
//                     "unit_price": "681,74 €/kg",
//                     "is_prime": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.99,
//                             "raw": "€89,99",
//                             "asin": "B086DQJPP6",
//                             "link": "https://www.amazon.de/Hallingers-Oster-Geschenk-Set-Schokolade-Tr%C3%BCffel-Ostereier/dp/B086DQJPP6/ref=sr_1_55?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-55"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.99,
//                         "raw": "€89,99",
//                         "asin": "B086DQJPP6",
//                         "link": "https://www.amazon.de/Hallingers-Oster-Geschenk-Set-Schokolade-Tr%C3%BCffel-Ostereier/dp/B086DQJPP6/ref=sr_1_55?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-55"
//                     }
//                 },
//                 {
//                     "position": 56,
//                     "title": "Gepp’s Feinkost Deluxe Geschenkbox für den Papa I Geschenkidee zum Vatertag zum Grillen I Vatertagsgeschenk gefüllt mit Saucen-Spezialitäten, Rubs, Grissini, Fleischzartmacher und Burgerpresse",
//                     "asin": "B08BRZL36C",
//                     "link": "https://www.amazon.de/Gepps-Geschenkidee-Grill-Liebhaber-Saucen-Spezialit%C3%A4ten-Fleischzartmacher/dp/B08BRZL36C/ref=sr_1_56?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-56",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91lxLpRD58L._AC_UL400_.jpg",
//                     "unit_price": "64,99 €/stück",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 25,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.99,
//                             "raw": "€64,99",
//                             "name": "64,99 €",
//                             "asin": "B08BRZL36C",
//                             "link": "https://www.amazon.de/Gepps-Geschenkidee-Grill-Liebhaber-Saucen-Spezialit%C3%A4ten-Fleischzartmacher/dp/B08BRZL36C/ref=sr_1_56?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-56"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.99,
//                         "raw": "€64,99",
//                         "name": "64,99 €",
//                         "asin": "B08BRZL36C",
//                         "link": "https://www.amazon.de/Gepps-Geschenkidee-Grill-Liebhaber-Saucen-Spezialit%C3%A4ten-Fleischzartmacher/dp/B08BRZL36C/ref=sr_1_56?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-56"
//                     }
//                 },
//                 {
//                     "position": 57,
//                     "title": "Hallingers Großes Gourmet Gewürze Set mit 10 Gewürzen (1060g) - Best of BBQ (Bundle) - Passt immer 2023, Grillen - Geschenk zu Mutter-/Vatertag 2023",
//                     "asin": "B085CFS4B4",
//                     "link": "https://www.amazon.de/Hallingers-Gro%C3%9Fes-Gourmet-Gew%C3%BCrze-Gew%C3%BCrzen/dp/B085CFS4B4/ref=sr_1_57?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-57",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71nabIoY+FL._AC_UL400_.jpg",
//                     "unit_price": "518,77 €/kg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 6,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "54,99 €",
//                             "asin": "B085CFS4B4",
//                             "link": "https://www.amazon.de/Hallingers-Gro%C3%9Fes-Gourmet-Gew%C3%BCrze-Gew%C3%BCrzen/dp/B085CFS4B4/ref=sr_1_57?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-57"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.99,
//                         "raw": "€54,99",
//                         "name": "54,99 €",
//                         "asin": "B085CFS4B4",
//                         "link": "https://www.amazon.de/Hallingers-Gro%C3%9Fes-Gourmet-Gew%C3%BCrze-Gew%C3%BCrzen/dp/B085CFS4B4/ref=sr_1_57?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-57"
//                     }
//                 },
//                 {
//                     "position": 58,
//                     "title": "Geschenkkorb / Präsentkorb Delikatessen Galaktisch mit Nudeln, Saucen, Öl, Essigcreme, Senf, Sirup, Schokolade | gut als: Geschenkkorb für Kunden, Geschenkkorb zum Jubiläum oder Geschenkkorb Luxus",
//                     "asin": "B00MRH5VE4",
//                     "link": "https://www.amazon.de/Geschenkkorb-Pr%C3%A4sentkorb-Delikatessen-Galaktisch-Essigcreme/dp/B00MRH5VE4/ref=sr_1_58?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-58",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/813AtZ6w3YL._AC_UL400_.jpg",
//                     "unit_price": "99,99 €/stück",
//                     "rating": 4.2,
//                     "ratings_total": 59,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.99,
//                             "raw": "€99,99",
//                             "name": "99,99 €",
//                             "asin": "B00MRH5VE4",
//                             "link": "https://www.amazon.de/Geschenkkorb-Pr%C3%A4sentkorb-Delikatessen-Galaktisch-Essigcreme/dp/B00MRH5VE4/ref=sr_1_58?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-58"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99.99,
//                         "raw": "€99,99",
//                         "name": "99,99 €",
//                         "asin": "B00MRH5VE4",
//                         "link": "https://www.amazon.de/Geschenkkorb-Pr%C3%A4sentkorb-Delikatessen-Galaktisch-Essigcreme/dp/B00MRH5VE4/ref=sr_1_58?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-58"
//                     }
//                 },
//                 {
//                     "position": 59,
//                     "title": "LAKRIDS BY BÜLOW - Love Selection Box 2022-450g - Pralinen-Geschenk mit Dänischen Gourmet Lakritz-Kugeln in edler Herz-Geschenkbox",
//                     "asin": "B09PRPX7N9",
//                     "link": "https://www.amazon.de/LAKRIDS-B%C3%9CLOW-Muttertags-Geschenk-Lakritz-Kugeln-Herz-Geschenkbox/dp/B09PRPX7N9/ref=sr_1_59?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-59",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71-B+XdkF9L._AC_UL400_.jpg",
//                     "unit_price": "142,22 €/kg",
//                     "rating": 4.5,
//                     "ratings_total": 443,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64,
//                             "raw": "€64,00",
//                             "name": "64,00 €",
//                             "asin": "B09PRPX7N9",
//                             "link": "https://www.amazon.de/LAKRIDS-B%C3%9CLOW-Muttertags-Geschenk-Lakritz-Kugeln-Herz-Geschenkbox/dp/B09PRPX7N9/ref=sr_1_59?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-59"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64,
//                         "raw": "€64,00",
//                         "name": "64,00 €",
//                         "asin": "B09PRPX7N9",
//                         "link": "https://www.amazon.de/LAKRIDS-B%C3%9CLOW-Muttertags-Geschenk-Lakritz-Kugeln-Herz-Geschenkbox/dp/B09PRPX7N9/ref=sr_1_59?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-59"
//                     }
//                 },
//                 {
//                     "position": 60,
//                     "title": "Hallingers Weihnachts-Geschenk-Set mit Gin, Pralinen & mehr (740g) - Berauschendes Fest (Bundle) - Weihnachten 2023, Für Sie#Für Ihn - Geschenk zu Mutter-/Vatertag 2023",
//                     "asin": "B08PSBQP16",
//                     "link": "https://www.amazon.de/Hallingers-Weihnachts-Geschenk-Set-Pralinen-mehr-740g/dp/B08PSBQP16/ref=sr_1_60?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-60",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71J-CnvZWuL._AC_UL400_.jpg",
//                     "unit_price": "114,85 €/kg",
//                     "is_prime": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 84.99,
//                             "raw": "€84,99",
//                             "asin": "B08PSBQP16",
//                             "link": "https://www.amazon.de/Hallingers-Weihnachts-Geschenk-Set-Pralinen-mehr-740g/dp/B08PSBQP16/ref=sr_1_60?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-60"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 84.99,
//                         "raw": "€84,99",
//                         "asin": "B08PSBQP16",
//                         "link": "https://www.amazon.de/Hallingers-Weihnachts-Geschenk-Set-Pralinen-mehr-740g/dp/B08PSBQP16/ref=sr_1_60?keywords=Gourmet-Geschenkset&qid=1684074394&refinements=p_36%3A5000-10000&sr=8-60"
//                     }
//                 }
//             ]
//         },
//         {
//             "idea": "Kochmütze",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "MISEMIYA - HUT GRILL KÜCHE MÜTZE CHEF MÜTZE RUNDE MÜTZE RUNDE KÜCHE - Ref.926 - Schwarz, Pack* 1",
//                     "asin": "B09CTLLV9H",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTc0MzY2MzY5MDYyODI2OjE2ODQwNzQ0NTQ6c3BfYXRmOjIwMDg5ODM1NjM0OTk4OjowOjo&url=%2FMISEMIYA-GRILL-K%25C3%259CCHE-Ref-926-Schwarz%2Fdp%2FB09CTLLV9H%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DKochm%25C3%25BCtze%26qid%3D1684074454%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/615er0yeJvL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 15,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50,
//                             "raw": "€50,00",
//                             "name": "50,00 €",
//                             "asin": "B09CTLLV9H",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTc0MzY2MzY5MDYyODI2OjE2ODQwNzQ0NTQ6c3BfYXRmOjIwMDg5ODM1NjM0OTk4OjowOjo&url=%2FMISEMIYA-GRILL-K%25C3%259CCHE-Ref-926-Schwarz%2Fdp%2FB09CTLLV9H%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DKochm%25C3%25BCtze%26qid%3D1684074454%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50,
//                         "raw": "€50,00",
//                         "name": "50,00 €",
//                         "asin": "B09CTLLV9H",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTc0MzY2MzY5MDYyODI2OjE2ODQwNzQ0NTQ6c3BfYXRmOjIwMDg5ODM1NjM0OTk4OjowOjo&url=%2FMISEMIYA-GRILL-K%25C3%259CCHE-Ref-926-Schwarz%2Fdp%2FB09CTLLV9H%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DKochm%25C3%25BCtze%26qid%3D1684074454%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "Wallario Vliestapete Fototapete Lustige Eule beim Kochen mit Kochmütze - Größe: 200 x 140 cm",
//                     "asin": "B09SLTZF1X",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTc0MzY2MzY5MDYyODI2OjE2ODQwNzQ0NTQ6c3BfYXRmOjIwMDMyMTgyNDU1Mjk4OjowOjo&url=%2FWallario-Vliestapete-Fototapete-Lustige-Kochm%25C3%25BCtze%2Fdp%2FB09SLTZF1X%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DKochm%25C3%25BCtze%26qid%3D1684074454%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71IaLRjUVvL._AC_UL400_.jpg",
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.95,
//                             "raw": "€59,95",
//                             "asin": "B09SLTZF1X",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTc0MzY2MzY5MDYyODI2OjE2ODQwNzQ0NTQ6c3BfYXRmOjIwMDMyMTgyNDU1Mjk4OjowOjo&url=%2FWallario-Vliestapete-Fototapete-Lustige-Kochm%25C3%25BCtze%2Fdp%2FB09SLTZF1X%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DKochm%25C3%25BCtze%26qid%3D1684074454%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.95,
//                         "raw": "€59,95",
//                         "asin": "B09SLTZF1X",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTc0MzY2MzY5MDYyODI2OjE2ODQwNzQ0NTQ6c3BfYXRmOjIwMDMyMTgyNDU1Mjk4OjowOjo&url=%2FWallario-Vliestapete-Fototapete-Lustige-Kochm%25C3%25BCtze%2Fdp%2FB09SLTZF1X%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DKochm%25C3%25BCtze%26qid%3D1684074454%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 3,
//                     "title": "MISEMIYA Unisex Kochmütze Champignon-Mütze 918",
//                     "asin": "B07KDWX7GM",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTc0MzY2MzY5MDYyODI2OjE2ODQwNzQ0NTQ6c3BfYXRmOjIwMDIzNDc0MDc5Njk4OjowOjo&url=%2FMISEMIYA-FRANZ%25C3%2596SISCHE-M%25C3%259CTZE-KOCHEN-Ref-918%2Fdp%2FB07KDWX7GM%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DKochm%25C3%25BCtze%26qid%3D1684074454%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Ma2ogk-GL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 311,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 63.26,
//                             "raw": "€63,26",
//                             "name": "63,26 €",
//                             "asin": "B07KDWX7GM",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTc0MzY2MzY5MDYyODI2OjE2ODQwNzQ0NTQ6c3BfYXRmOjIwMDIzNDc0MDc5Njk4OjowOjo&url=%2FMISEMIYA-FRANZ%25C3%2596SISCHE-M%25C3%259CTZE-KOCHEN-Ref-918%2Fdp%2FB07KDWX7GM%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DKochm%25C3%25BCtze%26qid%3D1684074454%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 63.26,
//                         "raw": "€63,26",
//                         "name": "63,26 €",
//                         "asin": "B07KDWX7GM",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTc0MzY2MzY5MDYyODI2OjE2ODQwNzQ0NTQ6c3BfYXRmOjIwMDIzNDc0MDc5Njk4OjowOjo&url=%2FMISEMIYA-FRANZ%25C3%2596SISCHE-M%25C3%259CTZE-KOCHEN-Ref-918%2Fdp%2FB07KDWX7GM%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DKochm%25C3%25BCtze%26qid%3D1684074454%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 4,
//                     "title": "MISEMIYA - HUT GRILL KÜCHE MÜTZE CHEF MÜTZE RUNDE MÜTZE RUNDE KÜCHE - Ref.926 - Weiß, Pack*",
//                     "asin": "B07N56RZFC",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTc0MzY2MzY5MDYyODI2OjE2ODQwNzQ0NTQ6c3BfYXRmOjIwMTA3NjY1NzM0OTk4OjowOjo&url=%2FMISEMIYA-GRILL-K%25C3%259CCHE-M%25C3%259CTZE-Ref-926%2Fdp%2FB07N56RZFC%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DKochm%25C3%25BCtze%26qid%3D1684074454%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61mjVCvkjsL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 4,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 52.05,
//                             "raw": "€52,05",
//                             "name": "52,05 €",
//                             "asin": "B07N56RZFC",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTc0MzY2MzY5MDYyODI2OjE2ODQwNzQ0NTQ6c3BfYXRmOjIwMTA3NjY1NzM0OTk4OjowOjo&url=%2FMISEMIYA-GRILL-K%25C3%259CCHE-M%25C3%259CTZE-Ref-926%2Fdp%2FB07N56RZFC%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DKochm%25C3%25BCtze%26qid%3D1684074454%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 52.05,
//                         "raw": "€52,05",
//                         "name": "52,05 €",
//                         "asin": "B07N56RZFC",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxOTc0MzY2MzY5MDYyODI2OjE2ODQwNzQ0NTQ6c3BfYXRmOjIwMTA3NjY1NzM0OTk4OjowOjo&url=%2FMISEMIYA-GRILL-K%25C3%259CCHE-M%25C3%259CTZE-Ref-926%2Fdp%2FB07N56RZFC%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DKochm%25C3%25BCtze%26qid%3D1684074454%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 5,
//                     "title": "MISEMIYA Unisex Kochmütze Champignon-Mütze 918",
//                     "asin": "B07KDWV4DD",
//                     "link": "https://www.amazon.de/MISEMIYA-FRANZ%C3%96SISCHE-KOCHEN-Ref-918-Schwarz/dp/B07KDWV4DD/ref=sr_1_5?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-5",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/6131uqKf48L._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 311,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 66.62,
//                             "raw": "€66,62",
//                             "name": "66,62 €",
//                             "asin": "B07KDWV4DD",
//                             "link": "https://www.amazon.de/MISEMIYA-FRANZ%C3%96SISCHE-KOCHEN-Ref-918-Schwarz/dp/B07KDWV4DD/ref=sr_1_5?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-5"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 66.62,
//                         "raw": "€66,62",
//                         "name": "66,62 €",
//                         "asin": "B07KDWV4DD",
//                         "link": "https://www.amazon.de/MISEMIYA-FRANZ%C3%96SISCHE-KOCHEN-Ref-918-Schwarz/dp/B07KDWV4DD/ref=sr_1_5?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-5"
//                     }
//                 },
//                 {
//                     "position": 6,
//                     "title": "Beistle 60963 Prismatischer Stoff zum Backen von Kochmützen, 2 Stück, Partyzubehör, Küchendekoration, Sommer, Picknick, Einheitsgröße, Gold",
//                     "asin": "B07PJ11Z7J",
//                     "link": "https://www.amazon.de/Beistle-60963-Kochm%C3%BCtze-Prismatic-Einheitsgr%C3%B6%C3%9Fe/dp/B07PJ11Z7J/ref=sr_1_6?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-6",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71HrR1Z2ZBL._AC_UL400_.jpg",
//                     "rating": 3.9,
//                     "ratings_total": 24,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 27.18,
//                             "raw": "€27,18",
//                             "name": "27,18 €",
//                             "asin": "B07PJ11Z7J",
//                             "link": "https://www.amazon.de/Beistle-60963-Kochm%C3%BCtze-Prismatic-Einheitsgr%C3%B6%C3%9Fe/dp/B07PJ11Z7J/ref=sr_1_6?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-6"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 27.18,
//                         "raw": "€27,18",
//                         "name": "27,18 €",
//                         "asin": "B07PJ11Z7J",
//                         "link": "https://www.amazon.de/Beistle-60963-Kochm%C3%BCtze-Prismatic-Einheitsgr%C3%B6%C3%9Fe/dp/B07PJ11Z7J/ref=sr_1_6?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-6"
//                     }
//                 },
//                 {
//                     "position": 7,
//                     "title": "INOOMP Bikermütze 8 Stück Piraten-Polyesterfaser",
//                     "asin": "B0C2Q4RJNJ",
//                     "link": "https://www.amazon.de/INOOMP-Herrenm%C3%BCtzen-Bikerm%C3%BCtze-Atmungsaktive-Polyesterfaser/dp/B0C2Q4RJNJ/ref=sr_1_7?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-7",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61Onp5f6ybL._AC_UL400_.jpg"
//                 },
//                 {
//                     "position": 8,
//                     "title": "MISEMIYA Unisex Kochmütze",
//                     "asin": "B07KDX84B1",
//                     "link": "https://www.amazon.de/MISEMIYA-VISIERKAPPE-VISIER-UNISEX-Ref-915/dp/B07KDX84B1/ref=sr_1_8?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-8",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71YA1TN+uGL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 12,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.96,
//                             "raw": "€50,96",
//                             "name": "50,96 €",
//                             "asin": "B07KDX84B1",
//                             "link": "https://www.amazon.de/MISEMIYA-VISIERKAPPE-VISIER-UNISEX-Ref-915/dp/B07KDX84B1/ref=sr_1_8?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-8"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.96,
//                         "raw": "€50,96",
//                         "name": "50,96 €",
//                         "asin": "B07KDX84B1",
//                         "link": "https://www.amazon.de/MISEMIYA-VISIERKAPPE-VISIER-UNISEX-Ref-915/dp/B07KDX84B1/ref=sr_1_8?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-8"
//                     }
//                 },
//                 {
//                     "position": 9,
//                     "title": "Kochmütze, Erwachsene, verstellbar, elastisch, für Küche, Kochen, Kochmütze",
//                     "asin": "B0B6TKM7BG",
//                     "link": "https://www.amazon.de/Kochm%C3%BCtze-Erwachsene-verstellbar-elastisch-Einheitsgr%EF%BF%BD%EF%BF%BD/dp/B0B6TKM7BG/ref=sr_1_9?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-9",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51f-8XRuaoL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 65,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.8,
//                             "raw": "€51,80",
//                             "name": "51,80 €",
//                             "asin": "B0B6TKM7BG",
//                             "link": "https://www.amazon.de/Kochm%C3%BCtze-Erwachsene-verstellbar-elastisch-Einheitsgr%EF%BF%BD%EF%BF%BD/dp/B0B6TKM7BG/ref=sr_1_9?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-9"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.8,
//                         "raw": "€51,80",
//                         "name": "51,80 €",
//                         "asin": "B0B6TKM7BG",
//                         "link": "https://www.amazon.de/Kochm%C3%BCtze-Erwachsene-verstellbar-elastisch-Einheitsgr%EF%BF%BD%EF%BF%BD/dp/B0B6TKM7BG/ref=sr_1_9?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-9"
//                     }
//                 },
//                 {
//                     "position": 10,
//                     "title": "DESERMO 10er Set Gastro Schiffchen verstellbar mit Klettverschluss | Hochwertige Profi Küchen Mütze | Für Frau & Mann passend für alle Größen | Große Farbauswahl | 220g/m² (Schwarz)",
//                     "asin": "B08C5GMCF7",
//                     "link": "https://www.amazon.de/DESERMO-verstellbar-Klettverschluss-Hochwertige-Farbauswahl/dp/B08C5GMCF7/ref=sr_1_10?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-10",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81Cfz3C2ltL._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 38
//                 },
//                 {
//                     "position": 11,
//                     "title": "MISEMIYA - HUT GRILL KÜCHE MÜTZE CHEF MÜTZE RUNDE MÜTZE RUNDE KÜCHE - Ref.926 - Weiß, Pack*",
//                     "asin": "B07N56RZFC",
//                     "link": "https://www.amazon.de/MISEMIYA-GRILL-K%C3%9CCHE-M%C3%9CTZE-Ref-926/dp/B07N56RZFC/ref=sr_1_11?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-11",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61mjVCvkjsL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 4,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 52.05,
//                             "raw": "€52,05",
//                             "name": "52,05 €",
//                             "asin": "B07N56RZFC",
//                             "link": "https://www.amazon.de/MISEMIYA-GRILL-K%C3%9CCHE-M%C3%9CTZE-Ref-926/dp/B07N56RZFC/ref=sr_1_11?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-11"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 52.05,
//                         "raw": "€52,05",
//                         "name": "52,05 €",
//                         "asin": "B07N56RZFC",
//                         "link": "https://www.amazon.de/MISEMIYA-GRILL-K%C3%9CCHE-M%C3%9CTZE-Ref-926/dp/B07N56RZFC/ref=sr_1_11?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-11"
//                     }
//                 },
//                 {
//                     "position": 12,
//                     "title": "Unisex Kochmütze, verstellbar, mit atmungsaktivem Netzstoff",
//                     "asin": "B09FSTFWTT",
//                     "link": "https://www.amazon.de/Kochm%C3%BCtze-verstellbar-atmungsaktivem-Netzstoff-schwarz/dp/B09FSTFWTT/ref=sr_1_12?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-12",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71hltp8w6LL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 89,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.29,
//                             "raw": "€54,29",
//                             "name": "54,29 €",
//                             "asin": "B09FSTFWTT",
//                             "link": "https://www.amazon.de/Kochm%C3%BCtze-verstellbar-atmungsaktivem-Netzstoff-schwarz/dp/B09FSTFWTT/ref=sr_1_12?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-12"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.29,
//                         "raw": "€54,29",
//                         "name": "54,29 €",
//                         "asin": "B09FSTFWTT",
//                         "link": "https://www.amazon.de/Kochm%C3%BCtze-verstellbar-atmungsaktivem-Netzstoff-schwarz/dp/B09FSTFWTT/ref=sr_1_12?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-12"
//                     }
//                 },
//                 {
//                     "position": 13,
//                     "title": "MISEMIYA Chef Bandana, Unisex, Erwachsene",
//                     "asin": "B07N58WK8Q",
//                     "link": "https://www.amazon.de/MISEMIYA-GRILL-K%C3%9CCHE-Ref-926-Schwarz/dp/B07N58WK8Q/ref=sr_1_13?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-13",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/615er0yeJvL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 28,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.75,
//                             "raw": "€51,75",
//                             "name": "51,75 €",
//                             "asin": "B07N58WK8Q",
//                             "link": "https://www.amazon.de/MISEMIYA-GRILL-K%C3%9CCHE-Ref-926-Schwarz/dp/B07N58WK8Q/ref=sr_1_13?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-13"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.75,
//                         "raw": "€51,75",
//                         "name": "51,75 €",
//                         "asin": "B07N58WK8Q",
//                         "link": "https://www.amazon.de/MISEMIYA-GRILL-K%C3%9CCHE-Ref-926-Schwarz/dp/B07N58WK8Q/ref=sr_1_13?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-13"
//                     }
//                 },
//                 {
//                     "position": 14,
//                     "title": "20 Stück Kochmütze, Unisex, Kochmütze, atmungsaktiv, Netzstoff, Bäckermütze, Kochmützen für Männer, Küche, Beanie, Totenkopfmütze, verstellbare Totenkopfmütze für Männer, schwarz, Einheitsgr��e",
//                     "asin": "B0BKP91WW9",
//                     "link": "https://www.amazon.de/atmungsaktiv-B%C3%A4ckerm%C3%BCtze-Totenkopfm%C3%BCtze-verstellbare-Einheitsgr%EF%BF%BD%EF%BF%BD/dp/B0BKP91WW9/ref=sr_1_14?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-14",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61omddlcH1L._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.44,
//                             "raw": "€54,44",
//                             "asin": "B0BKP91WW9",
//                             "link": "https://www.amazon.de/atmungsaktiv-B%C3%A4ckerm%C3%BCtze-Totenkopfm%C3%BCtze-verstellbare-Einheitsgr%EF%BF%BD%EF%BF%BD/dp/B0BKP91WW9/ref=sr_1_14?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-14"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.44,
//                         "raw": "€54,44",
//                         "asin": "B0BKP91WW9",
//                         "link": "https://www.amazon.de/atmungsaktiv-B%C3%A4ckerm%C3%BCtze-Totenkopfm%C3%BCtze-verstellbare-Einheitsgr%EF%BF%BD%EF%BF%BD/dp/B0BKP91WW9/ref=sr_1_14?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-14"
//                     }
//                 },
//                 {
//                     "position": 15,
//                     "title": "Kochmütze gesichtslose Puppe Stoff Geschenk für Familie Weihnachtsfeiertagsdekoration Auto Organizer Kinder Junge (A, One Size)",
//                     "asin": "B0B9MY7WMN",
//                     "link": "https://www.amazon.de/Kochm%C3%BCtze-gesichtslose-Geschenk-Weihnachtsfeiertagsdekoration-Organizer/dp/B0B9MY7WMN/ref=sr_1_15?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-15",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Ci8PMdtvL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 58.42,
//                             "raw": "€58,42",
//                             "asin": "B0B9MY7WMN",
//                             "link": "https://www.amazon.de/Kochm%C3%BCtze-gesichtslose-Geschenk-Weihnachtsfeiertagsdekoration-Organizer/dp/B0B9MY7WMN/ref=sr_1_15?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-15"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 58.42,
//                         "raw": "€58,42",
//                         "asin": "B0B9MY7WMN",
//                         "link": "https://www.amazon.de/Kochm%C3%BCtze-gesichtslose-Geschenk-Weihnachtsfeiertagsdekoration-Organizer/dp/B0B9MY7WMN/ref=sr_1_15?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-15"
//                     }
//                 },
//                 {
//                     "position": 16,
//                     "title": "Opromo Schürze und Kochmütze Baumwolltuch Einstellbar 6 Stück Schwarz Dot XL",
//                     "asin": "B01D9X50KO",
//                     "link": "https://www.amazon.de/opromo-Baumwolle-verstellbar-Sch%C3%BCrze-Kochm%C3%BCtze/dp/B01D9X50KO/ref=sr_1_16?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-16",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71dupi+LMqL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 58.79,
//                             "raw": "€58,79",
//                             "asin": "B01D9X50KO",
//                             "link": "https://www.amazon.de/opromo-Baumwolle-verstellbar-Sch%C3%BCrze-Kochm%C3%BCtze/dp/B01D9X50KO/ref=sr_1_16?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-16"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 58.79,
//                         "raw": "€58,79",
//                         "asin": "B01D9X50KO",
//                         "link": "https://www.amazon.de/opromo-Baumwolle-verstellbar-Sch%C3%BCrze-Kochm%C3%BCtze/dp/B01D9X50KO/ref=sr_1_16?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-16"
//                     }
//                 },
//                 {
//                     "position": 17,
//                     "title": "TopTie Koch Mantel Jacke Kurzarm Unisex 3 Packungen",
//                     "asin": "B0749JYZJ3",
//                     "link": "https://www.amazon.de/TopTie-Women-Short-Sleeve-Jacket-set1-3XL/dp/B0749JYZJ3/ref=sr_1_17?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-17",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/511NjpAhKVL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 394,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.41,
//                             "raw": "€51,41",
//                             "name": "51,41 €",
//                             "asin": "B0749JYZJ3",
//                             "link": "https://www.amazon.de/TopTie-Women-Short-Sleeve-Jacket-set1-3XL/dp/B0749JYZJ3/ref=sr_1_17?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-17"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.41,
//                         "raw": "€51,41",
//                         "name": "51,41 €",
//                         "asin": "B0749JYZJ3",
//                         "link": "https://www.amazon.de/TopTie-Women-Short-Sleeve-Jacket-set1-3XL/dp/B0749JYZJ3/ref=sr_1_17?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-17"
//                     }
//                 },
//                 {
//                     "position": 18,
//                     "title": "Black Temptation Unisex Workwear Bekleidung japanischen Chef Mantel Sushi Chef Jacke Küche Uniform, C",
//                     "asin": "B07C4V6B1Q",
//                     "link": "https://www.amazon.de/Unisex-Workwear-Bekleidung-japanischen-Uniform/dp/B07C4V6B1Q/ref=sr_1_18?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-18",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61MURnyoW8L._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 52.4,
//                             "raw": "€52,40",
//                             "asin": "B07C4V6B1Q",
//                             "link": "https://www.amazon.de/Unisex-Workwear-Bekleidung-japanischen-Uniform/dp/B07C4V6B1Q/ref=sr_1_18?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-18"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 52.4,
//                         "raw": "€52,40",
//                         "asin": "B07C4V6B1Q",
//                         "link": "https://www.amazon.de/Unisex-Workwear-Bekleidung-japanischen-Uniform/dp/B07C4V6B1Q/ref=sr_1_18?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-18"
//                     }
//                 },
//                 {
//                     "position": 19,
//                     "title": "Einweg-Hoch Kuchen Chef Hüte Papierhüte 10 PCS-Weiß",
//                     "asin": "B06XQFYZBH",
//                     "link": "https://www.amazon.de/Einweg-Hoch-Kuchen-Chef-Papierh%C3%BCte-PCS-Wei%C3%9F/dp/B06XQFYZBH/ref=sr_1_19?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-19",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41kSB2JFTrL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.02,
//                             "raw": "€56,02",
//                             "asin": "B06XQFYZBH",
//                             "link": "https://www.amazon.de/Einweg-Hoch-Kuchen-Chef-Papierh%C3%BCte-PCS-Wei%C3%9F/dp/B06XQFYZBH/ref=sr_1_19?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-19"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 56.02,
//                         "raw": "€56,02",
//                         "asin": "B06XQFYZBH",
//                         "link": "https://www.amazon.de/Einweg-Hoch-Kuchen-Chef-Papierh%C3%BCte-PCS-Wei%C3%9F/dp/B06XQFYZBH/ref=sr_1_19?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-19"
//                     }
//                 },
//                 {
//                     "position": 20,
//                     "title": "Black Temptation Japanische Sushi Koch Mantel Uniformen [L Größe für Unisex] - Küche Uniformen - B",
//                     "asin": "B07CC9XJC7",
//                     "link": "https://www.amazon.de/Japanische-Sushi-Mantel-Uniformen-Unisex/dp/B07CC9XJC7/ref=sr_1_20?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-20",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51EYNNtKYzL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 55.4,
//                             "raw": "€55,40",
//                             "asin": "B07CC9XJC7",
//                             "link": "https://www.amazon.de/Japanische-Sushi-Mantel-Uniformen-Unisex/dp/B07CC9XJC7/ref=sr_1_20?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-20"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 55.4,
//                         "raw": "€55,40",
//                         "asin": "B07CC9XJC7",
//                         "link": "https://www.amazon.de/Japanische-Sushi-Mantel-Uniformen-Unisex/dp/B07CC9XJC7/ref=sr_1_20?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-20"
//                     }
//                 },
//                 {
//                     "position": 21,
//                     "title": "(Preis / 12 PCS) Opromo Bunte Baumwollleinwand Kinderschürzen mit Tasche, Künstler-Schürze & Kochschürze (S-XXL) -GREENDOT-XXL",
//                     "asin": "B018VOGR80",
//                     "link": "https://www.amazon.de/Baumwollleinwand-Kindersch%C3%BCrzen-K%C3%BCnstler-Sch%C3%BCrze-Kochsch%C3%BCrze-GREENDOT-XXL/dp/B018VOGR80/ref=sr_1_21?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-21",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/813qd8vL+FL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 67.15,
//                             "raw": "€67,15",
//                             "asin": "B018VOGR80",
//                             "link": "https://www.amazon.de/Baumwollleinwand-Kindersch%C3%BCrzen-K%C3%BCnstler-Sch%C3%BCrze-Kochsch%C3%BCrze-GREENDOT-XXL/dp/B018VOGR80/ref=sr_1_21?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-21"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 67.15,
//                         "raw": "€67,15",
//                         "asin": "B018VOGR80",
//                         "link": "https://www.amazon.de/Baumwollleinwand-Kindersch%C3%BCrzen-K%C3%BCnstler-Sch%C3%BCrze-Kochsch%C3%BCrze-GREENDOT-XXL/dp/B018VOGR80/ref=sr_1_21?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-21"
//                     }
//                 },
//                 {
//                     "position": 22,
//                     "title": "Opromo Schürzen und Hut Kind Baumwolle Segeltuch Bunt S bis XXL 12 Sätze Grün L",
//                     "asin": "B0125V0IBW",
//                     "link": "https://www.amazon.de/Colorful-Baumwolle-Sch%C3%BCrzen-Partyzubeh%C3%B6r-Baumwoll-Canvas/dp/B0125V0IBW/ref=sr_1_22?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-22",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61bgZJ2ZvcL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 77.57,
//                             "raw": "€77,57",
//                             "asin": "B0125V0IBW",
//                             "link": "https://www.amazon.de/Colorful-Baumwolle-Sch%C3%BCrzen-Partyzubeh%C3%B6r-Baumwoll-Canvas/dp/B0125V0IBW/ref=sr_1_22?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-22"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 77.57,
//                         "raw": "€77,57",
//                         "asin": "B0125V0IBW",
//                         "link": "https://www.amazon.de/Colorful-Baumwolle-Sch%C3%BCrzen-Partyzubeh%C3%B6r-Baumwoll-Canvas/dp/B0125V0IBW/ref=sr_1_22?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-22"
//                     }
//                 },
//                 {
//                     "position": 23,
//                     "title": "WYRKYP Sonnenhut Sommer Handgefertigt 25 cm Haube Krepp Papier Sonnenhut Männer Und Frauen Freizeit Feiertag Strand Hut,57 cm",
//                     "asin": "B095SYLSMH",
//                     "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Handgefertigt-Freizeit-Feiertag/dp/B095SYLSMH/ref=sr_1_23?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-23",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/710q2V26+kL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.5,
//                             "raw": "€56,50",
//                             "asin": "B095SYLSMH",
//                             "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Handgefertigt-Freizeit-Feiertag/dp/B095SYLSMH/ref=sr_1_23?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-23"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 56.5,
//                         "raw": "€56,50",
//                         "asin": "B095SYLSMH",
//                         "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Handgefertigt-Freizeit-Feiertag/dp/B095SYLSMH/ref=sr_1_23?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-23"
//                     }
//                 },
//                 {
//                     "position": 24,
//                     "title": "WXJLYZRCXK Hut Frühling Sommerreisen Sonnencreme-Kappe Frauen Schatten/Hut, Besser Komfort/Pink/Medium",
//                     "asin": "B09MJ788HN",
//                     "link": "https://www.amazon.de/WXJLYZRCXK-Fr%C3%BChling-Sommerreisen-Sonnencreme-Kappe-Schatten/dp/B09MJ788HN/ref=sr_1_24?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-24",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61LbedrEZiL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.2,
//                             "raw": "€50,20",
//                             "asin": "B09MJ788HN",
//                             "link": "https://www.amazon.de/WXJLYZRCXK-Fr%C3%BChling-Sommerreisen-Sonnencreme-Kappe-Schatten/dp/B09MJ788HN/ref=sr_1_24?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-24"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.2,
//                         "raw": "€50,20",
//                         "asin": "B09MJ788HN",
//                         "link": "https://www.amazon.de/WXJLYZRCXK-Fr%C3%BChling-Sommerreisen-Sonnencreme-Kappe-Schatten/dp/B09MJ788HN/ref=sr_1_24?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-24"
//                     }
//                 },
//                 {
//                     "position": 25,
//                     "title": "WXJLYZRCXK Hut Hut Frauen Große Randstrand Hut Uv Schutzschatten Sonnencreme Solarkappe Ultra Feinhäkeln, Besser Komfort/Natural/Medium",
//                     "asin": "B09MJ99TL2",
//                     "link": "https://www.amazon.de/WXJLYZRCXK-Randstrand-Schutzschatten-Sonnencreme-Solarkappe/dp/B09MJ99TL2/ref=sr_1_25?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-25",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71TenlswJxL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.5,
//                             "raw": "€50,50",
//                             "asin": "B09MJ99TL2",
//                             "link": "https://www.amazon.de/WXJLYZRCXK-Randstrand-Schutzschatten-Sonnencreme-Solarkappe/dp/B09MJ99TL2/ref=sr_1_25?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-25"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.5,
//                         "raw": "€50,50",
//                         "asin": "B09MJ99TL2",
//                         "link": "https://www.amazon.de/WXJLYZRCXK-Randstrand-Schutzschatten-Sonnencreme-Solarkappe/dp/B09MJ99TL2/ref=sr_1_25?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-25"
//                     }
//                 },
//                 {
//                     "position": 26,
//                     "title": "WXJLYZRCXK Hut Spring-Summer Hat Handgewebt Solarhut Sonnencreme Schattenhhut/Hut, Besser Komfort/Natural/Medium",
//                     "asin": "B09MJ8LQV8",
//                     "link": "https://www.amazon.de/WXJLYZRCXK-Spring-Summer-Handgewebt-Sonnencreme-Schattenhhut/dp/B09MJ8LQV8/ref=sr_1_26?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-26",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71p1IjxNsbL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.2,
//                             "raw": "€51,20",
//                             "asin": "B09MJ8LQV8",
//                             "link": "https://www.amazon.de/WXJLYZRCXK-Spring-Summer-Handgewebt-Sonnencreme-Schattenhhut/dp/B09MJ8LQV8/ref=sr_1_26?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-26"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.2,
//                         "raw": "€51,20",
//                         "asin": "B09MJ8LQV8",
//                         "link": "https://www.amazon.de/WXJLYZRCXK-Spring-Summer-Handgewebt-Sonnencreme-Schattenhhut/dp/B09MJ8LQV8/ref=sr_1_26?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-26"
//                     }
//                 },
//                 {
//                     "position": 27,
//                     "title": "WYRKYP Sonnenhut Sommer Damen Hut Freizeit Strand Frauen Sonnenhut Elegant Breitrand Hut Seide Blume Eimer Hut Casual Cap Weibliche Fedora,Rotwein",
//                     "asin": "B095SWKX3Q",
//                     "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Freizeit-Breitrand-Weibliche/dp/B095SWKX3Q/ref=sr_1_27?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-27",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61SyM5zR2ML._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.2,
//                             "raw": "€54,20",
//                             "asin": "B095SWKX3Q",
//                             "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Freizeit-Breitrand-Weibliche/dp/B095SWKX3Q/ref=sr_1_27?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-27"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.2,
//                         "raw": "€54,20",
//                         "asin": "B095SWKX3Q",
//                         "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Freizeit-Breitrand-Weibliche/dp/B095SWKX3Q/ref=sr_1_27?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-27"
//                     }
//                 },
//                 {
//                     "position": 28,
//                     "title": "WYRKYP Sonnenhut Damen Stroh Sun Hut Mode Sun Sommer Hut Mädchen Damen Handgehte Strandhut,1",
//                     "asin": "B095SV1XP9",
//                     "link": "https://www.amazon.de/WYRKYP-Sonnenhut-M%C3%A4dchen-Handgehte-Strandhut/dp/B095SV1XP9/ref=sr_1_28?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-28",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61URs+S-YKL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 66.7,
//                             "raw": "€66,70",
//                             "asin": "B095SV1XP9",
//                             "link": "https://www.amazon.de/WYRKYP-Sonnenhut-M%C3%A4dchen-Handgehte-Strandhut/dp/B095SV1XP9/ref=sr_1_28?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-28"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 66.7,
//                         "raw": "€66,70",
//                         "asin": "B095SV1XP9",
//                         "link": "https://www.amazon.de/WYRKYP-Sonnenhut-M%C3%A4dchen-Handgehte-Strandhut/dp/B095SV1XP9/ref=sr_1_28?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-28"
//                     }
//                 },
//                 {
//                     "position": 29,
//                     "title": "WYRKYP Sun Hut Faltbarer Sonnenhut Frau Breitseite Sommer Strand Weiche Gesicht Dame Strohhut Mode Sonnenhut,Wie Das Bild",
//                     "asin": "B095SSCC1Y",
//                     "link": "https://www.amazon.de/WYRKYP-Faltbarer-Sonnenhut-Breitseite-Strohhut/dp/B095SSCC1Y/ref=sr_1_29?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-29",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61qQpwS2EJL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 76.8,
//                             "raw": "€76,80",
//                             "asin": "B095SSCC1Y",
//                             "link": "https://www.amazon.de/WYRKYP-Faltbarer-Sonnenhut-Breitseite-Strohhut/dp/B095SSCC1Y/ref=sr_1_29?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-29"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 76.8,
//                         "raw": "€76,80",
//                         "asin": "B095SSCC1Y",
//                         "link": "https://www.amazon.de/WYRKYP-Faltbarer-Sonnenhut-Breitseite-Strohhut/dp/B095SSCC1Y/ref=sr_1_29?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-29"
//                     }
//                 },
//                 {
//                     "position": 30,
//                     "title": "WXJLYZRCXK Hub Hanf Spitze Druckkopf Tragen Feder Bar Decoration Mode Kopf Band Bankett Hochzeitskleid Zylinder, Besser Komfort/White/M",
//                     "asin": "B09LV9XDQB",
//                     "link": "https://www.amazon.de/WXJLYZRCXK-Druckkopf-Decoration-Hochzeitskleid-Zylinder/dp/B09LV9XDQB/ref=sr_1_30?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-30",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61xSY-+xD0L._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.2,
//                             "raw": "€50,20",
//                             "asin": "B09LV9XDQB",
//                             "link": "https://www.amazon.de/WXJLYZRCXK-Druckkopf-Decoration-Hochzeitskleid-Zylinder/dp/B09LV9XDQB/ref=sr_1_30?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-30"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.2,
//                         "raw": "€50,20",
//                         "asin": "B09LV9XDQB",
//                         "link": "https://www.amazon.de/WXJLYZRCXK-Druckkopf-Decoration-Hochzeitskleid-Zylinder/dp/B09LV9XDQB/ref=sr_1_30?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-30"
//                     }
//                 },
//                 {
//                     "position": 31,
//                     "title": "WYRKYP Sonnenhut Dame Hut Strohhut Sommer Strand Sonnenhut Fischer Strohhut Uv-Schutz,Beige Eine Größe",
//                     "asin": "B094NPS76Z",
//                     "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Strohhut-Fischer-Uv-Schutz/dp/B094NPS76Z/ref=sr_1_31?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-31",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/617dWO1vxLL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.6,
//                             "raw": "€74,60",
//                             "asin": "B094NPS76Z",
//                             "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Strohhut-Fischer-Uv-Schutz/dp/B094NPS76Z/ref=sr_1_31?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-31"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 74.6,
//                         "raw": "€74,60",
//                         "asin": "B094NPS76Z",
//                         "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Strohhut-Fischer-Uv-Schutz/dp/B094NPS76Z/ref=sr_1_31?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-31"
//                     }
//                 },
//                 {
//                     "position": 32,
//                     "title": "WYRKYP Sonnenhut Sommer Frauen Vintage Black White Damen Kaffee Holiday Beach Sunshade Haus Hut,Schwarze Größe",
//                     "asin": "B095SV9XYS",
//                     "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Vintage-Sunshade-Schwarze/dp/B095SV9XYS/ref=sr_1_32?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-32",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61-iExAJDdL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 67.8,
//                             "raw": "€67,80",
//                             "asin": "B095SV9XYS",
//                             "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Vintage-Sunshade-Schwarze/dp/B095SV9XYS/ref=sr_1_32?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-32"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 67.8,
//                         "raw": "€67,80",
//                         "asin": "B095SV9XYS",
//                         "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Vintage-Sunshade-Schwarze/dp/B095SV9XYS/ref=sr_1_32?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-32"
//                     }
//                 },
//                 {
//                     "position": 33,
//                     "title": "WYRKYP Sonnenhut Sommer Strohhut Sonnenhut Dame Feste Bogen Verstellbarer Seiten Schweißgürtel Sommer Atmungsaktive Frau,Navy Eine Größe.",
//                     "asin": "B095SW6RCP",
//                     "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Verstellbarer-Schwei%C3%9Fg%C3%BCrtel-Atmungsaktive/dp/B095SW6RCP/ref=sr_1_33?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-33",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Ag4tF8qoL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 58.8,
//                             "raw": "€58,80",
//                             "asin": "B095SW6RCP",
//                             "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Verstellbarer-Schwei%C3%9Fg%C3%BCrtel-Atmungsaktive/dp/B095SW6RCP/ref=sr_1_33?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-33"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 58.8,
//                         "raw": "€58,80",
//                         "asin": "B095SW6RCP",
//                         "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Verstellbarer-Schwei%C3%9Fg%C3%BCrtel-Atmungsaktive/dp/B095SW6RCP/ref=sr_1_33?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-33"
//                     }
//                 },
//                 {
//                     "position": 34,
//                     "title": "Headsweats Cool Cap",
//                     "asin": "B09ZKBM5N6",
//                     "link": "https://www.amazon.de/Headsweats-Damen-Kochm%C3%BCtze-Pitch-Einheitsgr%C3%B6%C3%9Fe/dp/B09ZKBM5N6/ref=sr_1_34?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-34",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61P2yp+gx6L._AC_UL400_.jpg",
//                     "rating": 4,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 27.41,
//                             "raw": "€27,41",
//                             "name": "27,41 €",
//                             "asin": "B09ZKBM5N6",
//                             "link": "https://www.amazon.de/Headsweats-Damen-Kochm%C3%BCtze-Pitch-Einheitsgr%C3%B6%C3%9Fe/dp/B09ZKBM5N6/ref=sr_1_34?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-34"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 27.41,
//                         "raw": "€27,41",
//                         "name": "27,41 €",
//                         "asin": "B09ZKBM5N6",
//                         "link": "https://www.amazon.de/Headsweats-Damen-Kochm%C3%BCtze-Pitch-Einheitsgr%C3%B6%C3%9Fe/dp/B09ZKBM5N6/ref=sr_1_34?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-34"
//                     }
//                 },
//                 {
//                     "position": 35,
//                     "title": "WYRKYP Sonnenhut Sonnenhut Frauen Strohhut Sommer Strand Sonnenhut Damen Retro Wide-Breved Mode Damenhut,Einstellbar",
//                     "asin": "B095SSJP4J",
//                     "link": "https://www.amazon.de/WYRKYP-Sonnenhut-Strohhut-Wide-Breved-Einstellbar/dp/B095SSJP4J/ref=sr_1_35?keywords=Kochm%C3%BCtze&qid=1684074454&refinements=p_36%3A5000-10000&sr=8-35",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/614PfS8gxdL._AC_UL400_.jpg"
//                 }
//             ]
//         },
//         {
//             "idea": "Kochschürze",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "APRONISTA Schürze/Kochschürze, BBQSchürze und LatzSchürze ideal für Barista, Barkeeper, Florist, Bäcker, Friseur - Aus Baumwolle und Rindsleder - Hergestellt in der EU Schwarz, 380 g",
//                     "asin": "B0848CCMJD",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfYXRmOjIwMDg1MzE4OTgxOTk4OjowOjo&url=%2FAPRONISTA-Kochsch%25C3%25BCrze-BBQSch%25C3%25BCrze-LatzSch%25C3%25BCrze-Barkeeper%2Fdp%2FB0848CCMJD%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51IuS2hf9aL._AC_UL400_.jpg",
//                     "unit_price": "59,00 €/stück",
//                     "rating": 4.1,
//                     "ratings_total": 90,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59,
//                             "raw": "€59,00",
//                             "name": "59,00 €",
//                             "asin": "B0848CCMJD",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfYXRmOjIwMDg1MzE4OTgxOTk4OjowOjo&url=%2FAPRONISTA-Kochsch%25C3%25BCrze-BBQSch%25C3%25BCrze-LatzSch%25C3%25BCrze-Barkeeper%2Fdp%2FB0848CCMJD%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59,
//                         "raw": "€59,00",
//                         "name": "59,00 €",
//                         "asin": "B0848CCMJD",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfYXRmOjIwMDg1MzE4OTgxOTk4OjowOjo&url=%2FAPRONISTA-Kochsch%25C3%25BCrze-BBQSch%25C3%25BCrze-LatzSch%25C3%25BCrze-Barkeeper%2Fdp%2FB0848CCMJD%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "Black Forest Fox Büffel Leder Schürze Grillschürze BBQ Kochschürze Küchenschürze",
//                     "asin": "B0B1MSZ9Y8",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfYXRmOjIwMTAyMzEyODA3Mzk4OjowOjo&url=%2FBlack-Forest-Fox-Grillsch%25C3%25BCrze-K%25C3%25BCchensch%25C3%25BCrze%2Fdp%2FB0B1MSZ9Y8%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1%26smid%3DAUP2JYKQDAA1L",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51mqQ10KVvL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 392,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.9,
//                             "raw": "€69,90",
//                             "name": "69,90 €",
//                             "asin": "B0B1MSZ9Y8",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfYXRmOjIwMTAyMzEyODA3Mzk4OjowOjo&url=%2FBlack-Forest-Fox-Grillsch%25C3%25BCrze-K%25C3%25BCchensch%25C3%25BCrze%2Fdp%2FB0B1MSZ9Y8%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1%26smid%3DAUP2JYKQDAA1L"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.9,
//                         "raw": "€69,90",
//                         "name": "69,90 €",
//                         "asin": "B0B1MSZ9Y8",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfYXRmOjIwMTAyMzEyODA3Mzk4OjowOjo&url=%2FBlack-Forest-Fox-Grillsch%25C3%25BCrze-K%25C3%25BCchensch%25C3%25BCrze%2Fdp%2FB0B1MSZ9Y8%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1%26smid%3DAUP2JYKQDAA1L"
//                     }
//                 },
//                 {
//                     "position": 3,
//                     "title": "X APRONISTA® | Schürze & Kochschürze, BBQ Schürze, Grill Schürze und Latz Schürze | Kochschürze grau MEDIUM (M) - Aus Baumwolle und Rindsleder - Hergestellt in der EU, 380 g",
//                     "asin": "B0848M8MZ3",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfYXRmOjIwMDkyNjkyMDE2Mjk4OjowOjo&url=%2FAPRONISTA-Kochsch%25C3%25BCrze-BBQSch%25C3%25BCrze-LatzSch%25C3%25BCrze-Barkeeper%2Fdp%2FB0848M8MZ3%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Nvv7FqEyL._AC_UL400_.jpg",
//                     "unit_price": "59,00 €/stück",
//                     "rating": 5,
//                     "ratings_total": 2,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59,
//                             "raw": "€59,00",
//                             "name": "59,00 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 68,
//                             "raw": "€68,00",
//                             "name": "59,00 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59,
//                         "raw": "€59,00",
//                         "name": "59,00 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 4,
//                     "title": "Vondrak design Elegante Kochschürze Damen | Küchenschürze Damen mit 1 Tasche | Perfekte Schürze für Frauen | Schürzen mit Motiv aus hochwertigem Nylon | Unisex-Größe",
//                     "asin": "B07PY8YYVM",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfYXRmOjIwMTQ3NzQyMzE3NTk4OjowOjo&url=%2FVondrak-Provence-Sch%25C3%25BCrze-K%25C3%25BCchensch%25C3%25BCrze-Grillsch%25C3%25BCrze%2Fdp%2FB07PY8YYVM%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1%26smid%3DAK90QSGPD2BHZ",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71BlAmoFYiL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 61,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "54,99 €",
//                             "asin": "B07PY8YYVM",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfYXRmOjIwMTQ3NzQyMzE3NTk4OjowOjo&url=%2FVondrak-Provence-Sch%25C3%25BCrze-K%25C3%25BCchensch%25C3%25BCrze-Grillsch%25C3%25BCrze%2Fdp%2FB07PY8YYVM%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1%26smid%3DAK90QSGPD2BHZ"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.99,
//                         "raw": "€54,99",
//                         "name": "54,99 €",
//                         "asin": "B07PY8YYVM",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfYXRmOjIwMTQ3NzQyMzE3NTk4OjowOjo&url=%2FVondrak-Provence-Sch%25C3%25BCrze-K%25C3%25BCchensch%25C3%25BCrze-Grillsch%25C3%25BCrze%2Fdp%2FB07PY8YYVM%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1%26smid%3DAK90QSGPD2BHZ"
//                     }
//                 },
//                 {
//                     "position": 5,
//                     "title": "Le Creuset Schürze mit Taschen, Schlaufe und Bandzugsystem, Einheitsgröße, Schwarz, Baumwoll-Canvas, 95000700000000",
//                     "asin": "B000NY8Y5G",
//                     "link": "https://www.amazon.de/Le-Creuset-Sch%C3%BCrze-Klassik-schwarz/dp/B000NY8Y5G/ref=sr_1_5?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-5",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51DvARVE1VL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 194,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.81,
//                             "raw": "€59,81",
//                             "name": "59,81 €",
//                             "asin": "B000NY8Y5G",
//                             "link": "https://www.amazon.de/Le-Creuset-Sch%C3%BCrze-Klassik-schwarz/dp/B000NY8Y5G/ref=sr_1_5?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-5"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.81,
//                         "raw": "€59,81",
//                         "name": "59,81 €",
//                         "asin": "B000NY8Y5G",
//                         "link": "https://www.amazon.de/Le-Creuset-Sch%C3%BCrze-Klassik-schwarz/dp/B000NY8Y5G/ref=sr_1_5?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-5"
//                     }
//                 },
//                 {
//                     "position": 6,
//                     "title": "DALSTRONG Professionelle Kochschürze - 100% Baumwoll-Denim - mit 4 Taschen - wasserabweisend - Details aus echtem Leder - verstellbare Gurte",
//                     "asin": "B07P1917NZ",
//                     "link": "https://www.amazon.de/Dalstrong-Professional-Chefs-Kitchen-Apron/dp/B07P1917NZ/ref=sr_1_6?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-6",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71QBfj2CeLL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 168,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 60,
//                             "raw": "€60,00",
//                             "name": "60,00 €",
//                             "asin": "B07P1917NZ",
//                             "link": "https://www.amazon.de/Dalstrong-Professional-Chefs-Kitchen-Apron/dp/B07P1917NZ/ref=sr_1_6?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-6"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 60,
//                         "raw": "€60,00",
//                         "name": "60,00 €",
//                         "asin": "B07P1917NZ",
//                         "link": "https://www.amazon.de/Dalstrong-Professional-Chefs-Kitchen-Apron/dp/B07P1917NZ/ref=sr_1_6?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-6"
//                     }
//                 },
//                 {
//                     "position": 7,
//                     "title": "APRONISTA Schürze/Kochschürze, BBQSchürze und LatzSchürze ideal für Barista, Barkeeper, Florist, Bäcker, Friseur - Aus Baumwolle und Rindsleder - Hergestellt in der EU Schwarz, 380 g",
//                     "asin": "B0848CCMJD",
//                     "link": "https://www.amazon.de/APRONISTA-Kochsch%C3%BCrze-BBQSch%C3%BCrze-LatzSch%C3%BCrze-Barkeeper/dp/B0848CCMJD/ref=sr_1_7?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-7",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51IuS2hf9aL._AC_UL400_.jpg",
//                     "unit_price": "59,00 €/stück",
//                     "rating": 4.1,
//                     "ratings_total": 90,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59,
//                             "raw": "€59,00",
//                             "name": "59,00 €",
//                             "asin": "B0848CCMJD",
//                             "link": "https://www.amazon.de/APRONISTA-Kochsch%C3%BCrze-BBQSch%C3%BCrze-LatzSch%C3%BCrze-Barkeeper/dp/B0848CCMJD/ref=sr_1_7?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-7"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59,
//                         "raw": "€59,00",
//                         "name": "59,00 €",
//                         "asin": "B0848CCMJD",
//                         "link": "https://www.amazon.de/APRONISTA-Kochsch%C3%BCrze-BBQSch%C3%BCrze-LatzSch%C3%BCrze-Barkeeper/dp/B0848CCMJD/ref=sr_1_7?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-7"
//                     }
//                 },
//                 {
//                     "position": 8,
//                     "title": "GEFU Grillschürze BBQ, Kochschürze, Küchenschürze, Baumwolle und Leder, Schwarz / Braun, ca. 90 x 65 cm",
//                     "asin": "B07N7SW6JQ",
//                     "link": "https://www.amazon.de/GEFU-89421-Gefu-Grillsch%C3%BCrze/dp/B07N7SW6JQ/ref=sr_1_8?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-8",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41SQBUdSWqL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 117,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 52.37,
//                             "raw": "€52,37",
//                             "name": "52,37 €",
//                             "asin": "B07N7SW6JQ",
//                             "link": "https://www.amazon.de/GEFU-89421-Gefu-Grillsch%C3%BCrze/dp/B07N7SW6JQ/ref=sr_1_8?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-8"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 52.37,
//                         "raw": "€52,37",
//                         "name": "52,37 €",
//                         "asin": "B07N7SW6JQ",
//                         "link": "https://www.amazon.de/GEFU-89421-Gefu-Grillsch%C3%BCrze/dp/B07N7SW6JQ/ref=sr_1_8?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-8"
//                     }
//                 },
//                 {
//                     "position": 9,
//                     "title": "Vondrak design Elegante Kochschürze Damen | Küchenschürze Damen mit 1 Tasche | Perfekte Schürze für Frauen | Schürzen mit Motiv aus hochwertigem Nylon | Unisex-Größe",
//                     "asin": "B07PY8YYVM",
//                     "link": "https://www.amazon.de/Vondrak-Provence-Sch%C3%BCrze-K%C3%BCchensch%C3%BCrze-Grillsch%C3%BCrze/dp/B07PY8YYVM/ref=sr_1_9?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-9",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71BlAmoFYiL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 61,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "54,99 €",
//                             "asin": "B07PY8YYVM",
//                             "link": "https://www.amazon.de/Vondrak-Provence-Sch%C3%BCrze-K%C3%BCchensch%C3%BCrze-Grillsch%C3%BCrze/dp/B07PY8YYVM/ref=sr_1_9?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-9"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.99,
//                         "raw": "€54,99",
//                         "name": "54,99 €",
//                         "asin": "B07PY8YYVM",
//                         "link": "https://www.amazon.de/Vondrak-Provence-Sch%C3%BCrze-K%C3%BCchensch%C3%BCrze-Grillsch%C3%BCrze/dp/B07PY8YYVM/ref=sr_1_9?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-9"
//                     }
//                 },
//                 {
//                     "position": 10,
//                     "title": "DRAKENSBERG Grillschürze 'Bob' - Profi Koch-Schürze aus Leder für BBQ, Gastro, Bar, Barista, Geschenk für Männer Herren, nachhaltig handgemacht, Leder, retro-vintage",
//                     "asin": "B0B833QTJP",
//                     "link": "https://www.amazon.de/DRAKENSBERG-Grillsch%C3%BCrze-Bob-Koch-Sch%C3%BCrze-retro-vintage/dp/B0B833QTJP/ref=sr_1_10?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-10",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71BiPhOf+uL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 6,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99,
//                             "raw": "€99,00",
//                             "name": "99,00 €",
//                             "asin": "B0B833QTJP",
//                             "link": "https://www.amazon.de/DRAKENSBERG-Grillsch%C3%BCrze-Bob-Koch-Sch%C3%BCrze-retro-vintage/dp/B0B833QTJP/ref=sr_1_10?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-10"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99,
//                         "raw": "€99,00",
//                         "name": "99,00 €",
//                         "asin": "B0B833QTJP",
//                         "link": "https://www.amazon.de/DRAKENSBERG-Grillsch%C3%BCrze-Bob-Koch-Sch%C3%BCrze-retro-vintage/dp/B0B833QTJP/ref=sr_1_10?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-10"
//                     }
//                 },
//                 {
//                     "position": 11,
//                     "title": "DESERMO 5er Set Premium Latzschürze 100x80cm (LXB) - Verstellbare Küchenschürze für Frau & Mann – Kochschürze aus Baumwollmischung - Schürze mit großer Farbauswahl - 220g/m²(Braun)",
//                     "asin": "B01E2WSBFW",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDMzMzE1NDI5NTA3OjowOjo&url=%2FDESERMO-Latzsch%25C3%25BCrzen-braun-Baumwolle-Polyester%2Fdp%2FB01E2WSBFW%2Fref%3Dsr_1_11_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-11-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71bGBiu53hL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 315,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 53,
//                             "raw": "€53,00",
//                             "name": "53,00 €",
//                             "asin": "B01E2WSBFW",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDMzMzE1NDI5NTA3OjowOjo&url=%2FDESERMO-Latzsch%25C3%25BCrzen-braun-Baumwolle-Polyester%2Fdp%2FB01E2WSBFW%2Fref%3Dsr_1_11_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-11-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 53,
//                         "raw": "€53,00",
//                         "name": "53,00 €",
//                         "asin": "B01E2WSBFW",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDMzMzE1NDI5NTA3OjowOjo&url=%2FDESERMO-Latzsch%25C3%25BCrzen-braun-Baumwolle-Polyester%2Fdp%2FB01E2WSBFW%2Fref%3Dsr_1_11_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-11-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 12,
//                     "title": "REDSALT® Lederschürze 100% Büffel Leder 84x62cm braun | Profi BBQ Grillschürze Arbeitsschürze Küche Gastronomie Bar Barista | Kochschürze Küchenschürze Schürze Geschenke Männer",
//                     "asin": "B0BMGYNJ4Y",
//                     "link": "https://www.amazon.de/Ledersch%C3%BCrze-Grillsch%C3%BCrze-Arbeitssch%C3%BCrze-Gastronomie-K%C3%BCchensch%C3%BCrze/dp/B0BMGYNJ4Y/ref=sr_1_12?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-12",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71ssxLf1P4L._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 3,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.95,
//                             "raw": "€79,95",
//                             "name": "79,95 €",
//                             "asin": "B0BMGYNJ4Y",
//                             "link": "https://www.amazon.de/Ledersch%C3%BCrze-Grillsch%C3%BCrze-Arbeitssch%C3%BCrze-Gastronomie-K%C3%BCchensch%C3%BCrze/dp/B0BMGYNJ4Y/ref=sr_1_12?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-12"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.95,
//                         "raw": "€79,95",
//                         "name": "79,95 €",
//                         "asin": "B0BMGYNJ4Y",
//                         "link": "https://www.amazon.de/Ledersch%C3%BCrze-Grillsch%C3%BCrze-Arbeitssch%C3%BCrze-Gastronomie-K%C3%BCchensch%C3%BCrze/dp/B0BMGYNJ4Y/ref=sr_1_12?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-12"
//                     }
//                 },
//                 {
//                     "position": 13,
//                     "title": "DESERMO 5er Set Premium Latzschürze 100x80cm (LXB) - Verstellbare Küchenschürze für Frau & Mann – Kochschürze aus Baumwollmischung - Schürze mit großer Farbauswahl - 210g/m²(Weiß)",
//                     "asin": "B08Q469TVM",
//                     "link": "https://www.amazon.de/DESERMO-verstellbare-Latzsch%C3%BCrze-Hochwertige-Profi-Sch%C3%BCrze/dp/B08Q469TVM/ref=sr_1_13?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-13",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41EJYnk540L._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 10 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 315,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 53,
//                             "raw": "€53,00",
//                             "name": "53,00 €",
//                             "asin": "B08Q469TVM",
//                             "link": "https://www.amazon.de/DESERMO-verstellbare-Latzsch%C3%BCrze-Hochwertige-Profi-Sch%C3%BCrze/dp/B08Q469TVM/ref=sr_1_13?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-13"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 53,
//                         "raw": "€53,00",
//                         "name": "53,00 €",
//                         "asin": "B08Q469TVM",
//                         "link": "https://www.amazon.de/DESERMO-verstellbare-Latzsch%C3%BCrze-Hochwertige-Profi-Sch%C3%BCrze/dp/B08Q469TVM/ref=sr_1_13?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-13"
//                     }
//                 },
//                 {
//                     "position": 14,
//                     "title": "DESERMO Premium Schürze 50x80cm (LxB) | Hochwertige Kochschürze-Damen-Herren schützt beim Kochen und Backen | Bequeme Kellnerschürze über Bauchband flexibel anpassbar | Vorbinder-Stoffgewicht 210g/m²",
//                     "asin": "B085J111JB",
//                     "link": "https://www.amazon.de/DESERMO-Vorbinder-Hochwertige-Taillen-Sch%C3%BCrze-Baumwolle/dp/B085J111JB/ref=sr_1_14?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-14",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/818OepJgh6L._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 274,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 58,
//                             "raw": "€58,00",
//                             "name": "58,00 €",
//                             "asin": "B085J111JB",
//                             "link": "https://www.amazon.de/DESERMO-Vorbinder-Hochwertige-Taillen-Sch%C3%BCrze-Baumwolle/dp/B085J111JB/ref=sr_1_14?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-14"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 58,
//                         "raw": "€58,00",
//                         "name": "58,00 €",
//                         "asin": "B085J111JB",
//                         "link": "https://www.amazon.de/DESERMO-Vorbinder-Hochwertige-Taillen-Sch%C3%BCrze-Baumwolle/dp/B085J111JB/ref=sr_1_14?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-14"
//                     }
//                 },
//                 {
//                     "position": 15,
//                     "title": "DESERMO Basic Vorbinder 60x80cm (LXB) - Hochwertige Schürze für Frau & Mann - Extra leichte Kochschürze aus Baumwollmischung - 190g/m²(Weiß)",
//                     "asin": "B0876YZSF3",
//                     "link": "https://www.amazon.de/DESERMO-Vorbinder-Hervorragende-Taillen-Sch%C3%BCrze-Innovative/dp/B0876YZSF3/ref=sr_1_15?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-15",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91SFjxU+HPL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 179,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.99,
//                             "raw": "€89,99",
//                             "name": "89,99 €",
//                             "asin": "B0876YZSF3",
//                             "link": "https://www.amazon.de/DESERMO-Vorbinder-Hervorragende-Taillen-Sch%C3%BCrze-Innovative/dp/B0876YZSF3/ref=sr_1_15?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-15"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.99,
//                         "raw": "€89,99",
//                         "name": "89,99 €",
//                         "asin": "B0876YZSF3",
//                         "link": "https://www.amazon.de/DESERMO-Vorbinder-Hervorragende-Taillen-Sch%C3%BCrze-Innovative/dp/B0876YZSF3/ref=sr_1_15?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-15"
//                     }
//                 },
//                 {
//                     "position": 16,
//                     "title": "BURNHARD Grillschürze Leder Schwarz, Lederschürze 100% Büffelleder, Größenverstellbar, BBQ-Schürze mit Taschen, Kochschürze",
//                     "asin": "B0BBMNQ7RW",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDkzMDg0NzkwODk4OjowOjo&url=%2FBURNHARD-Grillsch%25C3%25BCrze-Ledersch%25C3%25BCrze-B%25C3%25BCffelleder-Gr%25C3%25B6%25C3%259Fenverstellbar%2Fdp%2FB0BBMNQ7RW%2Fref%3Dsr_1_16_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-16-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/6110BauPkzL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 10 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 35,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.9,
//                             "raw": "€79,90",
//                             "name": "79,90 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.9,
//                             "raw": "€99,90",
//                             "name": "79,90 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.9,
//                         "raw": "€79,90",
//                         "name": "79,90 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 17,
//                     "title": "your surprise Personalisierbare Lederschürze zum Grillen - Für Männer und Frauen personalisierbar als Geschenk für Grill- und Kochbegeisterte",
//                     "asin": "B073PC7G44",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDk2NDc4NjUwNzk4OjowOjo&url=%2FYourSurprise-Grillsch%25C3%25BCrze-Leder-PERSONALISIERBAR-gemacht%2Fdp%2FB073PC7G44%2Fref%3Dsr_1_17_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-17-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81uIUy3j3fL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 457,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.95,
//                             "raw": "€69,95",
//                             "name": "69,95 €",
//                             "asin": "B073PC7G44",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDk2NDc4NjUwNzk4OjowOjo&url=%2FYourSurprise-Grillsch%25C3%25BCrze-Leder-PERSONALISIERBAR-gemacht%2Fdp%2FB073PC7G44%2Fref%3Dsr_1_17_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-17-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.95,
//                         "raw": "€69,95",
//                         "name": "69,95 €",
//                         "asin": "B073PC7G44",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDk2NDc4NjUwNzk4OjowOjo&url=%2FYourSurprise-Grillsch%25C3%25BCrze-Leder-PERSONALISIERBAR-gemacht%2Fdp%2FB073PC7G44%2Fref%3Dsr_1_17_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-17-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 18,
//                     "title": "DESERMO Premium Vorbinder 80x100cm (LxB) - Hochwertige Profi-Schürze für Frau & Mann - Küchenschürze aus Baumwollmischung - Schürze mit großer Farbauswahl",
//                     "asin": "B085TQWN62",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDM0ODM2NDg5ODA3OjowOjo&url=%2FDESERMO-Hochwertige-Taillen-Sch%25C3%25BCrze-Innovative-Farbauswahl%2Fdp%2FB085TQWN62%2Fref%3Dsr_1_18_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-18-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71WrBCGOeKL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 96,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89,
//                             "raw": "€89,00",
//                             "name": "89,00 €",
//                             "asin": "B085TQWN62",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDM0ODM2NDg5ODA3OjowOjo&url=%2FDESERMO-Hochwertige-Taillen-Sch%25C3%25BCrze-Innovative-Farbauswahl%2Fdp%2FB085TQWN62%2Fref%3Dsr_1_18_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-18-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89,
//                         "raw": "€89,00",
//                         "name": "89,00 €",
//                         "asin": "B085TQWN62",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDM0ODM2NDg5ODA3OjowOjo&url=%2FDESERMO-Hochwertige-Taillen-Sch%25C3%25BCrze-Innovative-Farbauswahl%2Fdp%2FB085TQWN62%2Fref%3Dsr_1_18_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-18-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 19,
//                     "title": "MOESTA-BBQ 19280 - Schwarze Voll-Leder-Grillschürze – Lederschürze mit Nackenriemen – Einstellbare Unisex Kochschürze",
//                     "asin": "B083QM66M3",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMTE0Mjg2NjM5Mjk4OjowOjo&url=%2FMOESTA-BBQ-Grillsch%25C3%25BCrze-Ledersch%25C3%25BCrze-Vollrindleder-Spritzschutz%2Fdp%2FB083QM66M3%2Fref%3Dsr_1_19_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-19-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA16YY382J14ME2",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41iKpbVwNoS._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 7,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.9,
//                             "raw": "€99,90",
//                             "name": "99,90 €",
//                             "asin": "B083QM66M3",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMTE0Mjg2NjM5Mjk4OjowOjo&url=%2FMOESTA-BBQ-Grillsch%25C3%25BCrze-Ledersch%25C3%25BCrze-Vollrindleder-Spritzschutz%2Fdp%2FB083QM66M3%2Fref%3Dsr_1_19_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-19-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA16YY382J14ME2"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99.9,
//                         "raw": "€99,90",
//                         "name": "99,90 €",
//                         "asin": "B083QM66M3",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMTE0Mjg2NjM5Mjk4OjowOjo&url=%2FMOESTA-BBQ-Grillsch%25C3%25BCrze-Ledersch%25C3%25BCrze-Vollrindleder-Spritzschutz%2Fdp%2FB083QM66M3%2Fref%3Dsr_1_19_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-19-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA16YY382J14ME2"
//                     }
//                 },
//                 {
//                     "position": 20,
//                     "title": "Angus Stoke Lederschürze Grillen - Vollleder Grillschürze - Vintage Leder Schürze - BBQ & Kochschürze - Henry",
//                     "asin": "B092DJVX5C",
//                     "link": "https://www.amazon.de/Ledersch%C3%BCrze-Grillen-Vollleder-Grillsch%C3%BCrze-Kochsch%C3%BCrze/dp/B092DJVX5C/ref=sr_1_20?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-20",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/914DYlMZk-L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 3,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.9,
//                             "raw": "€59,90",
//                             "name": "59,90 €",
//                             "asin": "B092DJVX5C",
//                             "link": "https://www.amazon.de/Ledersch%C3%BCrze-Grillen-Vollleder-Grillsch%C3%BCrze-Kochsch%C3%BCrze/dp/B092DJVX5C/ref=sr_1_20?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-20"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.9,
//                         "raw": "€59,90",
//                         "name": "59,90 €",
//                         "asin": "B092DJVX5C",
//                         "link": "https://www.amazon.de/Ledersch%C3%BCrze-Grillen-Vollleder-Grillsch%C3%BCrze-Kochsch%C3%BCrze/dp/B092DJVX5C/ref=sr_1_20?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-20"
//                     }
//                 },
//                 {
//                     "position": 21,
//                     "title": "Vondrak design Elegante Kochschürze Damen - Vintage | Küchenschürze Damen mit 1 Tasche | Perfekte Schürze für Frauen | Schürzen mit Motiv aus hochwertigem Nylon | Unisex-Größe",
//                     "asin": "B0B2P44Z9L",
//                     "link": "https://www.amazon.de/Vondrak-design-Elegante-Kochsch%C3%BCrze-Damen/dp/B0B2P44Z9L/ref=sr_1_21?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-21",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61oXxbYEdlL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4,
//                     "ratings_total": 27,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "54,99 €",
//                             "asin": "B0B2P44Z9L",
//                             "link": "https://www.amazon.de/Vondrak-design-Elegante-Kochsch%C3%BCrze-Damen/dp/B0B2P44Z9L/ref=sr_1_21?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-21"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.99,
//                         "raw": "€54,99",
//                         "name": "54,99 €",
//                         "asin": "B0B2P44Z9L",
//                         "link": "https://www.amazon.de/Vondrak-design-Elegante-Kochsch%C3%BCrze-Damen/dp/B0B2P44Z9L/ref=sr_1_21?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-21"
//                     }
//                 },
//                 {
//                     "position": 22,
//                     "title": "RAJRANG BRINGING RAJASTHAN TO YOU Baumwollschürze mit 3 Taschen, solide Kochschürzen für Männer und Frauen, 88x69 cm",
//                     "asin": "B07GXSV359",
//                     "link": "https://www.amazon.de/RAJRANG-Marineblau-Sch%C3%BCrzen-5er-professionelles/dp/B07GXSV359/ref=sr_1_22?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-22",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61A20qi8E2L._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 450,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.19,
//                             "raw": "€56,19",
//                             "name": "56,19 €",
//                             "asin": "B07GXSV359",
//                             "link": "https://www.amazon.de/RAJRANG-Marineblau-Sch%C3%BCrzen-5er-professionelles/dp/B07GXSV359/ref=sr_1_22?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-22"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 56.19,
//                         "raw": "€56,19",
//                         "name": "56,19 €",
//                         "asin": "B07GXSV359",
//                         "link": "https://www.amazon.de/RAJRANG-Marineblau-Sch%C3%BCrzen-5er-professionelles/dp/B07GXSV359/ref=sr_1_22?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-22"
//                     }
//                 },
//                 {
//                     "position": 23,
//                     "title": "Qool24 Kochschürze 2er 4er 6er 8er 10er SET Pack Lang Schürze Latzschürze Grillschürze Gastro Kellner Restaurant Küchenschürze",
//                     "asin": "B09QYZSW6Y",
//                     "link": "https://www.amazon.de/Qool24-Kochsch%C3%BCrze-K%C3%BCchensch%C3%BCrze-Grillsch%C3%BCrze-Latzsch%C3%BCrze/dp/B09QYZSW6Y/ref=sr_1_23?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-23",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/7172y782gHL._AC_UL400_.jpg",
//                     "rating": 3.5,
//                     "ratings_total": 3,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "54,99 €",
//                             "asin": "B09QYZSW6Y",
//                             "link": "https://www.amazon.de/Qool24-Kochsch%C3%BCrze-K%C3%BCchensch%C3%BCrze-Grillsch%C3%BCrze-Latzsch%C3%BCrze/dp/B09QYZSW6Y/ref=sr_1_23?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-23"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.99,
//                         "raw": "€54,99",
//                         "name": "54,99 €",
//                         "asin": "B09QYZSW6Y",
//                         "link": "https://www.amazon.de/Qool24-Kochsch%C3%BCrze-K%C3%BCchensch%C3%BCrze-Grillsch%C3%BCrze-Latzsch%C3%BCrze/dp/B09QYZSW6Y/ref=sr_1_23?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-23"
//                     }
//                 },
//                 {
//                     "position": 24,
//                     "title": "DESERMO Premium Vorbinder 60x80cm (LxB) - Hochwertige Profi-Schürze für Frau & Mann - Küchenschürze aus Baumwollmischung - Schürze mit großer Farbauswahl",
//                     "asin": "B08DMVGF2H",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDQzNDA4MzY2NDA3OjowOjo&url=%2FDESERMO-Hochwertige-Taillen-Sch%25C3%25BCrze-Innovative-Farbauswahl%2Fdp%2FB08DMVGF2H%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81pEbq3nnGL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 463,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "asin": "B08DMVGF2H",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDQzNDA4MzY2NDA3OjowOjo&url=%2FDESERMO-Hochwertige-Taillen-Sch%25C3%25BCrze-Innovative-Farbauswahl%2Fdp%2FB08DMVGF2H%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "asin": "B08DMVGF2H",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDQzNDA4MzY2NDA3OjowOjo&url=%2FDESERMO-Hochwertige-Taillen-Sch%25C3%25BCrze-Innovative-Farbauswahl%2Fdp%2FB08DMVGF2H%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 25,
//                     "title": "DESERMO Premium Vorbinder 100cm x 100cm (L X B) - Hochwertige Profi-Schürze für Frau und Mann - Innovative Mischung aus Baumwolle und Polyester",
//                     "asin": "B084Q8LMDC",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDMzMzUxNTM5OTA3OjowOjo&url=%2FDESERMO-Vorbinder-Hochwertige-Profi-Sch%25C3%25BCrze-Innovative%2Fdp%2FB084Q8LMDC%2Fref%3Dsr_1_25_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-25-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81RpYC5jq6L._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 47,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.99,
//                             "raw": "€89,99",
//                             "name": "89,99 €",
//                             "asin": "B084Q8LMDC",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDMzMzUxNTM5OTA3OjowOjo&url=%2FDESERMO-Vorbinder-Hochwertige-Profi-Sch%25C3%25BCrze-Innovative%2Fdp%2FB084Q8LMDC%2Fref%3Dsr_1_25_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-25-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.99,
//                         "raw": "€89,99",
//                         "name": "89,99 €",
//                         "asin": "B084Q8LMDC",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDMzMzUxNTM5OTA3OjowOjo&url=%2FDESERMO-Vorbinder-Hochwertige-Profi-Sch%25C3%25BCrze-Innovative%2Fdp%2FB084Q8LMDC%2Fref%3Dsr_1_25_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-25-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 26,
//                     "title": "REDSALT® Premium Lederschürze 100% Büffel Wild Leder 84x62cm braun | Profi BBQ Grillschürze Arbeitsschürze Küche Gastronomie Bar Barista | Kochschürze Küchenschürze Schürze Geschenke für Männer",
//                     "asin": "B08KHJGPX8",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDQzMDE1NTUyNjA4OjowOjo&url=%2FLedersch%25C3%25BCrze-Grillsch%25C3%25BCrze-Gastronomie-Kochsch%25C3%25BCrze-K%25C3%25BCchensch%25C3%25BCrze%2Fdp%2FB08KHJGPX8%2Fref%3Dsr_1_26_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-26-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71x6ArKsC9L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 182,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.95,
//                             "raw": "€79,95",
//                             "name": "79,95 €",
//                             "asin": "B08KHJGPX8",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDQzMDE1NTUyNjA4OjowOjo&url=%2FLedersch%25C3%25BCrze-Grillsch%25C3%25BCrze-Gastronomie-Kochsch%25C3%25BCrze-K%25C3%25BCchensch%25C3%25BCrze%2Fdp%2FB08KHJGPX8%2Fref%3Dsr_1_26_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-26-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.95,
//                         "raw": "€79,95",
//                         "name": "79,95 €",
//                         "asin": "B08KHJGPX8",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo2NTk1MDYyNTAyNzk2Mzk2OjE2ODQwNzQ0Nzc6c3BfbXRmOjIwMDQzMDE1NTUyNjA4OjowOjo&url=%2FLedersch%25C3%25BCrze-Grillsch%25C3%25BCrze-Gastronomie-Kochsch%25C3%25BCrze-K%25C3%25BCchensch%25C3%25BCrze%2Fdp%2FB08KHJGPX8%2Fref%3Dsr_1_26_sspa%3Fkeywords%3DKochsch%25C3%25BCrze%26qid%3D1684074477%26refinements%3Dp_36%253A5000-10000%26sr%3D8-26-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 27,
//                     "title": "ZXCV Elektromagnetische Strahlenschutz Schwangere Frau Schürze EMF Shielding Anti-Strahlung stomachers",
//                     "asin": "B0813B8QRV",
//                     "link": "https://www.amazon.de/ZXCV-Elektromagnetische-Strahlenschutz-Schwangere-Anti-Strahlung/dp/B0813B8QRV/ref=sr_1_27?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-27",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51XWKkbsu7L._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 53.8,
//                             "raw": "€53,80",
//                             "name": "53,80 €",
//                             "asin": "B0813B8QRV",
//                             "link": "https://www.amazon.de/ZXCV-Elektromagnetische-Strahlenschutz-Schwangere-Anti-Strahlung/dp/B0813B8QRV/ref=sr_1_27?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-27"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 53.8,
//                         "raw": "€53,80",
//                         "name": "53,80 €",
//                         "asin": "B0813B8QRV",
//                         "link": "https://www.amazon.de/ZXCV-Elektromagnetische-Strahlenschutz-Schwangere-Anti-Strahlung/dp/B0813B8QRV/ref=sr_1_27?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-27"
//                     }
//                 },
//                 {
//                     "position": 28,
//                     "title": "ARAWAK BRAVE distressed denim schürze chef-küche bbq grill light blue handtuch schleife + quick release buckle + werkzeugtaschen einstellbare",
//                     "asin": "B0796V8Y46",
//                     "link": "https://www.amazon.de/Trends-Professionelle-Kochsch%C3%BCrze-Baumwolle-verstellbar/dp/B0796V8Y46/ref=sr_1_28?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-28",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/712HcINgbgL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 1631,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 39.39,
//                             "raw": "€39,39"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 82.13,
//                             "raw": "€82,13"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 39.39,
//                         "raw": "€39,39"
//                     }
//                 },
//                 {
//                     "position": 29,
//                     "title": "strongAnt Kochjacke Herren Langarm inkl. Kugelknöpfe easyClean schutzabweisender Stoff Slim Fit Professionelle Kochuniform Bäckerjacke",
//                     "asin": "B0BNW2GZ71",
//                     "link": "https://www.amazon.de/strongAnt-Kochjacke-Langarm-Kochsch%C3%BCrze-K%C3%BCchensch%C3%BCrze/dp/B0BNW2GZ71/ref=sr_1_29?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-29",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41x-KfWWbqL._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 352,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.95,
//                             "raw": "€54,95",
//                             "name": "54,95 €",
//                             "asin": "B0BNW2GZ71",
//                             "link": "https://www.amazon.de/strongAnt-Kochjacke-Langarm-Kochsch%C3%BCrze-K%C3%BCchensch%C3%BCrze/dp/B0BNW2GZ71/ref=sr_1_29?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-29"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.95,
//                         "raw": "€54,95",
//                         "name": "54,95 €",
//                         "asin": "B0BNW2GZ71",
//                         "link": "https://www.amazon.de/strongAnt-Kochjacke-Langarm-Kochsch%C3%BCrze-K%C3%BCchensch%C3%BCrze/dp/B0BNW2GZ71/ref=sr_1_29?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-29"
//                     }
//                 },
//                 {
//                     "position": 30,
//                     "title": "Carhartt Herren Firm Duck Schürze Apron",
//                     "asin": "B07F2N4CTZ",
//                     "link": "https://www.amazon.de/Carhartt-Sch%C3%BCrze-103439-Apron-Farbe/dp/B07F2N4CTZ/ref=sr_1_30?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-30",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/718lEGRUjiL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 879,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.77,
//                             "raw": "€51,77",
//                             "name": "51,77 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.95,
//                             "raw": "€69,95",
//                             "name": "51,77 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.77,
//                         "raw": "€51,77",
//                         "name": "51,77 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 31,
//                     "title": "Black Forest Fox Büffel Leder Schürze Grillschürze BBQ Kochschürze Küchenschürze",
//                     "asin": "B0B1MSZ9Y8",
//                     "link": "https://www.amazon.de/Black-Forest-Fox-Grillsch%C3%BCrze-K%C3%BCchensch%C3%BCrze/dp/B0B1MSZ9Y8/ref=sr_1_31?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-31",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51mqQ10KVvL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 392,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.9,
//                             "raw": "€69,90",
//                             "name": "69,90 €",
//                             "asin": "B0B1MSZ9Y8",
//                             "link": "https://www.amazon.de/Black-Forest-Fox-Grillsch%C3%BCrze-K%C3%BCchensch%C3%BCrze/dp/B0B1MSZ9Y8/ref=sr_1_31?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-31"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.9,
//                         "raw": "€69,90",
//                         "name": "69,90 €",
//                         "asin": "B0B1MSZ9Y8",
//                         "link": "https://www.amazon.de/Black-Forest-Fox-Grillsch%C3%BCrze-K%C3%BCchensch%C3%BCrze/dp/B0B1MSZ9Y8/ref=sr_1_31?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-31"
//                     }
//                 },
//                 {
//                     "position": 32,
//                     "title": "Carhartt Denim Apron Schürze - Arbeitsschürze aus robustem Denim",
//                     "asin": "B079T9QG11",
//                     "link": "https://www.amazon.de/Carhartt-Sch%C3%BCrze-103197-Apron-Denim/dp/B079T9QG11/ref=sr_1_32?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-32",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71NioxZDBOL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 142,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.61,
//                             "raw": "€50,61",
//                             "name": "50,61 €",
//                             "asin": "B079T9QG11",
//                             "link": "https://www.amazon.de/Carhartt-Sch%C3%BCrze-103197-Apron-Denim/dp/B079T9QG11/ref=sr_1_32?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-32"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.61,
//                         "raw": "€50,61",
//                         "name": "50,61 €",
//                         "asin": "B079T9QG11",
//                         "link": "https://www.amazon.de/Carhartt-Sch%C3%BCrze-103197-Apron-Denim/dp/B079T9QG11/ref=sr_1_32?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-32"
//                     }
//                 },
//                 {
//                     "position": 33,
//                     "title": "DALSTRONG Professionelle Küchenschürze",
//                     "asin": "B07NY4YCKL",
//                     "link": "https://www.amazon.de/Dalstrong-Professional-Chefs-Kitchen-Apron/dp/B07NY4YCKL/ref=sr_1_33?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-33",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81RqI-lPi9L._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 82,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 60,
//                             "raw": "€60,00",
//                             "name": "60,00 €",
//                             "asin": "B07NY4YCKL",
//                             "link": "https://www.amazon.de/Dalstrong-Professional-Chefs-Kitchen-Apron/dp/B07NY4YCKL/ref=sr_1_33?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-33"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 60,
//                         "raw": "€60,00",
//                         "name": "60,00 €",
//                         "asin": "B07NY4YCKL",
//                         "link": "https://www.amazon.de/Dalstrong-Professional-Chefs-Kitchen-Apron/dp/B07NY4YCKL/ref=sr_1_33?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-33"
//                     }
//                 },
//                 {
//                     "position": 34,
//                     "title": "SID & VAIN Lederschürze HEATHROW aus Vintage Leder I Grillschürze groß für Herren und Damen I Kochschürze Kellner Schürze handgefertigt",
//                     "asin": "B01HEZ7V6E",
//                     "link": "https://www.amazon.de/SID-VAIN-Ledersch%C3%BCrze-Grillsch%C3%BCrze-Kochsch%C3%BCrze/dp/B01HEZ7V6E/ref=sr_1_34?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-34",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81ty+at2ibL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4,
//                     "ratings_total": 26,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.9,
//                             "raw": "€89,90",
//                             "name": "89,90 €",
//                             "asin": "B01HEZ7V6E",
//                             "link": "https://www.amazon.de/SID-VAIN-Ledersch%C3%BCrze-Grillsch%C3%BCrze-Kochsch%C3%BCrze/dp/B01HEZ7V6E/ref=sr_1_34?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-34"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.9,
//                         "raw": "€89,90",
//                         "name": "89,90 €",
//                         "asin": "B01HEZ7V6E",
//                         "link": "https://www.amazon.de/SID-VAIN-Ledersch%C3%BCrze-Grillsch%C3%BCrze-Kochsch%C3%BCrze/dp/B01HEZ7V6E/ref=sr_1_34?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-34"
//                     }
//                 },
//                 {
//                     "position": 35,
//                     "title": "Angus Stoke Lederschürze Männer - Grillschürze & Kochschürze aus Echt-Leder - Vintage Patches Leder Schürze - Lenny",
//                     "asin": "B08XWKJGS3",
//                     "link": "https://www.amazon.de/Angus-Stoke-Ledersch%C3%BCrze-M%C3%A4nner-Grillsch%C3%BCrze/dp/B08XWKJGS3/ref=sr_1_35?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-35",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81bWNpuHEzL._AC_UL400_.jpg",
//                     "rating": 4.8,
//                     "ratings_total": 9,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.9,
//                             "raw": "€56,90",
//                             "name": "56,90 €",
//                             "asin": "B08XWKJGS3",
//                             "link": "https://www.amazon.de/Angus-Stoke-Ledersch%C3%BCrze-M%C3%A4nner-Grillsch%C3%BCrze/dp/B08XWKJGS3/ref=sr_1_35?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-35"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 56.9,
//                         "raw": "€56,90",
//                         "name": "56,90 €",
//                         "asin": "B08XWKJGS3",
//                         "link": "https://www.amazon.de/Angus-Stoke-Ledersch%C3%BCrze-M%C3%A4nner-Grillsch%C3%BCrze/dp/B08XWKJGS3/ref=sr_1_35?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-35"
//                     }
//                 },
//                 {
//                     "position": 36,
//                     "title": "Caldo Küchenschürze aus Baumwolle, für Herren und Damen, professionelle Kochschürze – verstellbare Träger mit Taschen und Handtuchschlaufe",
//                     "asin": "B07DRN76QM",
//                     "link": "https://www.amazon.de/Caldo-K%C3%BCchensch%C3%BCrze-Baumwolle-Professionelle-Handtuchschlaufe/dp/B07DRN76QM/ref=sr_1_36?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-36",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81TajWIdd7L._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 383,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.74,
//                             "raw": "€50,74",
//                             "name": "50,74 €",
//                             "asin": "B07DRN76QM",
//                             "link": "https://www.amazon.de/Caldo-K%C3%BCchensch%C3%BCrze-Baumwolle-Professionelle-Handtuchschlaufe/dp/B07DRN76QM/ref=sr_1_36?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-36"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.74,
//                         "raw": "€50,74",
//                         "name": "50,74 €",
//                         "asin": "B07DRN76QM",
//                         "link": "https://www.amazon.de/Caldo-K%C3%BCchensch%C3%BCrze-Baumwolle-Professionelle-Handtuchschlaufe/dp/B07DRN76QM/ref=sr_1_36?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-36"
//                     }
//                 },
//                 {
//                     "position": 37,
//                     "title": "SID & VAIN Lederschürze DEAN aus Premium Leder I Grillschürze groß für Herren und Damen I Kochschürze Kellner Schürze braun handgefertigt",
//                     "asin": "B07BXTFCGS",
//                     "link": "https://www.amazon.de/SID-VAIN-Ledersch%C3%BCrze-Grillsch%C3%BCrze-Kochsch%C3%BCrze/dp/B07BXTFCGS/ref=sr_1_37?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-37",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81ErhzaAIEL._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 10,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.9,
//                             "raw": "€89,90",
//                             "name": "89,90 €",
//                             "asin": "B07BXTFCGS",
//                             "link": "https://www.amazon.de/SID-VAIN-Ledersch%C3%BCrze-Grillsch%C3%BCrze-Kochsch%C3%BCrze/dp/B07BXTFCGS/ref=sr_1_37?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-37"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.9,
//                         "raw": "€89,90",
//                         "name": "89,90 €",
//                         "asin": "B07BXTFCGS",
//                         "link": "https://www.amazon.de/SID-VAIN-Ledersch%C3%BCrze-Grillsch%C3%BCrze-Kochsch%C3%BCrze/dp/B07BXTFCGS/ref=sr_1_37?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-37"
//                     }
//                 },
//                 {
//                     "position": 38,
//                     "title": "Le Jacquard Francais Schürze 27483 Gastronomie Rouge 90X96 cm Baumwolle",
//                     "asin": "B09R2KZKPC",
//                     "link": "https://www.amazon.de/Jacquard-Francais-Sch%C3%BCrze-Gastronomie-Baumwolle/dp/B09R2KZKPC/ref=sr_1_38?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-38",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61-EZPkvi-L._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 65,
//                             "raw": "€65,00",
//                             "name": "65,00 €",
//                             "asin": "B09R2KZKPC",
//                             "link": "https://www.amazon.de/Jacquard-Francais-Sch%C3%BCrze-Gastronomie-Baumwolle/dp/B09R2KZKPC/ref=sr_1_38?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-38"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 65,
//                         "raw": "€65,00",
//                         "name": "65,00 €",
//                         "asin": "B09R2KZKPC",
//                         "link": "https://www.amazon.de/Jacquard-Francais-Sch%C3%BCrze-Gastronomie-Baumwolle/dp/B09R2KZKPC/ref=sr_1_38?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-38"
//                     }
//                 },
//                 {
//                     "position": 39,
//                     "title": "X APRONISTA® | Schürze & Kochschürze, BBQ Schürze, Grill Schürze und Latz Schürze | Kochschürze grau LARGER (L) - Aus Baumwolle und Rindsleder - Hergestellt in der EU, 380 g",
//                     "asin": "B09YHZWNTD",
//                     "link": "https://www.amazon.de/APRONISTA%C2%AE-Sch%C3%BCrze-Kochsch%C3%BCrze-Grill-LARGER/dp/B09YHZWNTD/ref=sr_1_39?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-39",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/21rffM1OmyL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59,
//                             "raw": "€59,00",
//                             "asin": "B09YHZWNTD",
//                             "link": "https://www.amazon.de/APRONISTA%C2%AE-Sch%C3%BCrze-Kochsch%C3%BCrze-Grill-LARGER/dp/B09YHZWNTD/ref=sr_1_39?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-39"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59,
//                         "raw": "€59,00",
//                         "asin": "B09YHZWNTD",
//                         "link": "https://www.amazon.de/APRONISTA%C2%AE-Sch%C3%BCrze-Kochsch%C3%BCrze-Grill-LARGER/dp/B09YHZWNTD/ref=sr_1_39?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-39"
//                     }
//                 },
//                 {
//                     "position": 40,
//                     "title": "Esschert Leder BBQ Grillschürze Küchen Schürze Kochschürze Latzschürze Party",
//                     "asin": "B07PT9XJD2",
//                     "link": "https://www.amazon.de/Esschert-Grillsch%C3%BCrze-Sch%C3%BCrze-Kochsch%C3%BCrze-Latzsch%C3%BCrze/dp/B07PT9XJD2/ref=sr_1_40?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-40",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61+hOqLIheL._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 5,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 58.9,
//                             "raw": "€58,90",
//                             "name": "58,90 €",
//                             "asin": "B07PT9XJD2",
//                             "link": "https://www.amazon.de/Esschert-Grillsch%C3%BCrze-Sch%C3%BCrze-Kochsch%C3%BCrze-Latzsch%C3%BCrze/dp/B07PT9XJD2/ref=sr_1_40?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-40"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 58.9,
//                         "raw": "€58,90",
//                         "name": "58,90 €",
//                         "asin": "B07PT9XJD2",
//                         "link": "https://www.amazon.de/Esschert-Grillsch%C3%BCrze-Sch%C3%BCrze-Kochsch%C3%BCrze-Latzsch%C3%BCrze/dp/B07PT9XJD2/ref=sr_1_40?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-40"
//                     }
//                 },
//                 {
//                     "position": 41,
//                     "title": "X APRONISTA® | Schürze & Kochschürze, BBQ Schürze, Grill Schürze und Latz Schürze | Kochschürze schwarz LARGER (L) - Aus Baumwolle und Rindsleder - Hergestellt in der EU, 380 g",
//                     "asin": "B09YJ2M2Q6",
//                     "link": "https://www.amazon.de/APRONISTA%C2%AE-Sch%C3%BCrze-Kochsch%C3%BCrze-schwarz-LARGER/dp/B09YJ2M2Q6/ref=sr_1_41?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-41",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/31QkGJ7MGrL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59,
//                             "raw": "€59,00",
//                             "name": "Primary",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 68,
//                             "raw": "€68,00",
//                             "name": "Original",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59,
//                         "raw": "€59,00",
//                         "name": "Primary",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 42,
//                     "title": "FQYEP Kochschürze Chefkoch Jeans Schürze Damen Arbeitsschürze Garten mit Taschen Verstellbare Kreuzgurte Für Barista-Künstler-Mechaniker-Schmied",
//                     "asin": "B0B5R85CZF",
//                     "link": "https://www.amazon.de/FQYEP-Kochsch%C3%BCrze-Arbeitssch%C3%BCrze-Verstellbare-Barista-K%C3%BCnstler-Mechaniker-Schmied/dp/B0B5R85CZF/ref=sr_1_42?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-42",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71CvUcfgwIL._AC_UL400_.jpg"
//                 },
//                 {
//                     "position": 43,
//                     "title": "Everdure by heston blumenthal Grillschürze Schwarz 100% Baumwolle I Grill Zubehör Schürze Unisex I Kochschürze Einheitsgröße mit verstellbaren Riemen und Taschen",
//                     "asin": "B077TBGGS2",
//                     "link": "https://www.amazon.de/Everdure-Chef%C3%A2%E2%82%ACTMs-forkl%C3%83%C2%A6-48820023-Grill/dp/B077TBGGS2/ref=sr_1_43?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-43",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/7188OPGrMcL._AC_UL400_.jpg",
//                     "rating": 4,
//                     "ratings_total": 8,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 29.99,
//                             "raw": "€29,99",
//                             "name": "29,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 34.95,
//                             "raw": "€34,95",
//                             "name": "29,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 29.99,
//                         "raw": "€29,99",
//                         "name": "29,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 44,
//                     "title": "Agirlvct 2 Stück Küchenschürze mit Handwischen, wassertropfenfest mit 2 Taschen, Kochschürze für Damen, Herren, Kochschürze",
//                     "asin": "B09P9NK7LN",
//                     "link": "https://www.amazon.de/Agirlvct-K%C3%BCchensch%C3%BCrze-handabwischbar-wasserabweisend-Kochsch%C3%BCrzen/dp/B09P9NK7LN/ref=sr_1_44?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-44",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/615TcIZgepL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 350,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 21.07,
//                             "raw": "€21,07",
//                             "name": "21,07 €",
//                             "asin": "B09P9NK7LN",
//                             "link": "https://www.amazon.de/Agirlvct-K%C3%BCchensch%C3%BCrze-handabwischbar-wasserabweisend-Kochsch%C3%BCrzen/dp/B09P9NK7LN/ref=sr_1_44?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-44"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 21.07,
//                         "raw": "€21,07",
//                         "name": "21,07 €",
//                         "asin": "B09P9NK7LN",
//                         "link": "https://www.amazon.de/Agirlvct-K%C3%BCchensch%C3%BCrze-handabwischbar-wasserabweisend-Kochsch%C3%BCrzen/dp/B09P9NK7LN/ref=sr_1_44?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-44"
//                     }
//                 },
//                 {
//                     "position": 45,
//                     "title": "Küchenschürzen für Frauen, Blumenschürzen mit großen Taschen, Baumwollschürzen, Kochküche, Kochschürze, tolles Geschenk für Kinder und Töchter,5",
//                     "asin": "B0B1DF46MY",
//                     "link": "https://www.amazon.de/K%C3%BCchensch%C3%BCrzen-Blumensch%C3%BCrzen-Baumwollsch%C3%BCrzen-Kochk%C3%BCche-Kochsch%C3%BCrze/dp/B0B1DF46MY/ref=sr_1_45?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-45",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61ZahXYsS4L._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 58.37,
//                             "raw": "€58,37",
//                             "asin": "B0B1DF46MY",
//                             "link": "https://www.amazon.de/K%C3%BCchensch%C3%BCrzen-Blumensch%C3%BCrzen-Baumwollsch%C3%BCrzen-Kochk%C3%BCche-Kochsch%C3%BCrze/dp/B0B1DF46MY/ref=sr_1_45?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-45"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 58.37,
//                         "raw": "€58,37",
//                         "asin": "B0B1DF46MY",
//                         "link": "https://www.amazon.de/K%C3%BCchensch%C3%BCrzen-Blumensch%C3%BCrzen-Baumwollsch%C3%BCrzen-Kochk%C3%BCche-Kochsch%C3%BCrze/dp/B0B1DF46MY/ref=sr_1_45?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-45"
//                     }
//                 },
//                 {
//                     "position": 46,
//                     "title": "RED POINT Lederschürze Echt Leder für Kellner, Gärtner, Hand- und Heimwerker, Kochschürze, Grillschürze in braun",
//                     "asin": "B07HM1Z5CW",
//                     "link": "https://www.amazon.de/Ledersch%C3%BCrze-Kellner-Heimwerker-Kochsch%C3%BCrze-Grillsch%C3%BCrze/dp/B07HM1Z5CW/ref=sr_1_46?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-46",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61B3gYw48GL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.95,
//                             "raw": "€59,95",
//                             "asin": "B07HM1Z5CW",
//                             "link": "https://www.amazon.de/Ledersch%C3%BCrze-Kellner-Heimwerker-Kochsch%C3%BCrze-Grillsch%C3%BCrze/dp/B07HM1Z5CW/ref=sr_1_46?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-46"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.95,
//                         "raw": "€59,95",
//                         "asin": "B07HM1Z5CW",
//                         "link": "https://www.amazon.de/Ledersch%C3%BCrze-Kellner-Heimwerker-Kochsch%C3%BCrze-Grillsch%C3%BCrze/dp/B07HM1Z5CW/ref=sr_1_46?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-46"
//                     }
//                 },
//                 {
//                     "position": 47,
//                     "title": "Dickies Chef Latzschürze mit drei Taschen, verstellbar, 3 Stück",
//                     "asin": "B00E1YR9O6",
//                     "link": "https://www.amazon.de/Dickies-Three-Pocket-Adjustable-Apron/dp/B00E1YR9O6/ref=sr_1_47?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-47",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/717j9dEfTnL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 132,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51,
//                             "raw": "€51,00",
//                             "name": "51,00 €",
//                             "asin": "B00E1YR9O6",
//                             "link": "https://www.amazon.de/Dickies-Three-Pocket-Adjustable-Apron/dp/B00E1YR9O6/ref=sr_1_47?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-47"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51,
//                         "raw": "€51,00",
//                         "name": "51,00 €",
//                         "asin": "B00E1YR9O6",
//                         "link": "https://www.amazon.de/Dickies-Three-Pocket-Adjustable-Apron/dp/B00E1YR9O6/ref=sr_1_47?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-47"
//                     }
//                 },
//                 {
//                     "position": 48,
//                     "title": "wasserdichte und ölbeständige Schürze für Männer und Frauen, Bedruckte Baumwolle, Leinen, Kochschürze, Küchenschürze für sie/Sein Geburtstagsgeschenk, Männer und Frauen",
//                     "asin": "B0B1DHMSDB",
//                     "link": "https://www.amazon.de/wasserdichte-%C3%B6lbest%C3%A4ndige-Kochsch%C3%BCrze-K%C3%BCchensch%C3%BCrze-Geburtstagsgeschenk/dp/B0B1DHMSDB/ref=sr_1_48?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-48",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61csY-E-p+L._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 58.37,
//                             "raw": "€58,37",
//                             "asin": "B0B1DHMSDB",
//                             "link": "https://www.amazon.de/wasserdichte-%C3%B6lbest%C3%A4ndige-Kochsch%C3%BCrze-K%C3%BCchensch%C3%BCrze-Geburtstagsgeschenk/dp/B0B1DHMSDB/ref=sr_1_48?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-48"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 58.37,
//                         "raw": "€58,37",
//                         "asin": "B0B1DHMSDB",
//                         "link": "https://www.amazon.de/wasserdichte-%C3%B6lbest%C3%A4ndige-Kochsch%C3%BCrze-K%C3%BCchensch%C3%BCrze-Geburtstagsgeschenk/dp/B0B1DHMSDB/ref=sr_1_48?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-48"
//                     }
//                 },
//                 {
//                     "position": 49,
//                     "title": "Riparo Lederschurz | Kochschürze mit Taschen | Verstellbarem Küchenschürze | Grillschürze | Latzschürze | Küchenschürzen für Frauen Männer Chef Belastbar Einfach zu Reinigen",
//                     "asin": "B098MXM7TM",
//                     "link": "https://www.amazon.de/Riparo-Verstellbarem-K%C3%BCchensch%C3%BCrze-Grillsch%C3%BCrze-Chef-Belastbar/dp/B098MXM7TM/ref=sr_1_49?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-49",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/616IpavIqoS._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 4,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 80.87,
//                             "raw": "€80,87",
//                             "name": "80,87 €",
//                             "asin": "B098MXM7TM",
//                             "link": "https://www.amazon.de/Riparo-Verstellbarem-K%C3%BCchensch%C3%BCrze-Grillsch%C3%BCrze-Chef-Belastbar/dp/B098MXM7TM/ref=sr_1_49?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-49"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 80.87,
//                         "raw": "€80,87",
//                         "name": "80,87 €",
//                         "asin": "B098MXM7TM",
//                         "link": "https://www.amazon.de/Riparo-Verstellbarem-K%C3%BCchensch%C3%BCrze-Grillsch%C3%BCrze-Chef-Belastbar/dp/B098MXM7TM/ref=sr_1_49?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-49"
//                     }
//                 },
//                 {
//                     "position": 50,
//                     "title": "8 Stück Kinder Schürze und 8 Stück Kochmütze Set Kinderschürze mit 2 Taschen Kinder Verstellbare Kochschürze und Hüte für Jungen Mädchen Küche Kochen Backen Malerei Tragen(Mehrfarben, S für 2-6 Jahre)",
//                     "asin": "B081JL9LZG",
//                     "link": "https://www.amazon.de/Kochm%C3%BCtze-Kindersch%C3%BCrze-Verstellbare-Kochsch%C3%BCrze-Mehrfarben/dp/B081JL9LZG/ref=sr_1_50?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-50",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71MFSswNzUL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 170
//                 },
//                 {
//                     "position": 51,
//                     "title": "NOBONDO Kochschürze, 12 Stück, Unisex, schwarze Schürzen mit 2 geräumigen Taschen, maschinenwaschbar für Küche, Basteln, Grillen, Zeichnen",
//                     "asin": "B07DXVN4DC",
//                     "link": "https://www.amazon.de/NOBONDO-L%C3%A4tzchen-ger%C3%A4umige-Maschinenwaschbar-Zeichnen/dp/B07DXVN4DC/ref=sr_1_51?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-51",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61rki1fqqiL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 7429,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 22.98,
//                             "raw": "€22,98"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 77.86,
//                             "raw": "€77,86"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 22.98,
//                         "raw": "€22,98"
//                     }
//                 },
//                 {
//                     "position": 52,
//                     "title": "Black Forest Fox SET Büffel Leder Profi Grillschürze Koch & Küchen Schürze + Kamin Ofen Grill Schweißer feuerfeste Handschuhe",
//                     "asin": "B08KVZXPKP",
//                     "link": "https://www.amazon.de/Black-Forest-Fox-Grillsch%C3%BCrze-feuerfeste/dp/B08KVZXPKP/ref=sr_1_52?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-52",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61H4Dxe2FpL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 14,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 84.9,
//                             "raw": "€84,90",
//                             "name": "84,90 €",
//                             "asin": "B08KVZXPKP",
//                             "link": "https://www.amazon.de/Black-Forest-Fox-Grillsch%C3%BCrze-feuerfeste/dp/B08KVZXPKP/ref=sr_1_52?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-52"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 84.9,
//                         "raw": "€84,90",
//                         "name": "84,90 €",
//                         "asin": "B08KVZXPKP",
//                         "link": "https://www.amazon.de/Black-Forest-Fox-Grillsch%C3%BCrze-feuerfeste/dp/B08KVZXPKP/ref=sr_1_52?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-52"
//                     }
//                 },
//                 {
//                     "position": 53,
//                     "title": "Chef Works Herren Brio Kochschürze Schürzen",
//                     "asin": "B085ZB5ZR3",
//                     "link": "https://www.amazon.de/Chef-Works-Herren-Chefs-Sch%C3%BCrzen/dp/B085ZB5ZR3/ref=sr_1_53?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-53",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51WM559U+tL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 214,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 43.6,
//                             "raw": "€43,60",
//                             "name": "43,60 €",
//                             "asin": "B085ZB5ZR3",
//                             "link": "https://www.amazon.de/Chef-Works-Herren-Chefs-Sch%C3%BCrzen/dp/B085ZB5ZR3/ref=sr_1_53?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-53"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 43.6,
//                         "raw": "€43,60",
//                         "name": "43,60 €",
//                         "asin": "B085ZB5ZR3",
//                         "link": "https://www.amazon.de/Chef-Works-Herren-Chefs-Sch%C3%BCrzen/dp/B085ZB5ZR3/ref=sr_1_53?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-53"
//                     }
//                 },
//                 {
//                     "position": 54,
//                     "title": "PVC-Schürze, Schlachterschürze, Metzgerschürze - grün - 90 x 115 cm OVP",
//                     "asin": "B00I3E730U",
//                     "link": "https://www.amazon.de/PVC-Sch%C3%BCrze-Schlachtersch%C3%BCrze-Metzgersch%C3%BCrze-gr%C3%BCn-115/dp/B00I3E730U/ref=sr_1_54?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-54",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51kI86D2cLL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 87,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 22.8,
//                             "raw": "€22,80",
//                             "name": "22,80 €",
//                             "asin": "B00I3E730U",
//                             "link": "https://www.amazon.de/PVC-Sch%C3%BCrze-Schlachtersch%C3%BCrze-Metzgersch%C3%BCrze-gr%C3%BCn-115/dp/B00I3E730U/ref=sr_1_54?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-54"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 22.8,
//                         "raw": "€22,80",
//                         "name": "22,80 €",
//                         "asin": "B00I3E730U",
//                         "link": "https://www.amazon.de/PVC-Sch%C3%BCrze-Schlachtersch%C3%BCrze-Metzgersch%C3%BCrze-gr%C3%BCn-115/dp/B00I3E730U/ref=sr_1_54?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-54"
//                     }
//                 },
//                 {
//                     "position": 55,
//                     "title": "Chef Works Konische Kochschürze.",
//                     "asin": "B00VISWYDE",
//                     "link": "https://www.amazon.de/Chef-Works-LCBA-Sch%C3%BCrze-Tasche/dp/B00VISWYDE/ref=sr_1_55?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-55",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61I+61ocgwL._AC_UL400_.jpg",
//                     "rating": 3.6,
//                     "ratings_total": 6,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 18.91,
//                             "raw": "€18,91"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51,
//                             "raw": "€51,00"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 18.91,
//                         "raw": "€18,91"
//                     }
//                 },
//                 {
//                     "position": 56,
//                     "title": "Leinen Schürze Alva hellgrau, Schürze mit Taschen, Leinenschürze ohne Binden, Kochschürze grau, Öko-Tex zertifiziert",
//                     "asin": "B07VBMWYR3",
//                     "link": "https://www.amazon.de/Sch%C3%BCrze-hellgrau-Leinensch%C3%BCrze-Kochsch%C3%BCrze-zertifiziert/dp/B07VBMWYR3/ref=sr_1_56?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-56",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51oFYY8ZQLL._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 75,
//                             "raw": "€75,00",
//                             "name": "75,00 €",
//                             "asin": "B07VBMWYR3",
//                             "link": "https://www.amazon.de/Sch%C3%BCrze-hellgrau-Leinensch%C3%BCrze-Kochsch%C3%BCrze-zertifiziert/dp/B07VBMWYR3/ref=sr_1_56?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-56"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 75,
//                         "raw": "€75,00",
//                         "name": "75,00 €",
//                         "asin": "B07VBMWYR3",
//                         "link": "https://www.amazon.de/Sch%C3%BCrze-hellgrau-Leinensch%C3%BCrze-Kochsch%C3%BCrze-zertifiziert/dp/B07VBMWYR3/ref=sr_1_56?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-56"
//                     }
//                 },
//                 {
//                     "position": 57,
//                     "title": "Lustige Kochschürzen Schürzen Für Männer Frauen Mit Taschen, Einstellbar Kreuz Back Arbeit Kochen Küche Denim Leinwand Schürze (Color : Green, Size : OneSize)",
//                     "asin": "B09FG2XBPL",
//                     "link": "https://www.amazon.de/Lustige-Kochsch%C3%BCrzen-Sch%C3%BCrzen-Einstellbar-Leinwand/dp/B09FG2XBPL/ref=sr_1_57?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-57",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61Ay9lEAWjS._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 53.49,
//                             "raw": "€53,49",
//                             "asin": "B09FG2XBPL",
//                             "link": "https://www.amazon.de/Lustige-Kochsch%C3%BCrzen-Sch%C3%BCrzen-Einstellbar-Leinwand/dp/B09FG2XBPL/ref=sr_1_57?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-57"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 53.49,
//                         "raw": "€53,49",
//                         "asin": "B09FG2XBPL",
//                         "link": "https://www.amazon.de/Lustige-Kochsch%C3%BCrzen-Sch%C3%BCrzen-Einstellbar-Leinwand/dp/B09FG2XBPL/ref=sr_1_57?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-57"
//                     }
//                 },
//                 {
//                     "position": 58,
//                     "title": "carrotez Chef Apron, Waterdrop Resistant Cotton Canvas Cross Back Adjustable Apron, M-XXL",
//                     "asin": "B083YT3GSV",
//                     "link": "https://www.amazon.de/Carrotez-Kochsch%C3%BCrze-Baumwolle-Kreuzr%C3%BCcken-verstellbare/dp/B083YT3GSV/ref=sr_1_58?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-58",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61gvt-higML._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 1413,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 38.84,
//                             "raw": "€38,84",
//                             "name": "38,84 €",
//                             "asin": "B083YT3GSV",
//                             "link": "https://www.amazon.de/Carrotez-Kochsch%C3%BCrze-Baumwolle-Kreuzr%C3%BCcken-verstellbare/dp/B083YT3GSV/ref=sr_1_58?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-58"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 38.84,
//                         "raw": "€38,84",
//                         "name": "38,84 €",
//                         "asin": "B083YT3GSV",
//                         "link": "https://www.amazon.de/Carrotez-Kochsch%C3%BCrze-Baumwolle-Kreuzr%C3%BCcken-verstellbare/dp/B083YT3GSV/ref=sr_1_58?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-58"
//                     }
//                 },
//                 {
//                     "position": 59,
//                     "title": "MEDANTA 1039 Kochjacke Damen Kurzarm Chef Jacket Berufsbekleidung Gastronomie",
//                     "asin": "B08XB1RTVP",
//                     "link": "https://www.amazon.de/MEDANTA-1039-Kochjacke-Berufsbekleidung-Gastronomie/dp/B08XB1RTVP/ref=sr_1_59?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-59",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71mhchDYuML._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 3.9,
//                     "ratings_total": 11,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59,
//                             "raw": "€59,00",
//                             "name": "59,00 €",
//                             "asin": "B08XB1RTVP",
//                             "link": "https://www.amazon.de/MEDANTA-1039-Kochjacke-Berufsbekleidung-Gastronomie/dp/B08XB1RTVP/ref=sr_1_59?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-59"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59,
//                         "raw": "€59,00",
//                         "name": "59,00 €",
//                         "asin": "B08XB1RTVP",
//                         "link": "https://www.amazon.de/MEDANTA-1039-Kochjacke-Berufsbekleidung-Gastronomie/dp/B08XB1RTVP/ref=sr_1_59?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-59"
//                     }
//                 },
//                 {
//                     "position": 60,
//                     "title": "Bbq Grillschürze Jeans Schürze Herren Kochschürze Frauen Verstellbar mit Taschen, Kreuzgurten Für Kochkünstler, Gartenbauer",
//                     "asin": "B0B5RGKYTD",
//                     "link": "https://www.amazon.de/Grillsch%C3%BCrze-Kochsch%C3%BCrze-Verstellbar-Kreuzgurten-Kochk%C3%BCnstler/dp/B0B5RGKYTD/ref=sr_1_60?keywords=Kochsch%C3%BCrze&qid=1684074477&refinements=p_36%3A5000-10000&sr=8-60",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61V2ns67fZL._AC_UL400_.jpg"
//                 }
//             ]
//         },
//         {
//             "idea": "Backformen-Set",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "Le Creuset 4er-Set Mini Cocottes aus Steinzeug, je 200 ml, 10 x 5 cm, Feuer und Flamme (Gelb/Kirschrot/Ofenrot/Schwarz), 79212105129100",
//                     "asin": "B084W8Z87X",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfYXRmOjIwMTMyNTAyNzc3Njk4OjowOjo&url=%2FCREUSET-79212105129100-Mini-Cocotte-Br%25C3%25A4ter-Set-Steinzeug%2Fdp%2FB084W8Z87X%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81onXzzVsdL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.9,
//                     "ratings_total": 33,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 73.5,
//                             "raw": "€73,50",
//                             "name": "73,50 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 83,
//                             "raw": "€83,00",
//                             "name": "73,50 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 73.5,
//                         "raw": "€73,50",
//                         "name": "73,50 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "Circulon Momentum 4-teiliges Backformen Set mit Antihaftbeschichtung - Backblech, Kastenform, Springform, Tarteform - Spülmaschinenfest - PFOA-frei mit lebenslanger Garantie",
//                     "asin": "B06WWDGT22",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfYXRmOjIwMDY1Mzk2Nzc2NDk4OjowOjo&url=%2FCirculon-Momentum-Muffinblech-silberfarben-Karbonstahl%2Fdp%2FB06WWDGT22%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71z+L9bovzL._AC_UL400_.jpg",
//                     "unit_price": "16,15 €/stück",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 16,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.6,
//                             "raw": "€64,60",
//                             "name": "64,60 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 67.2,
//                             "raw": "€67,20",
//                             "name": "64,60 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.6,
//                         "raw": "€64,60",
//                         "name": "64,60 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 3,
//                     "title": "Le Creuset 4er-Set Tarteförmchen aus Steinzeug, Je 200 ml/ Ø 11 cm, Ofenrot, 91017411090000",
//                     "asin": "B019A1ONZW",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfYXRmOjIwMTMyNTAyNzc2MDk4OjowOjo&url=%2FCreuset-91017411090000-4-er-Tarte-Form-ofenrot%2Fdp%2FB019A1ONZW%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51CL7SqvyzL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 76,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 67.07,
//                             "raw": "€67,07",
//                             "name": "67,07 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74,
//                             "raw": "€74,00",
//                             "name": "67,07 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 67.07,
//                         "raw": "€67,07",
//                         "name": "67,07 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 4,
//                     "title": "GRÄWE 10er Set Pizzableche Ø 28 cm",
//                     "asin": "B086R1ZXCX",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfYXRmOjIwMDQxNjE1ODkwNzAxOjowOjo&url=%2FGR%25C3%2584WE-10er-Set-Pizzableche-28%2Fdp%2FB086R1ZXCX%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/710JaAUaxaL._AC_UL400_.jpg",
//                     "unit_price": "6,29 €/stück",
//                     "rating": 4.4,
//                     "ratings_total": 99,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 62.9,
//                             "raw": "€62,90",
//                             "name": "62,90 €",
//                             "asin": "B086R1ZXCX",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfYXRmOjIwMDQxNjE1ODkwNzAxOjowOjo&url=%2FGR%25C3%2584WE-10er-Set-Pizzableche-28%2Fdp%2FB086R1ZXCX%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 62.9,
//                         "raw": "€62,90",
//                         "name": "62,90 €",
//                         "asin": "B086R1ZXCX",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfYXRmOjIwMDQxNjE1ODkwNzAxOjowOjo&url=%2FGR%25C3%2584WE-10er-Set-Pizzableche-28%2Fdp%2FB086R1ZXCX%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 5,
//                     "title": "Dr. Oetker 3er Backformen-Set Back-Trend: Springform, Gugelhupfform und Kastenform, Kuchenformen aus Stahl mit keramisch verstärkter Antihaft-Beschichtung (Farbe: Creme/anthrazit), Menge: 3er Set",
//                     "asin": "B075QHWXYT",
//                     "link": "https://www.amazon.de/Dr-Oetker-4869-Back-Trend-Backformen-Set/dp/B075QHWXYT/ref=sr_1_5_mod_primary_new?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-5",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71pFLHf78CL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 320,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 41.21,
//                             "raw": "€41,21",
//                             "name": "41,21 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 43.57,
//                             "raw": "€43,57",
//                             "name": "43,57 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 41.21,
//                         "raw": "€41,21",
//                         "name": "41,21 €"
//                     }
//                 },
//                 {
//                     "position": 6,
//                     "title": "Stoneline Backform Set 7-teilig, beschichtet mit echten Steinpartikeln, Backformen aus Karbonstahl und Eisen, Kastenform, Muffinform, Backblech und Backgitter",
//                     "asin": "B0869RPNF5",
//                     "link": "https://www.amazon.de/Backformen-Set-Antihaftbeschichtung-Karbonstahl-Muffinbackblech-Bratbackform/dp/B0869RPNF5/ref=sr_1_6?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-6",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81oIKon7PkL._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 17,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.94,
//                             "raw": "€56,94",
//                             "name": "56,94 €",
//                             "asin": "B0869RPNF5",
//                             "link": "https://www.amazon.de/Backformen-Set-Antihaftbeschichtung-Karbonstahl-Muffinbackblech-Bratbackform/dp/B0869RPNF5/ref=sr_1_6?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-6"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 56.94,
//                         "raw": "€56,94",
//                         "name": "56,94 €",
//                         "asin": "B0869RPNF5",
//                         "link": "https://www.amazon.de/Backformen-Set-Antihaftbeschichtung-Karbonstahl-Muffinbackblech-Bratbackform/dp/B0869RPNF5/ref=sr_1_6?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-6"
//                     }
//                 },
//                 {
//                     "position": 7,
//                     "title": "Dr. Oetker 4er Backformen-Set Back-Harmonie, Springform (Flach- und Rohrbodeneinsatz), Kastenform und Gugelhupfform, keramisch verstärkter Antihaftbeschichtung,(Farbe: Grau) Menge: 1 x 4er-Set",
//                     "asin": "B00NSWH7G2",
//                     "link": "https://www.amazon.de/Dr-Oetker-Backformen-Set-Rohrbodeneinsatz-Antihaftbeschichtung/dp/B00NSWH7G2/ref=sr_1_7?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-7",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81PJswYKz8L._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Top Angebot"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 668,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 40.52,
//                             "raw": "€40,52",
//                             "name": "40,52 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.1,
//                             "raw": "€54,10",
//                             "name": "40,52 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 40.52,
//                         "raw": "€40,52",
//                         "name": "40,52 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 8,
//                     "title": "Relaxdays Springform Set 7-teilig, rund & quadratisch, 18-28 cm, Edelstahl, Antihaftbeschichtung, Backformen, anthrazit",
//                     "asin": "B0BT724Z14",
//                     "link": "https://www.amazon.de/Relaxdays-Springform-quadratisch-Antihaftbeschichtung-Backformen/dp/B0BT724Z14/ref=sr_1_8?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-8",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81kf9ayFnnL._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 24.95,
//                             "raw": "€24,95",
//                             "name": "24,95 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 29.9,
//                             "raw": "€29,90",
//                             "name": "24,95 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 24.95,
//                         "raw": "€24,95",
//                         "name": "24,95 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 9,
//                     "title": "MasterClass, 7-Teiliges Backformenset, Antihaftbeschichtet, Stapelbar: Bratpfanne, Quadratische Kuchenform, Kastenbrotform, Muffinschale, zwei Backbleche und Kühlregal, Geschenkbox",
//                     "asin": "B078KSG6JH",
//                     "link": "https://www.amazon.de/MasterClass-Backformen-Set-antihaftbeschichtet-Karbonstahl-stapelbar/dp/B078KSG6JH/ref=sr_1_9?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-9",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/710oOeh7EWL._AC_UL400_.jpg",
//                     "unit_price": "10,53 €/stück",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 3239,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 73.71,
//                             "raw": "€73,71",
//                             "name": "73,71 €",
//                             "asin": "B078KSG6JH",
//                             "link": "https://www.amazon.de/MasterClass-Backformen-Set-antihaftbeschichtet-Karbonstahl-stapelbar/dp/B078KSG6JH/ref=sr_1_9?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-9"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 73.71,
//                         "raw": "€73,71",
//                         "name": "73,71 €",
//                         "asin": "B078KSG6JH",
//                         "link": "https://www.amazon.de/MasterClass-Backformen-Set-antihaftbeschichtet-Karbonstahl-stapelbar/dp/B078KSG6JH/ref=sr_1_9?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-9"
//                     }
//                 },
//                 {
//                     "position": 10,
//                     "title": "PME - Runde Kuchenformen 15, 20, 25, 30 cm x 10 cm tief, 4er-Satz Silber",
//                     "asin": "B09VQ2LK25",
//                     "link": "https://www.amazon.de/PME-Runde-Kuchenformen-tief-4er-Satz/dp/B09VQ2LK25/ref=sr_1_10?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-10",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71OJjas1WML._AC_UL400_.jpg",
//                     "unit_price": "13,28 €/stück",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 59,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 53.13,
//                             "raw": "€53,13",
//                             "name": "53,13 €",
//                             "asin": "B09VQ2LK25",
//                             "link": "https://www.amazon.de/PME-Runde-Kuchenformen-tief-4er-Satz/dp/B09VQ2LK25/ref=sr_1_10?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-10"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 53.13,
//                         "raw": "€53,13",
//                         "name": "53,13 €",
//                         "asin": "B09VQ2LK25",
//                         "link": "https://www.amazon.de/PME-Runde-Kuchenformen-tief-4er-Satz/dp/B09VQ2LK25/ref=sr_1_10?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-10"
//                     }
//                 },
//                 {
//                     "position": 11,
//                     "title": "KitchenAid Aluminized Steel 3-teiliges Backformen-Set, 24cm Springform, 24cm Gugelhupfform, 27cm x 11cm Kastenform, PFAS-freie Antihaftbeschichtung, backofenfest, spülmaschinenfest, grau",
//                     "asin": "B0B5D9NPH1",
//                     "link": "https://www.amazon.de/KitchenAid-Backformen-Set-Gugelhupfform-Antihaftbeschichtung-sp%C3%BClmaschinenfest/dp/B0B5D9NPH1/ref=sr_1_11?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-11",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41X5+tlFkJL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4,
//                     "ratings_total": 11,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.9,
//                             "raw": "€69,90",
//                             "name": "69,90 €",
//                             "asin": "B0B5D9NPH1",
//                             "link": "https://www.amazon.de/KitchenAid-Backformen-Set-Gugelhupfform-Antihaftbeschichtung-sp%C3%BClmaschinenfest/dp/B0B5D9NPH1/ref=sr_1_11?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-11"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.9,
//                         "raw": "€69,90",
//                         "name": "69,90 €",
//                         "asin": "B0B5D9NPH1",
//                         "link": "https://www.amazon.de/KitchenAid-Backformen-Set-Gugelhupfform-Antihaftbeschichtung-sp%C3%BClmaschinenfest/dp/B0B5D9NPH1/ref=sr_1_11?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-11"
//                     }
//                 },
//                 {
//                     "position": 12,
//                     "title": "Original Kaiser Backformen Backset 10-tlg, Springform, Kastenform, Gugelhupfform, Spritzbeutel, Tüllen, Papierförmchen, Teigspachtel, Pinsel, Kuchenmesser",
//                     "asin": "B007WR4FGO",
//                     "link": "https://www.amazon.de/Kaiser-K%C3%B6nigskuchenform-Gugelhupfform-Papierbackf%C3%B6rmchen-Holz-Backpinsel/dp/B007WR4FGO/ref=sr_1_12?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-12",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61oICdMHSbL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 278,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 34.99,
//                             "raw": "€34,99",
//                             "name": "34,99 €",
//                             "asin": "B007WR4FGO",
//                             "link": "https://www.amazon.de/Kaiser-K%C3%B6nigskuchenform-Gugelhupfform-Papierbackf%C3%B6rmchen-Holz-Backpinsel/dp/B007WR4FGO/ref=sr_1_12?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-12"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 34.99,
//                         "raw": "€34,99",
//                         "name": "34,99 €",
//                         "asin": "B007WR4FGO",
//                         "link": "https://www.amazon.de/Kaiser-K%C3%B6nigskuchenform-Gugelhupfform-Papierbackf%C3%B6rmchen-Holz-Backpinsel/dp/B007WR4FGO/ref=sr_1_12?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-12"
//                     }
//                 },
//                 {
//                     "position": 13,
//                     "title": "AMT Gastroguss Eckpfanne/Bräter mit zwei Seitengriffen, 26 cm x 26 cm, 8 cm hoch, ideal auch als Bratentopf, Aluguss (Aluminium), Lotan® Antihaft-Beschichtung für fettfreies Braten, AMZN-E268",
//                     "asin": "B00DZNBSFK",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDUyNDg1MjAzNTA4OjowOjo&url=%2FAMT-Gastroguss-E268-Eckpfanne-Seitengriffe%2Fdp%2FB00DZNBSFK%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/618G5rLYk8L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4,
//                     "ratings_total": 23,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 88,
//                             "raw": "€88,00",
//                             "name": "88,00 €",
//                             "asin": "B00DZNBSFK",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDUyNDg1MjAzNTA4OjowOjo&url=%2FAMT-Gastroguss-E268-Eckpfanne-Seitengriffe%2Fdp%2FB00DZNBSFK%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 88,
//                         "raw": "€88,00",
//                         "name": "88,00 €",
//                         "asin": "B00DZNBSFK",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDUyNDg1MjAzNTA4OjowOjo&url=%2FAMT-Gastroguss-E268-Eckpfanne-Seitengriffe%2Fdp%2FB00DZNBSFK%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 14,
//                     "title": "Hobbybäcker Herz-Backrahmen, ►Herz-Form, Tortenring, ► Hochzeit, Muttertag, verstellbar von 29 - 38 cm, aus Edelstahl",
//                     "asin": "B06XPY7B76",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDA0MTU3MTc3OTQyOjowOjo&url=%2FHobbyb%25C3%25A4cker-Herz-Backrahmen-%25E2%2596%25BAHerz-Form-Tortenring-verstellbar%2Fdp%2FB06XPY7B76%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71HHOutJ0SL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 31,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.95,
//                             "raw": "€51,95",
//                             "name": "51,95 €",
//                             "asin": "B06XPY7B76",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDA0MTU3MTc3OTQyOjowOjo&url=%2FHobbyb%25C3%25A4cker-Herz-Backrahmen-%25E2%2596%25BAHerz-Form-Tortenring-verstellbar%2Fdp%2FB06XPY7B76%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.95,
//                         "raw": "€51,95",
//                         "name": "51,95 €",
//                         "asin": "B06XPY7B76",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDA0MTU3MTc3OTQyOjowOjo&url=%2FHobbyb%25C3%25A4cker-Herz-Backrahmen-%25E2%2596%25BAHerz-Form-Tortenring-verstellbar%2Fdp%2FB06XPY7B76%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 15,
//                     "title": "SPRINGLANE Keramik Auflaufform Misty Cliff 2er-Set, Steingut Backform für Lasagne, Quiche oder Tiramisu",
//                     "asin": "B08JZ5NWJ7",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMTAzMTMxNjM1MTk4OjowOjo&url=%2FKeramik-Auflaufform-Steingut-Backform-Tiramisu%2Fdp%2FB08JZ5NWJ7%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/613Q3WUWbIL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 5 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "rating": 4.6,
//                     "ratings_total": 305,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "asin": "B08JZ5NWJ7",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMTAzMTMxNjM1MTk4OjowOjo&url=%2FKeramik-Auflaufform-Steingut-Backform-Tiramisu%2Fdp%2FB08JZ5NWJ7%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "asin": "B08JZ5NWJ7",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMTAzMTMxNjM1MTk4OjowOjo&url=%2FKeramik-Auflaufform-Steingut-Backform-Tiramisu%2Fdp%2FB08JZ5NWJ7%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 16,
//                     "title": "MALACASA, Serie Bake.Bake, 4er Set Porzellan Backform Kuchenform Brotbackform Back Schale Auflaufform Ofenform Teller Suppenteller in 4 Größe Rot",
//                     "asin": "B01MS4A2WF",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDU1NjgxNzM3NDk4OjowOjo&url=%2FBake-Bake-Kuchenform-Brotbackform-Auflaufform-Suppenteller%2Fdp%2FB01MS4A2WF%2Fref%3Dsr_1_16_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-16-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DAP989AT1A2FUZ",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71fLJlEEIOL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 5 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 575,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.99,
//                             "raw": "€51,99",
//                             "name": "51,99 €",
//                             "asin": "B01MS4A2WF",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDU1NjgxNzM3NDk4OjowOjo&url=%2FBake-Bake-Kuchenform-Brotbackform-Auflaufform-Suppenteller%2Fdp%2FB01MS4A2WF%2Fref%3Dsr_1_16_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-16-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DAP989AT1A2FUZ"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.99,
//                         "raw": "€51,99",
//                         "name": "51,99 €",
//                         "asin": "B01MS4A2WF",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDU1NjgxNzM3NDk4OjowOjo&url=%2FBake-Bake-Kuchenform-Brotbackform-Auflaufform-Suppenteller%2Fdp%2FB01MS4A2WF%2Fref%3Dsr_1_16_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-16-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DAP989AT1A2FUZ"
//                     }
//                 },
//                 {
//                     "position": 17,
//                     "title": "MALACASA, Serie Bake.Bake, 4er Set Porzellan Backform Kuchenform Brotbackform Back Schale Auflaufform Ofenform Teller Suppenteller in 4 Größe Rot",
//                     "asin": "B01MS4A2WF",
//                     "link": "https://www.amazon.de/Bake-Bake-Kuchenform-Brotbackform-Auflaufform-Suppenteller/dp/B01MS4A2WF/ref=sr_1_17?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-17",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71fLJlEEIOL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 5 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 575,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.99,
//                             "raw": "€51,99",
//                             "name": "51,99 €",
//                             "asin": "B01MS4A2WF",
//                             "link": "https://www.amazon.de/Bake-Bake-Kuchenform-Brotbackform-Auflaufform-Suppenteller/dp/B01MS4A2WF/ref=sr_1_17?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-17"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.99,
//                         "raw": "€51,99",
//                         "name": "51,99 €",
//                         "asin": "B01MS4A2WF",
//                         "link": "https://www.amazon.de/Bake-Bake-Kuchenform-Brotbackform-Auflaufform-Suppenteller/dp/B01MS4A2WF/ref=sr_1_17?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-17"
//                     }
//                 },
//                 {
//                     "position": 18,
//                     "title": "Lasagne Auflaufform groß, Porzellan Backform Weiß, Auflaufformen Eckig backofenfest für Ofen 1L / 2L / 3.5L, Auflaufform Keramik Rechteckig - 3er Set",
//                     "asin": "B08Z79WMGM",
//                     "link": "https://www.amazon.de/UNICASA-Auflaufform-Auflaufformen-backofenfest-Rechteckig/dp/B08Z79WMGM/ref=sr_1_18?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-18",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51yqawodHSL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.2,
//                     "ratings_total": 192,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 77.99,
//                             "raw": "€77,99",
//                             "name": "77,99 €",
//                             "asin": "B08Z79WMGM",
//                             "link": "https://www.amazon.de/UNICASA-Auflaufform-Auflaufformen-backofenfest-Rechteckig/dp/B08Z79WMGM/ref=sr_1_18?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-18"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 77.99,
//                         "raw": "€77,99",
//                         "name": "77,99 €",
//                         "asin": "B08Z79WMGM",
//                         "link": "https://www.amazon.de/UNICASA-Auflaufform-Auflaufformen-backofenfest-Rechteckig/dp/B08Z79WMGM/ref=sr_1_18?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-18"
//                     }
//                 },
//                 {
//                     "position": 19,
//                     "title": "SPRINGLANE Keramik Auflaufform Misty Cliff 2er-Set, Steingut Backform für Lasagne, Quiche oder Tiramisu",
//                     "asin": "B08JZ5NWJ7",
//                     "link": "https://www.amazon.de/Keramik-Auflaufform-Steingut-Backform-Tiramisu/dp/B08JZ5NWJ7/ref=sr_1_19?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-19",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/613Q3WUWbIL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 5 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "rating": 4.6,
//                     "ratings_total": 305,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "asin": "B08JZ5NWJ7",
//                             "link": "https://www.amazon.de/Keramik-Auflaufform-Steingut-Backform-Tiramisu/dp/B08JZ5NWJ7/ref=sr_1_19?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-19"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "asin": "B08JZ5NWJ7",
//                         "link": "https://www.amazon.de/Keramik-Auflaufform-Steingut-Backform-Tiramisu/dp/B08JZ5NWJ7/ref=sr_1_19?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-19"
//                     }
//                 },
//                 {
//                     "position": 20,
//                     "title": "3tlg. Set - Schöne detailreiche Kuchen backen in superleitfähigen, nicht haftenden Guss-Backformen",
//                     "asin": "B08FC2KHB6",
//                     "link": "https://www.amazon.de/Sch%C3%B6ne-detailreiche-superleitf%C3%A4higen-haftenden-Guss-Backformen/dp/B08FC2KHB6/ref=sr_1_20?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-20",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51wwYEey50L._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 4,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 86.95,
//                             "raw": "€86,95",
//                             "name": "86,95 €",
//                             "asin": "B08FC2KHB6",
//                             "link": "https://www.amazon.de/Sch%C3%B6ne-detailreiche-superleitf%C3%A4higen-haftenden-Guss-Backformen/dp/B08FC2KHB6/ref=sr_1_20?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-20"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 86.95,
//                         "raw": "€86,95",
//                         "name": "86,95 €",
//                         "asin": "B08FC2KHB6",
//                         "link": "https://www.amazon.de/Sch%C3%B6ne-detailreiche-superleitf%C3%A4higen-haftenden-Guss-Backformen/dp/B08FC2KHB6/ref=sr_1_20?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-20"
//                     }
//                 },
//                 {
//                     "position": 21,
//                     "title": "Le Creuset Steinzeug Foie Gras Terrine mit Presse und Thermometer, 0,6 l, Kirsche, 91008406064110",
//                     "asin": "B009XRMQX0",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDI1NDA1MDk2MjAxOjowOjo&url=%2FCreuset-Steinzeug-Pastetenform-Thermometer-Geschenkbox%2Fdp%2FB009XRMQX0%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/7148GSnSwuL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 474,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.62,
//                             "raw": "€56,62",
//                             "name": "56,62 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 60.48,
//                             "raw": "€60,48",
//                             "name": "56,62 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 56.62,
//                         "raw": "€56,62",
//                         "name": "56,62 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 22,
//                     "title": "AMT Gastroguss GN 1/1 Mehrzweck - Backplatte (Wendeplatte) mit Saftrinne, 53 x 32,5 cm, Lotan® Antihaft-Beschichtung für fettfreies Braten, hitzebeständig, AMZN-5333WP",
//                     "asin": "B07MPCFJRF",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDM2NzM3NzAyODA4OjowOjo&url=%2FAMT-Gastronorm-Wendeplatte-Backplatte-Saftrinne%2Fdp%2FB07MPCFJRF%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71R38k-8NsL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 3.6,
//                     "ratings_total": 6,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99,
//                             "raw": "€99,00",
//                             "name": "99,00 €",
//                             "asin": "B07MPCFJRF",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDM2NzM3NzAyODA4OjowOjo&url=%2FAMT-Gastronorm-Wendeplatte-Backplatte-Saftrinne%2Fdp%2FB07MPCFJRF%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99,
//                         "raw": "€99,00",
//                         "name": "99,00 €",
//                         "asin": "B07MPCFJRF",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDM2NzM3NzAyODA4OjowOjo&url=%2FAMT-Gastronorm-Wendeplatte-Backplatte-Saftrinne%2Fdp%2FB07MPCFJRF%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 23,
//                     "title": "TLESS® 2in1 Aluguss Bräter mit Deckel backofengeeignet | Schmorpfanne & Schmortopf oder als Bratentopf für Induktion | sehr leicht & widerstandsfähig | Ø 24 cm 4 Liter rot",
//                     "asin": "B09QNBK8G4",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDYxNjIzNDg2OTk4OjowOjo&url=%2Fbackofengeeignet-Schmorpfanne-Schmortopf-Bratentopf-widerstandsf%25C3%25A4hig%2Fdp%2FB09QNBK8G4%2Fref%3Dsr_1_23_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-23-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81k5CfKdt2L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.9,
//                     "ratings_total": 615,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 70.32,
//                             "raw": "€70,32",
//                             "name": "70,32 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 87.9,
//                             "raw": "€87,90",
//                             "name": "70,32 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 70.32,
//                         "raw": "€70,32",
//                         "name": "70,32 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 24,
//                     "title": "220 Einwegbackformen Papierbackformen Einmal Backform Papier mit Backtrenn Funktion 215x100x35mm - Inkl. Verpackungslizenz in D",
//                     "asin": "B07XT1D3Y3",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDI3MDU2MzYzMzAzOjowOjo&url=%2FEinwegbackformen-Papierbackformen-Backform-Backtrenn-215x100x35mm%2Fdp%2FB07XT1D3Y3%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41M5F8LCToL._AC_UL400_.jpg",
//                     "unit_price": "0,32 €/stück",
//                     "rating": 4,
//                     "ratings_total": 1,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.8,
//                             "raw": "€69,80",
//                             "name": "69,80 €",
//                             "asin": "B07XT1D3Y3",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDI3MDU2MzYzMzAzOjowOjo&url=%2FEinwegbackformen-Papierbackformen-Backform-Backtrenn-215x100x35mm%2Fdp%2FB07XT1D3Y3%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.8,
//                         "raw": "€69,80",
//                         "name": "69,80 €",
//                         "asin": "B07XT1D3Y3",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo4MTk1Mjg4NTMwNjc4OTgxOjE2ODQwNzQ0ODg6c3BfbXRmOjIwMDI3MDU2MzYzMzAzOjowOjo&url=%2FEinwegbackformen-Papierbackformen-Backform-Backtrenn-215x100x35mm%2Fdp%2FB07XT1D3Y3%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DBackformen-Set%26qid%3D1684074488%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 25,
//                     "title": "KRUSTENZAUBER Gusseisen Topf Brot Backen [inkl. Gärkorb] - Hochwertiger Emaille Topf 26cm - Idealer gusseiserner Brottopf zum Backen, Gusseisentopf Brotbacken, Brot Backen Zubehör…",
//                     "asin": "B0B3J8VRB7",
//                     "link": "https://www.amazon.de/KRUSTENZAUBER-Gusseisen-Backen-inkl-G%C3%A4rkorb/dp/B0B3J8VRB7/ref=sr_1_25?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-25",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91otH9I5OaL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 110,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.95,
//                             "raw": "€69,95",
//                             "name": "69,95 €",
//                             "asin": "B0B3J8VRB7",
//                             "link": "https://www.amazon.de/KRUSTENZAUBER-Gusseisen-Backen-inkl-G%C3%A4rkorb/dp/B0B3J8VRB7/ref=sr_1_25?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-25"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.95,
//                         "raw": "€69,95",
//                         "name": "69,95 €",
//                         "asin": "B0B3J8VRB7",
//                         "link": "https://www.amazon.de/KRUSTENZAUBER-Gusseisen-Backen-inkl-G%C3%A4rkorb/dp/B0B3J8VRB7/ref=sr_1_25?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-25"
//                     }
//                 },
//                 {
//                     "position": 26,
//                     "title": "KRUSTENZAUBER Gusseisen Topf Brot Backen [inkl. Gärkorb] - Robuster 26cm gusseiserner Brottopf zum backen - Ideale Brotbackform mit Deckel, Gusseisenpfanne, Brot Backen Zubehör, Pfanne Gusseisen",
//                     "asin": "B0B3JKJC9J",
//                     "link": "https://www.amazon.de/KRUSTENZAUBER-Gusseisen-Backen-inkl-G%C3%A4rkorb/dp/B0B3JKJC9J/ref=sr_1_26?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-26",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/A1Zsk09bPtL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 639,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.95,
//                             "raw": "€59,95",
//                             "name": "59,95 €",
//                             "asin": "B0B3JKJC9J",
//                             "link": "https://www.amazon.de/KRUSTENZAUBER-Gusseisen-Backen-inkl-G%C3%A4rkorb/dp/B0B3JKJC9J/ref=sr_1_26?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-26"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.95,
//                         "raw": "€59,95",
//                         "name": "59,95 €",
//                         "asin": "B0B3JKJC9J",
//                         "link": "https://www.amazon.de/KRUSTENZAUBER-Gusseisen-Backen-inkl-G%C3%A4rkorb/dp/B0B3JKJC9J/ref=sr_1_26?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-26"
//                     }
//                 },
//                 {
//                     "position": 27,
//                     "title": "Pyrex 8023510, Set aus 3 Auflaufformen aus Glas – Borosilikatglas",
//                     "asin": "B076H44LDP",
//                     "link": "https://www.amazon.de/Pyrex-8023510-Lot-Rechteckige-Irresistible-verre-8023510/dp/B076H44LDP/ref=sr_1_27?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-27",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71g3IFmcs2L._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 310,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 33.8,
//                             "raw": "€33,80",
//                             "name": "33,80 €",
//                             "asin": "B076H44LDP",
//                             "link": "https://www.amazon.de/Pyrex-8023510-Lot-Rechteckige-Irresistible-verre-8023510/dp/B076H44LDP/ref=sr_1_27?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-27"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 33.8,
//                         "raw": "€33,80",
//                         "name": "33,80 €",
//                         "asin": "B076H44LDP",
//                         "link": "https://www.amazon.de/Pyrex-8023510-Lot-Rechteckige-Irresistible-verre-8023510/dp/B076H44LDP/ref=sr_1_27?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-27"
//                     }
//                 },
//                 {
//                     "position": 28,
//                     "title": "Le Creuset 4er-Set Mini Cocottes aus Steinzeug, je 200 ml, 10 x 5 cm, Meeresbrise (Grün/Türkis/Blau/Mint), 79212105139100",
//                     "asin": "B084W8Q5PV",
//                     "link": "https://www.amazon.de/Mini-Cocotte-Br%C3%A4ter-Set-4-teilig-Steinzeug-Meeresbrise/dp/B084W8Q5PV/ref=sr_1_28?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-28",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/515+i2NsRpL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.9,
//                     "ratings_total": 55,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.95,
//                             "raw": "€79,95",
//                             "name": "79,95 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 83,
//                             "raw": "€83,00",
//                             "name": "79,95 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.95,
//                         "raw": "€79,95",
//                         "name": "79,95 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 29,
//                     "title": "Keramik Auflaufform Mit Deckle, Backformen Rechteckig 39 x 24 x 9 cm, Pastetenform Steinzeug groß 3000 mL",
//                     "asin": "B08Z75G197",
//                     "link": "https://www.amazon.de/UNICASA-Auflaufform-Backformen-Rechteckig-Pastetenform/dp/B08Z75G197/ref=sr_1_29?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-29",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71GsoM+6cSS._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 10 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 81,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 73.99,
//                             "raw": "€73,99",
//                             "name": "73,99 €",
//                             "asin": "B08Z75G197",
//                             "link": "https://www.amazon.de/UNICASA-Auflaufform-Backformen-Rechteckig-Pastetenform/dp/B08Z75G197/ref=sr_1_29?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-29"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 73.99,
//                         "raw": "€73,99",
//                         "name": "73,99 €",
//                         "asin": "B08Z75G197",
//                         "link": "https://www.amazon.de/UNICASA-Auflaufform-Backformen-Rechteckig-Pastetenform/dp/B08Z75G197/ref=sr_1_29?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-29"
//                     }
//                 },
//                 {
//                     "position": 30,
//                     "title": "Le Creuset Antihaft Springform mit Rohrboden, Rund, Ø 26 cm, Sauerteigbeständig, Aus Karbonstahl gefertigt, Anthrazit/Orange, 94102326001100",
//                     "asin": "B0143QY9IU",
//                     "link": "https://www.amazon.de/Creuset-Antihaftbeschichtete-Springform-mit-Rohrboden/dp/B0143QY9IU/ref=sr_1_30?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-30",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61yiAkaFL8L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 218,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 57,
//                             "raw": "€57,00",
//                             "name": "57,00 €",
//                             "asin": "B0143QY9IU",
//                             "link": "https://www.amazon.de/Creuset-Antihaftbeschichtete-Springform-mit-Rohrboden/dp/B0143QY9IU/ref=sr_1_30?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-30"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 57,
//                         "raw": "€57,00",
//                         "name": "57,00 €",
//                         "asin": "B0143QY9IU",
//                         "link": "https://www.amazon.de/Creuset-Antihaftbeschichtete-Springform-mit-Rohrboden/dp/B0143QY9IU/ref=sr_1_30?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-30"
//                     }
//                 },
//                 {
//                     "position": 31,
//                     "title": "PME - Runde Kuchenformen 15, 20, 25 cm x 10 cm tief, 3er-Satz, RNDB68104, Silber",
//                     "asin": "B09SZ2VJL1",
//                     "link": "https://www.amazon.de/PME-Kuchenformen-3er-Satz-RNDB68104-Silber/dp/B09SZ2VJL1/ref=sr_1_31?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-31",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71iMb4AjtVL._AC_UL400_.jpg",
//                     "unit_price": "12,75 €/stück",
//                     "rating": 4.6,
//                     "ratings_total": 39,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 38.24,
//                             "raw": "€38,24",
//                             "name": "38,24 €",
//                             "asin": "B09SZ2VJL1",
//                             "link": "https://www.amazon.de/PME-Kuchenformen-3er-Satz-RNDB68104-Silber/dp/B09SZ2VJL1/ref=sr_1_31?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-31"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 38.24,
//                         "raw": "€38,24",
//                         "name": "38,24 €",
//                         "asin": "B09SZ2VJL1",
//                         "link": "https://www.amazon.de/PME-Kuchenformen-3er-Satz-RNDB68104-Silber/dp/B09SZ2VJL1/ref=sr_1_31?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-31"
//                     }
//                 },
//                 {
//                     "position": 32,
//                     "title": "Wilton Countless Celebrations Zahlen- und Buchstaben- Kuchenform, antihaftbeschichtet, 38 x 23 x 5 cm",
//                     "asin": "B01DDHR702",
//                     "link": "https://www.amazon.de/Wilton-2105-0801-Backform-Aluminium-Einheiten/dp/B01DDHR702/ref=sr_1_32?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-32",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81ayy7BsVnL._AC_UL400_.jpg",
//                     "unit_price": "7,24 €/stück",
//                     "rating": 4.5,
//                     "ratings_total": 6053,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 28.95,
//                             "raw": "€28,95",
//                             "name": "28,95 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 34.75,
//                             "raw": "€34,75",
//                             "name": "28,95 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 28.95,
//                         "raw": "€28,95",
//                         "name": "28,95 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 33,
//                     "title": "Dr. Oetker 2362 mit Hebeboden Ø 26 cm BACK-LIEBE EMAILLE, runde Backform mit schnitt- & kratzfester Emaille-Versiegelung, Kuchenform für köstliche Torten (Farbe: Blau), Menge: 1 Stück",
//                     "asin": "B000UVHXX6",
//                     "link": "https://www.amazon.de/Dr-Oetker-Garantieform-kratzfester-Emaille-Versiegelung/dp/B000UVHXX6/ref=sr_1_33?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-33",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41cZm2fzMWL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 849,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 30.99,
//                             "raw": "€30,99",
//                             "name": "30,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 49.99,
//                             "raw": "€49,99",
//                             "name": "30,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 30.99,
//                         "raw": "€30,99",
//                         "name": "30,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 34,
//                     "title": "Dr. Oetker Springform Ø 28 cm Back-Liebe Emaille – Runde Backform mit schnitt- & kratzfestem Emaille-Boden – 28er Kuchenbackform mit extra hohem Rand in Dunkelblau",
//                     "asin": "B014PJXXOQ",
//                     "link": "https://www.amazon.de/Dr-Oetker-Springform-kratzfestem-Emaille-Versiegelung/dp/B014PJXXOQ/ref=sr_1_34?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-34",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51RIhpgDLXL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 1895,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 32.99,
//                             "raw": "€32,99",
//                             "name": "32,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 44.99,
//                             "raw": "€44,99",
//                             "name": "32,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 32.99,
//                         "raw": "€32,99",
//                         "name": "32,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 35,
//                     "title": "Original Kaiser La Forme Plus Springform 28 cm rund, 2 Böden, Flach- und Rohrboden, SafeClick-Verschluss, Emailleboden, beschichtet, schnittfest, auslaufsicher",
//                     "asin": "B06WLH99VJ",
//                     "link": "https://www.amazon.de/Kaiser-SafeClick-Verschluss-schnittfest-auslaufsicher-antihaftbeschichtet/dp/B06WLH99VJ/ref=sr_1_35?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-35",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71vVWNXJVHL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 559,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 41.24,
//                             "raw": "€41,24",
//                             "name": "41,24 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "41,24 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 41.24,
//                         "raw": "€41,24",
//                         "name": "41,24 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 36,
//                     "title": "KAISERTAL® Robuster 4in1 Brotbackform mit Deckel [3L][28CM] Backform aus Gusseisen,Toastbrot Backform, Kastenform, Gusseisen Topf. Einsatzbereit auf alle Herdarten",
//                     "asin": "B0BHDG3KKK",
//                     "link": "https://www.amazon.de/KAISERTAL%C2%AE-Brotbackform-Gusseisen-Kastenform-Einsatzbereit/dp/B0BHDG3KKK/ref=sr_1_36?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-36",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91phWw3XsrL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 73,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.95,
//                             "raw": "€54,95",
//                             "name": "54,95 €",
//                             "asin": "B0BHDG3KKK",
//                             "link": "https://www.amazon.de/KAISERTAL%C2%AE-Brotbackform-Gusseisen-Kastenform-Einsatzbereit/dp/B0BHDG3KKK/ref=sr_1_36?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-36"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.95,
//                         "raw": "€54,95",
//                         "name": "54,95 €",
//                         "asin": "B0BHDG3KKK",
//                         "link": "https://www.amazon.de/KAISERTAL%C2%AE-Brotbackform-Gusseisen-Kastenform-Einsatzbereit/dp/B0BHDG3KKK/ref=sr_1_36?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-36"
//                     }
//                 },
//                 {
//                     "position": 37,
//                     "title": "Dr. Oetker Springform Ø 26 cm Back-Liebe Emaille – Inklusive Flach- und Rohrboden – Schnitt- & kratzfestem Emaille-Boden – 26er Kuchenbackform mit extra hohem Rand in Dunkelblau",
//                     "asin": "B017HAHVDY",
//                     "link": "https://www.amazon.de/Dr-Oetker-kratzfestem-Emaille-Boden-antihaftbeschichtetem/dp/B017HAHVDY/ref=sr_1_37_mod_primary_new?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-37",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51ahqeOHLfL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 397,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 40.52,
//                             "raw": "€40,52",
//                             "name": "40,52 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 41.99,
//                             "raw": "€41,99",
//                             "name": "41,99 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 40.52,
//                         "raw": "€40,52",
//                         "name": "40,52 €"
//                     }
//                 },
//                 {
//                     "position": 38,
//                     "title": "Dr. Oetker Rechteckige Springform mit Servierboden und Abdeckhaube 43,5 x 29,5 x 10,5 cm Back-Idee – Eckige Backform mit schnitt- & kratzfestem Flachboden & Emaille-Beschichtung – Kuchenbackform mit Antihaftbeschichtung",
//                     "asin": "B00EOOIHIA",
//                     "link": "https://www.amazon.de/Dr-Oetker-Rechteck-Springform-Kuchenblech-Transportbox/dp/B00EOOIHIA/ref=sr_1_38_mod_primary_new?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-38",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81CY50opJhL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 675,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 44.62,
//                             "raw": "€44,62",
//                             "name": "44,62 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 45.57,
//                             "raw": "€45,57",
//                             "name": "45,57 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 44.62,
//                         "raw": "€44,62",
//                         "name": "44,62 €"
//                     }
//                 },
//                 {
//                     "position": 39,
//                     "title": "Riess, 0485-022, Tortenform, 26 cm, 3-tlg, CLASSIC - BACKFORMEN, Höhe 8,0 cm, Profibäcker, Backform, Emaillie, schwarz, mit Rohrboden, Induktion",
//                     "asin": "B00172EX3U",
//                     "link": "https://www.amazon.de/Riess-Riess-_0485-022-Tortenform-3Tlg-Profib%C3%A4cker/dp/B00172EX3U/ref=sr_1_39?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-39",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81hCoOwLu9L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 166,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 61.4,
//                             "raw": "€61,40",
//                             "name": "61,40 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 86.9,
//                             "raw": "€86,90",
//                             "name": "61,40 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 61.4,
//                         "raw": "€61,40",
//                         "name": "61,40 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 40,
//                     "title": "3er Set Porzellan Backform, Auflaufform Groß - Ofenform Rechteckig, Schale Auflaufform Suppenteller Suppenschale in 3 Größe für Lasagne - Grün",
//                     "asin": "B09WY55DV6",
//                     "link": "https://www.amazon.de/3er-Porzellan-Backform-Auflaufform-Gro%C3%9F/dp/B09WY55DV6/ref=sr_1_40?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-40",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71RIqgoA-5L._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 5 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 2,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "asin": "B09WY55DV6",
//                             "link": "https://www.amazon.de/3er-Porzellan-Backform-Auflaufform-Gro%C3%9F/dp/B09WY55DV6/ref=sr_1_40?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-40"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "asin": "B09WY55DV6",
//                         "link": "https://www.amazon.de/3er-Porzellan-Backform-Auflaufform-Gro%C3%9F/dp/B09WY55DV6/ref=sr_1_40?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-40"
//                     }
//                 },
//                 {
//                     "position": 41,
//                     "title": "COSORI Heißluftfritteuse Zubehör, Sechsteiliges Zubehörset für 5,5L Fritteusen Air Fyer aller Marken, Backform, Pizza Pan, Grillrost, Dämpfen Rack, Silikonmatte, Muffinform",
//                     "asin": "B07PRJYGB2",
//                     "link": "https://www.amazon.de/Hei%C3%9Fluftfritteuse-Sechsteiliges-Zubeh%C3%B6rset-Fritteusen-Silikonmatte/dp/B07PRJYGB2/ref=sr_1_41?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-41",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71I5HXrLoXL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 7506,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 40.99,
//                             "raw": "€40,99",
//                             "name": "40,99 €",
//                             "asin": "B07PRJYGB2",
//                             "link": "https://www.amazon.de/Hei%C3%9Fluftfritteuse-Sechsteiliges-Zubeh%C3%B6rset-Fritteusen-Silikonmatte/dp/B07PRJYGB2/ref=sr_1_41?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-41"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 40.99,
//                         "raw": "€40,99",
//                         "name": "40,99 €",
//                         "asin": "B07PRJYGB2",
//                         "link": "https://www.amazon.de/Hei%C3%9Fluftfritteuse-Sechsteiliges-Zubeh%C3%B6rset-Fritteusen-Silikonmatte/dp/B07PRJYGB2/ref=sr_1_41?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-41"
//                     }
//                 },
//                 {
//                     "position": 42,
//                     "title": "Flavemotion Brotbackform mit Deckel – 2,5 L, rechteckig, Voreingebrannt – SuperCrispDesign für perfekte Krusten beim Brot Backen auch Kastenform, Gusseisen Topf, Auflaufform, Bräter, Dutch Oven",
//                     "asin": "B0926LYNRM",
//                     "link": "https://www.amazon.de/Flavemotion-Brotbackform-Brotkasten-Kuchenbackform-BROTBACKFORM/dp/B0926LYNRM/ref=sr_1_42?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-42",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91OinGD55YL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 11 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 297,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "asin": "B0926LYNRM",
//                             "link": "https://www.amazon.de/Flavemotion-Brotbackform-Brotkasten-Kuchenbackform-BROTBACKFORM/dp/B0926LYNRM/ref=sr_1_42?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-42"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "asin": "B0926LYNRM",
//                         "link": "https://www.amazon.de/Flavemotion-Brotbackform-Brotkasten-Kuchenbackform-BROTBACKFORM/dp/B0926LYNRM/ref=sr_1_42?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-42"
//                     }
//                 },
//                 {
//                     "position": 43,
//                     "title": "Auflaufform Set 4 teiliges Ofenform Set Auflaufformen für mehrere Personen aus Glas, Backform Füllmenge hitzebeständig, Alltag, besonderes Essen, Familienessen, Dinner, Mittagessen Bratform",
//                     "asin": "B087QZQX8V",
//                     "link": "https://www.amazon.de/Backgeschirr-Set-Kunststoffdeckeln-Glas-Backgeschirr-Set-Gefrierschrank-zu-Ofen-Sp%C3%BClmaschine/dp/B087QZQX8V/ref=sr_1_43?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-43",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61YeOa97zwL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 3462,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 35.99,
//                             "raw": "€35,99",
//                             "name": "35,99 €",
//                             "asin": "B087QZQX8V",
//                             "link": "https://www.amazon.de/Backgeschirr-Set-Kunststoffdeckeln-Glas-Backgeschirr-Set-Gefrierschrank-zu-Ofen-Sp%C3%BClmaschine/dp/B087QZQX8V/ref=sr_1_43?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-43"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 35.99,
//                         "raw": "€35,99",
//                         "name": "35,99 €",
//                         "asin": "B087QZQX8V",
//                         "link": "https://www.amazon.de/Backgeschirr-Set-Kunststoffdeckeln-Glas-Backgeschirr-Set-Gefrierschrank-zu-Ofen-Sp%C3%BClmaschine/dp/B087QZQX8V/ref=sr_1_43?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-43"
//                     }
//                 },
//                 {
//                     "position": 44,
//                     "title": "Zenker 7402 12er-Mini-Törtchen-Backblech, Ø 8,5 cm Special Creative, Cupcake Backform mit Antihaftbeschichtung, Ofenform für kleine runde Kuchen, Menge: 1 Stück, Farbe: schwarz",
//                     "asin": "B0051YIK7K",
//                     "link": "https://www.amazon.de/Zenker-Mini-T%C3%B6rtchen-Backblech-Backform-Antihaftbeschichtung-Kuchenform/dp/B0051YIK7K/ref=sr_1_44?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-44",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61vYFvZqr+L._AC_UL400_.jpg",
//                     "unit_price": "2,67 €/Jede/-r/-s",
//                     "rating": 4.6,
//                     "ratings_total": 973,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 32,
//                             "raw": "€32,00",
//                             "name": "32,00 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 35.16,
//                             "raw": "€35,16",
//                             "name": "32,00 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 32,
//                         "raw": "€32,00",
//                         "name": "32,00 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 45,
//                     "title": "Emile Henry VPC Spezialisten Brot Set Haus, Keramik, Kohle, 32,5 x 30 x 15 cm",
//                     "asin": "B079TX6HFH",
//                     "link": "https://www.amazon.de/Emile-Henry-EH509507-Brot-Set-Zuhause/dp/B079TX6HFH/ref=sr_1_45?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-45",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81Mh8FZTiKL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 417,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 92.9,
//                             "raw": "€92,90",
//                             "name": "92,90 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 109.9,
//                             "raw": "€109,90",
//                             "name": "92,90 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 92.9,
//                         "raw": "€92,90",
//                         "name": "92,90 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 46,
//                     "title": "Philips HD9945/01 Back-Kit Backform & Muffinformen für Airfryer XL (HD9240, HD9260) mit 1,2 kg Fassungsvolumen",
//                     "asin": "B07Y238TDN",
//                     "link": "https://www.amazon.de/Philips-HD9945-01-Back-Kit-Muffinformen/dp/B07Y238TDN/ref=sr_1_46?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-46",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71P6-bI2ZRL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 528,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 28.99,
//                             "raw": "€28,99",
//                             "name": "28,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 29.99,
//                             "raw": "€29,99",
//                             "name": "28,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 28.99,
//                         "raw": "€28,99",
//                         "name": "28,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 47,
//                     "title": "OMNIA Form Antihaft-, Backofen-Pfanne mit Keramik-Beschichtung für Campingofen",
//                     "asin": "B09VN5TR7M",
//                     "link": "https://www.amazon.de/OMNIA-Omnia-Form-Backofen-Pfanne-Keramik-Beschichtung-Campingofen/dp/B09VN5TR7M/ref=sr_1_47?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-47",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61A+ERAssmL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 145,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 20,
//                             "raw": "€20,00",
//                             "name": "20,00 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 22,
//                             "raw": "€22,00",
//                             "name": "20,00 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 20,
//                         "raw": "€20,00",
//                         "name": "20,00 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 48,
//                     "title": "Original Kaiser La Forme Plus Springform 26 cm rund, 2 Böden, Flach- und Rohrboden, SafeClick-Verschluss, Emailleboden, beschichtet, schnittfest, auslaufsicher",
//                     "asin": "B06WLHHDNR",
//                     "link": "https://www.amazon.de/Kaiser-SafeClick-Verschluss-schnittfest-auslaufsicher-antihaftbeschichtet/dp/B06WLHHDNR/ref=sr_1_48?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-48",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71N5KSGB+xL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 1210,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 33.99,
//                             "raw": "€33,99",
//                             "name": "33,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 49.99,
//                             "raw": "€49,99",
//                             "name": "33,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 33.99,
//                         "raw": "€33,99",
//                         "name": "33,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 49,
//                     "title": "Original Kaiser La Forme Plus Königskuchen Springform eckig 30 x 11 cm, Backform rechteckig, Emailleboden, schnittfest, Kuchenform eckig",
//                     "asin": "B06WRNQSDJ",
//                     "link": "https://www.amazon.de/Kaiser-SafeClick-Verschluss-Antihaftbeschichtung-schnittfester-Emaille-Boden/dp/B06WRNQSDJ/ref=sr_1_49?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-49",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61+kn7QiYSL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 292,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 37.99,
//                             "raw": "€37,99",
//                             "name": "37,99 €",
//                             "asin": "B06WRNQSDJ",
//                             "link": "https://www.amazon.de/Kaiser-SafeClick-Verschluss-Antihaftbeschichtung-schnittfester-Emaille-Boden/dp/B06WRNQSDJ/ref=sr_1_49?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-49"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 37.99,
//                         "raw": "€37,99",
//                         "name": "37,99 €",
//                         "asin": "B06WRNQSDJ",
//                         "link": "https://www.amazon.de/Kaiser-SafeClick-Verschluss-Antihaftbeschichtung-schnittfester-Emaille-Boden/dp/B06WRNQSDJ/ref=sr_1_49?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-49"
//                     }
//                 },
//                 {
//                     "position": 50,
//                     "title": "Römertopf Brotbacktopf aus Naturton bis zu ca. 1.000g Backgut, für natürliches Brotbacken, Weiß",
//                     "asin": "B0B6FQVH4S",
//                     "link": "https://www.amazon.de/R%C3%B6mertopf-Brotbacktopf-nat%C3%BCrliches-Brotbacken-252/dp/B0B6FQVH4S/ref=sr_1_50?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-50",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61l4nbdXX6L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 22,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.95,
//                             "raw": "€59,95",
//                             "name": "59,95 €",
//                             "asin": "B0B6FQVH4S",
//                             "link": "https://www.amazon.de/R%C3%B6mertopf-Brotbacktopf-nat%C3%BCrliches-Brotbacken-252/dp/B0B6FQVH4S/ref=sr_1_50?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-50"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.95,
//                         "raw": "€59,95",
//                         "name": "59,95 €",
//                         "asin": "B0B6FQVH4S",
//                         "link": "https://www.amazon.de/R%C3%B6mertopf-Brotbacktopf-nat%C3%BCrliches-Brotbacken-252/dp/B0B6FQVH4S/ref=sr_1_50?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-50"
//                     }
//                 },
//                 {
//                     "position": 51,
//                     "title": "Antihaftbeschichtete Mini-Kastenformen zum Brotbacken, 15,2 cm Karbonstahl-Backformen für rechteckiges Brot und Fleischbackgeschirr, Set mit 4 Mini-Kuchenformen für Kuchen, Hackbraten, Banane",
//                     "asin": "B08CDS7B2X",
//                     "link": "https://www.amazon.de/Antihaftbeschichtete-Mini-Brotbackformen-rechteckiges-Fleisch-Backgeschirr-Mini-Kuchenformen/dp/B08CDS7B2X/ref=sr_1_51?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-51",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61iAtWrehoL._AC_UL400_.jpg",
//                     "unit_price": "5,50 €/stück",
//                     "rating": 4.6,
//                     "ratings_total": 85,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 21.99,
//                             "raw": "€21,99",
//                             "name": "21,99 €",
//                             "asin": "B08CDS7B2X",
//                             "link": "https://www.amazon.de/Antihaftbeschichtete-Mini-Brotbackformen-rechteckiges-Fleisch-Backgeschirr-Mini-Kuchenformen/dp/B08CDS7B2X/ref=sr_1_51?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-51"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 21.99,
//                         "raw": "€21,99",
//                         "name": "21,99 €",
//                         "asin": "B08CDS7B2X",
//                         "link": "https://www.amazon.de/Antihaftbeschichtete-Mini-Brotbackformen-rechteckiges-Fleisch-Backgeschirr-Mini-Kuchenformen/dp/B08CDS7B2X/ref=sr_1_51?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-51"
//                     }
//                 },
//                 {
//                     "position": 52,
//                     "title": "Zenker 7481 creative studio 12er Mini-Törtchen-Backblech, Minikuchenform mit Antihaftbeschichtung, Kuchenform für kleine Kuchen, kreatives Backen (Farbe: rosa, silber), Menge: 1 Stück",
//                     "asin": "B07B6854J3",
//                     "link": "https://www.amazon.de/Zenker-Mini-T%C3%B6rtchen-Backblech-Minikuchenform-Antihaftbeschichtung-Kuchenform/dp/B07B6854J3/ref=sr_1_52?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-52",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/715aQFYyg6L._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 68,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 44.99,
//                             "raw": "€44,99",
//                             "name": "44,99 €",
//                             "asin": "B07B6854J3",
//                             "link": "https://www.amazon.de/Zenker-Mini-T%C3%B6rtchen-Backblech-Minikuchenform-Antihaftbeschichtung-Kuchenform/dp/B07B6854J3/ref=sr_1_52?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-52"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 44.99,
//                         "raw": "€44,99",
//                         "name": "44,99 €",
//                         "asin": "B07B6854J3",
//                         "link": "https://www.amazon.de/Zenker-Mini-T%C3%B6rtchen-Backblech-Minikuchenform-Antihaftbeschichtung-Kuchenform/dp/B07B6854J3/ref=sr_1_52?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-52"
//                     }
//                 },
//                 {
//                     "position": 53,
//                     "title": "Le Creuset Mini-Cocotte aus Steinzeug, 200 ml, 10 x 5 cm, Bamboo, 71901104080100",
//                     "asin": "B0B71CK86T",
//                     "link": "https://www.amazon.de/Creuset-Mini-Cocotte-Steinzeug-Bamboo-71901104080100/dp/B0B71CK86T/ref=sr_1_53?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-53",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51ELO-FPGtL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 436,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 25,
//                             "raw": "€25,00",
//                             "name": "25,00 €",
//                             "asin": "B0B71CK86T",
//                             "link": "https://www.amazon.de/Creuset-Mini-Cocotte-Steinzeug-Bamboo-71901104080100/dp/B0B71CK86T/ref=sr_1_53?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-53"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 25,
//                         "raw": "€25,00",
//                         "name": "25,00 €",
//                         "asin": "B0B71CK86T",
//                         "link": "https://www.amazon.de/Creuset-Mini-Cocotte-Steinzeug-Bamboo-71901104080100/dp/B0B71CK86T/ref=sr_1_53?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-53"
//                     }
//                 },
//                 {
//                     "position": 54,
//                     "title": "Tefal Perfectbake Set 5 Kuchenformen, 30 cm, 24 cm, 28 cm, 4 Quadrate 26 cm, Backblech, 38 x 28 cm, Antihaftbeschichtung, Aluminium, 100% recycelt, leichtes Herauslösen YY4220FA",
//                     "asin": "B07QJGSLP6",
//                     "link": "https://www.amazon.de/Tefal-YY4220FA-Perfectbake-Backformen-Antihaftbeschichtung/dp/B07QJGSLP6/ref=sr_1_54?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-54",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61Z600piROL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 108,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 62.28,
//                             "raw": "€62,28",
//                             "name": "62,28 €",
//                             "asin": "B07QJGSLP6",
//                             "link": "https://www.amazon.de/Tefal-YY4220FA-Perfectbake-Backformen-Antihaftbeschichtung/dp/B07QJGSLP6/ref=sr_1_54?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-54"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 62.28,
//                         "raw": "€62,28",
//                         "name": "62,28 €",
//                         "asin": "B07QJGSLP6",
//                         "link": "https://www.amazon.de/Tefal-YY4220FA-Perfectbake-Backformen-Antihaftbeschichtung/dp/B07QJGSLP6/ref=sr_1_54?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-54"
//                     }
//                 },
//                 {
//                     "position": 55,
//                     "title": "Zenker 7020 Springform mit Transporthaube Ø 28 cm DELUXE, Backform mit emailliertem Flachboden und Tragehaube, runde Kuchenform für saftigen Kuchen to go (Frabe: Schwarz metallic), Menge: 1 Stück",
//                     "asin": "B008N94R4K",
//                     "link": "https://www.amazon.de/Zenker-Springform-Transporthaube-emailliertem-Flachboden/dp/B008N94R4K/ref=sr_1_55?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-55",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/714MezaHJTL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 297,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 40.44,
//                             "raw": "€40,44",
//                             "name": "40,44 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 47.99,
//                             "raw": "€47,99",
//                             "name": "40,44 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 40.44,
//                         "raw": "€40,44",
//                         "name": "40,44 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 56,
//                     "title": "Wilton 3D-Kuchenform Buch, Groß, Aluminium",
//                     "asin": "B0000VYYOC",
//                     "link": "https://www.amazon.de/Wilton-2105-2521-Buchbackform-Stahl-40-66/dp/B0000VYYOC/ref=sr_1_56?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-56",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81NYA6+2huL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 746,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 22.95,
//                             "raw": "€22,95",
//                             "name": "22,95 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 23.9,
//                             "raw": "€23,90",
//                             "name": "22,95 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 22.95,
//                         "raw": "€22,95",
//                         "name": "22,95 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 57,
//                     "title": "Omnia Omnia Silikonform S2",
//                     "asin": "B07KGJRQ3M",
//                     "link": "https://www.amazon.de/Omnia-103-Silikonform-S2/dp/B07KGJRQ3M/ref=sr_1_57_mod_primary_new?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-57",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51Q65uPNHKL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 1081,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 17.4,
//                             "raw": "€17,40",
//                             "name": "17,40 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 24.99,
//                             "raw": "€24,99",
//                             "name": "24,99 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 17.4,
//                         "raw": "€17,40",
//                         "name": "17,40 €"
//                     }
//                 },
//                 {
//                     "position": 58,
//                     "title": "Philips Airfryer XXL Back-Meisterkit – kompatibel mit XXL-Modellen HD965x HD976x HD986x, Silikon (HD9952/00)",
//                     "asin": "B07998PKM6",
//                     "link": "https://www.amazon.de/Philips-HD9952-00-Back-Kit-Fassungsvolumen/dp/B07998PKM6/ref=sr_1_58?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-58",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51VwHpVLx7L._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 2924,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.83,
//                             "raw": "€54,83",
//                             "name": "54,83 €",
//                             "asin": "B07998PKM6",
//                             "link": "https://www.amazon.de/Philips-HD9952-00-Back-Kit-Fassungsvolumen/dp/B07998PKM6/ref=sr_1_58?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-58"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.83,
//                         "raw": "€54,83",
//                         "name": "54,83 €",
//                         "asin": "B07998PKM6",
//                         "link": "https://www.amazon.de/Philips-HD9952-00-Back-Kit-Fassungsvolumen/dp/B07998PKM6/ref=sr_1_58?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-58"
//                     }
//                 },
//                 {
//                     "position": 59,
//                     "title": "Dr. Oetker Garantieform Ø 28 cm Back-Liebe Emaille mit Flachboden und Rohrboden – Runde Ring-Backform mit Schnitt- & kratzfestem Emaille-Boden – 28er Kuchenbackform für Kuchen und Torten",
//                     "asin": "B000UVRG5G",
//                     "link": "https://www.amazon.de/Dr-Oetker-Garantieform-kratzfester-Emaille-Versiegelung/dp/B000UVRG5G/ref=sr_1_59?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-59",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Pwq+AuL3L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 466,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 55.12,
//                             "raw": "€55,12",
//                             "name": "55,12 €",
//                             "asin": "B000UVRG5G",
//                             "link": "https://www.amazon.de/Dr-Oetker-Garantieform-kratzfester-Emaille-Versiegelung/dp/B000UVRG5G/ref=sr_1_59?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-59"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 55.12,
//                         "raw": "€55,12",
//                         "name": "55,12 €",
//                         "asin": "B000UVRG5G",
//                         "link": "https://www.amazon.de/Dr-Oetker-Garantieform-kratzfester-Emaille-Versiegelung/dp/B000UVRG5G/ref=sr_1_59?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-59"
//                     }
//                 },
//                 {
//                     "position": 60,
//                     "title": "OMNIA Muffins Silikonbackform für Campingbackofen Muffinbackform Kuchenform",
//                     "asin": "B0824SBBDY",
//                     "link": "https://www.amazon.de/Muffins-Silikonbackform-Campingbackofen-Muffinbackform-Kuchenform/dp/B0824SBBDY/ref=sr_1_60?keywords=Backformen-Set&qid=1684074488&refinements=p_36%3A5000-10000&sr=8-60",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61XdxpFL+AL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 525,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 18.49,
//                             "raw": "€18,49",
//                             "name": "18,49 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 22.58,
//                             "raw": "€22,58",
//                             "name": "18,49 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 18.49,
//                         "raw": "€18,49",
//                         "name": "18,49 €",
//                         "is_primary": true
//                     }
//                 }
//             ]
//         },
//         {
//             "idea": "Geschirrtuch-Set",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "30x Geschirrtuch aus 100% Baumwolle Waffel-Piqué in grau",
//                     "asin": "B01MQNXPZ7",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo1NTE3MzE1NTE5Mzc5NTUzOjE2ODQwNzQ0OTI6c3BfYXRmOjIwMDAyOTYwMjUyMDMyOjowOjo&url=%2FGeschirrtuch-100-Baumwolle-Waffel-Piqu%25C3%25A9-grau%2Fdp%2FB01MQNXPZ7%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DGeschirrtuch-Set%26qid%3D1684074492%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/715n0Jb7zYL._AC_UL400_.jpg",
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.9,
//                             "raw": "€99,90",
//                             "asin": "B01MQNXPZ7",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo1NTE3MzE1NTE5Mzc5NTUzOjE2ODQwNzQ0OTI6c3BfYXRmOjIwMDAyOTYwMjUyMDMyOjowOjo&url=%2FGeschirrtuch-100-Baumwolle-Waffel-Piqu%25C3%25A9-grau%2Fdp%2FB01MQNXPZ7%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DGeschirrtuch-Set%26qid%3D1684074492%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99.9,
//                         "raw": "€99,90",
//                         "asin": "B01MQNXPZ7",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo1NTE3MzE1NTE5Mzc5NTUzOjE2ODQwNzQ0OTI6c3BfYXRmOjIwMDAyOTYwMjUyMDMyOjowOjo&url=%2FGeschirrtuch-100-Baumwolle-Waffel-Piqu%25C3%25A9-grau%2Fdp%2FB01MQNXPZ7%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DGeschirrtuch-Set%26qid%3D1684074492%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "Betz 50er Set Geschirrtücher Geschirrhandtuch Gläsertuch Halbleinen Jacquard MCT-11 Gläsertücher Küchentuch Handtuch Größe 50 x 70 cm Farbe rot-grau 196507",
//                     "asin": "B08L53HCXR",
//                     "link": "https://www.amazon.de/Betz-Geschirrt%C3%BCcher-Gl%C3%A4sert%C3%BCcher-K%C3%BCchent%C3%BCcher-Geschirrhandt%C3%BCcher/dp/B08L53HCXR/ref=sr_1_2?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-2",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81yT+xEI4lL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 75,
//                             "raw": "€75,00",
//                             "asin": "B08L53HCXR",
//                             "link": "https://www.amazon.de/Betz-Geschirrt%C3%BCcher-Gl%C3%A4sert%C3%BCcher-K%C3%BCchent%C3%BCcher-Geschirrhandt%C3%BCcher/dp/B08L53HCXR/ref=sr_1_2?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-2"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 75,
//                         "raw": "€75,00",
//                         "asin": "B08L53HCXR",
//                         "link": "https://www.amazon.de/Betz-Geschirrt%C3%BCcher-Gl%C3%A4sert%C3%BCcher-K%C3%BCchent%C3%BCcher-Geschirrhandt%C3%BCcher/dp/B08L53HCXR/ref=sr_1_2?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-2"
//                     }
//                 },
//                 {
//                     "position": 3,
//                     "title": "Urban Villa Trendy Stripes Küchentücher, Geschirrtücher aus 100% Baumwolle, (Größe: 51X76 CMS) Indigoblau/Weiß Hochabsorbierende Handtücher und Geschirrtücher(6er-Set)",
//                     "asin": "B07MH76WNB",
//                     "link": "https://www.amazon.de/Produkt-KTDRL-Kitchen-Towel-Indigo/dp/B07MH76WNB/ref=sr_1_3?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-3",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81q6bwvS6PL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 5916,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.41,
//                             "raw": "€69,41",
//                             "name": "69,41 €",
//                             "asin": "B07MH76WNB",
//                             "link": "https://www.amazon.de/Produkt-KTDRL-Kitchen-Towel-Indigo/dp/B07MH76WNB/ref=sr_1_3?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-3"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.41,
//                         "raw": "€69,41",
//                         "name": "69,41 €",
//                         "asin": "B07MH76WNB",
//                         "link": "https://www.amazon.de/Produkt-KTDRL-Kitchen-Towel-Indigo/dp/B07MH76WNB/ref=sr_1_3?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-3"
//                     }
//                 },
//                 {
//                     "position": 4,
//                     "title": "DII Sortiert, gewebt Küchen-Geschirrtuch-Set, Baumwolle, Schwarz, 18x28, 5",
//                     "asin": "B08H47JXZ1",
//                     "link": "https://www.amazon.de/DII-Geschirrtuch-Set-gewebt-Schwarz-St%C3%BCck/dp/B08H47JXZ1/ref=sr_1_4?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-4",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91gcKcMMABL._AC_UL400_.jpg",
//                     "unit_price": "14,83 €/stück",
//                     "rating": 4.6,
//                     "ratings_total": 457,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.16,
//                             "raw": "€74,16",
//                             "name": "74,16 €",
//                             "asin": "B08H47JXZ1",
//                             "link": "https://www.amazon.de/DII-Geschirrtuch-Set-gewebt-Schwarz-St%C3%BCck/dp/B08H47JXZ1/ref=sr_1_4?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-4"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 74.16,
//                         "raw": "€74,16",
//                         "name": "74,16 €",
//                         "asin": "B08H47JXZ1",
//                         "link": "https://www.amazon.de/DII-Geschirrtuch-Set-gewebt-Schwarz-St%C3%BCck/dp/B08H47JXZ1/ref=sr_1_4?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-4"
//                     }
//                 },
//                 {
//                     "position": 5,
//                     "title": "Utopia Towels Geschirrtücher, 38,1 x 63,3 cm, 100% ringgesponnene Baumwolle, super saugfähige Leinen-Küchentücher, weiche wiederverwendbare Reinigungsstange und Geschirrtücher Set (24 Stück, schwarz)",
//                     "asin": "B09YV32633",
//                     "link": "https://www.amazon.de/Utopia-Towels-Leinen-K%C3%BCchent%C3%BCcher-wiederverwendbare-Reinigungsstange/dp/B09YV32633/ref=sr_1_5?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-5",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/817fX-aAG3L._AC_UL400_.jpg",
//                     "unit_price": "2,35 €/stück",
//                     "rating": 4.3,
//                     "ratings_total": 17225,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.43,
//                             "raw": "€56,43",
//                             "name": "56,43 €",
//                             "asin": "B09YV32633",
//                             "link": "https://www.amazon.de/Utopia-Towels-Leinen-K%C3%BCchent%C3%BCcher-wiederverwendbare-Reinigungsstange/dp/B09YV32633/ref=sr_1_5?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-5"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 56.43,
//                         "raw": "€56,43",
//                         "name": "56,43 €",
//                         "asin": "B09YV32633",
//                         "link": "https://www.amazon.de/Utopia-Towels-Leinen-K%C3%BCchent%C3%BCcher-wiederverwendbare-Reinigungsstange/dp/B09YV32633/ref=sr_1_5?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-5"
//                     }
//                 },
//                 {
//                     "position": 6,
//                     "title": "DII Windowpane Terry Geschirrtuch-Set, aubergine, 16x26",
//                     "asin": "B00MXTV0R8",
//                     "link": "https://www.amazon.de/DII-100-Prozent-Baumwolle-Fensterscheibe-Aubergine/dp/B00MXTV0R8/ref=sr_1_6?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-6",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81FGJQFvDzL._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 1238,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 27.18,
//                             "raw": "€27,18",
//                             "name": "27,18 €",
//                             "asin": "B00MXTV0R8",
//                             "link": "https://www.amazon.de/DII-100-Prozent-Baumwolle-Fensterscheibe-Aubergine/dp/B00MXTV0R8/ref=sr_1_6?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-6"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 27.18,
//                         "raw": "€27,18",
//                         "name": "27,18 €",
//                         "asin": "B00MXTV0R8",
//                         "link": "https://www.amazon.de/DII-100-Prozent-Baumwolle-Fensterscheibe-Aubergine/dp/B00MXTV0R8/ref=sr_1_6?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-6"
//                     }
//                 },
//                 {
//                     "position": 7,
//                     "title": "Jemako Trockentücher im 3er Set Gross 45 x 80",
//                     "asin": "B007MB22T2",
//                     "link": "https://www.amazon.de/Jemako-Trockent%C3%BCcher-3er-Set-gross/dp/B007MB22T2/ref=sr_1_7_mod_primary_new?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-7",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71L54QsCoeL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 30,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74,
//                             "raw": "€74,00",
//                             "name": "74,00 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 78,
//                             "raw": "€78,00",
//                             "name": "78,00 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 74,
//                         "raw": "€74,00",
//                         "name": "74,00 €"
//                     }
//                 },
//                 {
//                     "position": 8,
//                     "title": "Simply Southwest Kaktus-Küchentücher, 3er-Set, buntes Frottee-Handtuch – gewebtes Jacquard-Handtuch – verziertes Kaktus-Geschirrtuch",
//                     "asin": "B07QL41WDN",
//                     "link": "https://www.amazon.de/Simply-Southwest-Kaktus-K%C3%BCchenhandtuch-Set-terrytowel-Jacquard-Towel-tea/dp/B07QL41WDN/ref=sr_1_8?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-8",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71gUd0n17TS._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 68.77,
//                             "raw": "€68,77",
//                             "asin": "B07QL41WDN",
//                             "link": "https://www.amazon.de/Simply-Southwest-Kaktus-K%C3%BCchenhandtuch-Set-terrytowel-Jacquard-Towel-tea/dp/B07QL41WDN/ref=sr_1_8?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-8"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 68.77,
//                         "raw": "€68,77",
//                         "asin": "B07QL41WDN",
//                         "link": "https://www.amazon.de/Simply-Southwest-Kaktus-K%C3%BCchenhandtuch-Set-terrytowel-Jacquard-Towel-tea/dp/B07QL41WDN/ref=sr_1_8?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-8"
//                     }
//                 },
//                 {
//                     "position": 9,
//                     "title": "Küchen-Geschirrtücher, Salsa-Streifen, 100 % natürliche, saugfähige Baumwolle, Größe 71,1 x 40,6 cm, festliches Rot, Orange, Grün und Blau, 12 Stück",
//                     "asin": "B07L1F218W",
//                     "link": "https://www.amazon.de/Aunti-Ems-Kitchen-Geschirrt%C3%BCcher-nat%C3%BCrliche/dp/B07L1F218W/ref=sr_1_9_mod_primary_new?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-9",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/910L9hQbiOL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 3249,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56,
//                             "raw": "€56,00",
//                             "name": "56,00 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 65.08,
//                             "raw": "€65,08",
//                             "name": "65,08 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 56,
//                         "raw": "€56,00",
//                         "name": "56,00 €"
//                     }
//                 },
//                 {
//                     "position": 10,
//                     "title": "CreaFlor Home 6er Set Geschirrtuch GIPFELGRUSS Mit Liebe gekocht 50x70cm 100% Baumwolle LaVida",
//                     "asin": "B08HV3H8JC",
//                     "link": "https://www.amazon.de/CreaFlor-Home-Geschirrtuch-GIPFELGRUSS-Baumwolle/dp/B08HV3H8JC/ref=sr_1_10?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-10",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71xCblcxCtL._AC_UL400_.jpg",
//                     "rating": 3,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.5,
//                             "raw": "€51,50",
//                             "name": "51,50 €",
//                             "asin": "B08HV3H8JC",
//                             "link": "https://www.amazon.de/CreaFlor-Home-Geschirrtuch-GIPFELGRUSS-Baumwolle/dp/B08HV3H8JC/ref=sr_1_10?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-10"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.5,
//                         "raw": "€51,50",
//                         "name": "51,50 €",
//                         "asin": "B08HV3H8JC",
//                         "link": "https://www.amazon.de/CreaFlor-Home-Geschirrtuch-GIPFELGRUSS-Baumwolle/dp/B08HV3H8JC/ref=sr_1_10?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-10"
//                     }
//                 },
//                 {
//                     "position": 11,
//                     "title": "Franco Kitchen Designers Set mit 4 dekorativen weichen und saugfähigen Geschirrtüchern aus Baumwolle, 38,1 x 63,5 cm, süße Ananas",
//                     "asin": "B08BKXT12C",
//                     "link": "https://www.amazon.de/Franco-Designers-Geschirrt%C3%BCcher-saugf%C3%A4hig-Baumwolle/dp/B08BKXT12C/ref=sr_1_11?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-11",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/810OOaDA3kL._AC_UL400_.jpg",
//                     "rating": 4.8,
//                     "ratings_total": 6089,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 24.01,
//                             "raw": "€24,01",
//                             "name": "24,01 €",
//                             "asin": "B08BKXT12C",
//                             "link": "https://www.amazon.de/Franco-Designers-Geschirrt%C3%BCcher-saugf%C3%A4hig-Baumwolle/dp/B08BKXT12C/ref=sr_1_11?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-11"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 24.01,
//                         "raw": "€24,01",
//                         "name": "24,01 €",
//                         "asin": "B08BKXT12C",
//                         "link": "https://www.amazon.de/Franco-Designers-Geschirrt%C3%BCcher-saugf%C3%A4hig-Baumwolle/dp/B08BKXT12C/ref=sr_1_11?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-11"
//                     }
//                 },
//                 {
//                     "position": 12,
//                     "title": "DII 100% Baumwolle, maschinenwaschbar, Everyday Kitchen Basic, Ultra saugstark, Jacquard Dekorative Dishtowel 45,7 x 71,1 cm Set von 3 – Fleur de Lis Basic Jacquard Fleur De Lis",
//                     "asin": "B00DU6UMWM",
//                     "link": "https://www.amazon.de/DII-Baumwolle-maschinenwaschbar-saugstark-Dekorative/dp/B00DU6UMWM/ref=sr_1_12?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-12",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81kTIF58ZyL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 2818,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 24.54,
//                             "raw": "€24,54",
//                             "name": "24,54 €",
//                             "asin": "B00DU6UMWM",
//                             "link": "https://www.amazon.de/DII-Baumwolle-maschinenwaschbar-saugstark-Dekorative/dp/B00DU6UMWM/ref=sr_1_12?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-12"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 24.54,
//                         "raw": "€24,54",
//                         "name": "24,54 €",
//                         "asin": "B00DU6UMWM",
//                         "link": "https://www.amazon.de/DII-Baumwolle-maschinenwaschbar-saugstark-Dekorative/dp/B00DU6UMWM/ref=sr_1_12?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-12"
//                     }
//                 },
//                 {
//                     "position": 13,
//                     "title": "Tork Extra Starke Industrie Papierwischtücher Blau W1, Hohe Saugfähigkeit, 1 × 750 Blatt, 130080",
//                     "asin": "B003S6SAFK",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo1NTE3MzE1NTE5Mzc5NTUzOjE2ODQwNzQ0OTI6c3BfbXRmOjIwMDQ2MzIyMjM1MDk4OjowOjo&url=%2FTork-130080-Papierwischt%25C3%25BCcher-Bodenst%25C3%25A4nder-Wandhalter-System%2Fdp%2FB003S6SAFK%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DGeschirrtuch-Set%26qid%3D1684074492%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA2RFLSGKHEW37V",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71xcsaudl8L._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 73,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 80.8,
//                             "raw": "€80,80",
//                             "name": "80,80 €",
//                             "asin": "B003S6SAFK",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo1NTE3MzE1NTE5Mzc5NTUzOjE2ODQwNzQ0OTI6c3BfbXRmOjIwMDQ2MzIyMjM1MDk4OjowOjo&url=%2FTork-130080-Papierwischt%25C3%25BCcher-Bodenst%25C3%25A4nder-Wandhalter-System%2Fdp%2FB003S6SAFK%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DGeschirrtuch-Set%26qid%3D1684074492%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA2RFLSGKHEW37V"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 80.8,
//                         "raw": "€80,80",
//                         "name": "80,80 €",
//                         "asin": "B003S6SAFK",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo1NTE3MzE1NTE5Mzc5NTUzOjE2ODQwNzQ0OTI6c3BfbXRmOjIwMDQ2MzIyMjM1MDk4OjowOjo&url=%2FTork-130080-Papierwischt%25C3%25BCcher-Bodenst%25C3%25A4nder-Wandhalter-System%2Fdp%2FB003S6SAFK%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DGeschirrtuch-Set%26qid%3D1684074492%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA2RFLSGKHEW37V"
//                     }
//                 },
//                 {
//                     "position": 14,
//                     "title": "BiOHY Reinigungsmittel Komplett Set mit Dosierer und 4 Mikrofasertücher (8 x 1 L) | Bad-, Fenster- und Bodenreiniger |kraftvoll & effizient | nachhaltige Bio Reiniger",
//                     "asin": "B09KCCZQL2",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo1NTE3MzE1NTE5Mzc5NTUzOjE2ODQwNzQ0OTI6c3BfbXRmOjIwMTE3Mzg2MDU2NTk4OjowOjo&url=%2FReinigungsmittel-Microfasert%25C3%25BCcher-Bodenreiniger-kraftvoll-nachhaltige%2Fdp%2FB09KCCZQL2%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DGeschirrtuch-Set%26qid%3D1684074492%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81Gi9+4rQ-L._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 5 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "unit_price": "8,13 €/l",
//                     "rating": 4.3,
//                     "ratings_total": 62,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 65.01,
//                             "raw": "€65,01",
//                             "name": "65,01 €",
//                             "asin": "B09KCCZQL2",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo1NTE3MzE1NTE5Mzc5NTUzOjE2ODQwNzQ0OTI6c3BfbXRmOjIwMTE3Mzg2MDU2NTk4OjowOjo&url=%2FReinigungsmittel-Microfasert%25C3%25BCcher-Bodenreiniger-kraftvoll-nachhaltige%2Fdp%2FB09KCCZQL2%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DGeschirrtuch-Set%26qid%3D1684074492%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 65.01,
//                         "raw": "€65,01",
//                         "name": "65,01 €",
//                         "asin": "B09KCCZQL2",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo1NTE3MzE1NTE5Mzc5NTUzOjE2ODQwNzQ0OTI6c3BfbXRmOjIwMTE3Mzg2MDU2NTk4OjowOjo&url=%2FReinigungsmittel-Microfasert%25C3%25BCcher-Bodenreiniger-kraftvoll-nachhaltige%2Fdp%2FB09KCCZQL2%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DGeschirrtuch-Set%26qid%3D1684074492%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 15,
//                     "title": "Sticky Toffee 100% Baumwolle Frottier Geschirrtücher, 4er Set Geschirrtücher | 40,6 x 71 cm | Grau | Weiche saugfähige Restaurant-Bar-Glas-küchentücher",
//                     "asin": "B075RC3KF4",
//                     "link": "https://www.amazon.de/Terry-Baumwolle-Geschirrtuch-St%C3%BCck-Grau/dp/B075RC3KF4/ref=sr_1_15?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-15",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/812q9BPth7L._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 887,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 26.95,
//                             "raw": "€26,95",
//                             "name": "26,95 €",
//                             "asin": "B075RC3KF4",
//                             "link": "https://www.amazon.de/Terry-Baumwolle-Geschirrtuch-St%C3%BCck-Grau/dp/B075RC3KF4/ref=sr_1_15?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-15"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 26.95,
//                         "raw": "€26,95",
//                         "name": "26,95 €",
//                         "asin": "B075RC3KF4",
//                         "link": "https://www.amazon.de/Terry-Baumwolle-Geschirrtuch-St%C3%BCck-Grau/dp/B075RC3KF4/ref=sr_1_15?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-15"
//                     }
//                 },
//                 {
//                     "position": 16,
//                     "title": "Disney Dish Towels 2 Piece Set Kitchen Cloths (Mickey Mouse Green)",
//                     "asin": "B01JHKZAPI",
//                     "link": "https://www.amazon.de/Disney-Geschirrt%C3%BCcher-Mickey-Minnie-2-teiliges/dp/B01JHKZAPI/ref=sr_1_16?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-16",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91VWnXLyKoL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 760,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 30.02,
//                             "raw": "€30,02",
//                             "name": "30,02 €",
//                             "asin": "B01JHKZAPI",
//                             "link": "https://www.amazon.de/Disney-Geschirrt%C3%BCcher-Mickey-Minnie-2-teiliges/dp/B01JHKZAPI/ref=sr_1_16?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-16"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 30.02,
//                         "raw": "€30,02",
//                         "name": "30,02 €",
//                         "asin": "B01JHKZAPI",
//                         "link": "https://www.amazon.de/Disney-Geschirrt%C3%BCcher-Mickey-Minnie-2-teiliges/dp/B01JHKZAPI/ref=sr_1_16?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-16"
//                     }
//                 },
//                 {
//                     "position": 17,
//                     "title": "4 Pack Küchentücher saugfähige Geschirrtücher für Küche wiederverwendbare Reinigung Küchentücher Dekoratives Set Küchentuch Frottee Geschirrtücher Handtuch für Küche Badezimmer (Zuckerschädel Stil)",
//                     "asin": "B0B62M4GLZ",
//                     "link": "https://www.amazon.de/K%C3%BCchent%C3%BCcher-Geschirrt%C3%BCcher-wiederverwendbare-Dekoratives-Zuckersch%C3%A4del/dp/B0B62M4GLZ/ref=sr_1_17?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-17",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81TSCxIp00L._AC_UL400_.jpg",
//                     "rating": 3.8,
//                     "ratings_total": 19,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 31.02,
//                             "raw": "€31,02",
//                             "name": "31,02 €",
//                             "asin": "B0B62M4GLZ",
//                             "link": "https://www.amazon.de/K%C3%BCchent%C3%BCcher-Geschirrt%C3%BCcher-wiederverwendbare-Dekoratives-Zuckersch%C3%A4del/dp/B0B62M4GLZ/ref=sr_1_17?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-17"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 31.02,
//                         "raw": "€31,02",
//                         "name": "31,02 €",
//                         "asin": "B0B62M4GLZ",
//                         "link": "https://www.amazon.de/K%C3%BCchent%C3%BCcher-Geschirrt%C3%BCcher-wiederverwendbare-Dekoratives-Zuckersch%C3%A4del/dp/B0B62M4GLZ/ref=sr_1_17?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-17"
//                     }
//                 },
//                 {
//                     "position": 18,
//                     "title": "Candy Cottons Set mit 6 Küchentüchern, 100% Baumwolle Küchentüchern, mit Aufhängeschlaufe, Geschirrtuch-Sets zum Waschen und Trocknen von Geschirr, Geschirrtüchern und Handtüchern 18x28, Ventage, rot",
//                     "asin": "B09WQYL28G",
//                     "link": "https://www.amazon.de/Candy-Cottons-Aufh%C3%A4ngeschlaufe-Geschirrtuch-Sets-Geschirrt%C3%BCchern/dp/B09WQYL28G/ref=sr_1_18?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-18",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51eWG48gNNL._AC_UL400_.jpg",
//                     "unit_price": "6,85 €/stück",
//                     "rating": 4.7,
//                     "ratings_total": 26,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 41.08,
//                             "raw": "€41,08",
//                             "name": "41,08 €",
//                             "asin": "B09WQYL28G",
//                             "link": "https://www.amazon.de/Candy-Cottons-Aufh%C3%A4ngeschlaufe-Geschirrtuch-Sets-Geschirrt%C3%BCchern/dp/B09WQYL28G/ref=sr_1_18?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-18"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 41.08,
//                         "raw": "€41,08",
//                         "name": "41,08 €",
//                         "asin": "B09WQYL28G",
//                         "link": "https://www.amazon.de/Candy-Cottons-Aufh%C3%A4ngeschlaufe-Geschirrtuch-Sets-Geschirrt%C3%BCchern/dp/B09WQYL28G/ref=sr_1_18?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-18"
//                     }
//                 },
//                 {
//                     "position": 19,
//                     "title": "DII Besonders saugfähig Everyday Küchentücher aus Baumwolle, Lake House Check, Dishtowel Set, 4",
//                     "asin": "B012GGS1WE",
//                     "link": "https://www.amazon.de/DII-maschinenwaschbar-%C3%9Cberpr%C3%BCfen-Heavyweight-Sp%C3%BClt%C3%BCcher/dp/B012GGS1WE/ref=sr_1_19?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-19",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81zzU0adZYL._AC_UL400_.jpg",
//                     "unit_price": "3,35 €/stück",
//                     "rating": 4.5,
//                     "ratings_total": 94,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 13.4,
//                             "raw": "€13,40",
//                             "name": "13,40 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 14.9,
//                             "raw": "€14,90",
//                             "name": "13,40 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 13.4,
//                         "raw": "€13,40",
//                         "name": "13,40 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 20,
//                     "title": "DII Dekoratives Geschirrtuch aus 100% Baumwolle, maschinenwaschbar, für den täglichen Gebrauch in der Küche geeignet, sehr saugfähig, aus Jacquard 3-teiliges Set Christmas Embroidery",
//                     "asin": "B076HL6L8M",
//                     "link": "https://www.amazon.de/DII-Dekoratives-Geschirrtuch-maschinenwaschbar-3-teiliges/dp/B076HL6L8M/ref=sr_1_20?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-20",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/818a9NaHHqL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 6992,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 20.78,
//                             "raw": "€20,78",
//                             "name": "20,78 €",
//                             "asin": "B076HL6L8M",
//                             "link": "https://www.amazon.de/DII-Dekoratives-Geschirrtuch-maschinenwaschbar-3-teiliges/dp/B076HL6L8M/ref=sr_1_20?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-20"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 20.78,
//                         "raw": "€20,78",
//                         "name": "20,78 €",
//                         "asin": "B076HL6L8M",
//                         "link": "https://www.amazon.de/DII-Dekoratives-Geschirrtuch-maschinenwaschbar-3-teiliges/dp/B076HL6L8M/ref=sr_1_20?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-20"
//                     }
//                 },
//                 {
//                     "position": 21,
//                     "title": "Camco Life is Better at The Campsite RV Geschirrtuch-Set, perfekt zum Trocknen von Händen und Geschirr, inklusive 2 Handtüchern, 68 x 41 cm (53301)",
//                     "asin": "B07ZPNJJGJ",
//                     "link": "https://www.amazon.de/Camco-Life-Better-Campsite-RV-Geschirrtuch-Set/dp/B07ZPNJJGJ/ref=sr_1_21?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-21",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81YuyGDdKuL._AC_UL400_.jpg",
//                     "rating": 4.8,
//                     "ratings_total": 810,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 21.65,
//                             "raw": "€21,65",
//                             "name": "21,65 €",
//                             "asin": "B07ZPNJJGJ",
//                             "link": "https://www.amazon.de/Camco-Life-Better-Campsite-RV-Geschirrtuch-Set/dp/B07ZPNJJGJ/ref=sr_1_21?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-21"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 21.65,
//                         "raw": "€21,65",
//                         "name": "21,65 €",
//                         "asin": "B07ZPNJJGJ",
//                         "link": "https://www.amazon.de/Camco-Life-Better-Campsite-RV-Geschirrtuch-Set/dp/B07ZPNJJGJ/ref=sr_1_21?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-21"
//                     }
//                 },
//                 {
//                     "position": 22,
//                     "title": "DII Halloween-Party-Tischdekoration",
//                     "asin": "B07HQ8HG6Q",
//                     "link": "https://www.amazon.de/DII-Tischdecke-maschinenwaschbar-Abendessen-Dishtowels/dp/B07HQ8HG6Q/ref=sr_1_22?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-22",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71x3jkypTWL._AC_UL400_.jpg",
//                     "unit_price": "8,37 €/stück",
//                     "rating": 4.7,
//                     "ratings_total": 16538,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 16.73,
//                             "raw": "€16,73",
//                             "name": "16,73 €",
//                             "asin": "B07HQ8HG6Q",
//                             "link": "https://www.amazon.de/DII-Tischdecke-maschinenwaschbar-Abendessen-Dishtowels/dp/B07HQ8HG6Q/ref=sr_1_22?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-22"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 16.73,
//                         "raw": "€16,73",
//                         "name": "16,73 €",
//                         "asin": "B07HQ8HG6Q",
//                         "link": "https://www.amazon.de/DII-Tischdecke-maschinenwaschbar-Abendessen-Dishtowels/dp/B07HQ8HG6Q/ref=sr_1_22?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-22"
//                     }
//                 },
//                 {
//                     "position": 23,
//                     "title": "DII Holiday Kitchen Towels Besticktes Geschirrtuch-Set, 45,7 x 71,1 cm, Cheers Merry & Joy, 3-teilig",
//                     "asin": "B07D1JQTG6",
//                     "link": "https://www.amazon.de/DII-Gericht-K%C3%BCche-Handt%C3%BCcher-18/dp/B07D1JQTG6/ref=sr_1_23?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-23",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71li1Zm9V4L._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 4645,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 24.06,
//                             "raw": "€24,06",
//                             "name": "24,06 €",
//                             "asin": "B07D1JQTG6",
//                             "link": "https://www.amazon.de/DII-Gericht-K%C3%BCche-Handt%C3%BCcher-18/dp/B07D1JQTG6/ref=sr_1_23?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-23"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 24.06,
//                         "raw": "€24,06",
//                         "name": "24,06 €",
//                         "asin": "B07D1JQTG6",
//                         "link": "https://www.amazon.de/DII-Gericht-K%C3%BCche-Handt%C3%BCcher-18/dp/B07D1JQTG6/ref=sr_1_23?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-23"
//                     }
//                 },
//                 {
//                     "position": 24,
//                     "title": "DII Everyday Kitchen Collection Geschirrtuch-Set, Sortiert, Baumwolle, Gartenschildchen, 18x28",
//                     "asin": "B08T4JYYC3",
//                     "link": "https://www.amazon.de/DII-Collection-Geschirrtuch-Set-Baumwolle-Gartenschildchen/dp/B08T4JYYC3/ref=sr_1_24?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-24",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81SY1UDS4nL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 424,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 26.41,
//                             "raw": "€26,41",
//                             "name": "26,41 €",
//                             "asin": "B08T4JYYC3",
//                             "link": "https://www.amazon.de/DII-Collection-Geschirrtuch-Set-Baumwolle-Gartenschildchen/dp/B08T4JYYC3/ref=sr_1_24?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-24"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 26.41,
//                         "raw": "€26,41",
//                         "name": "26,41 €",
//                         "asin": "B08T4JYYC3",
//                         "link": "https://www.amazon.de/DII-Collection-Geschirrtuch-Set-Baumwolle-Gartenschildchen/dp/B08T4JYYC3/ref=sr_1_24?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-24"
//                     }
//                 },
//                 {
//                     "position": 25,
//                     "title": "Gratico Küchentücher-Set mit 24 Mehlsack-Geschirrtüchern für Küche, weiße Farbe, Premium-Qualität, 100 % ringgesponnene Baumwolle, Küchentuch, Übergröße: 73 x 73 cm, sehr saugfähig, Küchenhandtücher",
//                     "asin": "B08NW1VFR7",
//                     "link": "https://www.amazon.de/Gratico-Geschirrt%C3%BCcher-strapazierf%C3%A4hig-Hotelqualit%C3%A4t-ringgesponnene/dp/B08NW1VFR7/ref=sr_1_25?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-25",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71tCiBtbNKL._AC_UL400_.jpg",
//                     "unit_price": "2,65 €/stück",
//                     "rating": 4.6,
//                     "ratings_total": 632,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 63.65,
//                             "raw": "€63,65",
//                             "name": "63,65 €",
//                             "asin": "B08NW1VFR7",
//                             "link": "https://www.amazon.de/Gratico-Geschirrt%C3%BCcher-strapazierf%C3%A4hig-Hotelqualit%C3%A4t-ringgesponnene/dp/B08NW1VFR7/ref=sr_1_25?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-25"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 63.65,
//                         "raw": "€63,65",
//                         "name": "63,65 €",
//                         "asin": "B08NW1VFR7",
//                         "link": "https://www.amazon.de/Gratico-Geschirrt%C3%BCcher-strapazierf%C3%A4hig-Hotelqualit%C3%A4t-ringgesponnene/dp/B08NW1VFR7/ref=sr_1_25?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-25"
//                     }
//                 },
//                 {
//                     "position": 26,
//                     "title": "Jemako Trockentücher im 3er Set Gross 45 x 80 cm (Türkis)",
//                     "asin": "B079DVK8RX",
//                     "link": "https://www.amazon.de/Jemako-Trockent%C3%BCcher-3er-Gross-T%C3%BCrkis/dp/B079DVK8RX/ref=sr_1_26?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-26",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/917YC7TzjbL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 25,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74,
//                             "raw": "€74,00",
//                             "name": "74,00 €",
//                             "asin": "B079DVK8RX",
//                             "link": "https://www.amazon.de/Jemako-Trockent%C3%BCcher-3er-Gross-T%C3%BCrkis/dp/B079DVK8RX/ref=sr_1_26?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-26"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 74,
//                         "raw": "€74,00",
//                         "name": "74,00 €",
//                         "asin": "B079DVK8RX",
//                         "link": "https://www.amazon.de/Jemako-Trockent%C3%BCcher-3er-Gross-T%C3%BCrkis/dp/B079DVK8RX/ref=sr_1_26?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-26"
//                     }
//                 },
//                 {
//                     "position": 27,
//                     "title": "Set mit 8 türkischen Baumwolle Hand Gesicht Kopf Gästetücher Gym Handtuch Set Pestemal Waschlappen Küche Geschirrtuch Geschirrtuch Set",
//                     "asin": "B011J4D8N6",
//                     "link": "https://www.amazon.de/BOSPHORUS-Handgesicht-Waschlappen-Geschirrtuch-Multicolor-1/dp/B011J4D8N6/ref=sr_1_27?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-27",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91HTWepWUhL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 570,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 66.69,
//                             "raw": "€66,69",
//                             "name": "66,69 €",
//                             "asin": "B011J4D8N6",
//                             "link": "https://www.amazon.de/BOSPHORUS-Handgesicht-Waschlappen-Geschirrtuch-Multicolor-1/dp/B011J4D8N6/ref=sr_1_27?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-27"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 66.69,
//                         "raw": "€66,69",
//                         "name": "66,69 €",
//                         "asin": "B011J4D8N6",
//                         "link": "https://www.amazon.de/BOSPHORUS-Handgesicht-Waschlappen-Geschirrtuch-Multicolor-1/dp/B011J4D8N6/ref=sr_1_27?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-27"
//                     }
//                 },
//                 {
//                     "position": 28,
//                     "title": "fecido klassisch Dunkle Farbe Küche Geschirrtücher schwarz",
//                     "asin": "B0776L5RF3",
//                     "link": "https://www.amazon.de/Fecido-Klassisch-Dunkle-Geschirrt%C3%BCcher-Schwarz/dp/B0776L5RF3/ref=sr_1_28?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-28",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91tNLTAdwQL._AC_UL400_.jpg",
//                     "rating": 4.8,
//                     "ratings_total": 1732,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 61.42,
//                             "raw": "€61,42",
//                             "name": "61,42 €",
//                             "asin": "B0776L5RF3",
//                             "link": "https://www.amazon.de/Fecido-Klassisch-Dunkle-Geschirrt%C3%BCcher-Schwarz/dp/B0776L5RF3/ref=sr_1_28?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-28"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 61.42,
//                         "raw": "€61,42",
//                         "name": "61,42 €",
//                         "asin": "B0776L5RF3",
//                         "link": "https://www.amazon.de/Fecido-Klassisch-Dunkle-Geschirrt%C3%BCcher-Schwarz/dp/B0776L5RF3/ref=sr_1_28?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-28"
//                     }
//                 },
//                 {
//                     "position": 29,
//                     "title": "Candy Cottons 6er Set Küchentücher 100% Baumwolle Küchentücher mit Aufhängeschlaufe, Geschirrtuch-Sets zum Waschen & Trocknen von Geschirr, Geschirrtücher & Handtücher 18x28, Moderner Stil, Rot",
//                     "asin": "B0859917W8",
//                     "link": "https://www.amazon.de/Candy-Cottons-K%C3%BCchenhandtuch-gestreift-Kitchen/dp/B0859917W8/ref=sr_1_29?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-29",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71PvxkG0XVL._AC_UL400_.jpg",
//                     "unit_price": "12,36 €/stück",
//                     "rating": 4.7,
//                     "ratings_total": 947,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.16,
//                             "raw": "€74,16",
//                             "name": "74,16 €",
//                             "asin": "B0859917W8",
//                             "link": "https://www.amazon.de/Candy-Cottons-K%C3%BCchenhandtuch-gestreift-Kitchen/dp/B0859917W8/ref=sr_1_29?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-29"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 74.16,
//                         "raw": "€74,16",
//                         "name": "74,16 €",
//                         "asin": "B0859917W8",
//                         "link": "https://www.amazon.de/Candy-Cottons-K%C3%BCchenhandtuch-gestreift-Kitchen/dp/B0859917W8/ref=sr_1_29?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-29"
//                     }
//                 },
//                 {
//                     "position": 30,
//                     "title": "Jemako Trockentücher im 3er Set mittel 45 x 60",
//                     "asin": "B007OHCBP4",
//                     "link": "https://www.amazon.de/Jemako-Trockent%C3%BCcher-3er-Set-mittel/dp/B007OHCBP4/ref=sr_1_30_mod_primary_new?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-30",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81LzIuMyS0L._AC_UL400_.jpg",
//                     "unit_price": "22,67 €/stück",
//                     "rating": 4.7,
//                     "ratings_total": 293,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 68,
//                             "raw": "€68,00",
//                             "name": "68,00 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.9,
//                             "raw": "€69,90",
//                             "name": "69,90 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 68,
//                         "raw": "€68,00",
//                         "name": "68,00 €"
//                     }
//                 },
//                 {
//                     "position": 31,
//                     "title": "ALCLEAR Auto Microfasertuch Trockenwunder für Autopflege, 10er Set, Autolack, Motorrad, Küche u. Haushalt – Microfaser Geschirrtuch - weiches Trockentuch - 60x40 cm blau",
//                     "asin": "B00IXD4HE6",
//                     "link": "https://www.amazon.de/ALCLEAR-10820901-Trockenwunder-Navy-10er/dp/B00IXD4HE6/ref=sr_1_31?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-31",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91+5ir+1weL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 3,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 93.59,
//                             "raw": "€93,59",
//                             "name": "93,59 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 103.45,
//                             "raw": "€103,45",
//                             "name": "93,59 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 93.59,
//                         "raw": "€93,59",
//                         "name": "93,59 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 32,
//                     "title": "Fecido Klassische Geschirrtücher – Strapazierfähig – Supersaugfähig – 100% Baumwolle – Profisionelle Qualitätsgeschirrtücher – Küchentücher 2er Set, Weiß",
//                     "asin": "B072R3N4WT",
//                     "link": "https://www.amazon.de/Fecido-Klassische-Geschirrt%C3%BCcher-Strapazierf%C3%A4hig-Qualit%C3%A4tsgeschirrt%C3%BCcher/dp/B072R3N4WT/ref=sr_1_32?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-32",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/8146ZMaeM6L._AC_UL400_.jpg",
//                     "rating": 4.8,
//                     "ratings_total": 2300,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 33.31,
//                             "raw": "€33,31",
//                             "name": "33,31 €",
//                             "asin": "B072R3N4WT",
//                             "link": "https://www.amazon.de/Fecido-Klassische-Geschirrt%C3%BCcher-Strapazierf%C3%A4hig-Qualit%C3%A4tsgeschirrt%C3%BCcher/dp/B072R3N4WT/ref=sr_1_32?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-32"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 33.31,
//                         "raw": "€33,31",
//                         "name": "33,31 €",
//                         "asin": "B072R3N4WT",
//                         "link": "https://www.amazon.de/Fecido-Klassische-Geschirrt%C3%BCcher-Strapazierf%C3%A4hig-Qualit%C3%A4tsgeschirrt%C3%BCcher/dp/B072R3N4WT/ref=sr_1_32?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-32"
//                     }
//                 },
//                 {
//                     "position": 33,
//                     "title": "Zeppoli Classic Küchentücher - 30 Pack - 14\" x 25\" - 100% natürliche Baumwolle Küche Geschirrtücher - wiederverwendbare Reinigungstücher - blaue Geschirrtücher - super saugfähig - Handtücher",
//                     "asin": "B07FF7Z3BQ",
//                     "link": "https://www.amazon.de/Royal-K%C3%BCchenhandt%C3%BCcher-nat%C3%BCrliche-Baumwolle-Barhandt%C3%BCcher/dp/B07FF7Z3BQ/ref=sr_1_33?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-33",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71FUG3gFClL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 24253
//                 },
//                 {
//                     "position": 34,
//                     "title": "Vansolinne Kaffeeküchentücher, lustige Geschirrtücher, Kaffeebar, Dekoration, 4er-Set – lustiges Gastgeberin, Geschenk, Einweihungsgeschenk, Muttertag, Hochzeit, Duschgeschenk",
//                     "asin": "B08Y583NYN",
//                     "link": "https://www.amazon.de/Vansolinne-Kaffeek%C3%BCchent%C3%BCcher-Geschirrt%C3%BCcher-Kaffeebar-Dekoration/dp/B08Y583NYN/ref=sr_1_34?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-34",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/718dDD2RbUL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 175,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 30.32,
//                             "raw": "€30,32",
//                             "name": "30,32 €",
//                             "asin": "B08Y583NYN",
//                             "link": "https://www.amazon.de/Vansolinne-Kaffeek%C3%BCchent%C3%BCcher-Geschirrt%C3%BCcher-Kaffeebar-Dekoration/dp/B08Y583NYN/ref=sr_1_34?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-34"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 30.32,
//                         "raw": "€30,32",
//                         "name": "30,32 €",
//                         "asin": "B08Y583NYN",
//                         "link": "https://www.amazon.de/Vansolinne-Kaffeek%C3%BCchent%C3%BCcher-Geschirrt%C3%BCcher-Kaffeebar-Dekoration/dp/B08Y583NYN/ref=sr_1_34?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-34"
//                     }
//                 },
//                 {
//                     "position": 35,
//                     "title": "Urban Villa Küchentücher, beige, mehrfarbig, Dobby-Streifen, Premium-Qualität, 100 % Baumwolle, Gehrungsecken, ultraweich (Größe: 50,8 x 76,2 cm), sehr saugfähige Stange und Geschirrtücher (3er-Set)",
//                     "asin": "B0BCZD4V51",
//                     "link": "https://www.amazon.de/Urban-Villa-Dobby-Streifen-Premium-Qualit%C3%A4t-Gehrungsecken/dp/B0BCZD4V51/ref=sr_1_35?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-35",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91Ckj5jlfgL._AC_UL400_.jpg"
//                 },
//                 {
//                     "position": 36,
//                     "title": "DII Everyday Collection Foodie Küchen-Set, Geschirrtuch und Geschirrtuch, Limette, 5-teilig",
//                     "asin": "B00SFENEOC",
//                     "link": "https://www.amazon.de/DII-CAMZ33979-100-Baumwolle-lime/dp/B00SFENEOC/ref=sr_1_36?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-36",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/715uTAH-U5L._AC_UL400_.jpg",
//                     "unit_price": "3,45 €/stück",
//                     "rating": 4.5,
//                     "ratings_total": 2205,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 17.27,
//                             "raw": "€17,27",
//                             "name": "17,27 €",
//                             "asin": "B00SFENEOC",
//                             "link": "https://www.amazon.de/DII-CAMZ33979-100-Baumwolle-lime/dp/B00SFENEOC/ref=sr_1_36?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-36"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 17.27,
//                         "raw": "€17,27",
//                         "name": "17,27 €",
//                         "asin": "B00SFENEOC",
//                         "link": "https://www.amazon.de/DII-CAMZ33979-100-Baumwolle-lime/dp/B00SFENEOC/ref=sr_1_36?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-36"
//                     }
//                 },
//                 {
//                     "position": 37,
//                     "title": "DII Cottontail Cottage Spring Kitchen Collection Geschirrtuch-Set, 45 x 71 cm, Ostermarkt",
//                     "asin": "B09QLMR9GC",
//                     "link": "https://www.amazon.de/DII-Cottontail-Collection-Geschirrtuch-Set-Ostermarkt/dp/B09QLMR9GC/ref=sr_1_37?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-37",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71us8NugvUL._AC_UL400_.jpg",
//                     "rating": 4.8,
//                     "ratings_total": 9,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 22.07,
//                             "raw": "€22,07",
//                             "name": "22,07 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 25.14,
//                             "raw": "€25,14",
//                             "name": "22,07 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 22.07,
//                         "raw": "€22,07",
//                         "name": "22,07 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 38,
//                     "title": "KAF Home Soho Geschirrtuch 10er-Set | 18 x 28 Zoll Geschirrtücher | Weiches und saugfähiges gemischtes Flachhandtuchset (Grau)",
//                     "asin": "B08F4PPKWG",
//                     "link": "https://www.amazon.de/KAF-Home-K%C3%BCchen-Geschirrt%C3%BCcher-gemischtes-Handt%C3%BCchern/dp/B08F4PPKWG/ref=sr_1_38?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-38",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91uuln4qNrL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 5875,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 29.43,
//                             "raw": "€29,43",
//                             "name": "29,43 €",
//                             "asin": "B08F4PPKWG",
//                             "link": "https://www.amazon.de/KAF-Home-K%C3%BCchen-Geschirrt%C3%BCcher-gemischtes-Handt%C3%BCchern/dp/B08F4PPKWG/ref=sr_1_38?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-38"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 29.43,
//                         "raw": "€29,43",
//                         "name": "29,43 €",
//                         "asin": "B08F4PPKWG",
//                         "link": "https://www.amazon.de/KAF-Home-K%C3%BCchen-Geschirrt%C3%BCcher-gemischtes-Handt%C3%BCchern/dp/B08F4PPKWG/ref=sr_1_38?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-38"
//                     }
//                 },
//                 {
//                     "position": 39,
//                     "title": "DII Buffalo Check Kitchen Collection Classic Farmhouse Geschirrtuch-Set, Baumwolle, Marineblau und cremefarben, 20x30, 3",
//                     "asin": "B01N4UGBSM",
//                     "link": "https://www.amazon.de/DII-Baumwolle-L%C3%A4tzchen-Fronttasche-Geschirrt%C3%BCcher/dp/B01N4UGBSM/ref=sr_1_39?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-39",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81Ib2D7Tl7L._AC_UL400_.jpg",
//                     "unit_price": "7,02 €/stück",
//                     "rating": 4.7,
//                     "ratings_total": 38136,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 21.06,
//                             "raw": "€21,06",
//                             "name": "21,06 €",
//                             "asin": "B01N4UGBSM",
//                             "link": "https://www.amazon.de/DII-Baumwolle-L%C3%A4tzchen-Fronttasche-Geschirrt%C3%BCcher/dp/B01N4UGBSM/ref=sr_1_39?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-39"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 21.06,
//                         "raw": "€21,06",
//                         "name": "21,06 €",
//                         "asin": "B01N4UGBSM",
//                         "link": "https://www.amazon.de/DII-Baumwolle-L%C3%A4tzchen-Fronttasche-Geschirrt%C3%BCcher/dp/B01N4UGBSM/ref=sr_1_39?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-39"
//                     }
//                 },
//                 {
//                     "position": 40,
//                     "title": "Bumble Küchenhandtücher - Geschirrhandtücher Handtücher Baumwolle – 6er Pack – XL Größe 50 x 70 cm – Edle, Weiche und Robuste Ringspinnbaumwolle in 3 Verschiedenen Texturen (Aqua)",
//                     "asin": "B07YVHG44K",
//                     "link": "https://www.amazon.de/Bumble-K%C3%BCchenhandt%C3%BCcher-Geschirrhandt%C3%BCcher-Ringspinnbaumwolle-Verschiedenen/dp/B07YVHG44K/ref=sr_1_40?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-40",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/A1gxD5XA8hL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 4323,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.28,
//                             "raw": "€50,28",
//                             "name": "50,28 €",
//                             "asin": "B07YVHG44K",
//                             "link": "https://www.amazon.de/Bumble-K%C3%BCchenhandt%C3%BCcher-Geschirrhandt%C3%BCcher-Ringspinnbaumwolle-Verschiedenen/dp/B07YVHG44K/ref=sr_1_40?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-40"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.28,
//                         "raw": "€50,28",
//                         "name": "50,28 €",
//                         "asin": "B07YVHG44K",
//                         "link": "https://www.amazon.de/Bumble-K%C3%BCchenhandt%C3%BCcher-Geschirrhandt%C3%BCcher-Ringspinnbaumwolle-Verschiedenen/dp/B07YVHG44K/ref=sr_1_40?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-40"
//                     }
//                 },
//                 {
//                     "position": 41,
//                     "title": "CreaFlor Home 6er Set Geschirrtücher MARITIM Ich Liebe das Meer weiß blau 50x70cm LaVida",
//                     "asin": "B08HV8466D",
//                     "link": "https://www.amazon.de/CreaFlor-Home-Geschirrt%C3%BCcher-MARITIM-50x70cm/dp/B08HV8466D/ref=sr_1_41?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-41",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61HrLYSKukL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.5,
//                             "raw": "€51,50",
//                             "asin": "B08HV8466D",
//                             "link": "https://www.amazon.de/CreaFlor-Home-Geschirrt%C3%BCcher-MARITIM-50x70cm/dp/B08HV8466D/ref=sr_1_41?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-41"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.5,
//                         "raw": "€51,50",
//                         "asin": "B08HV8466D",
//                         "link": "https://www.amazon.de/CreaFlor-Home-Geschirrt%C3%BCcher-MARITIM-50x70cm/dp/B08HV8466D/ref=sr_1_41?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-41"
//                     }
//                 },
//                 {
//                     "position": 42,
//                     "title": "Wet-It! Schwedisches Geschirrtuch-Set (RV Camper)",
//                     "asin": "B083JM61WG",
//                     "link": "https://www.amazon.de/Wet-It-Schweden-Geschirrtuch-RV-Camper-2er-Set/dp/B083JM61WG/ref=sr_1_42?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-42",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81I42lLZyRL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 143,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.02,
//                             "raw": "€59,02",
//                             "name": "59,02 €",
//                             "asin": "B083JM61WG",
//                             "link": "https://www.amazon.de/Wet-It-Schweden-Geschirrtuch-RV-Camper-2er-Set/dp/B083JM61WG/ref=sr_1_42?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-42"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.02,
//                         "raw": "€59,02",
//                         "name": "59,02 €",
//                         "asin": "B083JM61WG",
//                         "link": "https://www.amazon.de/Wet-It-Schweden-Geschirrtuch-RV-Camper-2er-Set/dp/B083JM61WG/ref=sr_1_42?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-42"
//                     }
//                 },
//                 {
//                     "position": 43,
//                     "title": "DII Terry Collection Geschirrtuch-Set, 45,7 x 71,1 cm, französisches Blau, Sortiert, 3-teilig",
//                     "asin": "B08R5KR5RC",
//                     "link": "https://www.amazon.de/DII-Collection-Geschirrtuch-Set-franz%C3%B6sisches-Sortiert/dp/B08R5KR5RC/ref=sr_1_43?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-43",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81kLytREYRL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 129,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 24.84,
//                             "raw": "€24,84",
//                             "name": "24,84 €",
//                             "asin": "B08R5KR5RC",
//                             "link": "https://www.amazon.de/DII-Collection-Geschirrtuch-Set-franz%C3%B6sisches-Sortiert/dp/B08R5KR5RC/ref=sr_1_43?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-43"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 24.84,
//                         "raw": "€24,84",
//                         "name": "24,84 €",
//                         "asin": "B08R5KR5RC",
//                         "link": "https://www.amazon.de/DII-Collection-Geschirrtuch-Set-franz%C3%B6sisches-Sortiert/dp/B08R5KR5RC/ref=sr_1_43?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-43"
//                     }
//                 },
//                 {
//                     "position": 44,
//                     "title": "DII Basic Solid Geschirrtuch-Kollektion Flachgewebte Baumwolle, Weiss/opulenter Garten, Small Set, 18x28, 6",
//                     "asin": "B00Z2Z7G7I",
//                     "link": "https://www.amazon.de/DII-100-Baumwolle-100-wei%C3%9F/dp/B00Z2Z7G7I/ref=sr_1_44?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-44",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51CU+hphaLL._AC_UL400_.jpg",
//                     "unit_price": "4,62 €/stück",
//                     "rating": 4.3,
//                     "ratings_total": 1213,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 27.73,
//                             "raw": "€27,73",
//                             "name": "27,73 €",
//                             "asin": "B00Z2Z7G7I",
//                             "link": "https://www.amazon.de/DII-100-Baumwolle-100-wei%C3%9F/dp/B00Z2Z7G7I/ref=sr_1_44?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-44"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 27.73,
//                         "raw": "€27,73",
//                         "name": "27,73 €",
//                         "asin": "B00Z2Z7G7I",
//                         "link": "https://www.amazon.de/DII-100-Baumwolle-100-wei%C3%9F/dp/B00Z2Z7G7I/ref=sr_1_44?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-44"
//                     }
//                 },
//                 {
//                     "position": 45,
//                     "title": "DII Everyday Kitchen Collection Geschirrtuch-Set, Baumwolle, Rot (Vintage Red), 12x12, 5",
//                     "asin": "B08PW4L5RM",
//                     "link": "https://www.amazon.de/DII-Everyday-Collection-Geschirrtuch-Set-Baumwolle/dp/B08PW4L5RM/ref=sr_1_45?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-45",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91dK-R5EhRL._AC_UL400_.jpg",
//                     "unit_price": "10,96 €/stück",
//                     "rating": 4.4,
//                     "ratings_total": 741,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.82,
//                             "raw": "€54,82",
//                             "name": "54,82 €",
//                             "asin": "B08PW4L5RM",
//                             "link": "https://www.amazon.de/DII-Everyday-Collection-Geschirrtuch-Set-Baumwolle/dp/B08PW4L5RM/ref=sr_1_45?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-45"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.82,
//                         "raw": "€54,82",
//                         "name": "54,82 €",
//                         "asin": "B08PW4L5RM",
//                         "link": "https://www.amazon.de/DII-Everyday-Collection-Geschirrtuch-Set-Baumwolle/dp/B08PW4L5RM/ref=sr_1_45?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-45"
//                     }
//                 },
//                 {
//                     "position": 46,
//                     "title": "DII 100% Baumwolle, maschinenwaschbar, Ultra saugstarker, Basic Everyday 30,5 x 30,5 cm Terry Küche Spültücher, Fensterscheibe Design, Set 6-aubergine Geschirrtuch Check Eggplant",
//                     "asin": "B00WWT07QE",
//                     "link": "https://www.amazon.de/DII-maschinenwaschbar-saugstarker-Fensterscheibe-Geschirrtuch/dp/B00WWT07QE/ref=sr_1_46?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-46",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81LAwNBOy-L._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 1110,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 28.12,
//                             "raw": "€28,12",
//                             "name": "28,12 €",
//                             "asin": "B00WWT07QE",
//                             "link": "https://www.amazon.de/DII-maschinenwaschbar-saugstarker-Fensterscheibe-Geschirrtuch/dp/B00WWT07QE/ref=sr_1_46?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-46"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 28.12,
//                         "raw": "€28,12",
//                         "name": "28,12 €",
//                         "asin": "B00WWT07QE",
//                         "link": "https://www.amazon.de/DII-maschinenwaschbar-saugstarker-Fensterscheibe-Geschirrtuch/dp/B00WWT07QE/ref=sr_1_46?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-46"
//                     }
//                 },
//                 {
//                     "position": 47,
//                     "title": "Honigbiene Küchendekoration mit Hummel-Untersetzern, Bienentablett, Honigbienen-Geschirrtücher-Set",
//                     "asin": "B08MQ1JGK8",
//                     "link": "https://www.amazon.de/Honigbiene-K%C3%BCchendekoration-Hummel-Untersetzern-Bienentablett-Honigbienen-Geschirrt%C3%BCcher-Set/dp/B08MQ1JGK8/ref=sr_1_47?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-47",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71vzBjKZ99L._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 28,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 58.02,
//                             "raw": "€58,02",
//                             "name": "58,02 €",
//                             "asin": "B08MQ1JGK8",
//                             "link": "https://www.amazon.de/Honigbiene-K%C3%BCchendekoration-Hummel-Untersetzern-Bienentablett-Honigbienen-Geschirrt%C3%BCcher-Set/dp/B08MQ1JGK8/ref=sr_1_47?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-47"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 58.02,
//                         "raw": "€58,02",
//                         "name": "58,02 €",
//                         "asin": "B08MQ1JGK8",
//                         "link": "https://www.amazon.de/Honigbiene-K%C3%BCchendekoration-Hummel-Untersetzern-Bienentablett-Honigbienen-Geschirrt%C3%BCcher-Set/dp/B08MQ1JGK8/ref=sr_1_47?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-47"
//                     }
//                 },
//                 {
//                     "position": 48,
//                     "title": "Weiß-Gold Marmor Handtuch Badetuch sehr saugfähig weich hängende Handtücher Tie Dye Küche Geschirrtuch Set Waschlappen 2 Stück",
//                     "asin": "B09H2H736Z",
//                     "link": "https://www.amazon.de/Wei%C3%9F-Gold-saugf%C3%A4hig-Handt%C3%BCcher-Geschirrtuch-Waschlappen/dp/B09H2H736Z/ref=sr_1_48?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-48",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61DXppRICbL._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 154,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 31.02,
//                             "raw": "€31,02",
//                             "name": "31,02 €",
//                             "asin": "B09H2H736Z",
//                             "link": "https://www.amazon.de/Wei%C3%9F-Gold-saugf%C3%A4hig-Handt%C3%BCcher-Geschirrtuch-Waschlappen/dp/B09H2H736Z/ref=sr_1_48?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-48"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 31.02,
//                         "raw": "€31,02",
//                         "name": "31,02 €",
//                         "asin": "B09H2H736Z",
//                         "link": "https://www.amazon.de/Wei%C3%9F-Gold-saugf%C3%A4hig-Handt%C3%BCcher-Geschirrtuch-Waschlappen/dp/B09H2H736Z/ref=sr_1_48?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-48"
//                     }
//                 },
//                 {
//                     "position": 49,
//                     "title": "Bersuse 100% Baumwolle - Laodicea Handtuch Geschirrtuch - Dunkelblau (6er Set)",
//                     "asin": "B07PDQZ68F",
//                     "link": "https://www.amazon.de/Bersuse-100-Baumwolle-Geschirrtuch-Diamantwebart/dp/B07PDQZ68F/ref=sr_1_49?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-49",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91Qoz-E1zzL._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 34,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 77.9,
//                             "raw": "€77,90",
//                             "name": "77,90 €",
//                             "asin": "B07PDQZ68F",
//                             "link": "https://www.amazon.de/Bersuse-100-Baumwolle-Geschirrtuch-Diamantwebart/dp/B07PDQZ68F/ref=sr_1_49?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-49"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 77.9,
//                         "raw": "€77,90",
//                         "name": "77,90 €",
//                         "asin": "B07PDQZ68F",
//                         "link": "https://www.amazon.de/Bersuse-100-Baumwolle-Geschirrtuch-Diamantwebart/dp/B07PDQZ68F/ref=sr_1_49?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-49"
//                     }
//                 },
//                 {
//                     "position": 50,
//                     "title": "CHENGU 4 Stück Frühling Küchentücher, saugfähige Geschirrtücher für Küche, wiederverwendbare Reinigungstücher Dekoratives Set, Küchentuch Frottee Geschirrtücher, Handtuch für Küche (Wildblumen-Stil)",
//                     "asin": "B0B2PCZQ1Y",
//                     "link": "https://www.amazon.de/K%C3%BCchen-Geschirrt%C3%BCcher-wiederverwendbare-Wildblumen-K%C3%BCchent%C3%BCcher-Geschirrt%C3%BCcher/dp/B0B2PCZQ1Y/ref=sr_1_50?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-50",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/714HkCujoVL._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 56,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 29.55,
//                             "raw": "€29,55",
//                             "name": "29,55 €",
//                             "asin": "B0B2PCZQ1Y",
//                             "link": "https://www.amazon.de/K%C3%BCchen-Geschirrt%C3%BCcher-wiederverwendbare-Wildblumen-K%C3%BCchent%C3%BCcher-Geschirrt%C3%BCcher/dp/B0B2PCZQ1Y/ref=sr_1_50?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-50"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 29.55,
//                         "raw": "€29,55",
//                         "name": "29,55 €",
//                         "asin": "B0B2PCZQ1Y",
//                         "link": "https://www.amazon.de/K%C3%BCchen-Geschirrt%C3%BCcher-wiederverwendbare-Wildblumen-K%C3%BCchent%C3%BCcher-Geschirrt%C3%BCcher/dp/B0B2PCZQ1Y/ref=sr_1_50?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-50"
//                     }
//                 },
//                 {
//                     "position": 51,
//                     "title": "Glart 44WG6 Watergate 6er Set Mikrofasertücher super saugfähiges Microfasertuch, 60x90 cm, orange & 44WH Premium Mikrofasertuch Auto Waschhandschuh für Autopflege Schaumdüse Autowäsche",
//                     "asin": "B0C2NSXWX1",
//                     "link": "https://www.amazon.de/Glart-Mikrofasert%C3%BCcher-Microfasertuch-Mikrofasertuch-Waschhandschuh/dp/B0C2NSXWX1/ref=sr_1_51?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-51",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61Q-bkKo-RL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.44,
//                             "raw": "€74,44",
//                             "asin": "B0C2NSXWX1",
//                             "link": "https://www.amazon.de/Glart-Mikrofasert%C3%BCcher-Microfasertuch-Mikrofasertuch-Waschhandschuh/dp/B0C2NSXWX1/ref=sr_1_51?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-51"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 74.44,
//                         "raw": "€74,44",
//                         "asin": "B0C2NSXWX1",
//                         "link": "https://www.amazon.de/Glart-Mikrofasert%C3%BCcher-Microfasertuch-Mikrofasertuch-Waschhandschuh/dp/B0C2NSXWX1/ref=sr_1_51?keywords=Geschirrtuch-Set&qid=1684074492&refinements=p_36%3A5000-10000&sr=8-51"
//                     }
//                 }
//             ]
//         },
//         {
//             "idea": "Küchenutensilien-Set",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "Silikon-Kochutensilien-Set, 7-teiliges Silikon-Kochutensilien-Set, antihaftbeschichtetes Küchen-Kochgeschirr, Titan-Edelstahlgriff mit Utensilienhalter, Spatel-Set zum Kochen, Regenbogenfarbe",
//                     "asin": "B0B45TPZG4",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfYXRmOjIwMTMyODA5MjU1Mjk4OjowOjo&url=%2FSilikon-Kochutensilien-Set-antihaftbeschichtetes-K%25C3%25BCchen-Kochgeschirr-Titan-Edelstahlgriff-Utensilienhalter%2Fdp%2FB0B45TPZG4%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1%26smid%3DA5BPKAE9XUQGZ",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61mN28gOxnL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 3.5,
//                     "ratings_total": 3,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.79,
//                             "raw": "€50,79",
//                             "name": "50,79 €",
//                             "asin": "B0B45TPZG4",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfYXRmOjIwMTMyODA5MjU1Mjk4OjowOjo&url=%2FSilikon-Kochutensilien-Set-antihaftbeschichtetes-K%25C3%25BCchen-Kochgeschirr-Titan-Edelstahlgriff-Utensilienhalter%2Fdp%2FB0B45TPZG4%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1%26smid%3DA5BPKAE9XUQGZ"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.79,
//                         "raw": "€50,79",
//                         "name": "50,79 €",
//                         "asin": "B0B45TPZG4",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfYXRmOjIwMTMyODA5MjU1Mjk4OjowOjo&url=%2FSilikon-Kochutensilien-Set-antihaftbeschichtetes-K%25C3%25BCchen-Kochgeschirr-Titan-Edelstahlgriff-Utensilienhalter%2Fdp%2FB0B45TPZG4%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1%26smid%3DA5BPKAE9XUQGZ"
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "Küchenutensilien Set Edelstahl 45 Teiligs Messerblock mit Messer mit Silikon Pfannenwender Suppenkelle Spaghettilöffel Grillzangen Messerschärfer Küchenhelfer Set",
//                     "asin": "B0BM9L9T9G",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfYXRmOjIwMTA2NjgxNDA2MTk4OjowOjo&url=%2FK%25C3%25BCchenutensilien-Pfannenwender-Spaghettil%25C3%25B6ffel-Messersch%25C3%25A4rfer-K%25C3%25BCchenhelfer%2Fdp%2FB0BM9L9T9G%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71LnCtB8KdL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 6,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 4,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "asin": "B0BM9L9T9G",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfYXRmOjIwMTA2NjgxNDA2MTk4OjowOjo&url=%2FK%25C3%25BCchenutensilien-Pfannenwender-Spaghettil%25C3%25B6ffel-Messersch%25C3%25A4rfer-K%25C3%25BCchenhelfer%2Fdp%2FB0BM9L9T9G%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "asin": "B0BM9L9T9G",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfYXRmOjIwMTA2NjgxNDA2MTk4OjowOjo&url=%2FK%25C3%25BCchenutensilien-Pfannenwender-Spaghettil%25C3%25B6ffel-Messersch%25C3%25A4rfer-K%25C3%25BCchenhelfer%2Fdp%2FB0BM9L9T9G%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 3,
//                     "title": "UPTALY 8-teiliges Küchenutensilien-Set aus Holz, natürliche schwarze Walnusslöffel, verdickte Schöpflöffel, robuste Kochschaufel, Holzschlitz-Pfannenwender, extra langer Rührlöffel, Schaber",
//                     "asin": "B0BVK7N969",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfYXRmOjIwMTQzMTA1NDE0OTk4OjowOjo&url=%2FUPTALY-K%25C3%25BCchenutensilien-Set-Walnussl%25C3%25B6ffel-Sch%25C3%25B6pfl%25C3%25B6ffel-Holzschlitz-Pfannenwender%2Fdp%2FB0BVK7N969%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61PwWucHTgL._AC_UL400_.jpg",
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59,
//                             "raw": "€59,00",
//                             "asin": "B0BVK7N969",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfYXRmOjIwMTQzMTA1NDE0OTk4OjowOjo&url=%2FUPTALY-K%25C3%25BCchenutensilien-Set-Walnussl%25C3%25B6ffel-Sch%25C3%25B6pfl%25C3%25B6ffel-Holzschlitz-Pfannenwender%2Fdp%2FB0BVK7N969%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59,
//                         "raw": "€59,00",
//                         "asin": "B0BVK7N969",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfYXRmOjIwMTQzMTA1NDE0OTk4OjowOjo&url=%2FUPTALY-K%25C3%25BCchenutensilien-Set-Walnussl%25C3%25B6ffel-Sch%25C3%25B6pfl%25C3%25B6ffel-Holzschlitz-Pfannenwender%2Fdp%2FB0BVK7N969%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 4,
//                     "title": "Luxshiny 8 Stücke Edelstahl Küchenutensilien Set Hitzebeständiger Kochgeschirr Küche Spatel Pfannenwender Schaumlöffel Suppenlöffel Grillwender Kochbesteck Küchenhelfer Küchenwerkzeug",
//                     "asin": "B0B6W1K1G4",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfYXRmOjIwMDYxOTYzNDI3MDk4OjowOjo&url=%2FLuxshiny-K%25C3%25BCchenutensilien-Hitzebest%25C3%25A4ndiger-Pfannenwender-K%25C3%25BCchenwerkzeug%2Fdp%2FB0B6W1K1G4%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61IhLnH7sTL._AC_UL400_.jpg",
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 58.29,
//                             "raw": "€58,29",
//                             "asin": "B0B6W1K1G4",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfYXRmOjIwMDYxOTYzNDI3MDk4OjowOjo&url=%2FLuxshiny-K%25C3%25BCchenutensilien-Hitzebest%25C3%25A4ndiger-Pfannenwender-K%25C3%25BCchenwerkzeug%2Fdp%2FB0B6W1K1G4%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 58.29,
//                         "raw": "€58,29",
//                         "asin": "B0B6W1K1G4",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfYXRmOjIwMDYxOTYzNDI3MDk4OjowOjo&url=%2FLuxshiny-K%25C3%25BCchenutensilien-Hitzebest%25C3%25A4ndiger-Pfannenwender-K%25C3%25BCchenwerkzeug%2Fdp%2FB0B6W1K1G4%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 5,
//                     "title": "RAXCO Küchenmessersets Mit block,18 Teiligs Küchenutensilien Sets Mit 5 Teiligs Messersets und 7 Teiligs Silikon Pfannenwender,Küchenhelfer sets",
//                     "asin": "B09Z2LXLV1",
//                     "link": "https://www.amazon.de/RAXCO-Pfannenwender-Spaghettil%C3%B6ffel-Messersch%C3%A4rfer-K%C3%BCchenutensilien/dp/B09Z2LXLV1/ref=sr_1_5?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-5",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71YuEe-fqFL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 138,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "54,99 €",
//                             "asin": "B09Z2LXLV1",
//                             "link": "https://www.amazon.de/RAXCO-Pfannenwender-Spaghettil%C3%B6ffel-Messersch%C3%A4rfer-K%C3%BCchenutensilien/dp/B09Z2LXLV1/ref=sr_1_5?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-5"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.99,
//                         "raw": "€54,99",
//                         "name": "54,99 €",
//                         "asin": "B09Z2LXLV1",
//                         "link": "https://www.amazon.de/RAXCO-Pfannenwender-Spaghettil%C3%B6ffel-Messersch%C3%A4rfer-K%C3%BCchenutensilien/dp/B09Z2LXLV1/ref=sr_1_5?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-5"
//                     }
//                 },
//                 {
//                     "position": 6,
//                     "title": "Küchenutensilien Set Edelstahl 45 Teiligs Messerblock mit Messer mit Silikon Pfannenwender Suppenkelle Spaghettilöffel Grillzangen Messerschärfer Küchenhelfer Set",
//                     "asin": "B0BM9L9T9G",
//                     "link": "https://www.amazon.de/K%C3%BCchenutensilien-Pfannenwender-Spaghettil%C3%B6ffel-Messersch%C3%A4rfer-K%C3%BCchenhelfer/dp/B0BM9L9T9G/ref=sr_1_6?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-6",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71LnCtB8KdL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 6,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 4,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "asin": "B0BM9L9T9G",
//                             "link": "https://www.amazon.de/K%C3%BCchenutensilien-Pfannenwender-Spaghettil%C3%B6ffel-Messersch%C3%A4rfer-K%C3%BCchenhelfer/dp/B0BM9L9T9G/ref=sr_1_6?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-6"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "asin": "B0BM9L9T9G",
//                         "link": "https://www.amazon.de/K%C3%BCchenutensilien-Pfannenwender-Spaghettil%C3%B6ffel-Messersch%C3%A4rfer-K%C3%BCchenhelfer/dp/B0BM9L9T9G/ref=sr_1_6?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-6"
//                     }
//                 },
//                 {
//                     "position": 7,
//                     "title": "Umite Chef Küchenutensilien-Set, 15-teilig, Silikon, hitzebeständig, antihaftbeschichtet, BPA-frei, Edelstahl-Griff, Pfannenwender, Löffel, Zange, Schneebesen, Kochgeschirr",
//                     "asin": "B082B9SSM5",
//                     "link": "https://www.amazon.de/Silikon-Kochutensilien-Set-15-teilig-antihaftbeschichtet-hitzebest%C3%A4ndig-Edelstahlgriff/dp/B082B9SSM5/ref=sr_1_7?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-7",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61XKoHSLHXL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 6563,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 32.99,
//                             "raw": "€32,99",
//                             "name": "32,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 33.99,
//                             "raw": "€33,99",
//                             "name": "32,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 32.99,
//                         "raw": "€32,99",
//                         "name": "32,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 8,
//                     "title": "STONELINE Back to Nature 9-teiliges Küchenhelfer Set, Griffe in Holz Optik, Kunststoff, mit praktischer Stütze, geeignet für antihaftbeschichtetes Kochgeschirr, Küchenutensilien Set",
//                     "asin": "B01IRHZZMU",
//                     "link": "https://www.amazon.de/STONELINE-K%C3%BCchenhelfer-Set-praktischer-antihaftbeschichtes-Backgeschirr/dp/B01IRHZZMU/ref=sr_1_8?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-8",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71KAeyDFRyL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 5 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.1,
//                     "ratings_total": 752,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 34.95,
//                             "raw": "€34,95",
//                             "name": "34,95 €",
//                             "asin": "B01IRHZZMU",
//                             "link": "https://www.amazon.de/STONELINE-K%C3%BCchenhelfer-Set-praktischer-antihaftbeschichtes-Backgeschirr/dp/B01IRHZZMU/ref=sr_1_8?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-8"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 34.95,
//                         "raw": "€34,95",
//                         "name": "34,95 €",
//                         "asin": "B01IRHZZMU",
//                         "link": "https://www.amazon.de/STONELINE-K%C3%BCchenhelfer-Set-praktischer-antihaftbeschichtes-Backgeschirr/dp/B01IRHZZMU/ref=sr_1_8?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-8"
//                     }
//                 },
//                 {
//                     "position": 9,
//                     "title": "kithcen Utensilien-Set mit 43 Kochutensilien-Set mit Aufbewahrungstasche, Nylon und Edelstahl, Küchenutensilien-Set, Backset, Küchenhelfer, Küchenzubehör (PURPLE01)",
//                     "asin": "B09FJGKVCT",
//                     "link": "https://www.amazon.de/VEICA-K%C3%BCchenutensilien-Set-Kochutensilien-Set-Aufbewahrungstasche-antihaftbeschichtet/dp/B09FJGKVCT/ref=sr_1_9?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-9",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71xG9irCGFL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 88,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.88,
//                             "raw": "€54,88",
//                             "name": "54,88 €",
//                             "asin": "B09FJGKVCT",
//                             "link": "https://www.amazon.de/VEICA-K%C3%BCchenutensilien-Set-Kochutensilien-Set-Aufbewahrungstasche-antihaftbeschichtet/dp/B09FJGKVCT/ref=sr_1_9?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-9"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.88,
//                         "raw": "€54,88",
//                         "name": "54,88 €",
//                         "asin": "B09FJGKVCT",
//                         "link": "https://www.amazon.de/VEICA-K%C3%BCchenutensilien-Set-Kochutensilien-Set-Aufbewahrungstasche-antihaftbeschichtet/dp/B09FJGKVCT/ref=sr_1_9?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-9"
//                     }
//                 },
//                 {
//                     "position": 10,
//                     "title": "Joseph Joseph Nest Plus - 5-teiliges Küchenutensilien-Set mit magnetischem Ständer - mehrfarbig",
//                     "asin": "B00R86CV7E",
//                     "link": "https://www.amazon.de/Joseph-K%C3%BCchhelfer-Set-magnetischem-Aufbewahrungsst%C3%A4nder-mehrfarbig/dp/B00R86CV7E/ref=sr_1_10?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-10",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51hU7a4ESbL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 1891,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 31.13,
//                             "raw": "€31,13",
//                             "name": "31,13 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 39.99,
//                             "raw": "€39,99",
//                             "name": "31,13 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 31.13,
//                         "raw": "€31,13",
//                         "name": "31,13 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 11,
//                     "title": "Holz Kochbesteck-Set, flacher Bratwender Wokwender Pfannenwender Spaghetti-Zange Servierlöffel Rührlöffel Suppenschöpflöffel Schaumlöffel Gewürz-/Öllöffel Salatgabel – 10-teiliges-Set",
//                     "asin": "B0BBGQ2GLZ",
//                     "link": "https://www.amazon.de/Kochbesteck-Set-Pfannenwender-Spaghetti-Zange-Servierl%C3%B6ffel-Suppensch%C3%B6pfl%C3%B6ffel/dp/B0BBGQ2GLZ/ref=sr_1_11?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-11",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91ss44Pg1nL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 308,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.97,
//                             "raw": "€51,97",
//                             "name": "51,97 €",
//                             "asin": "B0BBGQ2GLZ",
//                             "link": "https://www.amazon.de/Kochbesteck-Set-Pfannenwender-Spaghetti-Zange-Servierl%C3%B6ffel-Suppensch%C3%B6pfl%C3%B6ffel/dp/B0BBGQ2GLZ/ref=sr_1_11?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-11"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.97,
//                         "raw": "€51,97",
//                         "name": "51,97 €",
//                         "asin": "B0BBGQ2GLZ",
//                         "link": "https://www.amazon.de/Kochbesteck-Set-Pfannenwender-Spaghetti-Zange-Servierl%C3%B6ffel-Suppensch%C3%B6pfl%C3%B6ffel/dp/B0BBGQ2GLZ/ref=sr_1_11?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-11"
//                     }
//                 },
//                 {
//                     "position": 12,
//                     "title": "ORBLUE Silikon Küchenhelfer Set, 14-tlg Küchenhelfer Silikon mit Halterung, sichere Lebensmittelqualität köpfe und Edelstahlgriffe mit hitzebeständigen griffabdeckungen, küchenutensilien set, Schwarz",
//                     "asin": "B08LKW4CFG",
//                     "link": "https://www.amazon.de/Kochutensilien-Set-Lebensmittelqualit%C3%A4t-Edelstahlgriffe-hitzebest%C3%A4ndigen-Silikongriffabdeckungen/dp/B08LKW4CFG/ref=sr_1_12?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-12",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71-YmqcxM2L._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 1415,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 31.98,
//                             "raw": "€31,98",
//                             "name": "31,98 €",
//                             "asin": "B08LKW4CFG",
//                             "link": "https://www.amazon.de/Kochutensilien-Set-Lebensmittelqualit%C3%A4t-Edelstahlgriffe-hitzebest%C3%A4ndigen-Silikongriffabdeckungen/dp/B08LKW4CFG/ref=sr_1_12?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-12"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 31.98,
//                         "raw": "€31,98",
//                         "name": "31,98 €",
//                         "asin": "B08LKW4CFG",
//                         "link": "https://www.amazon.de/Kochutensilien-Set-Lebensmittelqualit%C3%A4t-Edelstahlgriffe-hitzebest%C3%A4ndigen-Silikongriffabdeckungen/dp/B08LKW4CFG/ref=sr_1_12?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-12"
//                     }
//                 },
//                 {
//                     "position": 13,
//                     "title": "Amazon Basics 3-teiliges Kochtopf-Set - Edelstahl, anti-haftend, induktionsgeeignet, mit Deckeln - 16/20/24 cm",
//                     "asin": "B07SYK3KX5",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMDMwNDYwMjU4ODAyOjowOjo&url=%2FAmazonBasics-3-teiliges-Kochgeschirr-Set-nicht-haftend-Induktionsherde%2Fdp%2FB07SYK3KX5%2Fref%3Dsr_1_13_ffob_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Y5NJ5CJVL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 3540,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 70.06,
//                             "raw": "€70,06",
//                             "name": "70,06 €",
//                             "asin": "B07SYK3KX5",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMDMwNDYwMjU4ODAyOjowOjo&url=%2FAmazonBasics-3-teiliges-Kochgeschirr-Set-nicht-haftend-Induktionsherde%2Fdp%2FB07SYK3KX5%2Fref%3Dsr_1_13_ffob_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 70.06,
//                         "raw": "€70,06",
//                         "name": "70,06 €",
//                         "asin": "B07SYK3KX5",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMDMwNDYwMjU4ODAyOjowOjo&url=%2FAmazonBasics-3-teiliges-Kochgeschirr-Set-nicht-haftend-Induktionsherde%2Fdp%2FB07SYK3KX5%2Fref%3Dsr_1_13_ffob_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 14,
//                     "title": "RAXCO Küchenmessersets Mit block,18 Teiligs Küchenutensilien Sets Mit 5 Teiligs Messersets und 7 Teiligs Silikon Pfannenwender,Küchenhelfer sets",
//                     "asin": "B09Z2LXLV1",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTUxMDQ2MDE0Nzk4OjowOjo&url=%2FRAXCO-Pfannenwender-Spaghettil%25C3%25B6ffel-Messersch%25C3%25A4rfer-K%25C3%25BCchenutensilien%2Fdp%2FB09Z2LXLV1%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71YuEe-fqFL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 138,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "54,99 €",
//                             "asin": "B09Z2LXLV1",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTUxMDQ2MDE0Nzk4OjowOjo&url=%2FRAXCO-Pfannenwender-Spaghettil%25C3%25B6ffel-Messersch%25C3%25A4rfer-K%25C3%25BCchenutensilien%2Fdp%2FB09Z2LXLV1%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.99,
//                         "raw": "€54,99",
//                         "name": "54,99 €",
//                         "asin": "B09Z2LXLV1",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTUxMDQ2MDE0Nzk4OjowOjo&url=%2FRAXCO-Pfannenwender-Spaghettil%25C3%25B6ffel-Messersch%25C3%25A4rfer-K%25C3%25BCchenutensilien%2Fdp%2FB09Z2LXLV1%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 15,
//                     "title": "SALTER COMBO-8568 7-teiliges Pfannen- und Küchenhelfer-Set, 20-/24-/28-cm-Bratpfannen, antihaftbeschichtet, Alu-Kochtöpfe mit 16-/20-cm-Deckeln aus gehärtetem Glas, Pfannenwender und Saucenlöffel",
//                     "asin": "B0BT8CWJQ6",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTUzNjgxNjgyMTk4OjowOjo&url=%2FSALTER-COMBO-8568-K%25C3%25BCchenhelfer-Set-28-cm-Bratpfannen-antihaftbeschichtet%2Fdp%2FB0BT8CWJQ6%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71QP3faqRxL._AC_UL400_.jpg",
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 87.29,
//                             "raw": "€87,29",
//                             "asin": "B0BT8CWJQ6",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTUzNjgxNjgyMTk4OjowOjo&url=%2FSALTER-COMBO-8568-K%25C3%25BCchenhelfer-Set-28-cm-Bratpfannen-antihaftbeschichtet%2Fdp%2FB0BT8CWJQ6%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 87.29,
//                         "raw": "€87,29",
//                         "asin": "B0BT8CWJQ6",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTUzNjgxNjgyMTk4OjowOjo&url=%2FSALTER-COMBO-8568-K%25C3%25BCchenhelfer-Set-28-cm-Bratpfannen-antihaftbeschichtet%2Fdp%2FB0BT8CWJQ6%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 16,
//                     "title": "Chef Frank Haasnoot Plattier-Set | Utensilienset | Schwarz | 4 Stück hochwertige Werkzeuge im Koffer | Kulinarisches Set | Garnierpinzette, Quenellöffel & Spatel | Lebensmittel-Styling | Plating Tools",
//                     "asin": "B0BW9MYP6P",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTI3MzMxMDQzMzk4OjowOjo&url=%2FFrank-Haasnoot-Utensilienset-Garnierpinzette-Lebensmittel-Styling%2Fdp%2FB0BW9MYP6P%2Fref%3Dsr_1_16_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-16-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51aprbdFwHL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.95,
//                             "raw": "€64,95",
//                             "asin": "B0BW9MYP6P",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTI3MzMxMDQzMzk4OjowOjo&url=%2FFrank-Haasnoot-Utensilienset-Garnierpinzette-Lebensmittel-Styling%2Fdp%2FB0BW9MYP6P%2Fref%3Dsr_1_16_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-16-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.95,
//                         "raw": "€64,95",
//                         "asin": "B0BW9MYP6P",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTI3MzMxMDQzMzk4OjowOjo&url=%2FFrank-Haasnoot-Utensilienset-Garnierpinzette-Lebensmittel-Styling%2Fdp%2FB0BW9MYP6P%2Fref%3Dsr_1_16_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-16-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 17,
//                     "title": "Silikon-Kochutensilien-Set, Pilz-Küchenutensilien-Set mit Kupfergriff, 24-teiliges Küchenhelfer-Set, antihaftbeschichtet, hitzebeständig, Küchenspatel-Set – Khaki",
//                     "asin": "B0892ZXBMN",
//                     "link": "https://www.amazon.de/Silikon-Kochutensilien-Set-Pilz-K%C3%BCchenutensilien-Set-K%C3%BCchenhelfer-Set-antihaftbeschichtet-K%C3%BCchenwerkzeug-Set/dp/B0892ZXBMN/ref=sr_1_17?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-17",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71ICNbG1oxL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 3800,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 46.23,
//                             "raw": "€46,23",
//                             "name": "46,23 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.73,
//                             "raw": "€51,73",
//                             "name": "46,23 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 46.23,
//                         "raw": "€46,23",
//                         "name": "46,23 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 18,
//                     "title": "KitchenAid 15er Set Küchenutensilien, Hitzeresistent, Spülmaschinenfest, 15 Kochutensilien – Schwarz",
//                     "asin": "B096KTC84M",
//                     "link": "https://www.amazon.de/KitchenAid-KQG447BXOBE-Basters-Edelstahl/dp/B096KTC84M/ref=sr_1_18?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-18",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71RqQ3CbkqS._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 112,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 48.38,
//                             "raw": "€48,38",
//                             "name": "48,38 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 52.93,
//                             "raw": "€52,93",
//                             "name": "48,38 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 48.38,
//                         "raw": "€48,38",
//                         "name": "48,38 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 19,
//                     "title": "WMF Profi Plus Küchenhelfer Set 7-teilig, Ständer mit 6 Helfern, Schöpflöffel, Schaumlöffel, Wokwender, Wender, Schneebesen, Gemüseschäler, Cromargan Edelstahl, spülmaschinengeeignet",
//                     "asin": "B01237RWFI",
//                     "link": "https://www.amazon.de/WMF-K%C3%BCchenhelfer-Set-Utensilienst%C3%A4nder-Gem%C3%BCsesch%C3%A4ler-sp%C3%BClmaschinengeeignet/dp/B01237RWFI/ref=sr_1_19?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-19",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/717VI6WzDKL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 1088,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 80.15,
//                             "raw": "€80,15",
//                             "name": "80,15 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 119.99,
//                             "raw": "€119,99",
//                             "name": "80,15 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 80.15,
//                         "raw": "€80,15",
//                         "name": "80,15 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 20,
//                     "title": "Joseph Joseph 10119 Elevate Küchenhelfer Set 6-teilig, Nylon 39.5 x 33 x 6.5 cm 6 Einheiten, Mehrfarbig",
//                     "asin": "B00R86DMY0",
//                     "link": "https://www.amazon.de/Joseph-10119-K%C3%BCchenhelfer-6-teilig-mehrfarbig/dp/B00R86DMY0/ref=sr_1_20?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-20",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51Huox1OPxL._AC_UL400_.jpg",
//                     "unit_price": "8,70 €/stück",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 910,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 52.22,
//                             "raw": "€52,22",
//                             "name": "52,22 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "52,22 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 52.22,
//                         "raw": "€52,22",
//                         "name": "52,22 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 21,
//                     "title": "FEUERSTEIN Küchenmesser Set 6-Tlg / Extrem Scharfe Kochmesser, Profi Messer aus Hochwertigem Edelstahl / Premium Messerset, Schwarz",
//                     "asin": "B09KT9V229",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTQ0NDk2MTk1OTk4OjowOjo&url=%2FFEUERSTEIN-K%25C3%25BCchenmesser-Japanische-Kochmesser-hochwertigem%2Fdp%2FB09KT9V229%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61a6VwiFOBL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 20,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.98,
//                             "raw": "€54,98",
//                             "name": "54,98 €",
//                             "asin": "B09KT9V229",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTQ0NDk2MTk1OTk4OjowOjo&url=%2FFEUERSTEIN-K%25C3%25BCchenmesser-Japanische-Kochmesser-hochwertigem%2Fdp%2FB09KT9V229%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.98,
//                         "raw": "€54,98",
//                         "name": "54,98 €",
//                         "asin": "B09KT9V229",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTQ0NDk2MTk1OTk4OjowOjo&url=%2FFEUERSTEIN-K%25C3%25BCchenmesser-Japanische-Kochmesser-hochwertigem%2Fdp%2FB09KT9V229%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 22,
//                     "title": "Taylors Eye Witness Messerset, 5-Teilig \", Mit Messerblock Aus Holz.",
//                     "asin": "B00JSTH5B6",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMDI5MzA2MzUyNjA1OjowOjo&url=%2FTaylors-Eye-Witness-Messerset-Messerblock%2Fdp%2FB00JSTH5B6%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61Z9P3IUAuL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 167,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 63.72,
//                             "raw": "€63,72",
//                             "name": "63,72 €",
//                             "asin": "B00JSTH5B6",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMDI5MzA2MzUyNjA1OjowOjo&url=%2FTaylors-Eye-Witness-Messerset-Messerblock%2Fdp%2FB00JSTH5B6%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 63.72,
//                         "raw": "€63,72",
//                         "name": "63,72 €",
//                         "asin": "B00JSTH5B6",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMDI5MzA2MzUyNjA1OjowOjo&url=%2FTaylors-Eye-Witness-Messerset-Messerblock%2Fdp%2FB00JSTH5B6%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 23,
//                     "title": "hecef 14-teiliges Messerset mit Block, Regenbogen-Titan-Beschichtung Messer mit Laser-Muster, Martensitischer Edelstahl Kochmesser-Set mit Schärfer, Steakmesser, Schere (Lila)",
//                     "asin": "B0BJCYHSB4",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTQ0ODE1MzE0NTk4OjowOjo&url=%2Fhecef-Regenbogen-Titan-Beschichtung-Laser-Muster-Martensitischer-Kochmesser-Set%2Fdp%2FB0BJCYHSB4%2Fref%3Dsr_1_23_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-23-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61qJsnxO71L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 8,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "asin": "B0BJCYHSB4",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTQ0ODE1MzE0NTk4OjowOjo&url=%2Fhecef-Regenbogen-Titan-Beschichtung-Laser-Muster-Martensitischer-Kochmesser-Set%2Fdp%2FB0BJCYHSB4%2Fref%3Dsr_1_23_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-23-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "asin": "B0BJCYHSB4",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTQ0ODE1MzE0NTk4OjowOjo&url=%2Fhecef-Regenbogen-Titan-Beschichtung-Laser-Muster-Martensitischer-Kochmesser-Set%2Fdp%2FB0BJCYHSB4%2Fref%3Dsr_1_23_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-23-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 24,
//                     "title": "Feuerstein Küchenmesser set 5-Tlg, extrem scharfe Kochmesser aus hochwertigem Edelstahl, Profi Messer set mit ergonomischen Harzgriff in Geschenkbox,",
//                     "asin": "B09W1PQGZP",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTQ1NzYwMTI5Nzk4OjowOjo&url=%2FFEUERSTEIN-K%25C3%25BCchenmesser-set-hochwertigem-ergonomischen-Geschenkbox%2Fdp%2FB09W1PQGZP%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Y44T2fbdL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 3,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.99,
//                             "raw": "€69,99",
//                             "name": "69,99 €",
//                             "asin": "B09W1PQGZP",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTQ1NzYwMTI5Nzk4OjowOjo&url=%2FFEUERSTEIN-K%25C3%25BCchenmesser-set-hochwertigem-ergonomischen-Geschenkbox%2Fdp%2FB09W1PQGZP%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.99,
//                         "raw": "€69,99",
//                         "name": "69,99 €",
//                         "asin": "B09W1PQGZP",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNjI2NjYyNTA2NzMxMTU2OjE2ODQwNzQ1MDE6c3BfbXRmOjIwMTQ1NzYwMTI5Nzk4OjowOjo&url=%2FFEUERSTEIN-K%25C3%25BCchenmesser-set-hochwertigem-ergonomischen-Geschenkbox%2Fdp%2FB09W1PQGZP%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DK%25C3%25BCchenutensilien-Set%26qid%3D1684074501%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 25,
//                     "title": "Umite Chef Silikon-Küchenutensilien-Set, 43-teilig, Silikon-Küchenspachtel, Utensilien-Set mit Edelstahlgriff, antihaftbeschichtet, hitzebeständig – bestes Küchen-Kochgeschirr-Set (Khaki)",
//                     "asin": "B096NRHF4D",
//                     "link": "https://www.amazon.de/Umite-Chef-Silikon-K%C3%BCchenhelfer-Set-Silikon-K%C3%BCchenspachtel-Set-antihaftbeschichtet/dp/B096NRHF4D/ref=sr_1_25?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-25",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/717dVepSTbL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 1319,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.23,
//                             "raw": "€54,23",
//                             "name": "54,23 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 62.77,
//                             "raw": "€62,77",
//                             "name": "54,23 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.23,
//                         "raw": "€54,23",
//                         "name": "54,23 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 26,
//                     "title": "WMF Profi Plus Küchenhelfer Set 6-teilig, Hängeleiste mit 5 Helfer, Schöpflöffel, Schaumlöffel, Saucenlöffel, Wender, Fleischgabel, Kelle, Cromargan Edelstahl teilmattiert, spülmaschinengeeignet",
//                     "asin": "B000WGMQWW",
//                     "link": "https://www.amazon.de/WMF-Schauml%C3%B6ffel-Set-Sch%C3%B6pfl%C3%B6ffel-Schauml%C3%B6ffel-sp%C3%BClmaschinengeeignet/dp/B000WGMQWW/ref=sr_1_26_mod_primary_new?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-26",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71+XanWJfhL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 271,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.01,
//                             "raw": "€89,01",
//                             "name": "89,01 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 90.64,
//                             "raw": "€90,64",
//                             "name": "90,64 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.01,
//                         "raw": "€89,01",
//                         "name": "89,01 €"
//                     }
//                 },
//                 {
//                     "position": 27,
//                     "title": "Rorence Küchenutensilien-Set: 12-teilige Küchenhelfer zum Backen Mixen Antihaft- und hitzebeständige Silikon- und Edelstahlgriffe (Utensilienhalter nicht im Lieferumfang enthalten) - Blau",
//                     "asin": "B08FFGTKQ6",
//                     "link": "https://www.amazon.de/Rorence-Silikon-Kochutensilien-Set-Antihaftbeschichtung-Edelstahl-Griffe-Utensilienhalter/dp/B08FFGTKQ6/ref=sr_1_27?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-27",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71qUdCkBauL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 1626,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.31,
//                             "raw": "€51,31",
//                             "name": "51,31 €",
//                             "asin": "B08FFGTKQ6",
//                             "link": "https://www.amazon.de/Rorence-Silikon-Kochutensilien-Set-Antihaftbeschichtung-Edelstahl-Griffe-Utensilienhalter/dp/B08FFGTKQ6/ref=sr_1_27?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-27"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.31,
//                         "raw": "€51,31",
//                         "name": "51,31 €",
//                         "asin": "B08FFGTKQ6",
//                         "link": "https://www.amazon.de/Rorence-Silikon-Kochutensilien-Set-Antihaftbeschichtung-Edelstahl-Griffe-Utensilienhalter/dp/B08FFGTKQ6/ref=sr_1_27?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-27"
//                     }
//                 },
//                 {
//                     "position": 28,
//                     "title": "Stanley Rogers Küchenhelfer-Set, Kochbesteck und Küchenwerkzeuge mit ergonomischem Premium-Softtouchgriff, hochwertiges Kochutensilien-Set (Farbe: Silber/Schwarz), Menge: 1 x 11er Set",
//                     "asin": "B07KFKYP7L",
//                     "link": "https://www.amazon.de/Stanley-Rogers-K%C3%BCchenhelfer-Set-Premium-Softtouchgriff-Kochutensilien-Set/dp/B07KFKYP7L/ref=sr_1_28?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-28",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61ITyEJ-YFL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 86,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.99,
//                             "raw": "€89,99",
//                             "name": "89,99 €",
//                             "asin": "B07KFKYP7L",
//                             "link": "https://www.amazon.de/Stanley-Rogers-K%C3%BCchenhelfer-Set-Premium-Softtouchgriff-Kochutensilien-Set/dp/B07KFKYP7L/ref=sr_1_28?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-28"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.99,
//                         "raw": "€89,99",
//                         "name": "89,99 €",
//                         "asin": "B07KFKYP7L",
//                         "link": "https://www.amazon.de/Stanley-Rogers-K%C3%BCchenhelfer-Set-Premium-Softtouchgriff-Kochutensilien-Set/dp/B07KFKYP7L/ref=sr_1_28?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-28"
//                     }
//                 },
//                 {
//                     "position": 29,
//                     "title": "RIOVARM Induktion Bratpfanne Set 20 24 28 cm Granit Antihaft Beschichtet Pfannenset mit Bakelit-Griff PFOA-freie Kratzfest Omelettpfannen für alle Herdarten",
//                     "asin": "B09P4Q8TX7",
//                     "link": "https://www.amazon.de/RIOVARM-Bratpfanne-Induktionsgeeignet-Antihaftbeschichtung-Bakelit-Griff/dp/B09P4Q8TX7/ref=sr_1_29?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-29",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/814Hvy5vP1L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.2,
//                     "ratings_total": 214,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.98,
//                             "raw": "€54,98",
//                             "name": "54,98 €",
//                             "asin": "B09P4Q8TX7",
//                             "link": "https://www.amazon.de/RIOVARM-Bratpfanne-Induktionsgeeignet-Antihaftbeschichtung-Bakelit-Griff/dp/B09P4Q8TX7/ref=sr_1_29?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-29"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.98,
//                         "raw": "€54,98",
//                         "name": "54,98 €",
//                         "asin": "B09P4Q8TX7",
//                         "link": "https://www.amazon.de/RIOVARM-Bratpfanne-Induktionsgeeignet-Antihaftbeschichtung-Bakelit-Griff/dp/B09P4Q8TX7/ref=sr_1_29?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-29"
//                     }
//                 },
//                 {
//                     "position": 30,
//                     "title": "Umite Chef Küchenutensilien-Set, antihaftbeschichtet, Silikon, Küchenutensilien, Spachtel-Set mit Halter, Holzgriff, hitzebeständiges Silikon, 24-teilig",
//                     "asin": "B08573DQ39",
//                     "link": "https://www.amazon.de/Umite-Chef-K%C3%BCchenutensilien-Set-Antihaft-Silikon-hitzebest%C3%A4ndiges/dp/B08573DQ39/ref=sr_1_30?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-30",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61gaE8WdZ+L._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 8376,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 30.99,
//                             "raw": "€30,99",
//                             "name": "30,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 32.99,
//                             "raw": "€32,99",
//                             "name": "30,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 30.99,
//                         "raw": "€30,99",
//                         "name": "30,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 31,
//                     "title": "25 Stück Küchenutensilien Set - Antihaft Hitzebeständiger Küchenhelfer Set - Küchenzubehör Küchenset Mit Edelstahlgriff Und Nylon - Kochbesteck Set Kochutensilien Set Kochgeschirr Set Kochset",
//                     "asin": "B075MDQ977",
//                     "link": "https://www.amazon.de/K%C3%BCchenhelfer-Kochutensilien-K%C3%BCchenutensilien-Kochgeschirr-Set-HomeHero/dp/B075MDQ977/ref=sr_1_31?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-31",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81DD-2MogFL._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Top Angebot"
//                     },
//                     "rating": 4.4,
//                     "ratings_total": 27527,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 28.04,
//                             "raw": "€28,04",
//                             "name": "28,04 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 49.99,
//                             "raw": "€49,99",
//                             "name": "28,04 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 28.04,
//                         "raw": "€28,04",
//                         "name": "28,04 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 32,
//                     "title": "KitchenAid 15 tlg. Küchenutensilien-Set KO447BXERI -Lipire Red",
//                     "asin": "B091TMXLJH",
//                     "link": "https://www.amazon.de/KitchenAid-tlg-K%C3%BCchenutensilien-KO447BXERI-Empire/dp/B091TMXLJH/ref=sr_1_32?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-32",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41syiJ5LD5S._AC_UL400_.jpg",
//                     "rating": 4.8,
//                     "ratings_total": 9,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 49,
//                             "raw": "€49,00",
//                             "name": "49,00 €",
//                             "asin": "B091TMXLJH",
//                             "link": "https://www.amazon.de/KitchenAid-tlg-K%C3%BCchenutensilien-KO447BXERI-Empire/dp/B091TMXLJH/ref=sr_1_32?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-32"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 49,
//                         "raw": "€49,00",
//                         "name": "49,00 €",
//                         "asin": "B091TMXLJH",
//                         "link": "https://www.amazon.de/KitchenAid-tlg-K%C3%BCchenutensilien-KO447BXERI-Empire/dp/B091TMXLJH/ref=sr_1_32?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-32"
//                     }
//                 },
//                 {
//                     "position": 33,
//                     "title": "Holz Kochbesteck-Set mit Halter, Pfannenwender Kochlöffel Schöpfkelle Nudelheber Servierlöffel Sieb – mit Haken, Halter und Ablage – 13-teiliges-Set Küchenset – hochwertig & nachhaltig",
//                     "asin": "B082VTKNMJ",
//                     "link": "https://www.amazon.de/Kochgeschirr-Antihaftbeschichtung-L%C3%B6ffelablage-handgefertigte-umweltfreundlich/dp/B082VTKNMJ/ref=sr_1_33?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-33",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91x4J6fz9DL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 1599,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 47.97,
//                             "raw": "€47,97",
//                             "name": "47,97 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.97,
//                             "raw": "€54,97",
//                             "name": "47,97 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 47.97,
//                         "raw": "€47,97",
//                         "name": "47,97 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 34,
//                     "title": "Joseph Joseph Elevate - 6-teiliges Küchenutensilien-Set - Mehrfarbig",
//                     "asin": "B083ZS83VX",
//                     "link": "https://www.amazon.de/Joseph-Elevate-6-teiliges-K%C3%BCchenutensilien-Set-Mehrfarben/dp/B083ZS83VX/ref=sr_1_34?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-34",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61CNPgEVP8L._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 5842
//                 },
//                 {
//                     "position": 35,
//                     "title": "Silikon Küchenutensilien Set, 11 Stück Silikon Kochutensilien Küchenutensilien Set Naturholzgriffe Kochwerkzeug Spachtel Löffel Küchen helfer Utensilien Set - Weiß",
//                     "asin": "B09682CL8C",
//                     "link": "https://www.amazon.de/Kochutensilien-K%C3%BCchenutensilien-Naturholzgriffe-Kochwerkzeug-K%C3%BCchen-helfer/dp/B09682CL8C/ref=sr_1_35?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-35",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51zYMThutAL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 5 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.8,
//                     "ratings_total": 12,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 47.59,
//                             "raw": "€47,59",
//                             "name": "47,59 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 53.79,
//                             "raw": "€53,79",
//                             "name": "47,59 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 47.59,
//                         "raw": "€47,59",
//                         "name": "47,59 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 36,
//                     "title": "WMF Gourmet Schüsselset 4-teilig, Edelstahl Schüsseln für die Küche 1,0l - 3,5l, Rührschüssel, Salatschüssel, Servierschüssel, Cromargan, stapelbar",
//                     "asin": "B00ZVUZVFI",
//                     "link": "https://www.amazon.de/WMF-Sch%C3%BCssel-Set-multifunktional-Salatsch%C3%BCssel-Serviersch%C3%BCssel/dp/B00ZVUZVFI/ref=sr_1_36?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-36",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/519jhuxvR-L._AC_UL400_.jpg",
//                     "bestseller": {
//                         "link": "https://www.amazon.de/gp/bestsellers/kitchen/3098724031/ref=sr_bs_35_3098724031_1",
//                         "category": "Rührschüsseln"
//                     },
//                     "unit_price": "8,75 €/stück",
//                     "rating": 4.8,
//                     "ratings_total": 9819,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 34.99,
//                             "raw": "€34,99",
//                             "name": "34,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "34,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 34.99,
//                         "raw": "€34,99",
//                         "name": "34,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 37,
//                     "title": "Homety Kartoffel Aufbewahrungsbox [3er Set]- Deko Küche - der ideale Weg für Zwiebel Aufbewahrung Kartoffel Aufbewahrung und Knoblauch Aufbewahrung (Weiss)",
//                     "asin": "B0B6C16V6G",
//                     "link": "https://www.amazon.de/Homety-Kartoffel-Aufbewahrungsbox-Deko-K%C3%BCche/dp/B0B6C16V6G/ref=sr_1_37?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-37",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71KhpsIKL-L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 44,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.97,
//                             "raw": "€64,97",
//                             "name": "64,97 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.97,
//                             "raw": "€69,97",
//                             "name": "64,97 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.97,
//                         "raw": "€64,97",
//                         "name": "64,97 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 38,
//                     "title": "Artcome 50 Stk Küchenutensilien-Set Kochutensilien aus Nylon und Edelstahl mit Utensilienhalter Spatel Löffelzange Schneebesen-Kochgeschirr-Set Küchenhelfer Küchengeräte Küchenzubehör",
//                     "asin": "B0891Y176B",
//                     "link": "https://www.amazon.de/Artcome-K%C3%BCchenutensilien-Set-Kochutensilien-Utensilienhalter-Schneebesen-Kochgeschirr-Set/dp/B0891Y176B/ref=sr_1_38?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-38",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71sAoNwcLSL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.2,
//                     "ratings_total": 297,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "54,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.99,
//                             "raw": "€56,99",
//                             "name": "54,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.99,
//                         "raw": "€54,99",
//                         "name": "54,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 39,
//                     "title": "D.Perlla Messerblock Set, 14-TLG Selbstschärfender Messerblock, Scharf Edelstahl Messerset Set mit Kunststoff-Griff und Holzblock, Profi Küchenmesserset",
//                     "asin": "B09V4TPTKF",
//                     "link": "https://www.amazon.de/D-Perlla-Messerblock-Selbstsch%C3%A4rfender-Kunststoff-Griff-K%C3%BCchenmesserset/dp/B09V4TPTKF/ref=sr_1_39?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-39",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71UsrFi7-9L._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 5 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 185,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.99,
//                             "raw": "€79,99",
//                             "name": "79,99 €",
//                             "asin": "B09V4TPTKF",
//                             "link": "https://www.amazon.de/D-Perlla-Messerblock-Selbstsch%C3%A4rfender-Kunststoff-Griff-K%C3%BCchenmesserset/dp/B09V4TPTKF/ref=sr_1_39?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-39"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.99,
//                         "raw": "€79,99",
//                         "name": "79,99 €",
//                         "asin": "B09V4TPTKF",
//                         "link": "https://www.amazon.de/D-Perlla-Messerblock-Selbstsch%C3%A4rfender-Kunststoff-Griff-K%C3%BCchenmesserset/dp/B09V4TPTKF/ref=sr_1_39?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-39"
//                     }
//                 },
//                 {
//                     "position": 40,
//                     "title": "Masthome Antihaft-Kochgeschirr-Set, 20-teilig, Induktionspfannen-Set mit Deckel, Aluminium-Töpfen und Pfannen, inklusive Bratpfannen, Stielkasserolle, Suppentopf, Küchenutensilien",
//                     "asin": "B09MHKVBMZ",
//                     "link": "https://www.amazon.de/Masthome-Antihaft-Kochgeschirr-Set-Induktionspfannen-Set-Aluminium-T%C3%B6pfen-K%C3%BCchenutensilien/dp/B09MHKVBMZ/ref=sr_1_40?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-40",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71e1M64MAbL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 6 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 3.9,
//                     "ratings_total": 79,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.69,
//                             "raw": "€89,69",
//                             "name": "89,69 €",
//                             "asin": "B09MHKVBMZ",
//                             "link": "https://www.amazon.de/Masthome-Antihaft-Kochgeschirr-Set-Induktionspfannen-Set-Aluminium-T%C3%B6pfen-K%C3%BCchenutensilien/dp/B09MHKVBMZ/ref=sr_1_40?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-40"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.69,
//                         "raw": "€89,69",
//                         "name": "89,69 €",
//                         "asin": "B09MHKVBMZ",
//                         "link": "https://www.amazon.de/Masthome-Antihaft-Kochgeschirr-Set-Induktionspfannen-Set-Aluminium-T%C3%B6pfen-K%C3%BCchenutensilien/dp/B09MHKVBMZ/ref=sr_1_40?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-40"
//                     }
//                 },
//                 {
//                     "position": 41,
//                     "title": "Zone Denmark Singles Dishwashing Set 22 x 6,7 x 19 cm 4 DELE Black",
//                     "asin": "B07JD2DR4R",
//                     "link": "https://www.amazon.de/Zone-Geschirrsp%C3%BCl-Set-Sp%C3%BCl-Set-Single-schwarz/dp/B07JD2DR4R/ref=sr_1_41?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-41",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81OMzGl1SKL._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 215,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 37.7,
//                             "raw": "€37,70",
//                             "name": "37,70 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 47,
//                             "raw": "€47,00",
//                             "name": "37,70 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 37.7,
//                         "raw": "€37,70",
//                         "name": "37,70 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 42,
//                     "title": "La Moustache - Kochgeschirr, Antihaftbeschichtet, Aluminiumguss | Töpfe mit Glasdeckel, Pfanne, Stielkasserolle | Für alle Küchen - Induktions, Gas, Elektro und Glaskeramikherd (8 Stücke)",
//                     "asin": "B08MQMC6GR",
//                     "link": "https://www.amazon.de/Moustache-Kochgeschirr-Set-antihaftbeschichtet-Aluminium-8-teilig/dp/B08MQMC6GR/ref=sr_1_42?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-42",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/7128pkZnEEL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 492,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 76.9,
//                             "raw": "€76,90",
//                             "name": "76,90 €",
//                             "asin": "B08MQMC6GR",
//                             "link": "https://www.amazon.de/Moustache-Kochgeschirr-Set-antihaftbeschichtet-Aluminium-8-teilig/dp/B08MQMC6GR/ref=sr_1_42?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-42"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 76.9,
//                         "raw": "€76,90",
//                         "name": "76,90 €",
//                         "asin": "B08MQMC6GR",
//                         "link": "https://www.amazon.de/Moustache-Kochgeschirr-Set-antihaftbeschichtet-Aluminium-8-teilig/dp/B08MQMC6GR/ref=sr_1_42?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-42"
//                     }
//                 },
//                 {
//                     "position": 43,
//                     "title": "Wok-Pfannenwender und Schöpflöffel, Schöpflöffel, chinesisches Wok-Utensilien-Set, 304 Edelstahl, Kochutensilien (38,1 cm, 38,1 cm, 35,6 cm)",
//                     "asin": "B08KVVRPVJ",
//                     "link": "https://www.amazon.de/Wok-Pfannenwender-Sch%C3%B6pfl%C3%B6ffel-chinesisches-Wok-Utensilien-Set-Kochutensilien/dp/B08KVVRPVJ/ref=sr_1_43?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-43",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/713l+xopxvL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 393
//                 },
//                 {
//                     "position": 44,
//                     "title": "iDesign 2er Set Schubladen Trennsystem für die Küche, größenverstellbarer Schubladen Einsatz aus Paulownia-Holz, Schubladen Organizer für Besteck und Küchenutensilien, beige",
//                     "asin": "B093MNZ7Z2",
//                     "link": "https://www.amazon.de/iDesign-Schubladen-Organizer-Kunststoff-Drawer-Dividers/dp/B093MNZ7Z2/ref=sr_1_44?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-44",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/611OkfyLeBS._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 34,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 41.77,
//                             "raw": "€41,77",
//                             "name": "41,77 €",
//                             "asin": "B093MNZ7Z2",
//                             "link": "https://www.amazon.de/iDesign-Schubladen-Organizer-Kunststoff-Drawer-Dividers/dp/B093MNZ7Z2/ref=sr_1_44?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-44"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 41.77,
//                         "raw": "€41,77",
//                         "name": "41,77 €",
//                         "asin": "B093MNZ7Z2",
//                         "link": "https://www.amazon.de/iDesign-Schubladen-Organizer-Kunststoff-Drawer-Dividers/dp/B093MNZ7Z2/ref=sr_1_44?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-44"
//                     }
//                 },
//                 {
//                     "position": 45,
//                     "title": "Joseph Joseph Elevate Utensilien in der Schublade Küchenhelfer-Set, 5-teilig inklusive Löffel, Pfannenwender, Schöpfkelle, mehrfarbig",
//                     "asin": "B0B1F48Y4G",
//                     "link": "https://www.amazon.de/Joseph-Utensils-Utensilien-Set-5-teilig-mehrfarbig/dp/B0B1F48Y4G/ref=sr_1_45?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-45",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61sMCrEbTIL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 2,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 41.23,
//                             "raw": "€41,23",
//                             "name": "41,23 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 43.04,
//                             "raw": "€43,04",
//                             "name": "41,23 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 41.23,
//                         "raw": "€41,23",
//                         "name": "41,23 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 46,
//                     "title": "Vtopmart Vorratsdosen Set, Müsli Schüttdose & Frischhaltedosen, Kunststoff Vorratsdosen luftdicht, Satz mit 24, 24 Etiketten für Getreide, Mehl, Zucker usw",
//                     "asin": "B0967WM727",
//                     "link": "https://www.amazon.de/Vtopmart-Vorratsdosen-Sch%C3%BCttdose-Frischhaltedosen-Kunststoff/dp/B0967WM727/ref=sr_1_46?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-46",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91lYP7D4z5L._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "coupon": {
//                         "badge_text": "Spare 6,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 1197,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 45.01,
//                             "raw": "€45,01",
//                             "name": "45,01 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 78.46,
//                             "raw": "€78,46",
//                             "name": "45,01 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 45.01,
//                         "raw": "€45,01",
//                         "name": "45,01 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 47,
//                     "title": "ABYSSE Harry Potter Teekanne mit Hogwarts-Kessel-Set",
//                     "asin": "B08Z35652W",
//                     "link": "https://www.amazon.de/Harry-Potter-Teekanne-mit-Hogwarts-Kessel-Set/dp/B08Z35652W/ref=sr_1_47?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-47",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/31j2iLS7TsL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 42,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 49.99,
//                             "raw": "€49,99",
//                             "name": "49,99 €",
//                             "asin": "B08Z35652W",
//                             "link": "https://www.amazon.de/Harry-Potter-Teekanne-mit-Hogwarts-Kessel-Set/dp/B08Z35652W/ref=sr_1_47?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-47"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 49.99,
//                         "raw": "€49,99",
//                         "name": "49,99 €",
//                         "asin": "B08Z35652W",
//                         "link": "https://www.amazon.de/Harry-Potter-Teekanne-mit-Hogwarts-Kessel-Set/dp/B08Z35652W/ref=sr_1_47?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-47"
//                     }
//                 },
//                 {
//                     "position": 48,
//                     "title": "FEUERSTEIN Küchenmesser Set 6-Tlg / Extrem Scharfe Kochmesser, Profi Messer aus Hochwertigem Edelstahl / Premium Messerset, Schwarz",
//                     "asin": "B09KT9V229",
//                     "link": "https://www.amazon.de/FEUERSTEIN-K%C3%BCchenmesser-Japanische-Kochmesser-hochwertigem/dp/B09KT9V229/ref=sr_1_48?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-48",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61a6VwiFOBL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 20,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.98,
//                             "raw": "€54,98",
//                             "name": "54,98 €",
//                             "asin": "B09KT9V229",
//                             "link": "https://www.amazon.de/FEUERSTEIN-K%C3%BCchenmesser-Japanische-Kochmesser-hochwertigem/dp/B09KT9V229/ref=sr_1_48?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-48"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.98,
//                         "raw": "€54,98",
//                         "name": "54,98 €",
//                         "asin": "B09KT9V229",
//                         "link": "https://www.amazon.de/FEUERSTEIN-K%C3%BCchenmesser-Japanische-Kochmesser-hochwertigem/dp/B09KT9V229/ref=sr_1_48?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-48"
//                     }
//                 },
//                 {
//                     "position": 49,
//                     "title": "MSR (Mountain Safety Research) Küchenutensilien Alpine Deluxe Kitchen Set, One size, 5337",
//                     "asin": "B00453ON6U",
//                     "link": "https://www.amazon.de/MSR-Mountain-Safety-Research-K%C3%BCchenutensilien/dp/B00453ON6U/ref=sr_1_49?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-49",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/616oB6SiWuS._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 218,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 67.9,
//                             "raw": "€67,90",
//                             "name": "67,90 €",
//                             "asin": "B00453ON6U",
//                             "link": "https://www.amazon.de/MSR-Mountain-Safety-Research-K%C3%BCchenutensilien/dp/B00453ON6U/ref=sr_1_49?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-49"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 67.9,
//                         "raw": "€67,90",
//                         "name": "67,90 €",
//                         "asin": "B00453ON6U",
//                         "link": "https://www.amazon.de/MSR-Mountain-Safety-Research-K%C3%BCchenutensilien/dp/B00453ON6U/ref=sr_1_49?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-49"
//                     }
//                 },
//                 {
//                     "position": 50,
//                     "title": "WMF Steakbesteck 12-teilig, Steakbesteck Set für 6 Personen, Steakmesser, Steakgabel, Cromargan Edelstahl poliert, Grillbesteck in Holzkiste",
//                     "asin": "B0019UPPK0",
//                     "link": "https://www.amazon.de/WMF-Steakbesteck-12-teilig-Steakgabel-Steakmesser/dp/B0019UPPK0/ref=sr_1_50?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-50",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71b9aj2BgCL._AC_UL400_.jpg",
//                     "bestseller": {
//                         "link": "https://www.amazon.de/gp/bestsellers/kitchen/3098666031/ref=sr_bs_49_3098666031_1",
//                         "category": "Steakmesser"
//                     },
//                     "unit_price": "2,35 €/stück",
//                     "rating": 4.8,
//                     "ratings_total": 9070,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 28.19,
//                             "raw": "€28,19",
//                             "name": "28,19 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "28,19 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 28.19,
//                         "raw": "€28,19",
//                         "name": "28,19 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 51,
//                     "title": "Messerblock | hecef Profi Messerset mit Holzblock 14-TLG | Edelstahl Küchenmesser Scharfe Set mit Messer und Rosa Griff | Profi Kochmesser Set mit Schere/Steak Messer/Messerschärfer (Rosa)",
//                     "asin": "B09ZTYBX2F",
//                     "link": "https://www.amazon.de/Messerblock-hecef-K%C3%BCchenmesser-Kochmesser-Messersch%C3%A4rfer/dp/B09ZTYBX2F/ref=sr_1_51?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-51",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61Gd5xGCEHL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 30,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "asin": "B09ZTYBX2F",
//                             "link": "https://www.amazon.de/Messerblock-hecef-K%C3%BCchenmesser-Kochmesser-Messersch%C3%A4rfer/dp/B09ZTYBX2F/ref=sr_1_51?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-51"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "asin": "B09ZTYBX2F",
//                         "link": "https://www.amazon.de/Messerblock-hecef-K%C3%BCchenmesser-Kochmesser-Messersch%C3%A4rfer/dp/B09ZTYBX2F/ref=sr_1_51?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-51"
//                     }
//                 },
//                 {
//                     "position": 52,
//                     "title": "mDesign Aufbewahrungsbox für die Küche – breite Holzbox mit integrierten Griffen – offene Ablage aus Bambus zur Aufbewahrung von Küchenutensilien – 2er-Set – naturfarben",
//                     "asin": "B087Y4M7KW",
//                     "link": "https://www.amazon.de/mDesign-Aufbewahrungsbox-f%C3%BCr-K%C3%BCche-K%C3%BCchenutensilien/dp/B087Y4M7KW/ref=sr_1_52?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-52",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Uq8nxpglL._AC_UL400_.jpg",
//                     "unit_price": "28,43 €/Stück",
//                     "rating": 4.5,
//                     "ratings_total": 228,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.85,
//                             "raw": "€56,85",
//                             "name": "56,85 €",
//                             "asin": "B087Y4M7KW",
//                             "link": "https://www.amazon.de/mDesign-Aufbewahrungsbox-f%C3%BCr-K%C3%BCche-K%C3%BCchenutensilien/dp/B087Y4M7KW/ref=sr_1_52?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-52"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 56.85,
//                         "raw": "€56,85",
//                         "name": "56,85 €",
//                         "asin": "B087Y4M7KW",
//                         "link": "https://www.amazon.de/mDesign-Aufbewahrungsbox-f%C3%BCr-K%C3%BCche-K%C3%BCchenutensilien/dp/B087Y4M7KW/ref=sr_1_52?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-52"
//                     }
//                 },
//                 {
//                     "position": 53,
//                     "title": "SOLTAKO Olivenholz Löffel Besteck Kochlöffel Reislöffel Spatel Schöpfkelle Salatbesteck Salatlöffel Küchenhelfer Besteckset Länge: 30cm (7-teilig + Besteckhalter)",
//                     "asin": "B08VDS7YFR",
//                     "link": "https://www.amazon.de/SOLTAKO-Olivenholz-Salatbesteck-Salatl%C3%B6ffel-K%C3%BCchenhelfer/dp/B08VDS7YFR/ref=sr_1_53?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-53",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81du1vv8f8L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 51,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.99,
//                             "raw": "€89,99",
//                             "name": "89,99 €",
//                             "asin": "B08VDS7YFR",
//                             "link": "https://www.amazon.de/SOLTAKO-Olivenholz-Salatbesteck-Salatl%C3%B6ffel-K%C3%BCchenhelfer/dp/B08VDS7YFR/ref=sr_1_53?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-53"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.99,
//                         "raw": "€89,99",
//                         "name": "89,99 €",
//                         "asin": "B08VDS7YFR",
//                         "link": "https://www.amazon.de/SOLTAKO-Olivenholz-Salatbesteck-Salatl%C3%B6ffel-K%C3%BCchenhelfer/dp/B08VDS7YFR/ref=sr_1_53?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-53"
//                     }
//                 },
//                 {
//                     "position": 54,
//                     "title": "GRIMM & BROTHERS: Whisky Karaffe 850ml- 2 Whisky Gläser mit Curling Effekt- 9 Whiskey Steine- Whiskey Geschenk- ECHTHOLZ- Whiskey Zubehör- Männer Geschenke - Whisky Dekanter Globus mit Gravur",
//                     "asin": "B08426DCKQ",
//                     "link": "https://www.amazon.de/Grimm-Brothers-Geschenke-Luftdichter-Verschluss/dp/B08426DCKQ/ref=sr_1_54?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-54",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81WyJ0VFWsL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 302,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.99,
//                             "raw": "€56,99",
//                             "name": "56,99 €",
//                             "asin": "B08426DCKQ",
//                             "link": "https://www.amazon.de/Grimm-Brothers-Geschenke-Luftdichter-Verschluss/dp/B08426DCKQ/ref=sr_1_54?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-54"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 56.99,
//                         "raw": "€56,99",
//                         "name": "56,99 €",
//                         "asin": "B08426DCKQ",
//                         "link": "https://www.amazon.de/Grimm-Brothers-Geschenke-Luftdichter-Verschluss/dp/B08426DCKQ/ref=sr_1_54?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-54"
//                     }
//                 },
//                 {
//                     "position": 55,
//                     "title": "Zone Denmark Geschirrspülset, Spülorganizer mit Seifenspender/Spülmittelspender, Spültuch und Spülbürste, 19 x 6 x 20,5 cm, Set mit 4 Teilen, Schwarz",
//                     "asin": "B079NF9C53",
//                     "link": "https://www.amazon.de/Zone-Denmark-Geschirrsp%C3%BClset-Zubeh%C3%B6r-Sp%C3%BClorganizer/dp/B079NF9C53/ref=sr_1_55?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-55",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71j+9kFXB6L._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 463,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 37.7,
//                             "raw": "€37,70",
//                             "name": "37,70 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 46.95,
//                             "raw": "€46,95",
//                             "name": "37,70 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 37.7,
//                         "raw": "€37,70",
//                         "name": "37,70 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 56,
//                     "title": "Lagostina Grancucina Kochlöffel-Set mit Hängeleiste, 7 Teile, Edelstahl 18/10, mit Schaumlöffel, Pfannenwender, Fleischgabel, Kelle, Spaghettilöffel, Kochlöffel und Leiste mit 6 Haken",
//                     "asin": "B00DQ8D1MW",
//                     "link": "https://www.amazon.de/Lagostina-Grancucina-K%C3%BCchen-edelstahl-St%C3%BCck/dp/B00DQ8D1MW/ref=sr_1_56_mod_primary_new?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-56",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61RtVsWjVzL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 737,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 78.61,
//                             "raw": "€78,61",
//                             "name": "78,61 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 82.32,
//                             "raw": "€82,32",
//                             "name": "82,32 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 78.61,
//                         "raw": "€78,61",
//                         "name": "78,61 €"
//                     }
//                 },
//                 {
//                     "position": 57,
//                     "title": "Amazon Basics 5-teiliges Kochgeschirr-Set mit Antihaftbeshichtung - induktionsgeeignet, mit Deckeln",
//                     "asin": "B07CPG3J2K",
//                     "link": "https://www.amazon.de/AmazonBasics-5-teiliges-Kochgeschirr-Set-nicht-haftend-Induktionsherde/dp/B07CPG3J2K/ref=sr_1_57?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-57",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61A09jNP4kL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.2,
//                     "ratings_total": 1998,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.6,
//                             "raw": "€64,60",
//                             "name": "64,60 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 67.32,
//                             "raw": "€67,32",
//                             "name": "64,60 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.6,
//                         "raw": "€64,60",
//                         "name": "64,60 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 58,
//                     "title": "FACKELMANN Küchenhelfer-Set Sense, Kochbesteck aus hochwertigem Kunststoff mit langlebigem und rutschfestem Soft-Touch-Griff, (Farbe: Schwarz/Silber), Menge: 1 x 7er Set",
//                     "asin": "B07JL64N7T",
//                     "link": "https://www.amazon.de/FACKELMANN-K%C3%BCchenhelfer-Set-hochwertigem-rutschfestem-Soft-Touch-Griff/dp/B07JL64N7T/ref=sr_1_58?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-58",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81YcDM5yt8L._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 190,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 62.92,
//                             "raw": "€62,92",
//                             "name": "62,92 €",
//                             "asin": "B07JL64N7T",
//                             "link": "https://www.amazon.de/FACKELMANN-K%C3%BCchenhelfer-Set-hochwertigem-rutschfestem-Soft-Touch-Griff/dp/B07JL64N7T/ref=sr_1_58?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-58"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 62.92,
//                         "raw": "€62,92",
//                         "name": "62,92 €",
//                         "asin": "B07JL64N7T",
//                         "link": "https://www.amazon.de/FACKELMANN-K%C3%BCchenhelfer-Set-hochwertigem-rutschfestem-Soft-Touch-Griff/dp/B07JL64N7T/ref=sr_1_58?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-58"
//                     }
//                 },
//                 {
//                     "position": 59,
//                     "title": "Echtwerk Tafelbesteck \"Avelino\", PVD gold mattiert, 68-teilig, für 12 Personen, Edelstahl 18/10, Besteck Set, inkl. Aufbewahrungs-Kassette",
//                     "asin": "B07VD99ZKV",
//                     "link": "https://www.amazon.de/Echtwerk-Tafelbesteck-68-teilig-Edelstahl-Aufbewahrungs-Kassette/dp/B07VD99ZKV/ref=sr_1_59?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-59",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61tAzq0eBvL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4,
//                     "ratings_total": 120,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 100.47,
//                             "raw": "€100,47",
//                             "name": "100,47 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 126.51,
//                             "raw": "€126,51",
//                             "name": "100,47 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 100.47,
//                         "raw": "€100,47",
//                         "name": "100,47 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 60,
//                     "title": "HaWare Besteck Set, 60-teiliges Besteckset für 12 Personen mit Messer Gabel Löffel, Edelstahl Essbesteck für Familie/Party/Hotel/Restaurant, Hochglanzpoliert & Spülmaschinenfest",
//                     "asin": "B08119S8Q2",
//                     "link": "https://www.amazon.de/HaWare-60-teiliges-Besteckset-Hochglanzpoliert-Sp%C3%BClmaschinenfest/dp/B08119S8Q2/ref=sr_1_60?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-60",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61DSHEM0FOL._AC_UL400_.jpg",
//                     "unit_price": "0,85 €/stück",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 618,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.99,
//                             "raw": "€50,99",
//                             "name": "50,99 €",
//                             "asin": "B08119S8Q2",
//                             "link": "https://www.amazon.de/HaWare-60-teiliges-Besteckset-Hochglanzpoliert-Sp%C3%BClmaschinenfest/dp/B08119S8Q2/ref=sr_1_60?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-60"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.99,
//                         "raw": "€50,99",
//                         "name": "50,99 €",
//                         "asin": "B08119S8Q2",
//                         "link": "https://www.amazon.de/HaWare-60-teiliges-Besteckset-Hochglanzpoliert-Sp%C3%BClmaschinenfest/dp/B08119S8Q2/ref=sr_1_60?keywords=K%C3%BCchenutensilien-Set&qid=1684074501&refinements=p_36%3A5000-10000&sr=8-60"
//                     }
//                 }
//             ]
//         },
//         {
//             "idea": "Gourmet-Würzmischungen",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "Wendland Magic Spice Porterhouse, 3 kg Würzmischung, Gastronomie-Qualität für Zuhause",
//                     "asin": "B0938225FP",
//                     "link": "https://www.amazon.de/Wendland-Spice-Porterhouse-W%C3%BCrzmischung-Gastronomie-Qualit%C3%A4t/dp/B0938225FP/ref=sr_1_1?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81KtwJEGP7S._AC_UL400_.jpg",
//                     "unit_price": "17,53 €/kg",
//                     "rating": 5,
//                     "ratings_total": 12,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 52.6,
//                             "raw": "€52,60",
//                             "name": "52,60 €",
//                             "asin": "B0938225FP",
//                             "link": "https://www.amazon.de/Wendland-Spice-Porterhouse-W%C3%BCrzmischung-Gastronomie-Qualit%C3%A4t/dp/B0938225FP/ref=sr_1_1?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 52.6,
//                         "raw": "€52,60",
//                         "name": "52,60 €",
//                         "asin": "B0938225FP",
//                         "link": "https://www.amazon.de/Wendland-Spice-Porterhouse-W%C3%BCrzmischung-Gastronomie-Qualit%C3%A4t/dp/B0938225FP/ref=sr_1_1?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-1"
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "Hallingers 24er Gewürz-Geschenk-Set, Gewürze aus aller Welt (445g) - Gewürze Deluxe Selektion 24 (Set) - Passt immer 2023, Für Sie#Für Ihn - Geschenk zu Mutter-/Vatertag 2023",
//                     "asin": "B00L2QCI1A",
//                     "link": "https://www.amazon.de/Hallingers-Gew%C3%BCrze-Selektion-Gew%C3%BCrzmischung-Miniglas/dp/B00L2QCI1A/ref=sr_1_2?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-2",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61ALpgLUitL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 5 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "unit_price": "117,62 €/kg",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 587,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 49.99,
//                             "raw": "€49,99",
//                             "name": "49,99 €",
//                             "asin": "B00L2QCI1A",
//                             "link": "https://www.amazon.de/Hallingers-Gew%C3%BCrze-Selektion-Gew%C3%BCrzmischung-Miniglas/dp/B00L2QCI1A/ref=sr_1_2?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-2"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 49.99,
//                         "raw": "€49,99",
//                         "name": "49,99 €",
//                         "asin": "B00L2QCI1A",
//                         "link": "https://www.amazon.de/Hallingers-Gew%C3%BCrze-Selektion-Gew%C3%BCrzmischung-Miniglas/dp/B00L2QCI1A/ref=sr_1_2?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-2"
//                     }
//                 },
//                 {
//                     "position": 3,
//                     "title": "Weber Gourmet Burger and Steak N Chop Seasoning Bundle",
//                     "asin": "B01FVCPBYG",
//                     "link": "https://www.amazon.de/Weber-Gourmet-Burger-Seasoning-Bundle/dp/B01FVCPBYG/ref=sr_1_3?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-3",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71KPs25YgRL._AC_UL400_.jpg",
//                     "unit_price": "39,58 €/stück",
//                     "rating": 4.9,
//                     "ratings_total": 56,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 39.58,
//                             "raw": "€39,58",
//                             "name": "39,58 €",
//                             "asin": "B01FVCPBYG",
//                             "link": "https://www.amazon.de/Weber-Gourmet-Burger-Seasoning-Bundle/dp/B01FVCPBYG/ref=sr_1_3?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-3"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 39.58,
//                         "raw": "€39,58",
//                         "name": "39,58 €",
//                         "asin": "B01FVCPBYG",
//                         "link": "https://www.amazon.de/Weber-Gourmet-Burger-Seasoning-Bundle/dp/B01FVCPBYG/ref=sr_1_3?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-3"
//                     }
//                 },
//                 {
//                     "position": 4,
//                     "title": "Rimoco Ottolenghi Geschenkset \"Simple\" - Set Inhalt: Ottolenghi Simple Das Kochbuch - Gewürze: Sumach 60g, Harissa 55g, Pul Biber 70g, Kardamom 45g, Zatar 50g, Berberitzen 40g",
//                     "asin": "B09D42YN9C",
//                     "link": "https://www.amazon.de/Rimoco-Ottolenghi-Geschenkset-Simple-Berberitzen/dp/B09D42YN9C/ref=sr_1_4?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-4",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81l3rPl-QVL._AC_UL400_.jpg",
//                     "unit_price": "26,88 €/kg",
//                     "is_prime": true,
//                     "rating": 4.9,
//                     "ratings_total": 27,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.9,
//                             "raw": "€69,90",
//                             "name": "69,90 €",
//                             "asin": "B09D42YN9C",
//                             "link": "https://www.amazon.de/Rimoco-Ottolenghi-Geschenkset-Simple-Berberitzen/dp/B09D42YN9C/ref=sr_1_4?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-4"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.9,
//                         "raw": "€69,90",
//                         "name": "69,90 €",
//                         "asin": "B09D42YN9C",
//                         "link": "https://www.amazon.de/Rimoco-Ottolenghi-Geschenkset-Simple-Berberitzen/dp/B09D42YN9C/ref=sr_1_4?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-4"
//                     }
//                 },
//                 {
//                     "position": 5,
//                     "title": "Hallingers Großes Gourmet Gewürze Set mit 10 Gewürzen (1060g) - Best of BBQ (Bundle) - Passt immer 2023, Grillen - Geschenk zu Mutter-/Vatertag 2023",
//                     "asin": "B085CFS4B4",
//                     "link": "https://www.amazon.de/Hallingers-Gro%C3%9Fes-Gourmet-Gew%C3%BCrze-Gew%C3%BCrzen/dp/B085CFS4B4/ref=sr_1_5?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-5",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71nabIoY+FL._AC_UL400_.jpg",
//                     "unit_price": "518,77 €/kg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 6,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "54,99 €",
//                             "asin": "B085CFS4B4",
//                             "link": "https://www.amazon.de/Hallingers-Gro%C3%9Fes-Gourmet-Gew%C3%BCrze-Gew%C3%BCrzen/dp/B085CFS4B4/ref=sr_1_5?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-5"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.99,
//                         "raw": "€54,99",
//                         "name": "54,99 €",
//                         "asin": "B085CFS4B4",
//                         "link": "https://www.amazon.de/Hallingers-Gro%C3%9Fes-Gourmet-Gew%C3%BCrze-Gew%C3%BCrzen/dp/B085CFS4B4/ref=sr_1_5?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-5"
//                     }
//                 },
//                 {
//                     "position": 6,
//                     "title": "Roland Trettl´s 6 Lieblingsgewürze in schöner Holzbox I Gewürze Geschenkset für Mann und Frau I 6 x Reingewürze & Gewürzmischungen in 170 ml Weißblechdosen I LIMITED EDITION",
//                     "asin": "B08NJSYD4Z",
//                     "link": "https://www.amazon.de/Lieblingsgew%C3%BCrze-Geschenkset-Reingew%C3%BCrze-Gew%C3%BCrzmischungen-Wei%C3%9Fblechdosen/dp/B08NJSYD4Z/ref=sr_1_6?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-6",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81x8wqexfBL._AC_UL400_.jpg",
//                     "unit_price": "43,47 €/kg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 40,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.99,
//                             "raw": "€64,99",
//                             "name": "59,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 7,
//                     "title": "Gepp’s Feinkost Deluxe Geschenkbox für den Papa I Geschenkidee zum Vatertag zum Grillen I Vatertagsgeschenk gefüllt mit Saucen-Spezialitäten, Rubs, Grissini, Fleischzartmacher und Burgerpresse",
//                     "asin": "B08BRZL36C",
//                     "link": "https://www.amazon.de/Gepps-Geschenkidee-Grill-Liebhaber-Saucen-Spezialit%C3%A4ten-Fleischzartmacher/dp/B08BRZL36C/ref=sr_1_7?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-7",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91lxLpRD58L._AC_UL400_.jpg",
//                     "unit_price": "64,99 €/stück",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 25,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.99,
//                             "raw": "€64,99",
//                             "name": "64,99 €",
//                             "asin": "B08BRZL36C",
//                             "link": "https://www.amazon.de/Gepps-Geschenkidee-Grill-Liebhaber-Saucen-Spezialit%C3%A4ten-Fleischzartmacher/dp/B08BRZL36C/ref=sr_1_7?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-7"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.99,
//                         "raw": "€64,99",
//                         "name": "64,99 €",
//                         "asin": "B08BRZL36C",
//                         "link": "https://www.amazon.de/Gepps-Geschenkidee-Grill-Liebhaber-Saucen-Spezialit%C3%A4ten-Fleischzartmacher/dp/B08BRZL36C/ref=sr_1_7?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-7"
//                     }
//                 },
//                 {
//                     "position": 8,
//                     "title": "Hallingers Bundle 24er Gewürz-Adventskalender & Genuss-Kochbuch (425g) - Gewürze Adventskalender & Happiness is handmade (Set) - Weihnachten 2023, Adventskalender - Geschenk zu Mutter-/Vatertag 2023",
//                     "asin": "B012CEMLTO",
//                     "link": "https://www.amazon.de/Hallingers-24er-Gew%C3%BCrz-Adventskalender-Gew%C3%BCrzen-aller/dp/B012CEMLTO/ref=sr_1_8?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-8",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71rq2aUVMML._AC_UL400_.jpg",
//                     "unit_price": "74,99 €/stück",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 361,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.99,
//                             "raw": "€74,99",
//                             "name": "74,99 €",
//                             "asin": "B012CEMLTO",
//                             "link": "https://www.amazon.de/Hallingers-24er-Gew%C3%BCrz-Adventskalender-Gew%C3%BCrzen-aller/dp/B012CEMLTO/ref=sr_1_8?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-8"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 74.99,
//                         "raw": "€74,99",
//                         "name": "74,99 €",
//                         "asin": "B012CEMLTO",
//                         "link": "https://www.amazon.de/Hallingers-24er-Gew%C3%BCrz-Adventskalender-Gew%C3%BCrzen-aller/dp/B012CEMLTO/ref=sr_1_8?keywords=Gourmet-W%C3%BCrzmischungen&qid=1684074520&refinements=p_36%3A5000-10000&sr=8-8"
//                     }
//                 }
//             ]
//         },
//         {
//             "idea": "Gourmet-Salzmühle",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "Moderne Salzmühle und Pfeffermühle, mit Druckknopf",
//                     "asin": "B005OQXCF0",
//                     "link": "https://www.amazon.de/Schleifen-Action-Salz-Pfefferm%C3%BChle-Grind-Gourmet/dp/B005OQXCF0/ref=sr_1_1?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/31aCrzYIxxL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 104,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.96,
//                             "raw": "€59,96",
//                             "name": "59,96 €",
//                             "asin": "B005OQXCF0",
//                             "link": "https://www.amazon.de/Schleifen-Action-Salz-Pfefferm%C3%BChle-Grind-Gourmet/dp/B005OQXCF0/ref=sr_1_1?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.96,
//                         "raw": "€59,96",
//                         "name": "59,96 €",
//                         "asin": "B005OQXCF0",
//                         "link": "https://www.amazon.de/Schleifen-Action-Salz-Pfefferm%C3%BChle-Grind-Gourmet/dp/B005OQXCF0/ref=sr_1_1?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-1"
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "Pfeffermühle Manuelle Gewürzmühle Maschinenset Salz- und Pfeffermühle Edelstahl 304 und Borosilikatglas Gittergitter Gourmet-Gewürzflasche Grob und fein einstellbare Salzmühle",
//                     "asin": "B0C2TG7J9V",
//                     "link": "https://www.amazon.de/Pfefferm%C3%BChle-Maschinenset-Borosilikatglas-Gittergitter-Gourmet-Gew%C3%BCrzflasche/dp/B0C2TG7J9V/ref=sr_1_2?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-2",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61EyE7j3nbL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.12,
//                             "raw": "€54,12",
//                             "asin": "B0C2TG7J9V",
//                             "link": "https://www.amazon.de/Pfefferm%C3%BChle-Maschinenset-Borosilikatglas-Gittergitter-Gourmet-Gew%C3%BCrzflasche/dp/B0C2TG7J9V/ref=sr_1_2?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-2"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.12,
//                         "raw": "€54,12",
//                         "asin": "B0C2TG7J9V",
//                         "link": "https://www.amazon.de/Pfefferm%C3%BChle-Maschinenset-Borosilikatglas-Gittergitter-Gourmet-Gew%C3%BCrzflasche/dp/B0C2TG7J9V/ref=sr_1_2?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-2"
//                     }
//                 },
//                 {
//                     "position": 3,
//                     "title": "Gewürzmühlen Hölzerne Salz- und Pfefferschleifer Holzmühlen Gourmet-Präzisionsmechanismen und erstklassige Meersalz-Pfefferkörner Salzmühlen",
//                     "asin": "B09WH1W1DF",
//                     "link": "https://www.amazon.de/WMDSYGD-Gew%C3%BCrzm%C3%BChlen-Pfefferschleifer-Gourmet-Pr%C3%A4zisionsmechanismen-Meersalz-Pfefferk%C3%B6rner/dp/B09WH1W1DF/ref=sr_1_3?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-3",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71hD2aUdLwL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 81.04,
//                             "raw": "€81,04",
//                             "asin": "B09WH1W1DF",
//                             "link": "https://www.amazon.de/WMDSYGD-Gew%C3%BCrzm%C3%BChlen-Pfefferschleifer-Gourmet-Pr%C3%A4zisionsmechanismen-Meersalz-Pfefferk%C3%B6rner/dp/B09WH1W1DF/ref=sr_1_3?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-3"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 81.04,
//                         "raw": "€81,04",
//                         "asin": "B09WH1W1DF",
//                         "link": "https://www.amazon.de/WMDSYGD-Gew%C3%BCrzm%C3%BChlen-Pfefferschleifer-Gourmet-Pr%C3%A4zisionsmechanismen-Meersalz-Pfefferk%C3%B6rner/dp/B09WH1W1DF/ref=sr_1_3?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-3"
//                     }
//                 },
//                 {
//                     "position": 4,
//                     "title": "Cole & Mason H12302G Keswick Wald Dunkles Holz Salzmühle, Holz / Acryl, 180mm, Gourmet Precision+, Enthält 1 x Salzmühle",
//                     "asin": "B003I86NZ2",
//                     "link": "https://www.amazon.de/Cole-Mason-H12302G-Salzm%C3%BChle-edelstahl/dp/B003I86NZ2/ref=sr_1_4?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-4",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61YNx8cKs6L._AC_UL400_.jpg",
//                     "rating": 3.9,
//                     "ratings_total": 15
//                 },
//                 {
//                     "position": 5,
//                     "title": "Elektrische Pfeffermühle Oder Salzmühle Spice Tall Power Vibrator Einstellbare Keramik Groby Gourmet Präzisionsmechanik Und Hochwertiges Meersalz Und Pfeffer Gewürzmühle Chili Mühle",
//                     "asin": "B09CM3MG3Z",
//                     "link": "https://www.amazon.de/Elektrische-Pfefferm%C3%BChle-Einstellbare-Pr%C3%A4zisionsmechanik-Hochwertiges/dp/B09CM3MG3Z/ref=sr_1_5?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-5",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/5192TiIoeoL._AC_UL400_.jpg"
//                 },
//                 {
//                     "position": 6,
//                     "title": "PEUGEOT - Salzmühle Line 18 cm + Steinsalz - Klassische Mahlgradeinstellung - Aluminium und recyceltes Holz mit PEFC-Siegel - Naturfarben",
//                     "asin": "B0BCXCKQML",
//                     "link": "https://www.amazon.de/PEUGEOT-Klassische-Mahlgradeinstellung-PEFC-Siegel-Naturfarben/dp/B0BCXCKQML/ref=sr_1_6?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-6",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/4110AHmk0UL._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 42.46,
//                             "raw": "€42,46",
//                             "name": "42,46 €",
//                             "asin": "B0BCXCKQML",
//                             "link": "https://www.amazon.de/PEUGEOT-Klassische-Mahlgradeinstellung-PEFC-Siegel-Naturfarben/dp/B0BCXCKQML/ref=sr_1_6?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-6"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 42.46,
//                         "raw": "€42,46",
//                         "name": "42,46 €",
//                         "asin": "B0BCXCKQML",
//                         "link": "https://www.amazon.de/PEUGEOT-Klassische-Mahlgradeinstellung-PEFC-Siegel-Naturfarben/dp/B0BCXCKQML/ref=sr_1_6?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-6"
//                     }
//                 },
//                 {
//                     "position": 7,
//                     "title": "Pump & Grind Salz oder Pfeffermühle, Edelstahl von Grind Gourmet",
//                     "asin": "B00B8BFQ40",
//                     "link": "https://www.amazon.de/Pump-Grind-Pfefferm%C3%BChle-Edelstahl-Gourmet/dp/B00B8BFQ40/ref=sr_1_7?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-7",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81i-+DPFEoL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 131,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 57.12,
//                             "raw": "€57,12",
//                             "name": "57,12 €",
//                             "asin": "B00B8BFQ40",
//                             "link": "https://www.amazon.de/Pump-Grind-Pfefferm%C3%BChle-Edelstahl-Gourmet/dp/B00B8BFQ40/ref=sr_1_7?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-7"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 57.12,
//                         "raw": "€57,12",
//                         "name": "57,12 €",
//                         "asin": "B00B8BFQ40",
//                         "link": "https://www.amazon.de/Pump-Grind-Pfefferm%C3%BChle-Edelstahl-Gourmet/dp/B00B8BFQ40/ref=sr_1_7?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-7"
//                     }
//                 },
//                 {
//                     "position": 8,
//                     "title": "PEUGEOT - Geschenkset Line - Pfeffermühle + Salzmühle 12 cm + Gewürztrichter - Klassische Mahlgradeinstellung - Aluminium + PEFC-zertifiziertes Holz - Karbonfarben",
//                     "asin": "B0BF11FMZ9",
//                     "link": "https://www.amazon.de/PEUGEOT-Pfefferm%C3%BChle-Gew%C3%BCrztrichter-Mahlgradeinstellung-PEFC-zertifiziertes/dp/B0BF11FMZ9/ref=sr_1_8?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-8",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61rYpkaUYgL._AC_UL400_.jpg",
//                     "rating": 3.6,
//                     "ratings_total": 3,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 85.85,
//                             "raw": "€85,85",
//                             "name": "85,85 €",
//                             "asin": "B0BF11FMZ9",
//                             "link": "https://www.amazon.de/PEUGEOT-Pfefferm%C3%BChle-Gew%C3%BCrztrichter-Mahlgradeinstellung-PEFC-zertifiziertes/dp/B0BF11FMZ9/ref=sr_1_8?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-8"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 85.85,
//                         "raw": "€85,85",
//                         "name": "85,85 €",
//                         "asin": "B0BF11FMZ9",
//                         "link": "https://www.amazon.de/PEUGEOT-Pfefferm%C3%BChle-Gew%C3%BCrztrichter-Mahlgradeinstellung-PEFC-zertifiziertes/dp/B0BF11FMZ9/ref=sr_1_8?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-8"
//                     }
//                 },
//                 {
//                     "position": 9,
//                     "title": "Cole & Mason H59428G Derwent Gunmetal Grau Salz und Pfeffer Mühle, 2er Set Manuell, Edelstahl/Acryl, 190mm, Precision+, Geschenkset",
//                     "asin": "B07DFSRTMS",
//                     "link": "https://www.amazon.de/Cole-Mason-H59428G-Geschenkset-Gunmetal/dp/B07DFSRTMS/ref=sr_1_9?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-9",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71dn3tWWQ6L._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 866,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 68.2,
//                             "raw": "€68,20",
//                             "name": "68,20 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.95,
//                             "raw": "€89,95",
//                             "name": "68,20 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 68.2,
//                         "raw": "€68,20",
//                         "name": "68,20 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 10,
//                     "title": "AdHoc EP62 Elektrische Pfeffer- | Salzmühle eMill.5, CeraCut® Keramik Mahlwerk, inkl. Batterien, Akazienholz|Edelstahl schwarz",
//                     "asin": "B09TDX49N8",
//                     "link": "https://www.amazon.de/AdHoc-Elektrische-Salzm%C3%BChle-Batterien-Akazienholz/dp/B09TDX49N8/ref=sr_1_10?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-10",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71UMymF1WwL._AC_UL400_.jpg",
//                     "rating": 3.9,
//                     "ratings_total": 3,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.9,
//                             "raw": "€64,90",
//                             "name": "64,90 €",
//                             "asin": "B09TDX49N8",
//                             "link": "https://www.amazon.de/AdHoc-Elektrische-Salzm%C3%BChle-Batterien-Akazienholz/dp/B09TDX49N8/ref=sr_1_10?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-10"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.9,
//                         "raw": "€64,90",
//                         "name": "64,90 €",
//                         "asin": "B09TDX49N8",
//                         "link": "https://www.amazon.de/AdHoc-Elektrische-Salzm%C3%BChle-Batterien-Akazienholz/dp/B09TDX49N8/ref=sr_1_10?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-10"
//                     }
//                 },
//                 {
//                     "position": 11,
//                     "title": "PEUGEOT - Geschenkset Paris Graphite - Pfeffermühle + Salzmühle 18 cm + Gewürztrichter - u‘Select-System - PEFC-zertifiziertes Holz - Graphit",
//                     "asin": "B0BF12TD32",
//                     "link": "https://www.amazon.de/PEUGEOT-Pfefferm%C3%BChle-Gew%C3%BCrztrichter-uSelect-System-PEFC-zertifiziertes/dp/B0BF12TD32/ref=sr_1_11?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-11",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51WDK5h1VUL._AC_UL400_.jpg",
//                     "rating": 1,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 86.2,
//                             "raw": "€86,20",
//                             "name": "86,20 €",
//                             "asin": "B0BF12TD32",
//                             "link": "https://www.amazon.de/PEUGEOT-Pfefferm%C3%BChle-Gew%C3%BCrztrichter-uSelect-System-PEFC-zertifiziertes/dp/B0BF12TD32/ref=sr_1_11?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-11"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 86.2,
//                         "raw": "€86,20",
//                         "name": "86,20 €",
//                         "asin": "B0BF12TD32",
//                         "link": "https://www.amazon.de/PEUGEOT-Pfefferm%C3%BChle-Gew%C3%BCrztrichter-uSelect-System-PEFC-zertifiziertes/dp/B0BF12TD32/ref=sr_1_11?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-11"
//                     }
//                 },
//                 {
//                     "position": 12,
//                     "title": "AdHoc MP46 Set Pfeffermühle und Salzmühle TEXTURA, Keramik Mahlwerk CeraCut®, Eschenholz/Edelstahl",
//                     "asin": "B015Q1KNFY",
//                     "link": "https://www.amazon.de/AdHoc-Gew%C3%BCrzm%C3%BChle-Edelstahl-Braun-15cm/dp/B015Q1KNFY/ref=sr_1_12?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-12",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/813HwWvQ6NL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 164,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 38.99,
//                             "raw": "€38,99",
//                             "name": "38,99 €",
//                             "asin": "B015Q1KNFY",
//                             "link": "https://www.amazon.de/AdHoc-Gew%C3%BCrzm%C3%BChle-Edelstahl-Braun-15cm/dp/B015Q1KNFY/ref=sr_1_12?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-12"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 38.99,
//                         "raw": "€38,99",
//                         "name": "38,99 €",
//                         "asin": "B015Q1KNFY",
//                         "link": "https://www.amazon.de/AdHoc-Gew%C3%BCrzm%C3%BChle-Edelstahl-Braun-15cm/dp/B015Q1KNFY/ref=sr_1_12?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-12"
//                     }
//                 },
//                 {
//                     "position": 13,
//                     "title": "AdHoc MP47 Set Pfeffermühle und Salzmühle TEXTURA GRANDE, Keramik Mahlwerk CeraCut®, Eschenholz/Edelstahl",
//                     "asin": "B06VWCK1G3",
//                     "link": "https://www.amazon.de/AdHoc-Pfeffer-Salzm%C3%BChle-sandgestrahlt-Edelstahl/dp/B06VWCK1G3/ref=sr_1_13?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-13",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81u1GCN+0vS._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 18,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 53.63,
//                             "raw": "€53,63",
//                             "name": "53,63 €",
//                             "asin": "B06VWCK1G3",
//                             "link": "https://www.amazon.de/AdHoc-Pfeffer-Salzm%C3%BChle-sandgestrahlt-Edelstahl/dp/B06VWCK1G3/ref=sr_1_13?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-13"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 53.63,
//                         "raw": "€53,63",
//                         "name": "53,63 €",
//                         "asin": "B06VWCK1G3",
//                         "link": "https://www.amazon.de/AdHoc-Pfeffer-Salzm%C3%BChle-sandgestrahlt-Edelstahl/dp/B06VWCK1G3/ref=sr_1_13?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-13"
//                     }
//                 },
//                 {
//                     "position": 14,
//                     "title": "PEUGEOT - Salzmühle Paris u‘Select 27 cm + Steinsalz - 6 voreingestellte Mahlgrade - Aus PEFC-zertifiziertem Holz - Graphit",
//                     "asin": "B0BDZZGQVL",
//                     "link": "https://www.amazon.de/PEUGEOT-Salzm%C3%BChle-Steinsalz-voreingestellte-PEFC-zertifiziertem/dp/B0BDZZGQVL/ref=sr_1_14?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-14",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41JRemX5b-L._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 52.9,
//                             "raw": "€52,90",
//                             "name": "Primary",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.4,
//                             "raw": "€59,40",
//                             "name": "Original",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 52.9,
//                         "raw": "€52,90",
//                         "name": "Primary",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 15,
//                     "title": "AdHoc MP711 Pfeffer- | Salzmühle Yono, CeraCut® Keramik Mahlwerk, Akazienholz|Edelstahl",
//                     "asin": "B09TDZ6CS8",
//                     "link": "https://www.amazon.de/AdHoc-Pfeffer-Salzm%C3%BChle-Akazienholz-Edelstahl/dp/B09TDZ6CS8/ref=sr_1_15?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-15",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81AmGN+4s9L._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 47.94,
//                             "raw": "€47,94",
//                             "name": "47,94 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 49.9,
//                             "raw": "€49,90",
//                             "name": "47,94 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 47.94,
//                         "raw": "€47,94",
//                         "name": "47,94 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 16,
//                     "title": "PEUGEOT- 100% natürliches Set Paris Nature- Pfeffermühle + Salzmühle 18 cm + Schwarzer Pfeffer- Klassische Mahlgradeinstellung- Recyceltes Holz mit PEFC-Siegel - Naturfarben",
//                     "asin": "B0BF11V79H",
//                     "link": "https://www.amazon.de/nat%C3%BCrliches-Pfefferm%C3%BChle-Klassische-Mahlgradeinstellung-PEFC-Siegel/dp/B0BF11V79H/ref=sr_1_16?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-16",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61gofd5EY9L._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 61.88,
//                             "raw": "€61,88",
//                             "name": "61,88 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.9,
//                             "raw": "€69,90",
//                             "name": "61,88 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 61.88,
//                         "raw": "€61,88",
//                         "name": "61,88 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 17,
//                     "title": "WMF Elektrische Mühle Ceramill Eichenholz Cromargan Edelstahl rostfrei spülmaschinengeeignet & Schüssel-Set Gourmet für die Küche 4-teilig Edelstahl Cromargan Multifunktional",
//                     "asin": "B0BKXNGKRG",
//                     "link": "https://www.amazon.de/WMF-Elektrische-sp%C3%BClmaschinengeeignet-Sch%C3%BCssel-Set-Multifunktional/dp/B0BKXNGKRG/ref=sr_1_17?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-17",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41mT1nP7cHL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 621,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 93.43,
//                             "raw": "€93,43",
//                             "name": "93,43 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 132.98,
//                             "raw": "€132,98",
//                             "name": "93,43 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 93.43,
//                         "raw": "€93,43",
//                         "name": "93,43 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 18,
//                     "title": "AdHoc EP04 Set Elektrische Pfeffer- und Salzmühle PEPMATIK, LED Licht (ohne Gewürz), Keramik Mahlwerk",
//                     "asin": "B0002L2Z1Y",
//                     "link": "https://www.amazon.de/AdHoc-Salz-Pfefferm%C3%BChle-automatisch-Edelstahl/dp/B0002L2Z1Y/ref=sr_1_18?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-18",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71D8l6sUGSS._AC_UL400_.jpg",
//                     "rating": 3.9,
//                     "ratings_total": 11,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 97.3,
//                             "raw": "€97,30",
//                             "name": "97,30 €",
//                             "asin": "B0002L2Z1Y",
//                             "link": "https://www.amazon.de/AdHoc-Salz-Pfefferm%C3%BChle-automatisch-Edelstahl/dp/B0002L2Z1Y/ref=sr_1_18?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-18"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 97.3,
//                         "raw": "€97,30",
//                         "name": "97,30 €",
//                         "asin": "B0002L2Z1Y",
//                         "link": "https://www.amazon.de/AdHoc-Salz-Pfefferm%C3%BChle-automatisch-Edelstahl/dp/B0002L2Z1Y/ref=sr_1_18?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-18"
//                     }
//                 },
//                 {
//                     "position": 19,
//                     "title": "Grind Gourmet Salz- und Pfeffermühle 2er Set mit modernem Daumen-Druck-Knopf-Mahlwerk, Premium Edelstahl, für schwarzen Pfeffer, Meersalz und Himalaya-Salz, mit Ständer\"",
//                     "asin": "B005G2PVOC",
//                     "link": "https://www.amazon.de/Grind-Gourmet-Original-Edelstahl-Grinder/dp/B005G2PVOC/ref=sr_1_19?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-19",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71ACiB+o1SL._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 6,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 63.76,
//                             "raw": "€63,76",
//                             "name": "63,76 €",
//                             "asin": "B005G2PVOC",
//                             "link": "https://www.amazon.de/Grind-Gourmet-Original-Edelstahl-Grinder/dp/B005G2PVOC/ref=sr_1_19?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-19"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 63.76,
//                         "raw": "€63,76",
//                         "name": "63,76 €",
//                         "asin": "B005G2PVOC",
//                         "link": "https://www.amazon.de/Grind-Gourmet-Original-Edelstahl-Grinder/dp/B005G2PVOC/ref=sr_1_19?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-19"
//                     }
//                 },
//                 {
//                     "position": 20,
//                     "title": "Cole & Mason H331935 Lyndhurst Salz und Pfeffermühle, 2er Set Manuell, Kastanie / Roségold, Esche / Edelstahl, 185mm, Gourmet Precision+, Geschenkset",
//                     "asin": "B07TVGT7DD",
//                     "link": "https://www.amazon.de/Cole-Mason-CH331935-Lyndhurst-Pfefferm%C3%BChle/dp/B07TVGT7DD/ref=sr_1_20?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-20",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/819PvrGgHtL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 30,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.34,
//                             "raw": "€51,34",
//                             "name": "51,34 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.84,
//                             "raw": "€56,84",
//                             "name": "51,34 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.34,
//                         "raw": "€51,34",
//                         "name": "51,34 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 21,
//                     "title": "AdHoc MP824 Pfeffer- | Salzmühle Ikon, CeraCut® Keramik Mahlwerk, Akazienholz|Edelstahl",
//                     "asin": "B09TDZ7LLY",
//                     "link": "https://www.amazon.de/AdHoc-Pfeffer-Salzm%C3%BChle-Akazienholz-Edelstahl/dp/B09TDZ7LLY/ref=sr_1_21?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-21",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/714uvyoasuL._AC_UL400_.jpg",
//                     "rating": 4,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.9,
//                             "raw": "€99,90",
//                             "name": "99,90 €",
//                             "asin": "B09TDZ7LLY",
//                             "link": "https://www.amazon.de/AdHoc-Pfeffer-Salzm%C3%BChle-Akazienholz-Edelstahl/dp/B09TDZ7LLY/ref=sr_1_21?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-21"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99.9,
//                         "raw": "€99,90",
//                         "name": "99,90 €",
//                         "asin": "B09TDZ7LLY",
//                         "link": "https://www.amazon.de/AdHoc-Pfeffer-Salzm%C3%BChle-Akazienholz-Edelstahl/dp/B09TDZ7LLY/ref=sr_1_21?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-21"
//                     }
//                 },
//                 {
//                     "position": 22,
//                     "title": "PEUGEOT - Salzmühle Paris u‘Select 22 cm + Steinsalz - 6 voreingestellte Mahlgrade - Aus PEFC-zertifiziertem Holz - Satinschwarz",
//                     "asin": "B0BF12NGTH",
//                     "link": "https://www.amazon.de/PEUGEOT-Salzm%C3%BChle-voreingestellte-PEFC-zertifiziertem-Satinschwarz/dp/B0BF12NGTH/ref=sr_1_22?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-22",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41fu1NKONjL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 47.14,
//                             "raw": "€47,14",
//                             "name": "Primary",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.9,
//                             "raw": "€54,90",
//                             "name": "Original",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 47.14,
//                         "raw": "€47,14",
//                         "name": "Primary",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 23,
//                     "title": "Derwent Geschenkset Kupfer & Acryl Mühlen Tablett, Clear, 17 x 11 x 24,5 cm",
//                     "asin": "B08MNVW9KV",
//                     "link": "https://www.amazon.de/Derwent-Geschenkset-Kupfer-M%C3%BChlen-Tablett/dp/B08MNVW9KV/ref=sr_1_23?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-23",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41Iy8pH-n8L._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 87.05,
//                             "raw": "€87,05",
//                             "name": "87,05 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 103.9,
//                             "raw": "€103,90",
//                             "name": "87,05 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 87.05,
//                         "raw": "€87,05",
//                         "name": "87,05 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 24,
//                     "title": "PEUGEOT - Salzmühle Paris u‘Select 18 cm + Steinsalz - 6 voreingestellte Mahlgrade - Aus PEFC-zertifiziertem Holz - Satinschwarz",
//                     "asin": "B0BF1567VT",
//                     "link": "https://www.amazon.de/PEUGEOT-Salzm%C3%BChle-voreingestellte-PEFC-zertifiziertem-Satinschwarz/dp/B0BF1567VT/ref=sr_1_24?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-24",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41ExLs4UtWL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.42,
//                             "raw": "€50,42",
//                             "asin": "B0BF1567VT",
//                             "link": "https://www.amazon.de/PEUGEOT-Salzm%C3%BChle-voreingestellte-PEFC-zertifiziertem-Satinschwarz/dp/B0BF1567VT/ref=sr_1_24?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-24"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.42,
//                         "raw": "€50,42",
//                         "asin": "B0BF1567VT",
//                         "link": "https://www.amazon.de/PEUGEOT-Salzm%C3%BChle-voreingestellte-PEFC-zertifiziertem-Satinschwarz/dp/B0BF1567VT/ref=sr_1_24?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-24"
//                     }
//                 },
//                 {
//                     "position": 25,
//                     "title": "PEUGEOT - Geschenkset Bali Palais des Épices - Pfeffermühle 8 cm aus Gusseisen + Salzbehälter aus Gusseisen + Bambustablett - Klassische Mahlgradeinstellung - Schwarz/Naturfarben",
//                     "asin": "B0B2WXXDHV",
//                     "link": "https://www.amazon.de/Peugeot-Bali-Pfefferm%C3%BChle-Mehrfarbig-Einheitsgr%C3%B6%C3%9Fe/dp/B0B2WXXDHV/ref=sr_1_25?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-25",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61CaUAauywL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 956
//                 },
//                 {
//                     "position": 26,
//                     "title": "WMF Ceramill Nature Salz und Pfeffermühle-Set 2-teilig, 17,9 cm, unbefüllt, Holz Keramikmahlwerk, Mühle für Salz, Pfeffer, Chillischoten & Gourmet Schüsselset 4-teilig, Edelstahl Schüsseln",
//                     "asin": "B0B5DMCVR8",
//                     "link": "https://www.amazon.de/WMF-Pfefferm%C3%BChle-Set-Keramikmahlwerk-Chillischoten-Sch%C3%BCsselset/dp/B0B5DMCVR8/ref=sr_1_26?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-26",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51iesyFdeRL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 266,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.98,
//                             "raw": "€79,98",
//                             "name": "79,98 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 88.93,
//                             "raw": "€88,93",
//                             "name": "79,98 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.98,
//                         "raw": "€79,98",
//                         "name": "79,98 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 27,
//                     "title": "BUTLERBELL® Salz und Pfeffermühle Holz inkl. Untersetzer in Luxus Geschenkbox ideal als Hochzeitsgeschenk | Geschenke zur Hochzeit | Hochzeitsgeschenke für Brautpaar | Einweihungsgeschenk",
//                     "asin": "B09VJZXVFN",
//                     "link": "https://www.amazon.de/BUTLERBELL%C2%AE-Pfefferm%C3%BChle-Hochzeitsgeschenk-Hochzeitsgeschenke-Einweihungsgeschenk/dp/B09VJZXVFN/ref=sr_1_27?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-27",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/715-ohbRuDL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 71,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "asin": "B09VJZXVFN",
//                             "link": "https://www.amazon.de/BUTLERBELL%C2%AE-Pfefferm%C3%BChle-Hochzeitsgeschenk-Hochzeitsgeschenke-Einweihungsgeschenk/dp/B09VJZXVFN/ref=sr_1_27?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-27"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "asin": "B09VJZXVFN",
//                         "link": "https://www.amazon.de/BUTLERBELL%C2%AE-Pfefferm%C3%BChle-Hochzeitsgeschenk-Hochzeitsgeschenke-Einweihungsgeschenk/dp/B09VJZXVFN/ref=sr_1_27?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-27"
//                     }
//                 },
//                 {
//                     "position": 28,
//                     "title": "Cole & Mason H321806 City Salz und Pfeffermühle, 2er Set Manuell, Beton / Acryl, 160mm, Precision+, Geschenkset",
//                     "asin": "B07MXVSQ47",
//                     "link": "https://www.amazon.de/Cole-Mason-H321806-City-Betonm%C3%BChlen/dp/B07MXVSQ47/ref=sr_1_28?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-28",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81uhmgJnt+L._AC_UL400_.jpg",
//                     "rating": 4.8,
//                     "ratings_total": 46,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.85,
//                             "raw": "€54,85",
//                             "name": "54,85 €",
//                             "asin": "B07MXVSQ47",
//                             "link": "https://www.amazon.de/Cole-Mason-H321806-City-Betonm%C3%BChlen/dp/B07MXVSQ47/ref=sr_1_28?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-28"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.85,
//                         "raw": "€54,85",
//                         "name": "54,85 €",
//                         "asin": "B07MXVSQ47",
//                         "link": "https://www.amazon.de/Cole-Mason-H321806-City-Betonm%C3%BChlen/dp/B07MXVSQ47/ref=sr_1_28?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-28"
//                     }
//                 },
//                 {
//                     "position": 29,
//                     "title": "Zwilling Table Salz und Pfeffermühle elektrisch 2er Set, mit Deckel, Gewürzmühle Edelstahl gebürstet, elektrische Salzmühle Keramikmahlwerk",
//                     "asin": "B0B3JWHT7V",
//                     "link": "https://www.amazon.de/Zwilling-Table-Pfefferm%C3%BChle-Gew%C3%BCrzm%C3%BChle-Keramikmahlwerk/dp/B0B3JWHT7V/ref=sr_1_29?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-29",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61zdnTPk6TL._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 134
//                 },
//                 {
//                     "position": 30,
//                     "title": "WMF Ceramill Nature Salz und Pfeffer Mühle Set 2-teilig unbefüllt, Keramikmahlwerk & Gourmet Schüsselset 4-teilig Edelstahl Schüsseln für die Küche 0,75l - 2,75l, Cromargan, stapelbar",
//                     "asin": "B09LDC42R5",
//                     "link": "https://www.amazon.de/WMF-unbef%C3%BCllt-Keramikmahlwerk-Sch%C3%BCsselset-Edelstahl/dp/B09LDC42R5/ref=sr_1_30?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-30",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/512jmBRpqOL._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 3,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.98,
//                             "raw": "€79,98",
//                             "name": "79,98 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 122.98,
//                             "raw": "€122,98",
//                             "name": "79,98 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.98,
//                         "raw": "€79,98",
//                         "name": "79,98 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 31,
//                     "title": "Fletchers' Mill Border Grill Pfeffermühle Salzmühle 8-Inch Cinnabar",
//                     "asin": "B00E8AERES",
//                     "link": "https://www.amazon.de/Fletchers-Bord%C3%BCre-Pfefferm%C3%BChle-Salzm%C3%BChle-Cinnabar/dp/B00E8AERES/ref=sr_1_31?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-31",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/31jyDqN8HiL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 56.6,
//                             "raw": "€56,60",
//                             "asin": "B00E8AERES",
//                             "link": "https://www.amazon.de/Fletchers-Bord%C3%BCre-Pfefferm%C3%BChle-Salzm%C3%BChle-Cinnabar/dp/B00E8AERES/ref=sr_1_31?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-31"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 56.6,
//                         "raw": "€56,60",
//                         "asin": "B00E8AERES",
//                         "link": "https://www.amazon.de/Fletchers-Bord%C3%BCre-Pfefferm%C3%BChle-Salzm%C3%BChle-Cinnabar/dp/B00E8AERES/ref=sr_1_31?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-31"
//                     }
//                 },
//                 {
//                     "position": 32,
//                     "title": "PEUGEOT - Salzmühle Paris Nature 30 cm + Alpensalz - Klassische Mahlgradeinstellung - Aus recyceltem Holz mit PEFC-Siegel - Naturfarben",
//                     "asin": "B08BX54SF8",
//                     "link": "https://www.amazon.de/Peugeot-Saveurs-Nature-Salzm%C3%BChle-Gew%C3%BCrzm%C3%BChle/dp/B08BX54SF8/ref=sr_1_32?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-32",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51yCamDJ+2L._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 62.9,
//                             "raw": "€62,90",
//                             "asin": "B08BX54SF8",
//                             "link": "https://www.amazon.de/Peugeot-Saveurs-Nature-Salzm%C3%BChle-Gew%C3%BCrzm%C3%BChle/dp/B08BX54SF8/ref=sr_1_32?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-32"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 62.9,
//                         "raw": "€62,90",
//                         "asin": "B08BX54SF8",
//                         "link": "https://www.amazon.de/Peugeot-Saveurs-Nature-Salzm%C3%BChle-Gew%C3%BCrzm%C3%BChle/dp/B08BX54SF8/ref=sr_1_32?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-32"
//                     }
//                 },
//                 {
//                     "position": 33,
//                     "title": "PepperMate Traditionelle Pfeffermühle 723 – Schließer Hohes Volumen Salz und Gourmet Peppercorn Grinder (schwarz)",
//                     "asin": "B00LEQLB8Y",
//                     "link": "https://www.amazon.de/PepperMate-Traditionelle-Pfefferm%C3%BChle-723-Peppercorn/dp/B00LEQLB8Y/ref=sr_1_33?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-33",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/618Cw04uwNL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 1649
//                 },
//                 {
//                     "position": 34,
//                     "title": "REDCVBN Pfeffermühle und Salzmühlen Shaker-Set, Pfeffermühle Salz- und Pfeffermühlen-Set Gourmet-Präzisionsmechanismen und Premium-Meersalz, einfach zu bedienende Füllung (Farbe: Schwarz)",
//                     "asin": "B09PKL4449",
//                     "link": "https://www.amazon.de/REDCVBN-Pfefferm%C3%BChle-Pfefferm%C3%BChlen-Set-Gourmet-Pr%C3%A4zisionsmechanismen-Premium-Meersalz/dp/B09PKL4449/ref=sr_1_34?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-34",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51PhDhQisMS._AC_UL400_.jpg"
//                 },
//                 {
//                     "position": 35,
//                     "title": "Starfisher Mühle oder Salzmühle Spice Tall Power Shaker Einstellbare Keramik-Grobheit Gourmet-Präzisionsmechanismen und Premium-Meersalz und Pfefferkörner",
//                     "asin": "B09ZKWZRPD",
//                     "link": "https://www.amazon.de/Starfisher-Keramik-Grobheit-Gourmet-Pr%C3%A4zisionsmechanismen-Premium-Meersalz-Pfefferk%C3%B6rner/dp/B09ZKWZRPD/ref=sr_1_35?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-35",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61MFfMwt3NL._AC_UL400_.jpg"
//                 },
//                 {
//                     "position": 36,
//                     "title": "Willow & Everett Salz und Pfeffermühle aus Edelstahl - 2 große manuelle Gewürzmühlen mit verstellbarem Mahlwerk, unbefüllt - Elegantes Set mit Ständer",
//                     "asin": "B01N33U6Y5",
//                     "link": "https://www.amazon.de/Willow-Everett-Pfefferstreuer-passenden-St%C3%A4nder/dp/B01N33U6Y5/ref=sr_1_36?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-36",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81bTOkihLLL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 28354,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 19.99,
//                             "raw": "€19,99",
//                             "name": "19,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 23.99,
//                             "raw": "€23,99",
//                             "name": "19,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 19.99,
//                         "raw": "€19,99",
//                         "name": "19,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 37,
//                     "title": "Starfisher Mühle oder Salzmühle Spice Tall Power Shaker Einstellbare Keramik-Grobheit Gourmet-Präzisionsmechanismen und Premium-Meersalz und Pfefferkörner",
//                     "asin": "B09ZKWPZXD",
//                     "link": "https://www.amazon.de/Starfisher-Keramik-Grobheit-Gourmet-Pr%C3%A4zisionsmechanismen-Premium-Meersalz-Pfefferk%C3%B6rner/dp/B09ZKWPZXD/ref=sr_1_37?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-37",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61JvKeReIoL._AC_UL400_.jpg"
//                 },
//                 {
//                     "position": 38,
//                     "title": "ERZHUI Pfeffermühlen- und Salzmühlen-Schüttler-Set, Pfeffermühlen-Salz- und Pfeffermühlen-Set - Edelstahlmühlen Gourmet-Präzisionsmechanismen und Premium-Meersalz,",
//                     "asin": "B09F8PS9PF",
//                     "link": "https://www.amazon.de/ERZHUI-Pfefferm%C3%BChlen-Salzm%C3%BChlen-Sch%C3%BCttler-Set-Pfefferm%C3%BChlen-Salz-Pfefferm%C3%BChlen-Set/dp/B09F8PS9PF/ref=sr_1_38?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-38",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51mkdLadr0S._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 57.6,
//                             "raw": "€57,60",
//                             "asin": "B09F8PS9PF",
//                             "link": "https://www.amazon.de/ERZHUI-Pfefferm%C3%BChlen-Salzm%C3%BChlen-Sch%C3%BCttler-Set-Pfefferm%C3%BChlen-Salz-Pfefferm%C3%BChlen-Set/dp/B09F8PS9PF/ref=sr_1_38?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-38"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 57.6,
//                         "raw": "€57,60",
//                         "asin": "B09F8PS9PF",
//                         "link": "https://www.amazon.de/ERZHUI-Pfefferm%C3%BChlen-Salzm%C3%BChlen-Sch%C3%BCttler-Set-Pfefferm%C3%BChlen-Salz-Pfefferm%C3%BChlen-Set/dp/B09F8PS9PF/ref=sr_1_38?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-38"
//                     }
//                 },
//                 {
//                     "position": 39,
//                     "title": "PepperMate Traditionelle Pfeffermühle 723 – Schließer Hohes Volumen Salz und Gourmet Peppercorn Grinder (weiß)",
//                     "asin": "B00W4QQS1S",
//                     "link": "https://www.amazon.de/PepperMate-Traditionelle-Pfefferm%C3%BChle-723-Peppercorn/dp/B00W4QQS1S/ref=sr_1_39_mod_primary_new?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-39",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61KX9LI4d6L._AC_UL400_.jpg",
//                     "rating": 4.8,
//                     "ratings_total": 1245,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 82.79,
//                             "raw": "€82,79",
//                             "name": "82,79 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 75.84,
//                             "raw": "€75,84",
//                             "name": "75,84 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 82.79,
//                         "raw": "€82,79",
//                         "name": "82,79 €"
//                     }
//                 },
//                 {
//                     "position": 40,
//                     "title": "ERZHUI Pfeffermühlen- und Salzmühlen-Schüttler-Set, Pfeffermühlen-Salz- und Pfeffermühlen-Set - Edelstahlmühlen Gourmet-Präzisionsmechanismen und Premium-Meersalz,",
//                     "asin": "B09F89Q55Y",
//                     "link": "https://www.amazon.de/ERZHUI-Pfefferm%C3%BChlen-Salzm%C3%BChlen-Sch%C3%BCttler-Set-Pfefferm%C3%BChlen-Salz-Pfefferm%C3%BChlen-Set/dp/B09F89Q55Y/ref=sr_1_40?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-40",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51SBfvTVvdL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 73.91,
//                             "raw": "€73,91",
//                             "asin": "B09F89Q55Y",
//                             "link": "https://www.amazon.de/ERZHUI-Pfefferm%C3%BChlen-Salzm%C3%BChlen-Sch%C3%BCttler-Set-Pfefferm%C3%BChlen-Salz-Pfefferm%C3%BChlen-Set/dp/B09F89Q55Y/ref=sr_1_40?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-40"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 73.91,
//                         "raw": "€73,91",
//                         "asin": "B09F89Q55Y",
//                         "link": "https://www.amazon.de/ERZHUI-Pfefferm%C3%BChlen-Salzm%C3%BChlen-Sch%C3%BCttler-Set-Pfefferm%C3%BChlen-Salz-Pfefferm%C3%BChlen-Set/dp/B09F89Q55Y/ref=sr_1_40?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-40"
//                     }
//                 },
//                 {
//                     "position": 41,
//                     "title": "ERZHUI Pfeffermühle und Salzmühlen Shaker-Set, Pfeffermühle Pfeffermühle, Edelstahlmühle mit Gourmet-Präzisionsmechanismus und Premium-Pfefferkörnern, Einfach",
//                     "asin": "B09F8HQPF2",
//                     "link": "https://www.amazon.de/ERZHUI-Pfefferm%C3%BChle-Edelstahlm%C3%BChle-Gourmet-Pr%C3%A4zisionsmechanismus-Premium-Pfefferk%C3%B6rnern/dp/B09F8HQPF2/ref=sr_1_41?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-41",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/518VS15dhIS._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.22,
//                             "raw": "€59,22",
//                             "asin": "B09F8HQPF2",
//                             "link": "https://www.amazon.de/ERZHUI-Pfefferm%C3%BChle-Edelstahlm%C3%BChle-Gourmet-Pr%C3%A4zisionsmechanismus-Premium-Pfefferk%C3%B6rnern/dp/B09F8HQPF2/ref=sr_1_41?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-41"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.22,
//                         "raw": "€59,22",
//                         "asin": "B09F8HQPF2",
//                         "link": "https://www.amazon.de/ERZHUI-Pfefferm%C3%BChle-Edelstahlm%C3%BChle-Gourmet-Pr%C3%A4zisionsmechanismus-Premium-Pfefferk%C3%B6rnern/dp/B09F8HQPF2/ref=sr_1_41?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-41"
//                     }
//                 },
//                 {
//                     "position": 42,
//                     "title": "ERZHUI Pfeffermühle und Salzmühlen Shaker-Set, Pfeffermühle, Edelstahlmühle mit Gourmet-Präzisionsmechanismus und Premium-Pfefferkörnern, einfach zu bedienende Füllung",
//                     "asin": "B09F8S59DV",
//                     "link": "https://www.amazon.de/ERZHUI-Pfefferm%C3%BChle-Edelstahlm%C3%BChle-Gourmet-Pr%C3%A4zisionsmechanismus-Premium-Pfefferk%C3%B6rnern/dp/B09F8S59DV/ref=sr_1_42?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-42",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/518VS15dhIS._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.92,
//                             "raw": "€54,92",
//                             "asin": "B09F8S59DV",
//                             "link": "https://www.amazon.de/ERZHUI-Pfefferm%C3%BChle-Edelstahlm%C3%BChle-Gourmet-Pr%C3%A4zisionsmechanismus-Premium-Pfefferk%C3%B6rnern/dp/B09F8S59DV/ref=sr_1_42?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-42"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.92,
//                         "raw": "€54,92",
//                         "asin": "B09F8S59DV",
//                         "link": "https://www.amazon.de/ERZHUI-Pfefferm%C3%BChle-Edelstahlm%C3%BChle-Gourmet-Pr%C3%A4zisionsmechanismus-Premium-Pfefferk%C3%B6rnern/dp/B09F8S59DV/ref=sr_1_42?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-42"
//                     }
//                 },
//                 {
//                     "position": 43,
//                     "title": "Salz und Pfefferstreuer, Pfeffermühle Edelstahl Manuell Keramikmahlwerk und Gewürzmühlen Mahlwerk Einfach mit Daumen Schieben zu Bedienen Einstellbarem Reisen Salzmühle Silber 2er Set",
//                     "asin": "B0837SDRZL",
//                     "link": "https://www.amazon.de/Pfefferstreuer-Pfefferm%C3%BChle-Keramikmahlwerk-Gew%C3%BCrzm%C3%BChlen-Einstellbarem/dp/B0837SDRZL/ref=sr_1_43?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-43",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61psSEpoP+L._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 42.93,
//                             "raw": "€42,93",
//                             "asin": "B0837SDRZL",
//                             "link": "https://www.amazon.de/Pfefferstreuer-Pfefferm%C3%BChle-Keramikmahlwerk-Gew%C3%BCrzm%C3%BChlen-Einstellbarem/dp/B0837SDRZL/ref=sr_1_43?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-43"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 42.93,
//                         "raw": "€42,93",
//                         "asin": "B0837SDRZL",
//                         "link": "https://www.amazon.de/Pfefferstreuer-Pfefferm%C3%BChle-Keramikmahlwerk-Gew%C3%BCrzm%C3%BChlen-Einstellbarem/dp/B0837SDRZL/ref=sr_1_43?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-43"
//                     }
//                 },
//                 {
//                     "position": 44,
//                     "title": "YANJ Pfeffermühle und Salzmühlen Shaker-Set, Pfeffermühle, Edelstahlmühle mit Gourmet-Präzisionsmechanismus und Premium-Pfefferkörnern, einfach zu bedienende Füllung",
//                     "asin": "B09FJP739F",
//                     "link": "https://www.amazon.de/YANJ-Pfefferm%C3%BChle-Edelstahlm%C3%BChle-Gourmet-Pr%C3%A4zisionsmechanismus-Premium-Pfefferk%C3%B6rnern/dp/B09FJP739F/ref=sr_1_44?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-44",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/518VS15dhIS._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 87.44,
//                             "raw": "€87,44",
//                             "asin": "B09FJP739F",
//                             "link": "https://www.amazon.de/YANJ-Pfefferm%C3%BChle-Edelstahlm%C3%BChle-Gourmet-Pr%C3%A4zisionsmechanismus-Premium-Pfefferk%C3%B6rnern/dp/B09FJP739F/ref=sr_1_44?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-44"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 87.44,
//                         "raw": "€87,44",
//                         "asin": "B09FJP739F",
//                         "link": "https://www.amazon.de/YANJ-Pfefferm%C3%BChle-Edelstahlm%C3%BChle-Gourmet-Pr%C3%A4zisionsmechanismus-Premium-Pfefferk%C3%B6rnern/dp/B09FJP739F/ref=sr_1_44?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-44"
//                     }
//                 },
//                 {
//                     "position": 45,
//                     "title": "Salz und Pfeffermühle Elektrische Salz- und Pfefferschleifer - Batteriebetrieben mit LED Licht nachfüllbarer automatischer einseitiger Pfeffer- und Salzmühle-Set Salzmühle Gewürzmühlen",
//                     "asin": "B09H6D7G9N",
//                     "link": "https://www.amazon.de/Pfefferm%C3%BChle-Elektrische-Salz-Pfefferschleifer-Batteriebetrieben/dp/B09H6D7G9N/ref=sr_1_45?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-45",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51HycTrn6UL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.54,
//                             "raw": "€59,54",
//                             "asin": "B09H6D7G9N",
//                             "link": "https://www.amazon.de/Pfefferm%C3%BChle-Elektrische-Salz-Pfefferschleifer-Batteriebetrieben/dp/B09H6D7G9N/ref=sr_1_45?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-45"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.54,
//                         "raw": "€59,54",
//                         "asin": "B09H6D7G9N",
//                         "link": "https://www.amazon.de/Pfefferm%C3%BChle-Elektrische-Salz-Pfefferschleifer-Batteriebetrieben/dp/B09H6D7G9N/ref=sr_1_45?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-45"
//                     }
//                 },
//                 {
//                     "position": 46,
//                     "title": "Cole & Mason H59412GU Cole and Mason Mill Derwent Salzmühle, Kupfer",
//                     "asin": "B01GJJIQYW",
//                     "link": "https://www.amazon.de/Cole-Mason-Pr%C3%A4zision-Pfefferm%C3%BChle-Salzm%C3%BChle/dp/B01GJJIQYW/ref=sr_1_46?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-46",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/616g1TYQ9tL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 107,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.07,
//                             "raw": "€64,07",
//                             "name": "64,07 €",
//                             "asin": "B01GJJIQYW",
//                             "link": "https://www.amazon.de/Cole-Mason-Pr%C3%A4zision-Pfefferm%C3%BChle-Salzm%C3%BChle/dp/B01GJJIQYW/ref=sr_1_46?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-46"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.07,
//                         "raw": "€64,07",
//                         "name": "64,07 €",
//                         "asin": "B01GJJIQYW",
//                         "link": "https://www.amazon.de/Cole-Mason-Pr%C3%A4zision-Pfefferm%C3%BChle-Salzm%C3%BChle/dp/B01GJJIQYW/ref=sr_1_46?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-46"
//                     }
//                 },
//                 {
//                     "position": 47,
//                     "title": "Cole & Mason H331904 Knightsbridge Salz und Pfeffermühle, Silber, Edelstahl, 120mm, Precision+, Geschenkset",
//                     "asin": "B07MXTS97X",
//                     "link": "https://www.amazon.de/Cole-Mason-H331904-Knightsbridge-Centenary/dp/B07MXTS97X/ref=sr_1_47?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-47",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51Oy6bk344L._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 4,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.37,
//                             "raw": "€54,37",
//                             "name": "54,37 €",
//                             "asin": "B07MXTS97X",
//                             "link": "https://www.amazon.de/Cole-Mason-H331904-Knightsbridge-Centenary/dp/B07MXTS97X/ref=sr_1_47?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-47"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.37,
//                         "raw": "€54,37",
//                         "name": "54,37 €",
//                         "asin": "B07MXTS97X",
//                         "link": "https://www.amazon.de/Cole-Mason-H331904-Knightsbridge-Centenary/dp/B07MXTS97X/ref=sr_1_47?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-47"
//                     }
//                 },
//                 {
//                     "position": 48,
//                     "title": "Cole & Mason H59408G Derwent Salz und Pfeffer Mühle Set/Cole & Mason H306119 Ramsgate Klare Salz und Pfeffer Mühle Tablett | Bundle",
//                     "asin": "B09BRV8CDB",
//                     "link": "https://www.amazon.de/Cole-Mason-H59408G-Derwent-Ramsgate/dp/B09BRV8CDB/ref=sr_1_48?keywords=Gourmet-Salzm%C3%BChle&qid=1684074522&refinements=p_36%3A5000-10000&sr=8-48",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51VFovAlz-L._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 90.16,
//                             "raw": "€90,16",
//                             "name": "90,16 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 93.9,
//                             "raw": "€93,90",
//                             "name": "90,16 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 90.16,
//                         "raw": "€90,16",
//                         "name": "90,16 €",
//                         "is_primary": true
//                     }
//                 }
//             ]
//         },
//         {
//             "idea": "Küchenwaage",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "Rosti Mensura Küchenwaage, Curry",
//                     "asin": "B0B7RH9LPF",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfYXRmOjIwMTQ5NDA5OTc0OTk4OjowOjo&url=%2FRosti-25684-Mensura-K%25C3%25BCchenwaage-Curry%2Fdp%2FB0B7RH9LPF%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1%26smid%3DA2R5LS8C57Q98W",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61HQSM8x8WL._AC_UL400_.jpg",
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.41,
//                             "raw": "€69,41",
//                             "asin": "B0B7RH9LPF",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfYXRmOjIwMTQ5NDA5OTc0OTk4OjowOjo&url=%2FRosti-25684-Mensura-K%25C3%25BCchenwaage-Curry%2Fdp%2FB0B7RH9LPF%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1%26smid%3DA2R5LS8C57Q98W"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.41,
//                         "raw": "€69,41",
//                         "asin": "B0B7RH9LPF",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfYXRmOjIwMTQ5NDA5OTc0OTk4OjowOjo&url=%2FRosti-25684-Mensura-K%25C3%25BCchenwaage-Curry%2Fdp%2FB0B7RH9LPF%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1%26smid%3DA2R5LS8C57Q98W"
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "Steinberg Systems SBS-PF-200 Paketwaage 200kg / 50 g Plattformwaage 27,5 x 27,5 x 2,5 cm Digital Waage LCD Display",
//                     "asin": "B07N43L2KX",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfYXRmOjIwMDcyNjM3NTczMzA4OjowOjo&url=%2FSteinberg-Systems-SBS-PF-200-Paketwaage-Plattformwaage%2Fdp%2FB07N43L2KX%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51aEzd73lxL._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 4,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89,
//                             "raw": "€89,00",
//                             "name": "89,00 €",
//                             "asin": "B07N43L2KX",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfYXRmOjIwMDcyNjM3NTczMzA4OjowOjo&url=%2FSteinberg-Systems-SBS-PF-200-Paketwaage-Plattformwaage%2Fdp%2FB07N43L2KX%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89,
//                         "raw": "€89,00",
//                         "name": "89,00 €",
//                         "asin": "B07N43L2KX",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfYXRmOjIwMDcyNjM3NTczMzA4OjowOjo&url=%2FSteinberg-Systems-SBS-PF-200-Paketwaage-Plattformwaage%2Fdp%2FB07N43L2KX%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 3,
//                     "title": "Steinberg Systems Kontrollwaage Marktwaage Obstwaage SBS-PW-302W (bis 30 kg, Genauigkeit 2 g, LED-Display, Akku bis 40 Std. Betrieb, versch. Funktionen)",
//                     "asin": "B07F9TNZXZ",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfYXRmOjIwMDY0Nzk5NTAzNjA4OjowOjo&url=%2FSteinberg-Systems-Preisrechenwaage-SBS-PW-302W-Genauigkeit%2Fdp%2FB07F9TNZXZ%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/619Yof0WOkL._AC_UL400_.jpg",
//                     "rating": 3.9,
//                     "ratings_total": 47,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79,
//                             "raw": "€79,00",
//                             "name": "79,00 €",
//                             "asin": "B07F9TNZXZ",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfYXRmOjIwMDY0Nzk5NTAzNjA4OjowOjo&url=%2FSteinberg-Systems-Preisrechenwaage-SBS-PW-302W-Genauigkeit%2Fdp%2FB07F9TNZXZ%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79,
//                         "raw": "€79,00",
//                         "name": "79,00 €",
//                         "asin": "B07F9TNZXZ",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfYXRmOjIwMDY0Nzk5NTAzNjA4OjowOjo&url=%2FSteinberg-Systems-Preisrechenwaage-SBS-PW-302W-Genauigkeit%2Fdp%2FB07F9TNZXZ%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 4,
//                     "title": "Steinberg Systems SBS-PW-402EP Kontrollwaage Marktwaage 40 kg / 2 g Preiswaage Digitale Waage 7 Speicherplätze Ladenwaage mit Preisanzeige Tischwaage mit LED-Display integrierter Akku",
//                     "asin": "B07MBZSY7S",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfYXRmOjIwMDY0Nzk5NTAzNTA4OjowOjo&url=%2FSteinberg-Systems-Kontrollwaage-SBS-PW-402EP-Speicherpl%25C3%25A4tze%2Fdp%2FB07MBZSY7S%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51miHBjjKhL._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 137,
//                     "sponsored": true
//                 },
//                 {
//                     "position": 5,
//                     "title": "Soehnle Page Profi Digitalwaage für max. 15 kg, digitale Küchenwaage mit großer Wiegefläche und Tara, praktische Haushaltswaage mit Hold-Funktion, Schwarz",
//                     "asin": "B002RLFS20",
//                     "link": "https://www.amazon.de/Soehnle-Digitalwaage-K%C3%BCchenwaage-Haushaltswaage-Hold-Funktion/dp/B002RLFS20/ref=sr_1_5?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-5",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/615vEfm63oS._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 12315,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 27.99,
//                             "raw": "€27,99",
//                             "name": "27,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "27,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 27.99,
//                         "raw": "€27,99",
//                         "name": "27,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 6,
//                     "title": "Beurer KS 34 Küchenwaage, für präzises Wiegen bis 15 kg, mit Tara-Zuwiegefunktion und Magic LED-Display, schwarz",
//                     "asin": "B07HM6TB3S",
//                     "link": "https://www.amazon.de/Beurer-K%C3%BCchenwaage-pr%C3%A4zises-Tara-Zuwiegefunktion-LED-Display/dp/B07HM6TB3S/ref=sr_1_6?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-6",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/411JZsi2SjL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 1699,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 29.99,
//                             "raw": "€29,99",
//                             "name": "29,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 36.49,
//                             "raw": "€36,49",
//                             "name": "29,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 29.99,
//                         "raw": "€29,99",
//                         "name": "29,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 7,
//                     "title": "CASO Kitchen EcoMate - Design Küchenwaage, batterielose Nutzung durch kinetische Energie, besonders flach, magnetisch, bis zu 5 kg in 1g- Schritten, Wiegefläche aus Edelstahl (Ø 11,5 cm)",
//                     "asin": "B09M8HWCMQ",
//                     "link": "https://www.amazon.de/CASO-Kitchen-EcoMate-K%C3%BCchenwaage-batterielose/dp/B09M8HWCMQ/ref=sr_1_7?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-7",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81xJeqr33PL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 24,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 22.99,
//                             "raw": "€22,99",
//                             "name": "22,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 24.99,
//                             "raw": "€24,99",
//                             "name": "22,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 22.99,
//                         "raw": "€22,99",
//                         "name": "22,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 8,
//                     "title": "Soehnle Page Aqua Proof, digitale Küchenwaagen, wasserfest, spülmaschinengeeignet, Gewicht bis zu 10 kg, Haushaltswaage mit Sensor-Touch, elektronische Waage inkl. Batterien, extraflaches Design",
//                     "asin": "B079FXXMS3",
//                     "link": "https://www.amazon.de/Soehnle-66225-Proof-Digitale-K%C3%BCchenwaage/dp/B079FXXMS3/ref=sr_1_8?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-8",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61vo9wEf4SL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 270,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 44.99,
//                             "raw": "€44,99",
//                             "name": "44,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "44,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 44.99,
//                         "raw": "€44,99",
//                         "name": "44,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 9,
//                     "title": "Caso L15 Design Küchenwaage, digitale Küchenwaage mit extra großer Wiegefläche (30x22 cm) aus gebürstetem Edelstahl, Wiegeskala bis max. 15kg, digital, großes Display, HOLD Funktion",
//                     "asin": "B01GV9MURO",
//                     "link": "https://www.amazon.de/K%C3%BCchenwaage-Wiegefl%C3%A4che-geb%C3%BCrstetem-Edelstahl-Wiegeskala/dp/B01GV9MURO/ref=sr_1_9?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-9",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/714qr4CvK5L._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 144,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 39.99,
//                             "raw": "€39,99",
//                             "name": "39,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 49.99,
//                             "raw": "€49,99",
//                             "name": "39,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 39.99,
//                         "raw": "€39,99",
//                         "name": "39,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 10,
//                     "title": "OXO Good Grips Digitale Küchenwaage aus Edelstahl – 5 kg",
//                     "asin": "B079D9B82W",
//                     "link": "https://www.amazon.de/OXO-Good-Grips-Lebensmittel-Ausziehbares/dp/B079D9B82W/ref=sr_1_10?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-10",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71+weIqVUMS._AC_UL400_.jpg",
//                     "rating": 4.8,
//                     "ratings_total": 5506,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 78.5,
//                             "raw": "€78,50"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 135.48,
//                             "raw": "€135,48"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 78.5,
//                         "raw": "€78,50"
//                     }
//                 },
//                 {
//                     "position": 11,
//                     "title": "Heston Blumenthal Precision Salter 1049A HBBKDR digitale Küchenwaage mit Doppelfunktion, high precision kitchen scale mit 2 Plattformen, 10kg/1g Kapazität & Präzisionsplattform 200g/0.1g",
//                     "asin": "B00DW419G0",
//                     "link": "https://www.amazon.de/Heston-Blumenthal-digitale-K%C3%BCchenwaage-Doppelfunktion/dp/B00DW419G0/ref=sr_1_11?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-11",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51xjzgE1rfL._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 1169,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.99,
//                             "raw": "€50,99",
//                             "name": "50,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "50,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.99,
//                         "raw": "€50,99",
//                         "name": "50,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 12,
//                     "title": "CASO Kitchen Energy Design Küchenwaage, digitale Küchenwaage, batterielose Nutzung durch kinetische Energie, umweltschonend und nachhaltig, bis 5 kg in 1 g-Schritten, edelstahl",
//                     "asin": "B071CDN277",
//                     "link": "https://www.amazon.de/K%C3%BCchenwaage-batterielose-kinetische-umweltschonend-g-Schritten/dp/B071CDN277/ref=sr_1_12?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-12",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71zFuwN94mL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 483,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 26.99,
//                             "raw": "€26,99",
//                             "name": "26,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 29.99,
//                             "raw": "€29,99",
//                             "name": "26,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 26.99,
//                         "raw": "€26,99",
//                         "name": "26,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 13,
//                     "title": "Steinberg Systems SBS-PF-70/5 Paketwaage Plattformwaage Digitalwaage (70 kg / 5 g, 23 x 23 cm, LCD Display, Koffer) Schwarz",
//                     "asin": "B00OCLM86M",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMDYxNDE3Mzc1MTk4OjowOjo&url=%2FSteinberg-Systems-SBS-PF-70-Paketwaage-Plattformwaage-Digitalwaage%2Fdp%2FB00OCLM86M%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61x7yFb4I8L._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 3,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69,
//                             "raw": "€69,00",
//                             "name": "69,00 €",
//                             "asin": "B00OCLM86M",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMDYxNDE3Mzc1MTk4OjowOjo&url=%2FSteinberg-Systems-SBS-PF-70-Paketwaage-Plattformwaage-Digitalwaage%2Fdp%2FB00OCLM86M%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69,
//                         "raw": "€69,00",
//                         "name": "69,00 €",
//                         "asin": "B00OCLM86M",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMDYxNDE3Mzc1MTk4OjowOjo&url=%2FSteinberg-Systems-SBS-PF-70-Paketwaage-Plattformwaage-Digitalwaage%2Fdp%2FB00OCLM86M%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 14,
//                     "title": "Steinberg Systems SBS-PF-100/10 Paketwaage Plattformwaage Digitalwaage (100 kg / 10 g, 23 x 23 cm, LCD Display, Koffer) Schwarz",
//                     "asin": "B00OCLMAE2",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMDgyMDU3MDY2MTk4OjowOjo&url=%2FSteinberg-Systems-SBS-PF-100-Plattformwaage-Digitalwaage%2Fdp%2FB00OCLMAE2%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/612MqBVmnnL._AC_UL400_.jpg",
//                     "rating": 3.5,
//                     "ratings_total": 6,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 85,
//                             "raw": "€85,00",
//                             "name": "85,00 €",
//                             "asin": "B00OCLMAE2",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMDgyMDU3MDY2MTk4OjowOjo&url=%2FSteinberg-Systems-SBS-PF-100-Plattformwaage-Digitalwaage%2Fdp%2FB00OCLMAE2%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 85,
//                         "raw": "€85,00",
//                         "name": "85,00 €",
//                         "asin": "B00OCLMAE2",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMDgyMDU3MDY2MTk4OjowOjo&url=%2FSteinberg-Systems-SBS-PF-100-Plattformwaage-Digitalwaage%2Fdp%2FB00OCLMAE2%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 15,
//                     "title": "G&G BPS 30kg/1g Preiswaage Marktwaage Ladenwaage",
//                     "asin": "B0038W4TW2",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMDY1MDk2MzU2Njk4OjowOjo&url=%2F30kg-BPSF30-Preiswaage-Marktwaage-Ladenwaage%2Fdp%2FB0038W4TW2%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DAAYEFYAX10BND",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41+vKNQ297L._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 197,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 68.99,
//                             "raw": "€68,99",
//                             "name": "68,99 €",
//                             "asin": "B0038W4TW2",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMDY1MDk2MzU2Njk4OjowOjo&url=%2F30kg-BPSF30-Preiswaage-Marktwaage-Ladenwaage%2Fdp%2FB0038W4TW2%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DAAYEFYAX10BND"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 68.99,
//                         "raw": "€68,99",
//                         "name": "68,99 €",
//                         "asin": "B0038W4TW2",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMDY1MDk2MzU2Njk4OjowOjo&url=%2F30kg-BPSF30-Preiswaage-Marktwaage-Ladenwaage%2Fdp%2FB0038W4TW2%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DAAYEFYAX10BND"
//                     }
//                 },
//                 {
//                     "position": 16,
//                     "title": "Steinberg Systems SBS-TW-3C Tischwaage 3 kg / 0,1 g 21 x 27 cm LCD RS232-Schnittstelle Akku 100 h Feinwaage Digitalwaage digitale Waage",
//                     "asin": "B085CHK6JD",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMDcyNjM3NTczNDA4OjowOjo&url=%2FSteinberg-Systems-Tischwaage-RS232-Schnittstelle-Digitalwaage%2Fdp%2FB085CHK6JD%2Fref%3Dsr_1_16_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-16-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/515yhgx0unL._AC_UL400_.jpg",
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 95,
//                             "raw": "€95,00",
//                             "asin": "B085CHK6JD",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMDcyNjM3NTczNDA4OjowOjo&url=%2FSteinberg-Systems-Tischwaage-RS232-Schnittstelle-Digitalwaage%2Fdp%2FB085CHK6JD%2Fref%3Dsr_1_16_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-16-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 95,
//                         "raw": "€95,00",
//                         "asin": "B085CHK6JD",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMDcyNjM3NTczNDA4OjowOjo&url=%2FSteinberg-Systems-Tischwaage-RS232-Schnittstelle-Digitalwaage%2Fdp%2FB085CHK6JD%2Fref%3Dsr_1_16_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-16-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 17,
//                     "title": "Beurer 706.1 KS 52 Design-Wandküchenwaage, klappbare Küchenwaage zur Montage an der Wand, platzsparend und jederzeit griffbereit, 22,5cm",
//                     "asin": "B001R7EX8K",
//                     "link": "https://www.amazon.de/Beurer-706-10-KS-52-Design-Wandk%C3%BCchenwaage/dp/B001R7EX8K/ref=sr_1_17?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-17",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51n6xqFm0gL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 1098,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 39.99,
//                             "raw": "€39,99",
//                             "name": "39,99 €",
//                             "asin": "B001R7EX8K",
//                             "link": "https://www.amazon.de/Beurer-706-10-KS-52-Design-Wandk%C3%BCchenwaage/dp/B001R7EX8K/ref=sr_1_17?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-17"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 39.99,
//                         "raw": "€39,99",
//                         "name": "39,99 €",
//                         "asin": "B001R7EX8K",
//                         "link": "https://www.amazon.de/Beurer-706-10-KS-52-Design-Wandk%C3%BCchenwaage/dp/B001R7EX8K/ref=sr_1_17?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-17"
//                     }
//                 },
//                 {
//                     "position": 18,
//                     "title": "My Weigh KD8000 Küchenwaage mit Teigausbeute-Anzeige, EU Adaptor",
//                     "asin": "B073QRGR1L",
//                     "link": "https://www.amazon.de/My-Weigh-KD8000-K%C3%BCchenwaage-Teigausbeute-Anzeige-Adaptor/dp/B073QRGR1L/ref=sr_1_18?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-18",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81f6FXRBk8L._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 138,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.9,
//                             "raw": "€64,90",
//                             "name": "64,90 €",
//                             "asin": "B073QRGR1L",
//                             "link": "https://www.amazon.de/My-Weigh-KD8000-K%C3%BCchenwaage-Teigausbeute-Anzeige-Adaptor/dp/B073QRGR1L/ref=sr_1_18?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-18"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.9,
//                         "raw": "€64,90",
//                         "name": "64,90 €",
//                         "asin": "B073QRGR1L",
//                         "link": "https://www.amazon.de/My-Weigh-KD8000-K%C3%BCchenwaage-Teigausbeute-Anzeige-Adaptor/dp/B073QRGR1L/ref=sr_1_18?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-18"
//                     }
//                 },
//                 {
//                     "position": 19,
//                     "title": "Beurer 704 KS 36 Präzisionswaage, Küchenwaage mit 0,1 g Einteilung, 2 kg Tragkraft, Feinwaage mit Tara-Zuwiegefunktion, inklusive Justiergewicht",
//                     "asin": "B003N2ALXI",
//                     "link": "https://www.amazon.de/Beurer-Pr%C3%A4zisionswaage-Justiergewicht-verschiedenen-Gewichtseinteilungen/dp/B003N2ALXI/ref=sr_1_19?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-19",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71fmKnXcWoL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 310,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 39.99,
//                             "raw": "€39,99"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 73.9,
//                             "raw": "€73,90"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 39.99,
//                         "raw": "€39,99"
//                     }
//                 },
//                 {
//                     "position": 20,
//                     "title": "WMF Digitale Küchenwaage 23 x 15 x 2 cm, Backwaage, Haushaltswaage, Sicherheitsglas, grammgenau, Tara-Funktion, 5kg Maximalgewicht, schwarz & Küchenhandtuch-Set, 3-teilig, Handtuch, Geschirrhandtuch",
//                     "asin": "B098BH7JPQ",
//                     "link": "https://www.amazon.de/WMF-Haushaltswaage-Sicherheitsglas-K%C3%BCchenhandtuch-Set-Geschirrhandtuch/dp/B098BH7JPQ/ref=sr_1_20?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-20",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/517yYjebNGL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 6,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.38,
//                             "raw": "€50,38",
//                             "name": "50,38 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 62.98,
//                             "raw": "€62,98",
//                             "name": "50,38 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.38,
//                         "raw": "€50,38",
//                         "name": "50,38 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 21,
//                     "title": "Klarstein Lugano - Uhrenbeweger, Uhrendreher, Uhrenkasten, Kapazität: 2 Uhren, 4 vorprogrammierte Bewegungs-Modi, Laufleise, sparsam, schwarz",
//                     "asin": "B076B4JFC5",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMTUzNjE3NjI0NDk4OjowOjo&url=%2FKlarstein-Uhrenbeweger-Uhrendreher-Uhrenkasten-Kapazit%25C3%25A4t%2Fdp%2FB076B4JFC5%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61kgC-68bDL._AC_UL400_.jpg",
//                     "rating": 3.9,
//                     "ratings_total": 209,
//                     "sponsored": true
//                 },
//                 {
//                     "position": 22,
//                     "title": "CRISTALICA Schale Pavel blau Ø 26cm",
//                     "asin": "B079T2D9YV",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTozNTQxOTA2MTY4NTIzNDg1OjE2ODQwNzQ1Njk6c3BfbXRmOjIwMDcxMjA1NjQ1MTA2OjowOjo&url=%2FObstschale-Servierteller-Handgeformte-Mundgeblasene-Glasschale%2Fdp%2FB079T2D9YV%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DK%25C3%25BCchenwaage%26qid%3D1684074569%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41uH04wxEHL._AC_UL400_.jpg",
//                     "sponsored": true
//                 },
//                 {
//                     "position": 23,
//                     "title": "KitchenCraft Living Nostalgia Mechanische Küchenwaage, Traditionelle Waage in Vintage-Optik mit Wiegeschale, Haushaltswaage, Back- und Kochzubehör, 4 kg Wiegekapazität – Cremeweiß",
//                     "asin": "B00JPH3U7Y",
//                     "link": "https://www.amazon.de/Wohnzimmer-Nostalgie-1-St%C3%BCck-K%C3%BCche-Mechanische-K%C3%BCchenwaage/dp/B00JPH3U7Y/ref=sr_1_23?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-23",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61CpAxMF1+L._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 491,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59,
//                             "raw": "€59,00",
//                             "name": "59,00 €",
//                             "asin": "B00JPH3U7Y",
//                             "link": "https://www.amazon.de/Wohnzimmer-Nostalgie-1-St%C3%BCck-K%C3%BCche-Mechanische-K%C3%BCchenwaage/dp/B00JPH3U7Y/ref=sr_1_23?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-23"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59,
//                         "raw": "€59,00",
//                         "name": "59,00 €",
//                         "asin": "B00JPH3U7Y",
//                         "link": "https://www.amazon.de/Wohnzimmer-Nostalgie-1-St%C3%BCck-K%C3%BCche-Mechanische-K%C3%BCchenwaage/dp/B00JPH3U7Y/ref=sr_1_23?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-23"
//                     }
//                 },
//                 {
//                     "position": 24,
//                     "title": "Beurer 708.4 KS 54 Küchenwaage (mit Edelstahlschüssel), 21,3cm",
//                     "asin": "B007BNGUSK",
//                     "link": "https://www.amazon.de/Beurer-KS-K%C3%BCchenwaage-mit-Edelstahlsch%C3%BCssel/dp/B007BNGUSK/ref=sr_1_24?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-24",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51Wj2BgFORL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 599,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 41.75,
//                             "raw": "€41,75",
//                             "name": "41,75 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 43.99,
//                             "raw": "€43,99",
//                             "name": "41,75 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 41.75,
//                         "raw": "€41,75",
//                         "name": "41,75 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 25,
//                     "title": "TIMEMORE Exklusiv - Black Mirror Basic PRO Kaffeewaage mit Timer, Durchflussmessung, Digitale Kaffeewaage mit 0,1g präziser Graduierung, Espresso waage mit Timer, Küchenwaage 2000 Gramm, Schwarz",
//                     "asin": "B0BLSFPWC9",
//                     "link": "https://www.amazon.de/TIMEMORE-Exklusiv-Kaffeewaage-Durchflussmessung-Graduierung/dp/B0BLSFPWC9/ref=sr_1_25?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-25",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Jb3bpa3WL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 128,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.84,
//                             "raw": "€51,84"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 65.44,
//                             "raw": "€65,44"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.84,
//                         "raw": "€51,84"
//                     }
//                 },
//                 {
//                     "position": 26,
//                     "title": "Digitale Küchenwaage, Schwarz | No plug",
//                     "asin": "B091NFLM1D",
//                     "link": "https://www.amazon.de/ZWILLING-Digitale-K%C3%BCchenwaage-Enfinigy-Wiegegewicht/dp/B091NFLM1D/ref=sr_1_26?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-26",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71E657hnu2L._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 137,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 37,
//                             "raw": "€37,00"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 87.82,
//                             "raw": "€87,82"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 37,
//                         "raw": "€37,00"
//                     }
//                 },
//                 {
//                     "position": 27,
//                     "title": "WMF Digitale Küchenwaage 20 x 20 x 1 cm, Backwaage, Haushaltswaage, Edelstahl, grammgenau, Tara-Funktion, 5kg Maximalgewicht, silber",
//                     "asin": "B005XJJ540",
//                     "link": "https://www.amazon.de/WMF-K%C3%BCchenwaage-Haushaltswaage-grammgenau-Maximalgewicht/dp/B005XJJ540/ref=sr_1_27?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-27",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71X9cpKm1qL._AC_UL400_.jpg",
//                     "rating": 4.2,
//                     "ratings_total": 297,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 49.49,
//                             "raw": "€49,49",
//                             "name": "49,49 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.99,
//                             "raw": "€54,99",
//                             "name": "49,49 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 49.49,
//                         "raw": "€49,49",
//                         "name": "49,49 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 28,
//                     "title": "Cecotec Küchenwaage Kochsteuerung 10400 Smart Healthy EasyHang. 8k maximale Kapazität, Edelstahl, Griff zum Aufhängen, LCD-Display, Extraflaches Design (10300 EcoPower Nutrition)",
//                     "asin": "B08K8ZLJ9V",
//                     "link": "https://www.amazon.de/LCD-Bildschirm-Druckgewicht-Verpackungsgewicht-Lebensmittel-Edelstahl-Finish/dp/B08K8ZLJ9V/ref=sr_1_28?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-28",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51v3KF39gqL._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 102,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 21.38,
//                             "raw": "€21,38"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 48.83,
//                             "raw": "€48,83"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 21.38,
//                         "raw": "€21,38"
//                     }
//                 },
//                 {
//                     "position": 29,
//                     "title": "sprechende Waage Küchenwaage digitale Anzeige 1-5.000g Vox 2",
//                     "asin": "B01MG2EFQS",
//                     "link": "https://www.amazon.de/K%C3%BCchenwaage-se-courrier-entwickelten-Sehbehinderten-mancelboutique/dp/B01MG2EFQS/ref=sr_1_29?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-29",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41MSwffQ3GL._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 2,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.95,
//                             "raw": "€79,95",
//                             "name": "79,95 €",
//                             "asin": "B01MG2EFQS",
//                             "link": "https://www.amazon.de/K%C3%BCchenwaage-se-courrier-entwickelten-Sehbehinderten-mancelboutique/dp/B01MG2EFQS/ref=sr_1_29?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-29"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.95,
//                         "raw": "€79,95",
//                         "name": "79,95 €",
//                         "asin": "B01MG2EFQS",
//                         "link": "https://www.amazon.de/K%C3%BCchenwaage-se-courrier-entwickelten-Sehbehinderten-mancelboutique/dp/B01MG2EFQS/ref=sr_1_29?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-29"
//                     }
//                 },
//                 {
//                     "position": 30,
//                     "title": "KitchenCraft Industrial Kitchen Küchenwaage, Mechanisch, großes Volumen, für schwere Lasten geeignet, aus recycelten Materialien, 27 x 27 x 34 cm",
//                     "asin": "B01N5LR7W6",
//                     "link": "https://www.amazon.de/Kitchen-Craft-INDSCALE10-K%C3%BCchenwaage-Industrial/dp/B01N5LR7W6/ref=sr_1_30?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-30",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71RU+UygScL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 220,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 57.15,
//                             "raw": "€57,15",
//                             "name": "57,15 €",
//                             "asin": "B01N5LR7W6",
//                             "link": "https://www.amazon.de/Kitchen-Craft-INDSCALE10-K%C3%BCchenwaage-Industrial/dp/B01N5LR7W6/ref=sr_1_30?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-30"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 57.15,
//                         "raw": "€57,15",
//                         "name": "57,15 €",
//                         "asin": "B01N5LR7W6",
//                         "link": "https://www.amazon.de/Kitchen-Craft-INDSCALE10-K%C3%BCchenwaage-Industrial/dp/B01N5LR7W6/ref=sr_1_30?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-30"
//                     }
//                 },
//                 {
//                     "position": 31,
//                     "title": "Beurer 706.51 KS 51 Küchenwaage, 20,3cm",
//                     "asin": "B007BNGUOO",
//                     "link": "https://www.amazon.de/Beurer-706-51-KS-51-K%C3%BCchenwaage/dp/B007BNGUOO/ref=sr_1_31?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-31",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81Y54b+K28L._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 96,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 34.19,
//                             "raw": "€34,19",
//                             "name": "34,19 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 41.99,
//                             "raw": "€41,99",
//                             "name": "34,19 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 34.19,
//                         "raw": "€34,19",
//                         "name": "34,19 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 32,
//                     "title": "G&G PLC 6000g/0,1g Präzisionswaage Feinwaage Industriewaage Tischwaage Goldwaage/Batteriebetrieb möglich",
//                     "asin": "B004R9DYKI",
//                     "link": "https://www.amazon.de/Pr%C3%A4zisionswaage-Laborwaage-Industriewaage-Tischwaage-Batteriebetrieb/dp/B004R9DYKI/ref=sr_1_32?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-32",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41iiFr33OJL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 64,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.85,
//                             "raw": "€79,85",
//                             "name": "79,85 €",
//                             "asin": "B004R9DYKI",
//                             "link": "https://www.amazon.de/Pr%C3%A4zisionswaage-Laborwaage-Industriewaage-Tischwaage-Batteriebetrieb/dp/B004R9DYKI/ref=sr_1_32?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-32"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.85,
//                         "raw": "€79,85",
//                         "name": "79,85 €",
//                         "asin": "B004R9DYKI",
//                         "link": "https://www.amazon.de/Pr%C3%A4zisionswaage-Laborwaage-Industriewaage-Tischwaage-Batteriebetrieb/dp/B004R9DYKI/ref=sr_1_32?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-32"
//                     }
//                 },
//                 {
//                     "position": 33,
//                     "title": "Soehnle Silvia Mechanische Küchenwaage, Metall, silberfarben",
//                     "asin": "B01N2TUAMI",
//                     "link": "https://www.amazon.de/Soehnle-K%C3%BCchenwaage-Silvia-mechanisch-silber/dp/B01N2TUAMI/ref=sr_1_33?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-33",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/713Fhz1fgtL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 29,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.86,
//                             "raw": "€51,86",
//                             "name": "51,86 €",
//                             "asin": "B01N2TUAMI",
//                             "link": "https://www.amazon.de/Soehnle-K%C3%BCchenwaage-Silvia-mechanisch-silber/dp/B01N2TUAMI/ref=sr_1_33?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-33"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.86,
//                         "raw": "€51,86",
//                         "name": "51,86 €",
//                         "asin": "B01N2TUAMI",
//                         "link": "https://www.amazon.de/Soehnle-K%C3%BCchenwaage-Silvia-mechanisch-silber/dp/B01N2TUAMI/ref=sr_1_33?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-33"
//                     }
//                 },
//                 {
//                     "position": 34,
//                     "title": "KitchenCraft Living Nostalgia Mechanische Küchenwaage, Traditionelle Waage in Vintage-Optik mit Wiegeschale, Haushaltswaage, Back- und Kochzubehör, 4 kg Wiegekapazität – Vintage Blau",
//                     "asin": "B00J8NA9EC",
//                     "link": "https://www.amazon.de/Kitchen-Craft-LNSCALEBLU-Mechanische-K%C3%BCchenwaage/dp/B00J8NA9EC/ref=sr_1_34?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-34",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/614gB-1iH5L._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 944,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54,
//                             "raw": "€54,00",
//                             "name": "54,00 €",
//                             "asin": "B00J8NA9EC",
//                             "link": "https://www.amazon.de/Kitchen-Craft-LNSCALEBLU-Mechanische-K%C3%BCchenwaage/dp/B00J8NA9EC/ref=sr_1_34?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-34"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54,
//                         "raw": "€54,00",
//                         "name": "54,00 €",
//                         "asin": "B00J8NA9EC",
//                         "link": "https://www.amazon.de/Kitchen-Craft-LNSCALEBLU-Mechanische-K%C3%BCchenwaage/dp/B00J8NA9EC/ref=sr_1_34?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-34"
//                     }
//                 },
//                 {
//                     "position": 35,
//                     "title": "Wilfa SPLASH Küchenwaage - Digitale Waage mit einer Kapazität von 5 kg, silikonbeschichtete Oberfläche, LCD-Display, schwarz",
//                     "asin": "B0889HM3C3",
//                     "link": "https://www.amazon.de/Wilfa-605915-K%C3%BCchenwaage-Kunststoff/dp/B0889HM3C3/ref=sr_1_35?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-35",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71+2GdBv+EL._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 16,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 67.99,
//                             "raw": "€67,99",
//                             "name": "67,99 €",
//                             "asin": "B0889HM3C3",
//                             "link": "https://www.amazon.de/Wilfa-605915-K%C3%BCchenwaage-Kunststoff/dp/B0889HM3C3/ref=sr_1_35?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-35"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 67.99,
//                         "raw": "€67,99",
//                         "name": "67,99 €",
//                         "asin": "B0889HM3C3",
//                         "link": "https://www.amazon.de/Wilfa-605915-K%C3%BCchenwaage-Kunststoff/dp/B0889HM3C3/ref=sr_1_35?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-35"
//                     }
//                 },
//                 {
//                     "position": 36,
//                     "title": "Küchenwaagen | Analog | Edelstahl | Abnehmbare Schale | Schwarz",
//                     "asin": "B07HFD8DDR",
//                     "link": "https://www.amazon.de/Nedis-KASC110BK-Retro-Kchenwaage-analog-Schwarz/dp/B07HFD8DDR/ref=sr_1_36?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-36",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61b1YXRg6hL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 460,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 23.99,
//                             "raw": "€23,99"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 53.57,
//                             "raw": "€53,57"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 23.99,
//                         "raw": "€23,99"
//                     }
//                 },
//                 {
//                     "position": 37,
//                     "title": "KitchenCraft Nostalgia Mechanische Küchenwaage mit Schale und Gewichten in Geschenkbox, Gusseisen mit Ständer aus Akazienholz",
//                     "asin": "B000XO0V2A",
//                     "link": "https://www.amazon.de/Natural-Elements-Gusseiserne-schwarzem-Akazienholzfu%C3%9F/dp/B000XO0V2A/ref=sr_1_37?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-37",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81vljnWUboL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 300,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 106.09,
//                             "raw": "€106,09",
//                             "name": "106,09 €",
//                             "asin": "B000XO0V2A",
//                             "link": "https://www.amazon.de/Natural-Elements-Gusseiserne-schwarzem-Akazienholzfu%C3%9F/dp/B000XO0V2A/ref=sr_1_37?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-37"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 106.09,
//                         "raw": "€106,09",
//                         "name": "106,09 €",
//                         "asin": "B000XO0V2A",
//                         "link": "https://www.amazon.de/Natural-Elements-Gusseiserne-schwarzem-Akazienholzfu%C3%9F/dp/B000XO0V2A/ref=sr_1_37?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-37"
//                     }
//                 },
//                 {
//                     "position": 38,
//                     "title": "RÖSLE Digitale Küchenwaage mit Slide-Mechanismus, Hochwertige Digitalwaage mit Wiegefläche aus Edelstahl 18/10, LCD-Display, Tragkraft bis 5 kg, inkl. Batterien, 17,8 x 17 x 2,2 cm",
//                     "asin": "B06XRX78LK",
//                     "link": "https://www.amazon.de/R%C3%B6sle-16232-K%C3%BCchenwaage-Edelstahl-Funktion/dp/B06XRX78LK/ref=sr_1_38?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-38",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41pWe08uNCL._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 61,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 42.62,
//                             "raw": "€42,62",
//                             "name": "42,62 €",
//                             "asin": "B06XRX78LK",
//                             "link": "https://www.amazon.de/R%C3%B6sle-16232-K%C3%BCchenwaage-Edelstahl-Funktion/dp/B06XRX78LK/ref=sr_1_38?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-38"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 42.62,
//                         "raw": "€42,62",
//                         "name": "42,62 €",
//                         "asin": "B06XRX78LK",
//                         "link": "https://www.amazon.de/R%C3%B6sle-16232-K%C3%BCchenwaage-Edelstahl-Funktion/dp/B06XRX78LK/ref=sr_1_38?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-38"
//                     }
//                 },
//                 {
//                     "position": 39,
//                     "title": "Salter 1094 WDDR Digitale Küchenwaage Eco Bambus, wiederaufladbare elektrische Waage mit Tara-Funktion, USB-Kabelladegerät im Lieferumfang enthalten, Sustainable Made Waage, LCD-Anzeige, max 5kg",
//                     "asin": "B08CF2BD9C",
//                     "link": "https://www.amazon.de/Salter-Bambus-wiederaufladbare-digitale-K%C3%BCchenwaage/dp/B08CF2BD9C/ref=sr_1_39?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-39",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71OhWVfDvlL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 85,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 32.99,
//                             "raw": "€32,99",
//                             "name": "32,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 39.75,
//                             "raw": "€39,75",
//                             "name": "32,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 32.99,
//                         "raw": "€32,99",
//                         "name": "32,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 40,
//                     "title": "deutsch sprechende Waage Küchenwaage digitale Anzeige 3.000 g VOX 3000 TS",
//                     "asin": "B00G8CJYFQ",
//                     "link": "https://www.amazon.de/deutsch-sprechende-K%C3%BCchenwaage-digitale-Anzeige/dp/B00G8CJYFQ/ref=sr_1_40?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-40",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51yBdjvsvNL._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 103
//                 },
//                 {
//                     "position": 41,
//                     "title": "Salter 1242 WHDR matte Glasplattform Elektronische Küchenwaage, Waage digitale Max 10kg, Aquatronikfunktion Flüssigkeiten in ml oder fl.oz, gut lesbare LCD-Anzeige, tara-funktion",
//                     "asin": "B00C73GCDC",
//                     "link": "https://www.amazon.de/Salter-matte-Glaswaage-Elektronische-Messergebnisse/dp/B00C73GCDC/ref=sr_1_41?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-41",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61xJ2i577XL._AC_UL400_.jpg",
//                     "rating": 4,
//                     "ratings_total": 207,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 32.99,
//                             "raw": "€32,99",
//                             "name": "32,99 €",
//                             "asin": "B00C73GCDC",
//                             "link": "https://www.amazon.de/Salter-matte-Glaswaage-Elektronische-Messergebnisse/dp/B00C73GCDC/ref=sr_1_41?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-41"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 32.99,
//                         "raw": "€32,99",
//                         "name": "32,99 €",
//                         "asin": "B00C73GCDC",
//                         "link": "https://www.amazon.de/Salter-matte-Glaswaage-Elektronische-Messergebnisse/dp/B00C73GCDC/ref=sr_1_41?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-41"
//                     }
//                 },
//                 {
//                     "position": 42,
//                     "title": "Salter 1079 WHDR Max elektronische Küchenwaage, 15 kg, Schneiden & Wiegen, misst Flüssigkeiten, tara-funktion, schlankes Design, ablesbares Display, Hochglanzoberfläche, metrische/imperial, Weiß",
//                     "asin": "B01DKRZI7Y",
//                     "link": "https://www.amazon.de/Salter-digitale-K%C3%BCchenwaage-inkl-Schneidebrett/dp/B01DKRZI7Y/ref=sr_1_42?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-42",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51BPDcXfGYL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 2,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "rating": 4.4,
//                     "ratings_total": 1183,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 33.11,
//                             "raw": "€33,11",
//                             "name": "33,11 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 44.99,
//                             "raw": "€44,99",
//                             "name": "33,11 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 33.11,
//                         "raw": "€33,11",
//                         "name": "33,11 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 43,
//                     "title": "Krups XF5548 Prep&Cook Küchenwaage (Bluetooth, kompatibel mit Prep&Cook und Cook4Me App, automatische Einheitenumrechnung) schwarz",
//                     "asin": "B07WRPV4G2",
//                     "link": "https://www.amazon.de/Krups-XF5548-K%C3%BCchenwaage-automatische-Einheitenumrechnung/dp/B07WRPV4G2/ref=sr_1_43?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-43",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71oakoJWnAL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 96,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 70.65,
//                             "raw": "€70,65",
//                             "name": "70,65 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.99,
//                             "raw": "€74,99",
//                             "name": "70,65 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 70.65,
//                         "raw": "€70,65",
//                         "name": "70,65 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 44,
//                     "title": "Küchenwaage My Weigh KD7000 silber",
//                     "asin": "B000EVFC8K",
//                     "link": "https://www.amazon.de/K%C3%BCchenwaage-My-Weigh-KD7000-silber/dp/B000EVFC8K/ref=sr_1_44?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-44",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81c9thY2isL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 531,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.9,
//                             "raw": "€64,90",
//                             "name": "64,90 €",
//                             "asin": "B000EVFC8K",
//                             "link": "https://www.amazon.de/K%C3%BCchenwaage-My-Weigh-KD7000-silber/dp/B000EVFC8K/ref=sr_1_44?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-44"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.9,
//                         "raw": "€64,90",
//                         "name": "64,90 €",
//                         "asin": "B000EVFC8K",
//                         "link": "https://www.amazon.de/K%C3%BCchenwaage-My-Weigh-KD7000-silber/dp/B000EVFC8K/ref=sr_1_44?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-44"
//                     }
//                 },
//                 {
//                     "position": 45,
//                     "title": "TERRAILLON Profi-Küchenwaage – Tara, Flüssigkeitsumrechnung, Anti-Staub-Beschichtung, Wiegebereich 10 kg – Modell 'Supreme' Silber/Schwarz",
//                     "asin": "B002VXCD4K",
//                     "link": "https://www.amazon.de/Terraillon-K%C3%BCchenwaage-Tara-Funktion-Fl%C3%BCssigkeitsanzeige-Anti-Staub-Beschichtung/dp/B002VXCD4K/ref=sr_1_45?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-45",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41HTMldUt7L._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 68,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 82.65,
//                             "raw": "€82,65",
//                             "name": "82,65 €",
//                             "asin": "B002VXCD4K",
//                             "link": "https://www.amazon.de/Terraillon-K%C3%BCchenwaage-Tara-Funktion-Fl%C3%BCssigkeitsanzeige-Anti-Staub-Beschichtung/dp/B002VXCD4K/ref=sr_1_45?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-45"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 82.65,
//                         "raw": "€82,65",
//                         "name": "82,65 €",
//                         "asin": "B002VXCD4K",
//                         "link": "https://www.amazon.de/Terraillon-K%C3%BCchenwaage-Tara-Funktion-Fl%C3%BCssigkeitsanzeige-Anti-Staub-Beschichtung/dp/B002VXCD4K/ref=sr_1_45?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-45"
//                     }
//                 },
//                 {
//                     "position": 46,
//                     "title": "ZWILLING Enfinigy Digitale Küchenwaage, max. Gewicht: 10 kg, Gramm und Unzen, 0,1 g Genauigkeit, Schwarz",
//                     "asin": "B08QDQBGDC",
//                     "link": "https://www.amazon.de/ZWILLING-53011-053-0-Enfinigy-Digitale-Lebensmittelwaage/dp/B08QDQBGDC/ref=sr_1_46?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-46",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61Gh9bGpcPL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 152,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 61.26,
//                             "raw": "€61,26",
//                             "name": "61,26 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 63.49,
//                             "raw": "€63,49",
//                             "name": "61,26 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 61.26,
//                         "raw": "€61,26",
//                         "name": "61,26 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 47,
//                     "title": "Küchenwaage My Weigh KD8000",
//                     "asin": "B00VEKX35Y",
//                     "link": "https://www.amazon.de/My-Weigh-SCMKD8000-K%C3%BCchenwaage-KD8000/dp/B00VEKX35Y/ref=sr_1_47?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-47",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71e0jvPrjGL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 2866,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 62.9,
//                             "raw": "€62,90",
//                             "name": "62,90 €",
//                             "asin": "B00VEKX35Y",
//                             "link": "https://www.amazon.de/My-Weigh-SCMKD8000-K%C3%BCchenwaage-KD8000/dp/B00VEKX35Y/ref=sr_1_47?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-47"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 62.9,
//                         "raw": "€62,90",
//                         "name": "62,90 €",
//                         "asin": "B00VEKX35Y",
//                         "link": "https://www.amazon.de/My-Weigh-SCMKD8000-K%C3%BCchenwaage-KD8000/dp/B00VEKX35Y/ref=sr_1_47?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-47"
//                     }
//                 },
//                 {
//                     "position": 48,
//                     "title": "KitchenCraft Classic Collection Mechanische Küchenwaage mit Schale, Geschenkbox, Cremefarben, 2kg",
//                     "asin": "B002AT0UPY",
//                     "link": "https://www.amazon.de/Kitchen-Craft-Mechanische-K%C3%BCchenwaage-cremefarben/dp/B002AT0UPY/ref=sr_1_48?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-48",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61PqWL+5R1L._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 149,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 72.22,
//                             "raw": "€72,22",
//                             "name": "72,22 €",
//                             "asin": "B002AT0UPY",
//                             "link": "https://www.amazon.de/Kitchen-Craft-Mechanische-K%C3%BCchenwaage-cremefarben/dp/B002AT0UPY/ref=sr_1_48?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-48"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 72.22,
//                         "raw": "€72,22",
//                         "name": "72,22 €",
//                         "asin": "B002AT0UPY",
//                         "link": "https://www.amazon.de/Kitchen-Craft-Mechanische-K%C3%BCchenwaage-cremefarben/dp/B002AT0UPY/ref=sr_1_48?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-48"
//                     }
//                 },
//                 {
//                     "position": 49,
//                     "title": "Küchenwaage FRANSKE weiß Shabby chic Waage Schalenwaage im Landhausstil",
//                     "asin": "B01E5O5NX0",
//                     "link": "https://www.amazon.de/K%C3%BCchenwaage-FRANSKE-shabby-Schalenwaage-Landhausstil/dp/B01E5O5NX0/ref=sr_1_49?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-49",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/817jJtcjU3L._AC_UL400_.jpg",
//                     "rating": 3.7,
//                     "ratings_total": 5,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.49,
//                             "raw": "€64,49",
//                             "name": "64,49 €",
//                             "asin": "B01E5O5NX0",
//                             "link": "https://www.amazon.de/K%C3%BCchenwaage-FRANSKE-shabby-Schalenwaage-Landhausstil/dp/B01E5O5NX0/ref=sr_1_49?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-49"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.49,
//                         "raw": "€64,49",
//                         "name": "64,49 €",
//                         "asin": "B01E5O5NX0",
//                         "link": "https://www.amazon.de/K%C3%BCchenwaage-FRANSKE-shabby-Schalenwaage-Landhausstil/dp/B01E5O5NX0/ref=sr_1_49?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-49"
//                     }
//                 },
//                 {
//                     "position": 50,
//                     "title": "Terraillon Vintage-Küchenwaage – Manuelle Tara, großes Ziffernblatt und 1 L Schüssel, Wiegebereich 5 kg – Modell 'Tradition 500' Schwarz",
//                     "asin": "B07RV46V28",
//                     "link": "https://www.amazon.de/Terraillon-14477-K%C3%BCchenwaage-18-Stahl/dp/B07RV46V28/ref=sr_1_50_mod_primary_new?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-50",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61Oyjo7laYL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 111,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 43.71,
//                             "raw": "€43,71",
//                             "name": "43,71 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 44.51,
//                             "raw": "€44,51",
//                             "name": "44,51 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 43.71,
//                         "raw": "€43,71",
//                         "name": "43,71 €"
//                     }
//                 },
//                 {
//                     "position": 51,
//                     "title": "Küchenwaagen - Digital - Edelstahl - Timerfunktion - Thermometer Funktion - Abnehmbare Schale - Silber",
//                     "asin": "B07HFF86ZG",
//                     "link": "https://www.amazon.de/Nedis-KASC111SI-Kchenwaage-Beleuchtetes-Temperatur/dp/B07HFF86ZG/ref=sr_1_51?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-51",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61y0xv0VvBL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 60,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 26.99,
//                             "raw": "€26,99",
//                             "name": "26,99 €",
//                             "asin": "B07HFF86ZG",
//                             "link": "https://www.amazon.de/Nedis-KASC111SI-Kchenwaage-Beleuchtetes-Temperatur/dp/B07HFF86ZG/ref=sr_1_51?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-51"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 26.99,
//                         "raw": "€26,99",
//                         "name": "26,99 €",
//                         "asin": "B07HFF86ZG",
//                         "link": "https://www.amazon.de/Nedis-KASC111SI-Kchenwaage-Beleuchtetes-Temperatur/dp/B07HFF86ZG/ref=sr_1_51?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-51"
//                     }
//                 },
//                 {
//                     "position": 52,
//                     "title": "Tada Präzise Portionen Analoge Lebensmittelwaage Edelstahl Mechanische Küchenwaage Abnehmbare Schüssel Tara-Funktion Retro Stil Küche Freundlich (5 kg, Schüssel Top)",
//                     "asin": "B07WR25X8S",
//                     "link": "https://www.amazon.de/Tada-Mechanische-K%C3%BCchenwaage-5-kg/dp/B07WR25X8S/ref=sr_1_52?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-52",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71q0g6FDzKL._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 83,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.39,
//                             "raw": "€59,39"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 63.9,
//                             "raw": "€63,90"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.39,
//                         "raw": "€59,39"
//                     }
//                 },
//                 {
//                     "position": 53,
//                     "title": "Soehnle 67080 Page Profi Küchenwaage",
//                     "asin": "B003LSUC9W",
//                     "link": "https://www.amazon.de/Soehnle-67080-67080-Page-Profi-K%C3%BCchenwaage/dp/B003LSUC9W/ref=sr_1_53?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-53",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71l2JFdbqHL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 165,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 61,
//                             "raw": "€61,00",
//                             "name": "61,00 €",
//                             "asin": "B003LSUC9W",
//                             "link": "https://www.amazon.de/Soehnle-67080-67080-Page-Profi-K%C3%BCchenwaage/dp/B003LSUC9W/ref=sr_1_53?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-53"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 61,
//                         "raw": "€61,00",
//                         "name": "61,00 €",
//                         "asin": "B003LSUC9W",
//                         "link": "https://www.amazon.de/Soehnle-67080-67080-Page-Profi-K%C3%BCchenwaage/dp/B003LSUC9W/ref=sr_1_53?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-53"
//                     }
//                 },
//                 {
//                     "position": 54,
//                     "title": "Tefal BC8000V0 Elektronische Küchenwaage Simply 10 Skala, 10 kg/1 g, Umwandlung flüssiger Tare, Himbeerrot",
//                     "asin": "B07B46ZZGW",
//                     "link": "https://www.amazon.de/Tefal-K%C3%BCchenwaage/dp/B07B46ZZGW/ref=sr_1_54?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-54",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81WgCR0EdrL._AC_UL400_.jpg",
//                     "rating": 4.9,
//                     "ratings_total": 17,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 48.23,
//                             "raw": "€48,23",
//                             "name": "48,23 €",
//                             "asin": "B07B46ZZGW",
//                             "link": "https://www.amazon.de/Tefal-K%C3%BCchenwaage/dp/B07B46ZZGW/ref=sr_1_54?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-54"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 48.23,
//                         "raw": "€48,23",
//                         "name": "48,23 €",
//                         "asin": "B07B46ZZGW",
//                         "link": "https://www.amazon.de/Tefal-K%C3%BCchenwaage/dp/B07B46ZZGW/ref=sr_1_54?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-54"
//                     }
//                 },
//                 {
//                     "position": 55,
//                     "title": "Küchenwaage FRANSKE Mint Shabby chic Waage Schalenwaage im Landhausstil",
//                     "asin": "B01MY7N5N8",
//                     "link": "https://www.amazon.de/K%C3%BCchenwaage-FRANSKE-Shabby-Schalenwaage-Landhausstil/dp/B01MY7N5N8/ref=sr_1_55?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-55",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61RseplGExL._AC_UL400_.jpg",
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 57.5,
//                             "raw": "€57,50",
//                             "name": "57,50 €",
//                             "asin": "B01MY7N5N8",
//                             "link": "https://www.amazon.de/K%C3%BCchenwaage-FRANSKE-Shabby-Schalenwaage-Landhausstil/dp/B01MY7N5N8/ref=sr_1_55?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-55"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 57.5,
//                         "raw": "€57,50",
//                         "name": "57,50 €",
//                         "asin": "B01MY7N5N8",
//                         "link": "https://www.amazon.de/K%C3%BCchenwaage-FRANSKE-Shabby-Schalenwaage-Landhausstil/dp/B01MY7N5N8/ref=sr_1_55?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-55"
//                     }
//                 },
//                 {
//                     "position": 56,
//                     "title": "My Weigh Sprechende Küchenwaage, Kunststoff, anthrazit",
//                     "asin": "B00CC01GRC",
//                     "link": "https://www.amazon.de/My-Weigh-Sprechende-K%C3%BCchenwaage-Kunststoff/dp/B00CC01GRC/ref=sr_1_56?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-56",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81jPILqRm3L._AC_UL400_.jpg",
//                     "rating": 3.8,
//                     "ratings_total": 159,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.33,
//                             "raw": "€59,33",
//                             "name": "59,33 €",
//                             "asin": "B00CC01GRC",
//                             "link": "https://www.amazon.de/My-Weigh-Sprechende-K%C3%BCchenwaage-Kunststoff/dp/B00CC01GRC/ref=sr_1_56?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-56"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.33,
//                         "raw": "€59,33",
//                         "name": "59,33 €",
//                         "asin": "B00CC01GRC",
//                         "link": "https://www.amazon.de/My-Weigh-Sprechende-K%C3%BCchenwaage-Kunststoff/dp/B00CC01GRC/ref=sr_1_56?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-56"
//                     }
//                 },
//                 {
//                     "position": 57,
//                     "title": "SOEHNLE PROFESSIONAL Kompaktwaage 9203 bis 5000g - 1g",
//                     "asin": "B003W2HXLW",
//                     "link": "https://www.amazon.de/Waagen-SOEHNLE-PROFESSIONAL-Kompaktwaage-Ablesbarkeit/dp/B003W2HXLW/ref=sr_1_57?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-57",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61zE4eM8xWL._AC_UL400_.jpg",
//                     "rating": 3.6,
//                     "ratings_total": 34,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 100,
//                             "raw": "€100,00",
//                             "name": "100,00 €",
//                             "asin": "B003W2HXLW",
//                             "link": "https://www.amazon.de/Waagen-SOEHNLE-PROFESSIONAL-Kompaktwaage-Ablesbarkeit/dp/B003W2HXLW/ref=sr_1_57?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-57"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 100,
//                         "raw": "€100,00",
//                         "name": "100,00 €",
//                         "asin": "B003W2HXLW",
//                         "link": "https://www.amazon.de/Waagen-SOEHNLE-PROFESSIONAL-Kompaktwaage-Ablesbarkeit/dp/B003W2HXLW/ref=sr_1_57?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-57"
//                     }
//                 },
//                 {
//                     "position": 58,
//                     "title": "WMF Gourmet Kräutertopf mit Bewässerungssystem 3-teilig, 36x 12,5X 12,5 cm, weiß & Digitale Küchenwaage 23 x 15 x 2 cm, Backwaage, Sicherheitsglas, grammgenau, 5kg Maximalgewicht, schwarz",
//                     "asin": "B09Q97B5JQ",
//                     "link": "https://www.amazon.de/WMF-Kr%C3%A4utertopf-Bew%C3%A4sserungssystem-Sicherheitsglas-Maximalgewicht/dp/B09Q97B5JQ/ref=sr_1_58?keywords=K%C3%BCchenwaage&qid=1684074569&refinements=p_36%3A5000-10000&sr=8-58",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/412bFoq9+QL._AC_UL400_.jpg",
//                     "rating": 4.3,
//                     "ratings_total": 2210,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 73.98,
//                             "raw": "€73,98",
//                             "name": "73,98 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 112.98,
//                             "raw": "€112,98",
//                             "name": "73,98 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 73.98,
//                         "raw": "€73,98",
//                         "name": "73,98 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 59,
//                     "title": "Thaliar Professionelle digitale Küchenwaage, 5 kg bis 1 g, präzise Messung, Edelstahl-Küchenwaage, LCD-Display, Präzisionswaage, Tara-Funktion",
//                     "asin": "B08RCS3JC4",
//                     "link": "https://www.amazon.de/K%C3%BCchenwaage-digital-Professionelle-digitalwaage-Tara-Funktion/dp/B08RCS3JC4/ref=sxbs_aspa_sqa?c_c=315252645&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenwaage&keywords=K%C3%BCchenwaage&pd_rd_i=B08RCS3JC4&pd_rd_r=f2de42b4-4ea2-4ace-a23d-4124de2a5690&pd_rd_w=k4Dnx&pd_rd_wg=Qfa9B&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=28DMR6V23W8G6SQF30Q9&qid=1684074569&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-1-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51-9Uaa4lNL._AC_UL400_.jpg",
//                     "amazons_choice": {
//                         "link": "https://www.amazon.de/K%C3%BCchenwaage-digital-Professionelle-digitalwaage-Tara-Funktion/dp/B08RCS3JC4/ref=sxbs_aspa_sqa?c_c=315252645&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenwaage&keywords=K%C3%BCchenwaage&pd_rd_i=B08RCS3JC4&pd_rd_r=f2de42b4-4ea2-4ace-a23d-4124de2a5690&pd_rd_w=k4Dnx&pd_rd_wg=Qfa9B&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=28DMR6V23W8G6SQF30Q9&qid=1684074569&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-1-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                         "keywords": "für \"Küchenwaage\""
//                     },
//                     "coupon": {
//                         "badge_text": "Spare 5 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "rating": 4.5,
//                     "ratings_total": 1055,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 6
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 10.99,
//                             "raw": "€10,99",
//                             "name": "10,99 €",
//                             "asin": "B08RCS3JC4",
//                             "link": "https://www.amazon.de/K%C3%BCchenwaage-digital-Professionelle-digitalwaage-Tara-Funktion/dp/B08RCS3JC4/ref=sxbs_aspa_sqa?c_c=315252645&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenwaage&keywords=K%C3%BCchenwaage&pd_rd_i=B08RCS3JC4&pd_rd_r=f2de42b4-4ea2-4ace-a23d-4124de2a5690&pd_rd_w=k4Dnx&pd_rd_wg=Qfa9B&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=28DMR6V23W8G6SQF30Q9&qid=1684074569&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-1-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 10.99,
//                         "raw": "€10,99",
//                         "name": "10,99 €",
//                         "asin": "B08RCS3JC4",
//                         "link": "https://www.amazon.de/K%C3%BCchenwaage-digital-Professionelle-digitalwaage-Tara-Funktion/dp/B08RCS3JC4/ref=sxbs_aspa_sqa?c_c=315252645&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenwaage&keywords=K%C3%BCchenwaage&pd_rd_i=B08RCS3JC4&pd_rd_r=f2de42b4-4ea2-4ace-a23d-4124de2a5690&pd_rd_w=k4Dnx&pd_rd_wg=Qfa9B&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=28DMR6V23W8G6SQF30Q9&qid=1684074569&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-1-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a"
//                     }
//                 },
//                 {
//                     "position": 60,
//                     "title": "Eono by Amazon - Digitale Küchenwaage, Premium Edelstahl-Lebensmittelwaage, wiegt Gramm und Unzen zum Backen und Kochen 5 kg/1 g - 15 Jahre Garantie",
//                     "asin": "B088QY2JR5",
//                     "link": "https://www.amazon.de/Eono-Amazon-Digitale-K%C3%BCchenwaage-Edelstahl-Lebensmittelwaage/dp/B088QY2JR5/ref=sxbs_aspa_sqa?c_c=315252645&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenwaage&keywords=K%C3%BCchenwaage&pd_rd_i=B088QY2JR5&pd_rd_r=f2de42b4-4ea2-4ace-a23d-4124de2a5690&pd_rd_w=k4Dnx&pd_rd_wg=Qfa9B&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=28DMR6V23W8G6SQF30Q9&qid=1684074569&refinements=p_36%3A5000-10000&sbo=EO%2BcdOgoisx3waG5xqcZQA%3D%3D&sr=1-2-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Q8ijXe3wL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 10 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "rating": 4.5,
//                     "ratings_total": 31680,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 6
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 9.99,
//                             "raw": "€9,99"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 17.99,
//                             "raw": "€17,99"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 9.99,
//                         "raw": "€9,99"
//                     }
//                 },
//                 {
//                     "position": 61,
//                     "title": "Soehnle Page Compact 300 weiß, digitale Küchenwaage bis zu 5 kg Tragkraft, Küchenwaage mit leicht ablesbarer LCD-Anzeige, Digitalwaage mit Zuwiegefunktion",
//                     "asin": "B079J5CWSY",
//                     "link": "https://www.amazon.de/Soehnle-61501-K%C3%BCchenwaage-LCD-Anzeige-Zuwiegefunktion/dp/B079J5CWSY/ref=sxbs_aspa_sqa?c_c=315252645&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenwaage&keywords=K%C3%BCchenwaage&pd_rd_i=B079J5CWSY&pd_rd_r=f2de42b4-4ea2-4ace-a23d-4124de2a5690&pd_rd_w=k4Dnx&pd_rd_wg=Qfa9B&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=28DMR6V23W8G6SQF30Q9&qid=1684074569&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-4-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61TsR+SqBVL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 8915,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 6
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 11.99,
//                             "raw": "€11,99",
//                             "name": "11,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 19.99,
//                             "raw": "€19,99",
//                             "name": "11,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 11.99,
//                         "raw": "€11,99",
//                         "name": "11,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 62,
//                     "title": "ETEKCITY Küchenwaage, Küchenwaage digital 5KG mit großem LCD-Display,Digitalwaage, Briefwaage mit Großer Edelstahl Wiegefläche, Flüssigkeitsmessung, hohe Präzision auf bis zu 1g, Tara-Funktion, Silber",
//                     "asin": "B07PTNZT75",
//                     "link": "https://www.amazon.de/ETEKCITY-Digitalwaage-elektronische-Fl%C3%BCssigkeitsmessung-Tara-Funktion/dp/B07PTNZT75/ref=sxbs_aspa_sqa?c_c=315252645&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenwaage&keywords=K%C3%BCchenwaage&pd_rd_i=B07PTNZT75&pd_rd_r=f2de42b4-4ea2-4ace-a23d-4124de2a5690&pd_rd_w=k4Dnx&pd_rd_wg=Qfa9B&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=28DMR6V23W8G6SQF30Q9&qid=1684074569&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-5-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/7157x4p8zTL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 10 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "rating": 4.6,
//                     "ratings_total": 146810,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 6
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 11.99,
//                             "raw": "€11,99",
//                             "name": "11,99 €",
//                             "asin": "B07PTNZT75",
//                             "link": "https://www.amazon.de/ETEKCITY-Digitalwaage-elektronische-Fl%C3%BCssigkeitsmessung-Tara-Funktion/dp/B07PTNZT75/ref=sxbs_aspa_sqa?c_c=315252645&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenwaage&keywords=K%C3%BCchenwaage&pd_rd_i=B07PTNZT75&pd_rd_r=f2de42b4-4ea2-4ace-a23d-4124de2a5690&pd_rd_w=k4Dnx&pd_rd_wg=Qfa9B&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=28DMR6V23W8G6SQF30Q9&qid=1684074569&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-5-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 11.99,
//                         "raw": "€11,99",
//                         "name": "11,99 €",
//                         "asin": "B07PTNZT75",
//                         "link": "https://www.amazon.de/ETEKCITY-Digitalwaage-elektronische-Fl%C3%BCssigkeitsmessung-Tara-Funktion/dp/B07PTNZT75/ref=sxbs_aspa_sqa?c_c=315252645&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenwaage&keywords=K%C3%BCchenwaage&pd_rd_i=B07PTNZT75&pd_rd_r=f2de42b4-4ea2-4ace-a23d-4124de2a5690&pd_rd_w=k4Dnx&pd_rd_wg=Qfa9B&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=28DMR6V23W8G6SQF30Q9&qid=1684074569&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-5-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a"
//                     }
//                 },
//                 {
//                     "position": 63,
//                     "title": "Küchenwaage Digital, 10KG Küchenwaage mit LCD Display und Tara Funktion, Küchenwaage zum Backen und Kochen, Messen von Zutaten, Schmuck so weiter, 1g",
//                     "asin": "B093D4YH8N",
//                     "link": "https://www.amazon.de/K%C3%BCchenwaage-Digital-Display-Funktion-Zutaten/dp/B093D4YH8N/ref=sxbs_aspa_sqa?c_c=315252645&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenwaage&keywords=K%C3%BCchenwaage&pd_rd_i=B093D4YH8N&pd_rd_r=f2de42b4-4ea2-4ace-a23d-4124de2a5690&pd_rd_w=k4Dnx&pd_rd_wg=Qfa9B&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=28DMR6V23W8G6SQF30Q9&qid=1684074569&refinements=p_36%3A5000-10000&sbo=EO%2BcdOgoisx3waG5xqcZQA%3D%3D&sr=1-6-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81NYy-VBwsL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 20 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "rating": 4.8,
//                     "ratings_total": 3075,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 6
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 14.99,
//                             "raw": "€14,99"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 16.99,
//                             "raw": "€16,99"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 14.99,
//                         "raw": "€14,99"
//                     }
//                 },
//                 {
//                     "position": 64,
//                     "title": "GRIFEMA Profi Digitale Küchenwaage (Hochpräzise 1g-5000g) - Feinwaage mit LCD Display und 2 Batterien, Waage Küchenwaage Abstellautomatik und Tara, Digitalwaage mit 6 Maßeinheit, Briefwaage Edelstahl",
//                     "asin": "B08NDVTN5C",
//                     "link": "https://www.amazon.de/GRIFEMA-K%C3%BCchenwaage-LCD-Display-Electronische-Exklusiv/dp/B08NDVTN5C/ref=sxbs_aspa_sqa?c_c=315252645&content-id=amzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607%3Aamzn1.sym.f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&cv_ct_cx=K%C3%BCchenwaage&keywords=K%C3%BCchenwaage&pd_rd_i=B08NDVTN5C&pd_rd_r=f2de42b4-4ea2-4ace-a23d-4124de2a5690&pd_rd_w=k4Dnx&pd_rd_wg=Qfa9B&pf_rd_p=f5cee6b1-7ec5-4a7a-adf7-e32232e4a607&pf_rd_r=28DMR6V23W8G6SQF30Q9&qid=1684074569&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-8-73334bb4-9627-4ee0-9973-f4c5cbe3fe5a",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71jf9hhP45L._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 1417,
//                     "is_carousel": true,
//                     "carousel": {
//                         "id": "MAIN",
//                         "total_items": 6
//                     },
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 8.99,
//                             "raw": "€8,99",
//                             "name": "8,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 9.59,
//                             "raw": "€9,59",
//                             "name": "8,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 8.99,
//                         "raw": "€8,99",
//                         "name": "8,99 €",
//                         "is_primary": true
//                     }
//                 }
//             ]
//         },
//         {
//             "idea": "Thermometer für Lebensmittel",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "Grillthermometer kabellos, Paris Rhône Smarte Bluetooth-Fleischthermometer mit 80m Reichweite, IP67 WiFi-Meat Thermometer, Bratenthermometer für Backofen, Grills und Heißluftfritteuse via App",
//                     "asin": "B0BS1C3CG5",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfYXRmOjIwMTU0MjY5MDA1Mjk4OjowOjo&url=%2FGrillthermometer-Bluetooth-Fleischthermometer-Thermometer-Bratenthermometer-Hei%25C3%259Fluftfritteuse%2Fdp%2FB0BS1C3CG5%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81Y1AhVnD6L._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 30 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 16,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.99,
//                             "raw": "€99,99",
//                             "name": "99,99 €",
//                             "asin": "B0BS1C3CG5",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfYXRmOjIwMTU0MjY5MDA1Mjk4OjowOjo&url=%2FGrillthermometer-Bluetooth-Fleischthermometer-Thermometer-Bratenthermometer-Hei%25C3%259Fluftfritteuse%2Fdp%2FB0BS1C3CG5%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99.99,
//                         "raw": "€99,99",
//                         "name": "99,99 €",
//                         "asin": "B0BS1C3CG5",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfYXRmOjIwMTU0MjY5MDA1Mjk4OjowOjo&url=%2FGrillthermometer-Bluetooth-Fleischthermometer-Thermometer-Bratenthermometer-Hei%25C3%259Fluftfritteuse%2Fdp%2FB0BS1C3CG5%2Fref%3Dsr_1_1_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-1-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "TROTEC Lebensmittel-Thermometer BP5F Haushaltsthermometer Einstichthermometer Grillthermometer",
//                     "asin": "B00BLIRJEU",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfYXRmOjIwMTE1NTExNDc5Njk4OjowOjo&url=%2FTROTEC-Lebensmittel-Thermometer-BP5-F%2Fdp%2FB00BLIRJEU%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51Cm5fRNHKL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.2,
//                     "ratings_total": 12,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.95,
//                             "raw": "€64,95",
//                             "name": "64,95 €",
//                             "asin": "B00BLIRJEU",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfYXRmOjIwMTE1NTExNDc5Njk4OjowOjo&url=%2FTROTEC-Lebensmittel-Thermometer-BP5-F%2Fdp%2FB00BLIRJEU%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.95,
//                         "raw": "€64,95",
//                         "name": "64,95 €",
//                         "asin": "B00BLIRJEU",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfYXRmOjIwMTE1NTExNDc5Njk4OjowOjo&url=%2FTROTEC-Lebensmittel-Thermometer-BP5-F%2Fdp%2FB00BLIRJEU%2Fref%3Dsr_1_2_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 3,
//                     "title": "Grillthermometer, Smart Alarm BBQ Thermometer für Truthahnbraten für Milch für Lebensmittel für Fleisch",
//                     "asin": "B0B5VPMSH6",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfYXRmOjIwMDYwOTcxNTU4MDk4OjowOjo&url=%2FJUMZ-Grillthermometer-Thermometer-Truthahnbraten-Lebensmittel%2Fdp%2FB0B5VPMSH6%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61EqF9Hrc2L._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 5 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 61.42,
//                             "raw": "€61,42",
//                             "asin": "B0B5VPMSH6",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfYXRmOjIwMDYwOTcxNTU4MDk4OjowOjo&url=%2FJUMZ-Grillthermometer-Thermometer-Truthahnbraten-Lebensmittel%2Fdp%2FB0B5VPMSH6%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 61.42,
//                         "raw": "€61,42",
//                         "asin": "B0B5VPMSH6",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfYXRmOjIwMDYwOTcxNTU4MDk4OjowOjo&url=%2FJUMZ-Grillthermometer-Thermometer-Truthahnbraten-Lebensmittel%2Fdp%2FB0B5VPMSH6%2Fref%3Dsr_1_3_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-3-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 4,
//                     "title": "SWOQ Grillthermometer, Thermometer für Lebensmittel für Milch für Truthahnbraten für Fleisch",
//                     "asin": "B0B5VT8RTQ",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfYXRmOjIwMDg5NTg1NzU2Mzk4OjowOjo&url=%2FSWOQ-Grillthermometer-Thermometer-Lebensmittel-Truthahnbraten%2Fdp%2FB0B5VT8RTQ%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61qKNJe0P-L._AC_UL400_.jpg",
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 67.35,
//                             "raw": "€67,35",
//                             "asin": "B0B5VT8RTQ",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfYXRmOjIwMDg5NTg1NzU2Mzk4OjowOjo&url=%2FSWOQ-Grillthermometer-Thermometer-Lebensmittel-Truthahnbraten%2Fdp%2FB0B5VT8RTQ%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 67.35,
//                         "raw": "€67,35",
//                         "asin": "B0B5VT8RTQ",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfYXRmOjIwMDg5NTg1NzU2Mzk4OjowOjo&url=%2FSWOQ-Grillthermometer-Thermometer-Lebensmittel-Truthahnbraten%2Fdp%2FB0B5VT8RTQ%2Fref%3Dsr_1_4_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-4-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9hdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 5,
//                     "title": "TROTEC Lebensmittel-Thermometer BP5F Haushaltsthermometer Einstichthermometer Grillthermometer",
//                     "asin": "B00BLIRJEU",
//                     "link": "https://www.amazon.de/TROTEC-Lebensmittel-Thermometer-BP5-F/dp/B00BLIRJEU/ref=sr_1_5?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-5",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51Cm5fRNHKL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.2,
//                     "ratings_total": 12,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.95,
//                             "raw": "€64,95",
//                             "name": "64,95 €",
//                             "asin": "B00BLIRJEU",
//                             "link": "https://www.amazon.de/TROTEC-Lebensmittel-Thermometer-BP5-F/dp/B00BLIRJEU/ref=sr_1_5?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-5"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.95,
//                         "raw": "€64,95",
//                         "name": "64,95 €",
//                         "asin": "B00BLIRJEU",
//                         "link": "https://www.amazon.de/TROTEC-Lebensmittel-Thermometer-BP5-F/dp/B00BLIRJEU/ref=sr_1_5?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-5"
//                     }
//                 },
//                 {
//                     "position": 6,
//                     "title": "Testo 0563 1063 106 Lebensmittel-Kern-Thermometer, inklusive TopSafe (wasserdichte Schutzhülle, IP67), Befestigungs-Clip, Fühler-Schutzkappe und Batterien",
//                     "asin": "B001TUTR1I",
//                     "link": "https://www.amazon.de/Testo-0563-1063-Lebensmittel-Kern-Thermometer-F%C3%BChler-Schutzkappe/dp/B001TUTR1I/ref=sr_1_6?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-6",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/31H4eXMXZbL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 75,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.24,
//                             "raw": "€64,24",
//                             "name": "64,24 €",
//                             "asin": "B001TUTR1I",
//                             "link": "https://www.amazon.de/Testo-0563-1063-Lebensmittel-Kern-Thermometer-F%C3%BChler-Schutzkappe/dp/B001TUTR1I/ref=sr_1_6?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-6"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.24,
//                         "raw": "€64,24",
//                         "name": "64,24 €",
//                         "asin": "B001TUTR1I",
//                         "link": "https://www.amazon.de/Testo-0563-1063-Lebensmittel-Kern-Thermometer-F%C3%BChler-Schutzkappe/dp/B001TUTR1I/ref=sr_1_6?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-6"
//                     }
//                 },
//                 {
//                     "position": 7,
//                     "title": "TFA Dostmann Scantemp 330 Infrarot-Thermometer, berührungsloses Messen, ideal für Lebensmittel, Handwerk, Hobby, L 32 x B 78 x H 133 mm",
//                     "asin": "B00JOGA5T2",
//                     "link": "https://www.amazon.de/TFA-Dostmann-Infrarot-Thermometer-ber%C3%BChrungsloses-Lebensmittel/dp/B00JOGA5T2/ref=sr_1_7?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-7",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/517i1cEPW7L._AC_UL400_.jpg",
//                     "rating": 3.8,
//                     "ratings_total": 53,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 32.99,
//                             "raw": "€32,99",
//                             "name": "32,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 45.99,
//                             "raw": "€45,99",
//                             "name": "32,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 32.99,
//                         "raw": "€32,99",
//                         "name": "32,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 8,
//                     "title": "TFA Dostmann Thermo Jack digitales Einstichthermometer, 30.1047.02, Temperaturkontrolle von Lebensmittel, abwaschbar, L 116 (192) x B 38 x H 20 mm",
//                     "asin": "B007MS7NU8",
//                     "link": "https://www.amazon.de/TFA-Dostmann-Einstichthermometer-30-1047-02-Temperaturkontrolle/dp/B007MS7NU8/ref=sr_1_8?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-8",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61nnnAk6raL._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 57,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 22.99,
//                             "raw": "€22,99",
//                             "name": "22,99 €",
//                             "asin": "B007MS7NU8",
//                             "link": "https://www.amazon.de/TFA-Dostmann-Einstichthermometer-30-1047-02-Temperaturkontrolle/dp/B007MS7NU8/ref=sr_1_8?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-8"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 22.99,
//                         "raw": "€22,99",
//                         "name": "22,99 €",
//                         "asin": "B007MS7NU8",
//                         "link": "https://www.amazon.de/TFA-Dostmann-Einstichthermometer-30-1047-02-Temperaturkontrolle/dp/B007MS7NU8/ref=sr_1_8?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-8"
//                     }
//                 },
//                 {
//                     "position": 9,
//                     "title": "Classic Superfast, Thermapen 3,&nbsp;Profi-Thermometer f&uuml;r Lebensmittel, grau",
//                     "asin": "B0747RHDMK",
//                     "link": "https://www.amazon.de/Classic-Superfast-Thermapen-Profi-Thermometer-Lebensmittel/dp/B0747RHDMK/ref=sr_1_9?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-9",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/31o6aVclqBL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 41,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.59,
//                             "raw": "€64,59",
//                             "name": "64,59 €",
//                             "asin": "B0747RHDMK",
//                             "link": "https://www.amazon.de/Classic-Superfast-Thermapen-Profi-Thermometer-Lebensmittel/dp/B0747RHDMK/ref=sr_1_9?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-9"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.59,
//                         "raw": "€64,59",
//                         "name": "64,59 €",
//                         "asin": "B0747RHDMK",
//                         "link": "https://www.amazon.de/Classic-Superfast-Thermapen-Profi-Thermometer-Lebensmittel/dp/B0747RHDMK/ref=sr_1_9?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-9"
//                     }
//                 },
//                 {
//                     "position": 10,
//                     "title": "Hochpräzises Elektronisches Thermometer, Digitales Thermometer EM502C für Lebensmittel Industrie mit Digitaler Wassertemperatur Anzeige",
//                     "asin": "B07TBDGQ26",
//                     "link": "https://www.amazon.de/Hochpr%C3%A4zises-Elektronisches-Thermometer-Lebensmittel-Wassertemperatur/dp/B07TBDGQ26/ref=sr_1_10?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-10",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51WhAcFTyuL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 10 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 3,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 52.07,
//                             "raw": "€52,07",
//                             "name": "52,07 €",
//                             "asin": "B07TBDGQ26",
//                             "link": "https://www.amazon.de/Hochpr%C3%A4zises-Elektronisches-Thermometer-Lebensmittel-Wassertemperatur/dp/B07TBDGQ26/ref=sr_1_10?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-10"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 52.07,
//                         "raw": "€52,07",
//                         "name": "52,07 €",
//                         "asin": "B07TBDGQ26",
//                         "link": "https://www.amazon.de/Hochpr%C3%A4zises-Elektronisches-Thermometer-Lebensmittel-Wassertemperatur/dp/B07TBDGQ26/ref=sr_1_10?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-10"
//                     }
//                 },
//                 {
//                     "position": 11,
//                     "title": "Einstich Thermometer Lebensmittel Nahrung Frucht Obst Kontrolle T12",
//                     "asin": "B004MPAAKO",
//                     "link": "https://www.amazon.de/Einstich-Thermometer-Lebensmittel-Nahrung-Kontrolle/dp/B004MPAAKO/ref=sr_1_11?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-11",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51hlYgo+TZL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 73.99,
//                             "raw": "€73,99",
//                             "asin": "B004MPAAKO",
//                             "link": "https://www.amazon.de/Einstich-Thermometer-Lebensmittel-Nahrung-Kontrolle/dp/B004MPAAKO/ref=sr_1_11?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-11"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 73.99,
//                         "raw": "€73,99",
//                         "asin": "B004MPAAKO",
//                         "link": "https://www.amazon.de/Einstich-Thermometer-Lebensmittel-Nahrung-Kontrolle/dp/B004MPAAKO/ref=sr_1_11?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-11"
//                     }
//                 },
//                 {
//                     "position": 12,
//                     "title": "Etekcity Lasergrip 800 (Nicht für Menschen) Digitales Infrarot-Thermometer Laser-Temperaturpistole Berührungslos-58℉ -1382℉ (-50℃ bis 750℃), Standardgröße, Gelb & Schwarz",
//                     "asin": "B00DMI62HM",
//                     "link": "https://www.amazon.de/ETEKCITY-Thermometer-Laser-Pyrometer-Infrarotmesspistole-Hintergrundbeleuchtung/dp/B00DMI62HM/ref=sr_1_12_mod_primary_new?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-12",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71R0Eoylj8L._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 6882,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.93,
//                             "raw": "€89,93",
//                             "name": "89,93 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 95.17,
//                             "raw": "€95,17",
//                             "name": "95,17 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.93,
//                         "raw": "€89,93",
//                         "name": "89,93 €"
//                     }
//                 },
//                 {
//                     "position": 13,
//                     "title": "Heston Blumenthal Precision by Salter 540A HBBKCR Premium 5-in-1-Digitalthermometer – Heißluft-Fritteusen-Sonde, Grill, Rösten, Zwei Sensoren, Lebensmittel-/Ofentemperatur, Genauigkeit 0,1 °C",
//                     "asin": "B00Q505YE6",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMDc4MTQ3OTMxOTk4OjowOjo&url=%2FHeston-Blumenthal-digitales-Thermometer-Salter%2Fdp%2FB00Q505YE6%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA2X5QKTIJPT88W",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/615-CZp12-L._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 11,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 3.8,
//                     "ratings_total": 109,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 68.99,
//                             "raw": "€68,99",
//                             "name": "68,99 €",
//                             "asin": "B00Q505YE6",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMDc4MTQ3OTMxOTk4OjowOjo&url=%2FHeston-Blumenthal-digitales-Thermometer-Salter%2Fdp%2FB00Q505YE6%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA2X5QKTIJPT88W"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 68.99,
//                         "raw": "€68,99",
//                         "name": "68,99 €",
//                         "asin": "B00Q505YE6",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMDc4MTQ3OTMxOTk4OjowOjo&url=%2FHeston-Blumenthal-digitales-Thermometer-Salter%2Fdp%2FB00Q505YE6%2Fref%3Dsr_1_13_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-13-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA2X5QKTIJPT88W"
//                     }
//                 },
//                 {
//                     "position": 14,
//                     "title": "flintronic Kabellose Smarte Fleischthermometer, 50m Komplett Kabelloses, Smart-Fleischthermometer für Ofen Grill Küche BBQ, Smoker Rotisserie mit Bluetooth Anschlussmöglichkeiten",
//                     "asin": "B0BRXVB5LP",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMTE1MjU0MTQ1Nzk4OjowOjo&url=%2Fflintronic-Fleischthermometer-Kabelloses-Smart-Fleischthermometer-Anschlussm%25C3%25B6glichkeiten%2Fdp%2FB0BRXVB5LP%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/718w65dXAbL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 3.3,
//                     "ratings_total": 29,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.99,
//                             "raw": "€79,99",
//                             "name": "79,99 €",
//                             "asin": "B0BRXVB5LP",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMTE1MjU0MTQ1Nzk4OjowOjo&url=%2Fflintronic-Fleischthermometer-Kabelloses-Smart-Fleischthermometer-Anschlussm%25C3%25B6glichkeiten%2Fdp%2FB0BRXVB5LP%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 79.99,
//                         "raw": "€79,99",
//                         "name": "79,99 €",
//                         "asin": "B0BRXVB5LP",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMTE1MjU0MTQ1Nzk4OjowOjo&url=%2Fflintronic-Fleischthermometer-Kabelloses-Smart-Fleischthermometer-Anschlussm%25C3%25B6glichkeiten%2Fdp%2FB0BRXVB5LP%2Fref%3Dsr_1_14_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-14-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 15,
//                     "title": "PeakTech 5400 - IR Messgerät, Infrarotthermometer (-50°/260°), Oberflächen Thermometer, Taupunktscanner (-30°/100°), LED Umweltmessgerät, Thermodetektor Feuchtigkeit (0-100%), Taupunkt Messgerät",
//                     "asin": "B01MAWN60N",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMDg3MjI3MTQ3Mzk4OjowOjo&url=%2FPeakTech-IR-Thermometer-Taupunktmessger%25C3%25A4t-Oberfl%25C3%25A4chentemperatur-Luftfeuchtigkeit%2Fdp%2FB01MAWN60N%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71tiW4fhGXL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 85.1,
//                             "raw": "€85,10",
//                             "asin": "B01MAWN60N",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMDg3MjI3MTQ3Mzk4OjowOjo&url=%2FPeakTech-IR-Thermometer-Taupunktmessger%25C3%25A4t-Oberfl%25C3%25A4chentemperatur-Luftfeuchtigkeit%2Fdp%2FB01MAWN60N%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 85.1,
//                         "raw": "€85,10",
//                         "asin": "B01MAWN60N",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMDg3MjI3MTQ3Mzk4OjowOjo&url=%2FPeakTech-IR-Thermometer-Taupunktmessger%25C3%25A4t-Oberfl%25C3%25A4chentemperatur-Luftfeuchtigkeit%2Fdp%2FB01MAWN60N%2Fref%3Dsr_1_15_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-15-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 16,
//                     "title": "Kabellose Fleischthermometer, IPX7 Meat Thermometer Für Oven, Grill, Kitchen BBQ, Smoker, Pfanne und Rotisserie, Bratenthermometer Perfekter Fleischgenuss via App",
//                     "asin": "B0BTDKBFP7",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMTI3ODY5NTEzODk4OjowOjo&url=%2FFleischthermometer-Thermometer-Rotisserie-Bratenthermometer-Fleischgenuss%2Fdp%2FB0BTDKBFP7%2Fref%3Dsr_1_16_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-16-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71yuWasHRJL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 20,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 1232,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.99,
//                             "raw": "€89,99",
//                             "name": "89,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.99,
//                             "raw": "€99,99",
//                             "name": "89,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.99,
//                         "raw": "€89,99",
//                         "name": "89,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 17,
//                     "title": "XINGYU Sonden-Thermometer, Sonden-Typ Küchen-Lebensmittelthermometer, Lebensmittel-Thermometer, Lebensmittel-Thermometer, Thermometer, elektronisches Thermometer, Wassertemperatur, Öl",
//                     "asin": "B086WJV9KS",
//                     "link": "https://www.amazon.de/XINGYU-Sonden-Thermometer-K%C3%BCchen-Lebensmittelthermometer-Lebensmittel-Thermometer-Wassertemperatur/dp/B086WJV9KS/ref=sr_1_17?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-17",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51tMgZNQrxL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 90.39,
//                             "raw": "€90,39",
//                             "asin": "B086WJV9KS",
//                             "link": "https://www.amazon.de/XINGYU-Sonden-Thermometer-K%C3%BCchen-Lebensmittelthermometer-Lebensmittel-Thermometer-Wassertemperatur/dp/B086WJV9KS/ref=sr_1_17?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-17"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 90.39,
//                         "raw": "€90,39",
//                         "asin": "B086WJV9KS",
//                         "link": "https://www.amazon.de/XINGYU-Sonden-Thermometer-K%C3%BCchen-Lebensmittelthermometer-Lebensmittel-Thermometer-Wassertemperatur/dp/B086WJV9KS/ref=sr_1_17?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-17"
//                     }
//                 },
//                 {
//                     "position": 18,
//                     "title": "xiamenchangketongmaoyi Fleischthermometer Kochthermometer Digital Digitale Lebensmittel Thermometer Lebensmittel Thermometer Ideal für Raucher Orange",
//                     "asin": "B08G11R57C",
//                     "link": "https://www.amazon.de/xiamenchangketongmaoyi-Fleischthermometer-Kochthermometer-Lebensmittel-Thermometer/dp/B08G11R57C/ref=sr_1_18?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-18",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/5137ueKgbSL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 32.56,
//                             "raw": "€32,56",
//                             "asin": "B08G11R57C",
//                             "link": "https://www.amazon.de/xiamenchangketongmaoyi-Fleischthermometer-Kochthermometer-Lebensmittel-Thermometer/dp/B08G11R57C/ref=sr_1_18?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-18"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 32.56,
//                         "raw": "€32,56",
//                         "asin": "B08G11R57C",
//                         "link": "https://www.amazon.de/xiamenchangketongmaoyi-Fleischthermometer-Kochthermometer-Lebensmittel-Thermometer/dp/B08G11R57C/ref=sr_1_18?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-18"
//                     }
//                 },
//                 {
//                     "position": 19,
//                     "title": "xiamenchangketongmaoyi Kochthermometer Fleischthermometer Lebensmittel Thermometer Sonde Lebensmittel Thermometer Perfekte für Sweets Fleisch Thermometer Wireless",
//                     "asin": "B08G1139FP",
//                     "link": "https://www.amazon.de/xiamenchangketongmaoyi-Kochthermometer-Fleischthermometer-Lebensmittel-Thermometer/dp/B08G1139FP/ref=sr_1_19?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-19",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51z9BDpiFaL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 88.88,
//                             "raw": "€88,88",
//                             "asin": "B08G1139FP",
//                             "link": "https://www.amazon.de/xiamenchangketongmaoyi-Kochthermometer-Fleischthermometer-Lebensmittel-Thermometer/dp/B08G1139FP/ref=sr_1_19?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-19"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 88.88,
//                         "raw": "€88,88",
//                         "asin": "B08G1139FP",
//                         "link": "https://www.amazon.de/xiamenchangketongmaoyi-Kochthermometer-Fleischthermometer-Lebensmittel-Thermometer/dp/B08G1139FP/ref=sr_1_19?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-19"
//                     }
//                 },
//                 {
//                     "position": 20,
//                     "title": "xiamenchangketongmaoyi Fleischthermometer Für Backofen Kochthermometer Fleisch Thermometer Sonde BBQ Digitale Fleisch Thermometer Digitale Lebensmittel Thermometer",
//                     "asin": "B08G118H7D",
//                     "link": "https://www.amazon.de/xiamenchangketongmaoyi-Fleischthermometer-Kochthermometer-Thermometer-Lebensmittel/dp/B08G118H7D/ref=sr_1_20?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-20",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51quh7yQ4VL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 12.24,
//                             "raw": "€12,24",
//                             "asin": "B08G118H7D",
//                             "link": "https://www.amazon.de/xiamenchangketongmaoyi-Fleischthermometer-Kochthermometer-Thermometer-Lebensmittel/dp/B08G118H7D/ref=sr_1_20?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-20"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 12.24,
//                         "raw": "€12,24",
//                         "asin": "B08G118H7D",
//                         "link": "https://www.amazon.de/xiamenchangketongmaoyi-Fleischthermometer-Kochthermometer-Thermometer-Lebensmittel/dp/B08G118H7D/ref=sr_1_20?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-20"
//                     }
//                 },
//                 {
//                     "position": 21,
//                     "title": "SHUIYUE TA612C Thermoelement-Thermometer Typ K/J mit 4 Kanälen -200~1372℃/ -328~2501℉ Digitales LCD-Handheld-Temperaturmessgerät 5000 Gruppen Datenspeicherung mit USB-Daten-Upload und Echtzeitmessung",
//                     "asin": "B0BLC2RYJ6",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMTUwNjc3MjMyMTk4OjowOjo&url=%2FSHUIYUE-Thermoelement-Thermometer-LCD-Handheld-Temperaturmessger%25C3%25A4t-Datenspeicherung-USB-Daten-Upload%2Fdp%2FB0BLC2RYJ6%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71O+9BPasWL._AC_UL400_.jpg",
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 71.99,
//                             "raw": "€71,99",
//                             "asin": "B0BLC2RYJ6",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMTUwNjc3MjMyMTk4OjowOjo&url=%2FSHUIYUE-Thermoelement-Thermometer-LCD-Handheld-Temperaturmessger%25C3%25A4t-Datenspeicherung-USB-Daten-Upload%2Fdp%2FB0BLC2RYJ6%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 71.99,
//                         "raw": "€71,99",
//                         "asin": "B0BLC2RYJ6",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMTUwNjc3MjMyMTk4OjowOjo&url=%2FSHUIYUE-Thermoelement-Thermometer-LCD-Handheld-Temperaturmessger%25C3%25A4t-Datenspeicherung-USB-Daten-Upload%2Fdp%2FB0BLC2RYJ6%2Fref%3Dsr_1_21_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-21-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 22,
//                     "title": "USB Datenlogger UT330A / UT330C Temperatur Daten Logger 60000 Datensätze für Temperatur Luftfeuchtigkeit und Druck(UT330A)",
//                     "asin": "B07VYRKNBY",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMTUzODM0MDk1Mjk4OjowOjo&url=%2FDatenlogger-UT330A-Temperatur-Datens%25C3%25A4tze-Luftfeuchtigkeit%2Fdp%2FB07VYRKNBY%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA1A6WWKJ95BCLL",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51C6iQLiA5L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 2.4,
//                     "ratings_total": 3,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.79,
//                             "raw": "€64,79",
//                             "name": "64,79 €",
//                             "asin": "B07VYRKNBY",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMTUzODM0MDk1Mjk4OjowOjo&url=%2FDatenlogger-UT330A-Temperatur-Datens%25C3%25A4tze-Luftfeuchtigkeit%2Fdp%2FB07VYRKNBY%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA1A6WWKJ95BCLL"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.79,
//                         "raw": "€64,79",
//                         "name": "64,79 €",
//                         "asin": "B07VYRKNBY",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMTUzODM0MDk1Mjk4OjowOjo&url=%2FDatenlogger-UT330A-Temperatur-Datens%25C3%25A4tze-Luftfeuchtigkeit%2Fdp%2FB07VYRKNBY%2Fref%3Dsr_1_22_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-22-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1%26smid%3DA1A6WWKJ95BCLL"
//                     }
//                 },
//                 {
//                     "position": 23,
//                     "title": "Inkbird IBT-4XS Bluetooth Barbecue Grillthermometer mit dem 1000mAh Li-Batterie, Magnet Montagedesign und Rotations Lesebildschirm Thermometer für BBQ, Küche, Grill, Ofen, Fleisch",
//                     "asin": "B076QBJVWX",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMDQ4MTAzNzE3ODk4OjowOjo&url=%2FInkbird-Grillthermometer-Li-Batterie-Montagedesign-Lesebildschirm%2Fdp%2FB076QBJVWX%2Fref%3Dsr_1_23_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-23-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/613rMMG9N+L._AC_UL400_.jpg",
//                     "bestseller": {
//                         "link": "https://www.amazon.de/gp/bestsellers/kitchen/3311411/ref=sr_bs_22_3311411_1",
//                         "category": "Fleischgabeln & Grillgabeln"
//                     },
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 3208,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 47.24,
//                             "raw": "€47,24",
//                             "name": "47,24 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 65.99,
//                             "raw": "€65,99",
//                             "name": "47,24 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 47.24,
//                         "raw": "€47,24",
//                         "name": "47,24 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 24,
//                     "title": "Inkbird IBT-6XS Grillthermometer mit 6 Sonden, Magnetisch BBQ Thermometer Bluetooth Fleischthermometer mit 1000mAh Li-Batterie, Bratenthermometer mit 180 Grad Bildschirmanzeige Drehen für BBQ,Backofen",
//                     "asin": "B071KY366R",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMDQ4MTAzNzE2ODk4OjowOjo&url=%2FInkbird-Thermometer-Funk-Grillthermometer-Temperaturf%25C3%25BChlern-thermometer%2Fdp%2FB071KY366R%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61Q7+scC-sL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 277,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 64.99,
//                             "raw": "€64,99",
//                             "name": "64,99 €",
//                             "asin": "B071KY366R",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMDQ4MTAzNzE2ODk4OjowOjo&url=%2FInkbird-Thermometer-Funk-Grillthermometer-Temperaturf%25C3%25BChlern-thermometer%2Fdp%2FB071KY366R%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 64.99,
//                         "raw": "€64,99",
//                         "name": "64,99 €",
//                         "asin": "B071KY366R",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MToxNDU4MTAyODQxMDYxODM0OjE2ODQwNzQ1NzQ6c3BfbXRmOjIwMDQ4MTAzNzE2ODk4OjowOjo&url=%2FInkbird-Thermometer-Funk-Grillthermometer-Temperaturf%25C3%25BChlern-thermometer%2Fdp%2FB071KY366R%2Fref%3Dsr_1_24_sspa%3Fkeywords%3DThermometer%2Bf%25C3%25BCr%2BLebensmittel%26qid%3D1684074574%26refinements%3Dp_36%253A5000-10000%26sr%3D8-24-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 25,
//                     "title": "Rysmliuhan Shop Thermometer Küche Fleischthermometer Salter Fleisch Thermometer Fleisch Thermometer Sonde BBQ Lebensmittel Thermometer Perfekte für BBQ",
//                     "asin": "B08DNHBHW1",
//                     "link": "https://www.amazon.de/Rysmliuhan-Shop-Thermometer-Fleischthermometer-Lebensmittel/dp/B08DNHBHW1/ref=sr_1_25?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-25",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51UVGYudL-L._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 20.57,
//                             "raw": "€20,57",
//                             "asin": "B08DNHBHW1",
//                             "link": "https://www.amazon.de/Rysmliuhan-Shop-Thermometer-Fleischthermometer-Lebensmittel/dp/B08DNHBHW1/ref=sr_1_25?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-25"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 20.57,
//                         "raw": "€20,57",
//                         "asin": "B08DNHBHW1",
//                         "link": "https://www.amazon.de/Rysmliuhan-Shop-Thermometer-Fleischthermometer-Lebensmittel/dp/B08DNHBHW1/ref=sr_1_25?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-25"
//                     }
//                 },
//                 {
//                     "position": 26,
//                     "title": "Gofeibao Thermometer Küche Fleischthermometer Für Backofen Instant Lesen Fleisch Thermometer BBQ Fleisch Thermometer Lebensmittel Thermometer",
//                     "asin": "B08G14HBQX",
//                     "link": "https://www.amazon.de/Gofeibao-Thermometer-Fleischthermometer-Backofen-Lebensmittel/dp/B08G14HBQX/ref=sr_1_26?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-26",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51Gm4pwgnqL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 21.06,
//                             "raw": "€21,06",
//                             "asin": "B08G14HBQX",
//                             "link": "https://www.amazon.de/Gofeibao-Thermometer-Fleischthermometer-Backofen-Lebensmittel/dp/B08G14HBQX/ref=sr_1_26?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-26"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 21.06,
//                         "raw": "€21,06",
//                         "asin": "B08G14HBQX",
//                         "link": "https://www.amazon.de/Gofeibao-Thermometer-Fleischthermometer-Backofen-Lebensmittel/dp/B08G14HBQX/ref=sr_1_26?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-26"
//                     }
//                 },
//                 {
//                     "position": 27,
//                     "title": "ThermoPro TempSpike Fleischthermometer Kabellos 150m Bluetooth Grillthermometer IP67 Meat Thermometer Bratenthermometer für Backofen Grill Smoker Rotisserie Airfryer Sicher im Geschirrspüler",
//                     "asin": "B0BC12NSRD",
//                     "link": "https://www.amazon.de/ThermoPro-Fleischthermometer-Grillthermometer-Bratenthermometer-Geschirrsp%C3%BCler/dp/B0BC12NSRD/ref=sr_1_27?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-27",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71fHKkqw9gL._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 2262,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 84.12,
//                             "raw": "€84,12",
//                             "name": "84,12 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 129.99,
//                             "raw": "€129,99",
//                             "name": "84,12 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 84.12,
//                         "raw": "€84,12",
//                         "name": "84,12 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 28,
//                     "title": "Bosch Infrarotthermometer UniversalTemp (-30°C…+500°C, einfach und präzise messen, 3 vordefinierte Materialgruppen)",
//                     "asin": "B07T85G8BZ",
//                     "link": "https://www.amazon.de/Bosch-Infrarot-Thermometer-UniversalTemp-Temperaturbereich/dp/B07T85G8BZ/ref=sr_1_28?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-28",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61t8GQF9E0L._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.6,
//                     "ratings_total": 4277,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 39.95,
//                             "raw": "€39,95",
//                             "name": "39,95 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 55.64,
//                             "raw": "€55,64",
//                             "name": "39,95 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 39.95,
//                         "raw": "€39,95",
//                         "name": "39,95 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 29,
//                     "title": "SuperFast Thermapen One Thermometer – Digitales Fleischthermometer für Küche, Lebensmittel, Kochen, Grill, BBQ, Smoker, Süßigkeiten, Heimbrauen, Kaffee und Öl, Frittieren, Mitternachtsblau",
//                     "asin": "B0BT4WYVPX",
//                     "link": "https://www.amazon.de/SuperFast-Thermapen-Thermometer-Fleischthermometer-Mitternachtsblau/dp/B0BT4WYVPX/ref=sr_1_29?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-29",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61J8ndVPB5L._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 50,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89.43,
//                             "raw": "€89,43"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 97.68,
//                             "raw": "€97,68"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 89.43,
//                         "raw": "€89,43"
//                     }
//                 },
//                 {
//                     "position": 30,
//                     "title": "Grillthermometer kabellos, Paris Rhône Smarte Bluetooth-Fleischthermometer mit 80m Reichweite, IP67 WiFi-Meat Thermometer, Bratenthermometer für Backofen, Grills und Heißluftfritteuse via App",
//                     "asin": "B0BS1C3CG5",
//                     "link": "https://www.amazon.de/Grillthermometer-Bluetooth-Fleischthermometer-Thermometer-Bratenthermometer-Hei%C3%9Fluftfritteuse/dp/B0BS1C3CG5/ref=sr_1_30?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-30",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81Y1AhVnD6L._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 30 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4.3,
//                     "ratings_total": 16,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.99,
//                             "raw": "€99,99",
//                             "name": "99,99 €",
//                             "asin": "B0BS1C3CG5",
//                             "link": "https://www.amazon.de/Grillthermometer-Bluetooth-Fleischthermometer-Thermometer-Bratenthermometer-Hei%C3%9Fluftfritteuse/dp/B0BS1C3CG5/ref=sr_1_30?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-30"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 99.99,
//                         "raw": "€99,99",
//                         "name": "99,99 €",
//                         "asin": "B0BS1C3CG5",
//                         "link": "https://www.amazon.de/Grillthermometer-Bluetooth-Fleischthermometer-Thermometer-Bratenthermometer-Hei%C3%9Fluftfritteuse/dp/B0BS1C3CG5/ref=sr_1_30?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-30"
//                     }
//                 },
//                 {
//                     "position": 31,
//                     "title": "RÖSLE Bratenthermometer digital, Hochwertiges Thermometer zur Bestimmung der idealen Fleischkerntemperatur auf dem Grill oder im Backofen, -20 °C bis 250 °C, mit Magnethalterung, Edelstahl 18/10",
//                     "asin": "B01DWDBWEE",
//                     "link": "https://www.amazon.de/R%C3%96SLE-Bratenthermometer-digital-Thermometer-Edelstahl/dp/B01DWDBWEE/ref=sr_1_31?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-31",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61VdRwlWjAL._AC_UL400_.jpg",
//                     "deal": {
//                         "link": "https://www.amazon.de/gp/goldbox/",
//                         "badge_text": "Befristetes Angebot"
//                     },
//                     "rating": 4.5,
//                     "ratings_total": 1004,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 31.99,
//                             "raw": "€31,99",
//                             "name": "31,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 52.95,
//                             "raw": "€52,95",
//                             "name": "31,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 31.99,
//                         "raw": "€31,99",
//                         "name": "31,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 32,
//                     "title": "TFA Dostmann Thermo Jack Pro Digitales Einstichthermometer, Profi-Klappthermometer, abwaschnar, ideal für Lebensmittel,L 150 (260) x B 40 x H 19 mm",
//                     "asin": "B00C19I5LO",
//                     "link": "https://www.amazon.de/TFA-Dostmann-Einstichthermometer-Profi-Klappthermometer-Lebensmittel/dp/B00C19I5LO/ref=sr_1_32?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-32",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61m+0228cOL._AC_UL400_.jpg",
//                     "rating": 3.5,
//                     "ratings_total": 17,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 60.96,
//                             "raw": "€60,96",
//                             "name": "60,96 €",
//                             "asin": "B00C19I5LO",
//                             "link": "https://www.amazon.de/TFA-Dostmann-Einstichthermometer-Profi-Klappthermometer-Lebensmittel/dp/B00C19I5LO/ref=sr_1_32?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-32"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 60.96,
//                         "raw": "€60,96",
//                         "name": "60,96 €",
//                         "asin": "B00C19I5LO",
//                         "link": "https://www.amazon.de/TFA-Dostmann-Einstichthermometer-Profi-Klappthermometer-Lebensmittel/dp/B00C19I5LO/ref=sr_1_32?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-32"
//                     }
//                 },
//                 {
//                     "position": 33,
//                     "title": "Thermometer Bluetooth Signal 50m Fleischthermometer Kabellos Grillthermometer Meat Thermometer Bratenthermometer für Ofen Grill Küche BBQ Smoker Rotisserie,Deutsche IOS/Android kompatible APP",
//                     "asin": "B0BJQ4TX4Z",
//                     "link": "https://www.amazon.de/Thermometer-Fleischthermometer-Grillthermometer-Bratenthermometer-Rotisserie/dp/B0BJQ4TX4Z/ref=sr_1_33?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-33",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51excNCtlEL._AC_UL400_.jpg",
//                     "rating": 4,
//                     "ratings_total": 190,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.9,
//                             "raw": "€59,90"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.9,
//                         "raw": "€59,90"
//                     }
//                 },
//                 {
//                     "position": 34,
//                     "title": "TFA Dostmann Digitales Einstich-Infrarot-Thermometer Dualtemp Pro, 31.1119, gemäß HACCP + EN 13485, zwei Temperatur-Messverfahren, weiß,L 39 x B 22 x H 160 (275) mm",
//                     "asin": "B000GFV4P4",
//                     "link": "https://www.amazon.de/TFA-Dostmann-31-1119-K-Einstich-Infrarot-Thermometer-Dual-Temp-PRO/dp/B000GFV4P4/ref=sr_1_34?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-34",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/611DrKD1apL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.2,
//                     "ratings_total": 34,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 85.99,
//                             "raw": "€85,99",
//                             "name": "85,99 €",
//                             "asin": "B000GFV4P4",
//                             "link": "https://www.amazon.de/TFA-Dostmann-31-1119-K-Einstich-Infrarot-Thermometer-Dual-Temp-PRO/dp/B000GFV4P4/ref=sr_1_34?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-34"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 85.99,
//                         "raw": "€85,99",
//                         "name": "85,99 €",
//                         "asin": "B000GFV4P4",
//                         "link": "https://www.amazon.de/TFA-Dostmann-31-1119-K-Einstich-Infrarot-Thermometer-Dual-Temp-PRO/dp/B000GFV4P4/ref=sr_1_34?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-34"
//                     }
//                 },
//                 {
//                     "position": 35,
//                     "title": "SCALESON S410 Grill- und Bratenthermometer/Kabellos/App-Konnektivität / 10 Meter Reichweite/Schnellladung in nur 5 Minuten",
//                     "asin": "B09J91QYQJ",
//                     "link": "https://www.amazon.de/SCALESON-S410-Grill-und-Bratenthermometer/dp/B09J91QYQJ/ref=sr_1_35?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-35",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51tzjIWoqnL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 3.9,
//                     "ratings_total": 35,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.9,
//                             "raw": "€69,90",
//                             "name": "69,90 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 79.9,
//                             "raw": "€79,90",
//                             "name": "69,90 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.9,
//                         "raw": "€69,90",
//                         "name": "69,90 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 36,
//                     "title": "TFA Dostmann Digitales Einstichthermometer, 30.1064.02.K, mit Werkszertifikat, Edelstahlfühler, wasserdicht, drehbares Display, weiß, Kunststoff, (L) 206x (B) 31 x (H) 18 mm",
//                     "asin": "B08ML6G2M2",
//                     "link": "https://www.amazon.de/TFA-Dostmann-Einstichthermometer-30-1064-02-K-Werkszertifikat/dp/B08ML6G2M2/ref=sr_1_36?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-36",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/717YrnNBJcL._AC_UL400_.jpg",
//                     "rating": 4.6,
//                     "ratings_total": 25,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 36.62,
//                             "raw": "€36,62",
//                             "name": "36,62 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 43.99,
//                             "raw": "€43,99",
//                             "name": "36,62 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 36.62,
//                         "raw": "€36,62",
//                         "name": "36,62 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 37,
//                     "title": "Inkbird IBT-4XS Bluetooth Barbecue Grillthermometer mit dem 1000mAh Li-Batterie, Magnet Montagedesign und Rotations Lesebildschirm Thermometer für BBQ, Küche, Grill, Ofen, Fleisch",
//                     "asin": "B076QBJVWX",
//                     "link": "https://www.amazon.de/Inkbird-Grillthermometer-Li-Batterie-Montagedesign-Lesebildschirm/dp/B076QBJVWX/ref=sr_1_37?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-37",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/613rMMG9N+L._AC_UL400_.jpg",
//                     "bestseller": {
//                         "link": "https://www.amazon.de/gp/bestsellers/kitchen/3311411/ref=sr_bs_36_3311411_1",
//                         "category": "Fleischgabeln & Grillgabeln"
//                     },
//                     "rating": 4.6,
//                     "ratings_total": 3208,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 21.99,
//                             "raw": "€21,99"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 21.99,
//                         "raw": "€21,99"
//                     }
//                 },
//                 {
//                     "position": 38,
//                     "title": "WMF Profi Plus Küchenthermometer digital 23 cm, mit LCD-Anzeige, Cromargan Edelstahl, hitzebeständig bis 200°C",
//                     "asin": "B0026FCELU",
//                     "link": "https://www.amazon.de/WMF-K%C3%BCchenthermometer-LCD-Anzeige-Cromargan-hitzebest%C3%A4ndig/dp/B0026FCELU/ref=sr_1_38_mod_primary_new?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-38",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51JkqyZ3tzL._AC_UL400_.jpg",
//                     "rating": 4.5,
//                     "ratings_total": 201,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 30.58,
//                             "raw": "€30,58",
//                             "name": "30,58 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 31.95,
//                             "raw": "€31,95",
//                             "name": "31,95 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 30.58,
//                         "raw": "€30,58",
//                         "name": "30,58 €"
//                     }
//                 },
//                 {
//                     "position": 39,
//                     "title": "as - Schwabe Profi Infrarot-Thermometer, Kontaktloses Temperatur-Messen von -50 bis +900°C, Infrarot Temperaturmessgerät, berührungsloses, digitales Infrarot Thermometer, IP20 I 24102",
//                     "asin": "B01I3IP7PS",
//                     "link": "https://www.amazon.de/Infrarot-Thermometer-Pyrometer-ber%C3%BChrungslose-Temperatur/dp/B01I3IP7PS/ref=sr_1_39?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-39",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71YfCGkjOBL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.5,
//                     "ratings_total": 63,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.49,
//                             "raw": "€51,49",
//                             "name": "51,49 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 74.99,
//                             "raw": "€74,99",
//                             "name": "51,49 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.49,
//                         "raw": "€51,49",
//                         "name": "51,49 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 40,
//                     "title": "Testo 0560 0103 103 Klapp-Thermometer, Länge 11 cm, klein, Schutzklasse IP55, inklusive Batterien",
//                     "asin": "B0047446JG",
//                     "link": "https://www.amazon.de/Testo-0560-0103-Klapp-Thermometer-Schutzklasse/dp/B0047446JG/ref=sr_1_40?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-40",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41cHFjBfuVL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 52,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 58.28,
//                             "raw": "€58,28",
//                             "name": "58,28 €",
//                             "asin": "B0047446JG",
//                             "link": "https://www.amazon.de/Testo-0560-0103-Klapp-Thermometer-Schutzklasse/dp/B0047446JG/ref=sr_1_40?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-40"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 58.28,
//                         "raw": "€58,28",
//                         "name": "58,28 €",
//                         "asin": "B0047446JG",
//                         "link": "https://www.amazon.de/Testo-0560-0103-Klapp-Thermometer-Schutzklasse/dp/B0047446JG/ref=sr_1_40?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-40"
//                     }
//                 },
//                 {
//                     "position": 41,
//                     "title": "GEFU Grillthermometer CONTROL+ - smartes Bratenthermometer mit 2 Sonden & LCD Temperaturanzeige, BBQ Thermometer, Küchenthermometer für Fleisch",
//                     "asin": "B08SW19MSL",
//                     "link": "https://www.amazon.de/GEFU-21940-Grill-Bratenthermometer-CONTROL/dp/B08SW19MSL/ref=sr_1_41_mod_primary_new?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=8-41",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/610B6R6fjfL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.95,
//                             "raw": "€69,95",
//                             "name": "69,95 €"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 76.22,
//                             "raw": "€76,22",
//                             "name": "76,22 €"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.95,
//                         "raw": "€69,95",
//                         "name": "69,95 €"
//                     }
//                 },
//                 {
//                     "position": 42,
//                     "title": "Grillthermometer Kabellos Fleischthermometer Funk Bratenthermometer über iOS Android mit Intelligenter App Bluetooth Küchenthermometer Thermometer Steak Backofen Garten BBQ Camping Grill Zubehör",
//                     "asin": "B0BJQ4F7DL",
//                     "link": "https://www.amazon.de/Grillthermometer-Fleischthermometer-Bratenthermometer-Intelligenter-K%C3%BCchenthermometer/dp/B0BJQ4F7DL/ref=sr_1_42?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-42",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71k2rFlk+RL._AC_UL400_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 5,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "59,99 €",
//                             "asin": "B0BJQ4F7DL",
//                             "link": "https://www.amazon.de/Grillthermometer-Fleischthermometer-Bratenthermometer-Intelligenter-K%C3%BCchenthermometer/dp/B0BJQ4F7DL/ref=sr_1_42?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-42"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "59,99 €",
//                         "asin": "B0BJQ4F7DL",
//                         "link": "https://www.amazon.de/Grillthermometer-Fleischthermometer-Bratenthermometer-Intelligenter-K%C3%BCchenthermometer/dp/B0BJQ4F7DL/ref=sr_1_42?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-42"
//                     }
//                 },
//                 {
//                     "position": 43,
//                     "title": "Heston Blumenthal Precision by Salter 540A HBBKCR Premium 5-in-1-Digitalthermometer – Heißluft-Fritteusen-Sonde, Grill, Rösten, Zwei Sensoren, Lebensmittel-/Ofentemperatur, Genauigkeit 0,1 °C",
//                     "asin": "B00Q505YE6",
//                     "link": "https://www.amazon.de/Heston-Blumenthal-digitales-Thermometer-Salter/dp/B00Q505YE6/ref=sr_1_43?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-43",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/615-CZp12-L._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 11,00 €",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 3.8,
//                     "ratings_total": 109,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 68.99,
//                             "raw": "€68,99",
//                             "name": "68,99 €",
//                             "asin": "B00Q505YE6",
//                             "link": "https://www.amazon.de/Heston-Blumenthal-digitales-Thermometer-Salter/dp/B00Q505YE6/ref=sr_1_43?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-43"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 68.99,
//                         "raw": "€68,99",
//                         "name": "68,99 €",
//                         "asin": "B00Q505YE6",
//                         "link": "https://www.amazon.de/Heston-Blumenthal-digitales-Thermometer-Salter/dp/B00Q505YE6/ref=sr_1_43?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-43"
//                     }
//                 },
//                 {
//                     "position": 44,
//                     "title": "as - Schwabe Infrarot-Thermometer/Pyrometer – Kontaktloses Temperatur-Messen von -50 bis +380°C – Infrarot Temperaturmessgerät – Berührungsloses Thermometer – Digital, 24101",
//                     "asin": "B01I3IPL2W",
//                     "link": "https://www.amazon.de/Infrarot-Thermometer-Pyrometer-ber%C3%BChrungslose-Temperatur/dp/B01I3IPL2W/ref=sr_1_44?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-44",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71YUnWEN9PL._AC_UL400_.jpg",
//                     "rating": 4.1,
//                     "ratings_total": 44,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 21.99,
//                             "raw": "€21,99",
//                             "name": "21,99 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 62.99,
//                             "raw": "€62,99",
//                             "name": "21,99 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 21.99,
//                         "raw": "€21,99",
//                         "name": "21,99 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 45,
//                     "title": "Digitales Infrarot-Thermometer, Kethvoz IR Laser Industrial Pyrometer Messen hohe Temperatur -58℉~2732℉ (-50℃ ~ 1500℃), Double Laser Touchless Temp Pistole mit D:S=30:1",
//                     "asin": "B09JBG4KBK",
//                     "link": "https://www.amazon.de/Infrarot-Thermometer-Kethvoz-Industrial-Temperatur-58%E2%84%89-2732%E2%84%89/dp/B09JBG4KBK/ref=sr_1_45?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-45",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61FNORmH9WL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 10 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "rating": 4,
//                     "ratings_total": 43,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 65.99,
//                             "raw": "€65,99",
//                             "name": "65,99 €",
//                             "asin": "B09JBG4KBK",
//                             "link": "https://www.amazon.de/Infrarot-Thermometer-Kethvoz-Industrial-Temperatur-58%E2%84%89-2732%E2%84%89/dp/B09JBG4KBK/ref=sr_1_45?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-45"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 65.99,
//                         "raw": "€65,99",
//                         "name": "65,99 €",
//                         "asin": "B09JBG4KBK",
//                         "link": "https://www.amazon.de/Infrarot-Thermometer-Kethvoz-Industrial-Temperatur-58%E2%84%89-2732%E2%84%89/dp/B09JBG4KBK/ref=sr_1_45?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-45"
//                     }
//                 },
//                 {
//                     "position": 46,
//                     "title": "Yagoal Fleischthermometer Kochthermometer Instant Lesen Fleisch Thermometer Zucker Thermometer Lebensmittel Thermometer Sonde Kochen Thermometer Für Fleisch",
//                     "asin": "B08FHZ3M49",
//                     "link": "https://www.amazon.de/Yagoal-Fleischthermometer-Kochthermometer-Thermometer-Lebensmittel/dp/B08FHZ3M49/ref=sr_1_46?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-46",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51z9BDpiFaL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 88.68,
//                             "raw": "€88,68",
//                             "asin": "B08FHZ3M49",
//                             "link": "https://www.amazon.de/Yagoal-Fleischthermometer-Kochthermometer-Thermometer-Lebensmittel/dp/B08FHZ3M49/ref=sr_1_46?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-46"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 88.68,
//                         "raw": "€88,68",
//                         "asin": "B08FHZ3M49",
//                         "link": "https://www.amazon.de/Yagoal-Fleischthermometer-Kochthermometer-Thermometer-Lebensmittel/dp/B08FHZ3M49/ref=sr_1_46?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-46"
//                     }
//                 },
//                 {
//                     "position": 47,
//                     "title": "SMSOM Lebensmittel Thermometer, digitales Lebensmittel Thermometer Sofortlese, Fleischthermometer zum Kochen, Grillen, Rauchen, Backen, Truthahn, Milch",
//                     "asin": "B095W6YJRM",
//                     "link": "https://www.amazon.de/SMSOM-Lebensmittel-Thermometer-Sofortlese-Fleischthermometer/dp/B095W6YJRM/ref=sr_1_47?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-47",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41A6lgCHMbS._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 98.44,
//                             "raw": "€98,44",
//                             "asin": "B095W6YJRM",
//                             "link": "https://www.amazon.de/SMSOM-Lebensmittel-Thermometer-Sofortlese-Fleischthermometer/dp/B095W6YJRM/ref=sr_1_47?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-47"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 98.44,
//                         "raw": "€98,44",
//                         "asin": "B095W6YJRM",
//                         "link": "https://www.amazon.de/SMSOM-Lebensmittel-Thermometer-Sofortlese-Fleischthermometer/dp/B095W6YJRM/ref=sr_1_47?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-47"
//                     }
//                 },
//                 {
//                     "position": 48,
//                     "title": "Fleischthermometer 2 STÜCKE Instant Lesen Sie Candy Thermometer digitales Kochen von Lebensmittel Thermometer for Grillküche BBQ Raucherofenölmilch Grillthermometer",
//                     "asin": "B0C27TS2SF",
//                     "link": "https://www.amazon.de/Fleischthermometer-Thermometer-Lebensmittel-Raucherofen%C3%B6lmilch-Grillthermometer/dp/B0C27TS2SF/ref=sr_1_48?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-48",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51ep94gHtnL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 50.81,
//                             "raw": "€50,81",
//                             "asin": "B0C27TS2SF",
//                             "link": "https://www.amazon.de/Fleischthermometer-Thermometer-Lebensmittel-Raucherofen%C3%B6lmilch-Grillthermometer/dp/B0C27TS2SF/ref=sr_1_48?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-48"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 50.81,
//                         "raw": "€50,81",
//                         "asin": "B0C27TS2SF",
//                         "link": "https://www.amazon.de/Fleischthermometer-Thermometer-Lebensmittel-Raucherofen%C3%B6lmilch-Grillthermometer/dp/B0C27TS2SF/ref=sr_1_48?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-48"
//                     }
//                 },
//                 {
//                     "position": 49,
//                     "title": "XIXIDIAN Digital Fleisch-Thermometer, Faltbare Lange Sonde, hintergrundbeleuchteter LCD-Bildschirm, sofortiges Lesen von Lebensmittel Thermometer, für Lebensmittel, Fleisch, Grill",
//                     "asin": "B094MT5T33",
//                     "link": "https://www.amazon.de/XIXIDIAN-Fleisch-Thermometer-hintergrundbeleuchteter-LCD-Bildschirm-Lebensmittel/dp/B094MT5T33/ref=sr_1_49?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-49",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51YFCZE-vFS._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 85,
//                             "raw": "€85,00",
//                             "asin": "B094MT5T33",
//                             "link": "https://www.amazon.de/XIXIDIAN-Fleisch-Thermometer-hintergrundbeleuchteter-LCD-Bildschirm-Lebensmittel/dp/B094MT5T33/ref=sr_1_49?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-49"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 85,
//                         "raw": "€85,00",
//                         "asin": "B094MT5T33",
//                         "link": "https://www.amazon.de/XIXIDIAN-Fleisch-Thermometer-hintergrundbeleuchteter-LCD-Bildschirm-Lebensmittel/dp/B094MT5T33/ref=sr_1_49?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-49"
//                     }
//                 },
//                 {
//                     "position": 50,
//                     "title": "DZX Digitales Küche-Lebensmittel-Thermometer wasserdicht mit hintergrundbeleuchteter Sensing-Küche kochendes Lebensmittel-Thermometer für Fleisch-Wasser-Milch-kochende Lebensmittel BBQ-elektronisch",
//                     "asin": "B09B36CBGL",
//                     "link": "https://www.amazon.de/K%C3%BCche-Lebensmittel-Thermometer-hintergrundbeleuchteter-Lebensmittel-Thermometer-Fleisch-Wasser-Milch-kochende-BBQ-elektronisch/dp/B09B36CBGL/ref=sr_1_50?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-50",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/616TT2xtLnL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 71.94,
//                             "raw": "€71,94",
//                             "asin": "B09B36CBGL",
//                             "link": "https://www.amazon.de/K%C3%BCche-Lebensmittel-Thermometer-hintergrundbeleuchteter-Lebensmittel-Thermometer-Fleisch-Wasser-Milch-kochende-BBQ-elektronisch/dp/B09B36CBGL/ref=sr_1_50?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-50"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 71.94,
//                         "raw": "€71,94",
//                         "asin": "B09B36CBGL",
//                         "link": "https://www.amazon.de/K%C3%BCche-Lebensmittel-Thermometer-hintergrundbeleuchteter-Lebensmittel-Thermometer-Fleisch-Wasser-Milch-kochende-BBQ-elektronisch/dp/B09B36CBGL/ref=sr_1_50?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-50"
//                     }
//                 },
//                 {
//                     "position": 51,
//                     "title": "AikTryee Lebensmittel-/Fleischthermometer, Ofenthermometer mit Zifferblatt Thermometer (10–290 °C), für Truthahn-Grill-Öl",
//                     "asin": "B094V3R7H8",
//                     "link": "https://www.amazon.de/AikTryee-Lebensmittel-Fleischthermometer-Ofenthermometer-Truthahn-Grill-%C3%96l/dp/B094V3R7H8/ref=sr_1_51?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-51",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61Xyc65ba0S._AC_UL400_.jpg",
//                     "rating": 4.4,
//                     "ratings_total": 383,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 51.27,
//                             "raw": "€51,27",
//                             "name": "51,27 €",
//                             "asin": "B094V3R7H8",
//                             "link": "https://www.amazon.de/AikTryee-Lebensmittel-Fleischthermometer-Ofenthermometer-Truthahn-Grill-%C3%96l/dp/B094V3R7H8/ref=sr_1_51?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-51"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 51.27,
//                         "raw": "€51,27",
//                         "name": "51,27 €",
//                         "asin": "B094V3R7H8",
//                         "link": "https://www.amazon.de/AikTryee-Lebensmittel-Fleischthermometer-Ofenthermometer-Truthahn-Grill-%C3%96l/dp/B094V3R7H8/ref=sr_1_51?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-51"
//                     }
//                 },
//                 {
//                     "position": 52,
//                     "title": "Digital Fleisch Thermometer Sofort lesen Kochthermometer Langesonde Lebensmittel Thermometer Wasserdichte Küchenhermometer Grillthermometer",
//                     "asin": "B0C27SFWV9",
//                     "link": "https://www.amazon.de/Kochthermometer-Lebensmittel-Wasserdichte-K%C3%BCchenhermometer-Grillthermometer/dp/B0C27SFWV9/ref=sr_1_52?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-52",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/71Bef00S4pL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 57.46,
//                             "raw": "€57,46",
//                             "asin": "B0C27SFWV9",
//                             "link": "https://www.amazon.de/Kochthermometer-Lebensmittel-Wasserdichte-K%C3%BCchenhermometer-Grillthermometer/dp/B0C27SFWV9/ref=sr_1_52?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-52"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 57.46,
//                         "raw": "€57,46",
//                         "asin": "B0C27SFWV9",
//                         "link": "https://www.amazon.de/Kochthermometer-Lebensmittel-Wasserdichte-K%C3%BCchenhermometer-Grillthermometer/dp/B0C27SFWV9/ref=sr_1_52?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-52"
//                     }
//                 },
//                 {
//                     "position": 53,
//                     "title": "Wisyurt Drahtloses Bluetooth Elektronisches Intelligentes Ofen Thermometer KüChen Lebensmittel Thermometer",
//                     "asin": "B0BQXFDMF6",
//                     "link": "https://www.amazon.de/Wisyurt-Elektronisches-Intelligentes-Thermometer-Lebensmittel/dp/B0BQXFDMF6/ref=sr_1_53?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-53",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51BZvWfnWXL._AC_UL400_.jpg",
//                     "coupon": {
//                         "badge_text": "Spare 5 %",
//                         "text": "mit Rabattgutschein"
//                     },
//                     "is_prime": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 71.99,
//                             "raw": "€71,99",
//                             "asin": "B0BQXFDMF6",
//                             "link": "https://www.amazon.de/Wisyurt-Elektronisches-Intelligentes-Thermometer-Lebensmittel/dp/B0BQXFDMF6/ref=sr_1_53?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-53"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 71.99,
//                         "raw": "€71,99",
//                         "asin": "B0BQXFDMF6",
//                         "link": "https://www.amazon.de/Wisyurt-Elektronisches-Intelligentes-Thermometer-Lebensmittel/dp/B0BQXFDMF6/ref=sr_1_53?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-53"
//                     }
//                 },
//                 {
//                     "position": 54,
//                     "title": "RÖSLE Barbecue-Kerntemperaturmesser & RÖSLE Marinierspritze, Hochwertige Spritze für 50 ml Fleischmarinade, intensives Aroma, Edelstahl 18/10, PP Polypropylen, Länge 22,5 cm, spülmaschinengeeignet",
//                     "asin": "B0BLBG33LJ",
//                     "link": "https://www.amazon.de/R%C3%96SLE-Barbecue-Kerntemperaturmesser-Marinierspritze-Fleischmarinade-sp%C3%BClmaschinengeeignet/dp/B0BLBG33LJ/ref=sr_1_54?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-54",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41ufjQyGLHL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 5,
//                     "ratings_total": 1,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54.48,
//                             "raw": "€54,48",
//                             "name": "54,48 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 66.9,
//                             "raw": "€66,90",
//                             "name": "54,48 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54.48,
//                         "raw": "€54,48",
//                         "name": "54,48 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 55,
//                     "title": "chunnron Fleischthermometer Für Backofen Kochthermometer Digitale Fleisch Thermometer Kochen Digitale Fleisch Thermometer Lebensmittel Thermometer",
//                     "asin": "B08F27439B",
//                     "link": "https://www.amazon.de/chunnron-Fleischthermometer-Kochthermometer-Thermometer-Lebensmittel/dp/B08F27439B/ref=sr_1_55?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-55",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51z9BDpiFaL._AC_UL400_.jpg"
//                 },
//                 {
//                     "position": 56,
//                     "title": "XIXIDIAN Lebensmittel Thermometer, hochpräziser Sondentyp für Haushaltsküche Öle Temperatur Backen und Wassertemperaturmessung Grill Handheld Lebensmittel Nadel Thermometer",
//                     "asin": "B0927QVSSP",
//                     "link": "https://www.amazon.de/XIXIDIAN-Lebensmittel-hochpr%C3%A4ziser-Haushaltsk%C3%BCche-Wassertemperaturmessung/dp/B0927QVSSP/ref=sr_1_56?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-56",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51fuJnC99VL._AC_UL400_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 90.06,
//                             "raw": "€90,06",
//                             "asin": "B0927QVSSP",
//                             "link": "https://www.amazon.de/XIXIDIAN-Lebensmittel-hochpr%C3%A4ziser-Haushaltsk%C3%BCche-Wassertemperaturmessung/dp/B0927QVSSP/ref=sr_1_56?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-56"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 90.06,
//                         "raw": "€90,06",
//                         "asin": "B0927QVSSP",
//                         "link": "https://www.amazon.de/XIXIDIAN-Lebensmittel-hochpr%C3%A4ziser-Haushaltsk%C3%BCche-Wassertemperaturmessung/dp/B0927QVSSP/ref=sr_1_56?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-56"
//                     }
//                 },
//                 {
//                     "position": 57,
//                     "title": "Mastrad MEAT°IT Bluetooth Fleischthermometer - Thermometer digital - Bratenthermometer für Küche BBQ Grill - kabellos Einstichthermometer für Temperatur und Kochgrad-Überwachung",
//                     "asin": "B08KPNK9TT",
//                     "link": "https://www.amazon.de/Mastrad-MEAT%C2%B0-Bluetooth-Fleischthermometer-Kochgrad-%C3%9Cberwachung/dp/B08KPNK9TT/ref=sr_1_57?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-57",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/718uE70AXIL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "rating": 3.9,
//                     "ratings_total": 247,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.9,
//                             "raw": "€69,90",
//                             "name": "69,90 €",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 89,
//                             "raw": "€89,00",
//                             "name": "69,90 €",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.9,
//                         "raw": "€69,90",
//                         "name": "69,90 €",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 58,
//                     "title": "ETI Lebensmittel Check Professionelle Digitale Lebensmittel Thermometer mit Sonde (gelb)",
//                     "asin": "B01MXQFUVV",
//                     "link": "https://www.amazon.de/ETI-Lebensmittel-Professionelle-Digitale-Thermometer/dp/B01MXQFUVV/ref=sr_1_58?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-58",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51iSr3w7CVL._AC_UL400_.jpg",
//                     "is_prime": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 85.64,
//                             "raw": "€85,64",
//                             "asin": "B01MXQFUVV",
//                             "link": "https://www.amazon.de/ETI-Lebensmittel-Professionelle-Digitale-Thermometer/dp/B01MXQFUVV/ref=sr_1_58?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-58"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 85.64,
//                         "raw": "€85,64",
//                         "asin": "B01MXQFUVV",
//                         "link": "https://www.amazon.de/ETI-Lebensmittel-Professionelle-Digitale-Thermometer/dp/B01MXQFUVV/ref=sr_1_58?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-58"
//                     }
//                 },
//                 {
//                     "position": 59,
//                     "title": "KLHHG Pen-Typ-Zeiger-Thermometer Mechanisches Fleisch-Thermometer Edelstahl-Sonden-Lebensmittel-Thermometer",
//                     "asin": "B09X9J8YLC",
//                     "link": "https://www.amazon.de/KLHHG-Pen-Typ-Zeiger-Thermometer-Mechanisches-Fleisch-Thermometer-Edelstahl-Sonden-Lebensmittel-Thermometer/dp/B09X9J8YLC/ref=sr_1_59?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-59",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51ZRpsoLazL._AC_UL400_.jpg"
//                 },
//                 {
//                     "position": 60,
//                     "title": "KLHHG BBQ Thermometer Küche Haushalt Lebensmittel Thermometer Grill Thermometer BBQ Countdown Edelstahl Sonde Thermometer",
//                     "asin": "B09XV2JM4P",
//                     "link": "https://www.amazon.de/KLHHG-Thermometer-Lebensmittel-Countdown-Edelstahl/dp/B09XV2JM4P/ref=sr_1_60?keywords=Thermometer+f%C3%BCr+Lebensmittel&qid=1684074574&refinements=p_36%3A5000-10000&sr=8-60",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61xRPVoA-PL._AC_UL400_.jpg"
//                 }
//             ]
//         },
//         {
//             "idea": "Rezepte-Buch für Geburtstagspartys",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "Das Kinderfestebuch: Anregungen, Spiele, Lieder- und Rezepte zur Gestaltung von Kinder- und Geburtstagsfesten: Anregungen, Spiele, Lieder, Bastel- und ... Gestaltung von Kinder- und Geburtstagsfesten",
//                     "asin": "3772515371",
//                     "link": "https://www.amazon.de/Das-Kinderfestebuch-Anregungen-Gestaltung-Geburtstagsfesten/dp/3772515371/ref=sr_1_1?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51CfxzOXuwL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Christiane Kutik",
//                             "link": "https://www.amazon.de/Christiane-Kutik/e/B0043BW58C?ref=sr_ntt_srch_lnk_1&qid=1684074598&sr=8-1"
//                         }
//                     ],
//                     "rating": 4.4,
//                     "ratings_total": 10,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 29,
//                             "raw": "€29,00",
//                             "name": "Gebundenes Buch",
//                             "asin": "3772515371",
//                             "link": "https://www.amazon.de/Das-Kinderfestebuch-Anregungen-Gestaltung-Geburtstagsfesten/dp/3772515371/ref=sr_1_1?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 29,
//                         "raw": "€29,00",
//                         "name": "Gebundenes Buch",
//                         "asin": "3772515371",
//                         "link": "https://www.amazon.de/Das-Kinderfestebuch-Anregungen-Gestaltung-Geburtstagsfesten/dp/3772515371/ref=sr_1_1?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-1"
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "Meine wilde Pflanzenküche: Bestimmen, Sammeln und Kochen von Wildpflanzen",
//                     "asin": "3038005525",
//                     "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo3Nzk0MjE2MDc0Njg5MjQ1OjE2ODQwNzQ1OTg6c3BfbXRmOjIwMDQ5NjUyMzE5Mzk4OjowOjo&url=%2FMeine-wilde-Pflanzenk%25C3%25BCche-Bestimmen-Wildpflanzen%2Fdp%2F3038005525%2Fref%3Dsr_1_2_sspa%3Fkeywords%3Drezepte-buch%2Bf%25C3%25BCr%2Bgeburtstagsparty%26qid%3D1684074598%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51wzdj0r5oL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Meret Bissegger",
//                             "link": "https://www.amazon.de/Meret-Bissegger/e/B00J31TDBO?ref=sr_ntt_srch_lnk_2&qid=1684074598&sr=8-2"
//                         }
//                     ],
//                     "rating": 4.7,
//                     "ratings_total": 79,
//                     "sponsored": true,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 54,
//                             "raw": "€54,00",
//                             "name": "Gebundene Ausgabe",
//                             "asin": "3038005525",
//                             "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo3Nzk0MjE2MDc0Njg5MjQ1OjE2ODQwNzQ1OTg6c3BfbXRmOjIwMDQ5NjUyMzE5Mzk4OjowOjo&url=%2FMeine-wilde-Pflanzenk%25C3%25BCche-Bestimmen-Wildpflanzen%2Fdp%2F3038005525%2Fref%3Dsr_1_2_sspa%3Fkeywords%3Drezepte-buch%2Bf%25C3%25BCr%2Bgeburtstagsparty%26qid%3D1684074598%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 54,
//                         "raw": "€54,00",
//                         "name": "Gebundene Ausgabe",
//                         "asin": "3038005525",
//                         "link": "https://www.amazon.de/sspa/click?ie=UTF8&spc=MTo3Nzk0MjE2MDc0Njg5MjQ1OjE2ODQwNzQ1OTg6c3BfbXRmOjIwMDQ5NjUyMzE5Mzk4OjowOjo&url=%2FMeine-wilde-Pflanzenk%25C3%25BCche-Bestimmen-Wildpflanzen%2Fdp%2F3038005525%2Fref%3Dsr_1_2_sspa%3Fkeywords%3Drezepte-buch%2Bf%25C3%25BCr%2Bgeburtstagsparty%26qid%3D1684074598%26refinements%3Dp_36%253A5000-10000%26sr%3D8-2-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9tdGY%26psc%3D1"
//                     }
//                 },
//                 {
//                     "position": 3,
//                     "title": "X17-Mein Rezeptebuch, Kochbuch, A5, Ringebuch, zum Selberschreiben, eintragen, aus belgischem Leder braun\"brandy\"; die wahre\"Kochlust\"! Austauschbare Hefte! 100% Made in Germany",
//                     "asin": "B095KNBHK8",
//                     "link": "https://www.amazon.de/Kochbuch-Rezeptebuch-belgischem-Leder-brandy/dp/B095KNBHK8/ref=sr_1_fkmr0_1?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-1-fkmr0",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/41oW+vO6uuS._AC_UY218_.jpg",
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 87.9,
//                             "raw": "€87,90",
//                             "asin": "B095KNBHK8",
//                             "link": "https://www.amazon.de/Kochbuch-Rezeptebuch-belgischem-Leder-brandy/dp/B095KNBHK8/ref=sr_1_fkmr0_1?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-1-fkmr0"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 87.9,
//                         "raw": "€87,90",
//                         "asin": "B095KNBHK8",
//                         "link": "https://www.amazon.de/Kochbuch-Rezeptebuch-belgischem-Leder-brandy/dp/B095KNBHK8/ref=sr_1_fkmr0_1?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-1-fkmr0"
//                     }
//                 },
//                 {
//                     "position": 4,
//                     "title": "Die 100 besten Rezepte der besten Foodblogger: Der RezepteBuch.com Award 2021",
//                     "asin": "3766725270",
//                     "link": "https://www.amazon.de/Die-100-besten-Rezepte-Foodblogger/dp/3766725270/ref=sr_1_fkmr0_2?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-2-fkmr0",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61JL5sfgOrL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "rezeptebuch.com"
//                         }
//                     ],
//                     "rating": 3.9,
//                     "ratings_total": 11,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 35,
//                             "raw": "€35,00",
//                             "name": "Gebundenes Buch",
//                             "asin": "3766725270",
//                             "link": "https://www.amazon.de/Die-100-besten-Rezepte-Foodblogger/dp/3766725270/ref=sr_1_fkmr0_2?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-2-fkmr0"
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 19.99,
//                             "raw": "€19,99",
//                             "name": "Kindle",
//                             "asin": "B093XV32VN",
//                             "link": "https://www.amazon.de/Die-100-besten-Rezepte-Foodblogger-ebook/dp/B093XV32VN/ref=sr_1_fkmr0_2?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-2-fkmr0"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 35,
//                         "raw": "€35,00",
//                         "name": "Gebundenes Buch",
//                         "asin": "3766725270",
//                         "link": "https://www.amazon.de/Die-100-besten-Rezepte-Foodblogger/dp/3766725270/ref=sr_1_fkmr0_2?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-2-fkmr0"
//                     }
//                 },
//                 {
//                     "position": 5,
//                     "title": "LEGO 41806 DOTS Ultimatives Partyset, Partyspiele für Kinder und DIY Partytütenfüller mit Spielzeug Cupcakes, Armbändern und Wimpeln, kreative Dekoration, Geschenke für Mädchen und Jungen",
//                     "asin": "B0BBRVGXPY",
//                     "link": "https://www.amazon.de/LEGO-41806-Ultimatives-Partyspiele-Partyt%C3%BCtenf%C3%BCller/dp/B0BBRVGXPY/ref=sr_1_fkmr1_1?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-1-fkmr1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/91x75iOdKnL._AC_UY218_.jpg",
//                     "rating": 4.7,
//                     "ratings_total": 153,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 33.99,
//                             "raw": "€33,99",
//                             "name": "Primary",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 49.99,
//                             "raw": "€49,99",
//                             "name": "Original",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 33.99,
//                         "raw": "€33,99",
//                         "name": "Primary",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 6,
//                     "title": "Simon Goldsteins Geburtstagsparty",
//                     "asin": "3938065338",
//                     "link": "https://www.amazon.de/Simon-Goldsteins-Geburtstagsparty-Heidrun-J%C3%A4nchen/dp/3938065338/ref=sr_1_fkmr1_2?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-2-fkmr1",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61LV8oq248L._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Heidrun Jänchen",
//                             "link": "https://www.amazon.de/Heidrun-J%25C3%25A4nchen/e/B00455S7SS?ref=sr_ntt_srch_lnk_fkmr1_2&qid=1684074598&sr=8-2-fkmr1"
//                         }
//                     ],
//                     "rating": 4.6,
//                     "ratings_total": 6
//                 },
//                 {
//                     "position": 7,
//                     "title": "Das große Buch der Desserts: Warenkunde, Küchenpraxis, Rezepte (TEUBNER Edition)",
//                     "asin": "3833819995",
//                     "link": "https://www.amazon.de/Das-gro%C3%9Fe-Buch-Desserts-K%C3%BCchenpraxis/dp/3833819995/ref=sr_1_fkmr2_1?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-1-fkmr2",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/21CluATQEcL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Katrin Wittmann"
//                         }
//                     ],
//                     "rating": 4.7,
//                     "ratings_total": 107,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 69.9,
//                             "raw": "€69,90",
//                             "name": "Gebundenes Buch",
//                             "asin": "3833819995",
//                             "link": "https://www.amazon.de/Das-gro%C3%9Fe-Buch-Desserts-K%C3%BCchenpraxis/dp/3833819995/ref=sr_1_fkmr2_1?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-1-fkmr2"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 69.9,
//                         "raw": "€69,90",
//                         "name": "Gebundenes Buch",
//                         "asin": "3833819995",
//                         "link": "https://www.amazon.de/Das-gro%C3%9Fe-Buch-Desserts-K%C3%BCchenpraxis/dp/3833819995/ref=sr_1_fkmr2_1?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-1-fkmr2"
//                     }
//                 },
//                 {
//                     "position": 8,
//                     "title": "Patisserie: Aus der renommierten Konditorschule Schritt für Schritt erklärt. Raffinierte Backrezepte aus der französischen Küche: Zaubern Sie Tartes, Pralinen und leckere Kuchen auf den Tisch!.",
//                     "asin": "3784356214",
//                     "link": "https://www.amazon.de/Patisserie-Backrezepte-renommierten-Konditorschule-Fortgeschrittene/dp/3784356214/ref=sr_1_fkmr2_2?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-2-fkmr2",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/61VJYotoDdL._AC_UY218_.jpg",
//                     "authors": [
//                         {
//                             "name": "Le Cordon Bleu"
//                         }
//                     ],
//                     "rating": 4.6,
//                     "ratings_total": 51,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 78,
//                             "raw": "€78,00",
//                             "name": "Gebundene Ausgabe",
//                             "asin": "3784356214",
//                             "link": "https://www.amazon.de/Patisserie-Backrezepte-renommierten-Konditorschule-Fortgeschrittene/dp/3784356214/ref=sr_1_fkmr2_2?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-2-fkmr2"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 78,
//                         "raw": "€78,00",
//                         "name": "Gebundene Ausgabe",
//                         "asin": "3784356214",
//                         "link": "https://www.amazon.de/Patisserie-Backrezepte-renommierten-Konditorschule-Fortgeschrittene/dp/3784356214/ref=sr_1_fkmr2_2?keywords=rezepte-buch+f%C3%BCr+geburtstagsparty&qid=1684074598&refinements=p_36%3A5000-10000&sr=8-2-fkmr2"
//                     }
//                 }
//             ]
//         },
//         {
//             "idea": "'Chef's Table'-DVD-Boxset",
//             "results": [
//                 {
//                     "position": 1,
//                     "title": "Chef's Path Vorratsdosen Set Frischhaltedosen mit Deckel - Ideal als Vorratsbehälter, Aufbewahrungsbox Küche, Vorratsbehälter, Vorratsdose, Aufbewahrung Küche Aufbewahrung & Organisation (24)",
//                     "asin": "B0822JNHFY",
//                     "link": "https://www.amazon.de/Chefs-Path-Frischhaltedosen-Set-Speisekammer-Kunststoffdosen/dp/B0822JNHFY/ref=sr_1_fkmr0_1?keywords=%27Chef%27s+Table%27-DVD-Boxset&qid=1684074601&refinements=p_36%3A5000-10000&sr=8-1-fkmr0",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/81iX47vVsOL._AC_UY218_.jpg",
//                     "unit_price": "2,50 €/l",
//                     "is_prime": true,
//                     "rating": 4.7,
//                     "ratings_total": 65334,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 59.99,
//                             "raw": "€59,99",
//                             "name": "Primary",
//                             "is_primary": true
//                         },
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 99.99,
//                             "raw": "€99,99",
//                             "name": "Original",
//                             "is_rrp": true
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 59.99,
//                         "raw": "€59,99",
//                         "name": "Primary",
//                         "is_primary": true
//                     }
//                 },
//                 {
//                     "position": 2,
//                     "title": "Chef 's Choice 23,8 x 5.1 x 7.0 cm Manuelle Diamant Hone Messerschärfer, schwarz/Remasuri",
//                     "asin": "B007IVBET0",
//                     "link": "https://www.amazon.de/Choice-Manuelle-Diamant-Messersch%C3%A4rfer-Remasuri/dp/B007IVBET0/ref=sr_1_fkmr0_2?keywords=%27Chef%27s+Table%27-DVD-Boxset&qid=1684074601&refinements=p_36%3A5000-10000&sr=8-2-fkmr0",
//                     "categories": [
//                         {
//                             "name": "Alle Kategorien",
//                             "id": "aps"
//                         }
//                     ],
//                     "image": "https://m.media-amazon.com/images/I/51OlJfJpoVS._AC_UY218_.jpg",
//                     "is_prime": true,
//                     "rating": 4.4,
//                     "ratings_total": 5386,
//                     "prices": [
//                         {
//                             "symbol": "€",
//                             "currency": "EUR",
//                             "value": 53.36,
//                             "raw": "€53,36",
//                             "asin": "B007IVBET0",
//                             "link": "https://www.amazon.de/Choice-Manuelle-Diamant-Messersch%C3%A4rfer-Remasuri/dp/B007IVBET0/ref=sr_1_fkmr0_2?keywords=%27Chef%27s+Table%27-DVD-Boxset&qid=1684074601&refinements=p_36%3A5000-10000&sr=8-2-fkmr0"
//                         }
//                     ],
//                     "price": {
//                         "symbol": "€",
//                         "currency": "EUR",
//                         "value": 53.36,
//                         "raw": "€53,36",
//                         "asin": "B007IVBET0",
//                         "link": "https://www.amazon.de/Choice-Manuelle-Diamant-Messersch%C3%A4rfer-Remasuri/dp/B007IVBET0/ref=sr_1_fkmr0_2?keywords=%27Chef%27s+Table%27-DVD-Boxset&qid=1684074601&refinements=p_36%3A5000-10000&sr=8-2-fkmr0"
//                     }
//                 }
//             ]
//         },
//         {
//             "idea": "'Masterchef'-Tasse",
//             "results": []
//         }
//     ],
//     "status": 200,
//     "statusText": "",
//     "headers": {
//         "content-type": "text/html; charset=utf-8"
//     },
//     "config": {
//         "transitional": {
//             "silentJSONParsing": true,
//             "forcedJSONParsing": true,
//             "clarifyTimeoutError": false
//         },
//         "adapter": [
//             "xhr",
//             "http"
//         ],
//         "transformRequest": [
//             null
//         ],
//         "transformResponse": [
//             null
//         ],
//         "timeout": 0,
//         "xsrfCookieName": "XSRF-TOKEN",
//         "xsrfHeaderName": "X-XSRF-TOKEN",
//         "maxContentLength": -1,
//         "maxBodyLength": -1,
//         "env": {},
//         "headers": {
//             "Accept": "application/json, text/plain, */*",
//             "Content-Type": "application/json"
//         },
//         "method": "post",
//         "url": "https://product-scraper-ggbm.onrender.com",
//         "data": "{\"age\":\"33\",\"hobby\":\"Kochen\",\"life\":\"\",\"char\":\"\",\"art\":\"\",\"cat\":\"\",\"priceFrom\":\"50\",\"priceTo\":\"100\",\"type\":\"Partner\",\"issue\":\"Geburtstag\",\"name\":\"hello\"}"
//     },
//     "request": {}
// });


  const handleMobileUsage = (value) => {
    setMobileUsage(value);
    setCurrentStep(currentStep + 1);
  };

  const handleTextareaChange = (event) => {
    setMessage(event.target.value);
  };

  const handleAppleUsage = (value) => {
    setAppleUsage(value);
    setCurrentStep(currentStep + 1);
  };

  const handleSoftwareUntil = () => {
    if (softwareUntil) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleServerSelection = () => {
    if (softwareSelection) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleTimeSelection = () => {
    if (time) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleTransferData = (value) => {
    setTransferData(value);
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);

  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const updateMobileUsage = (value) => {
    setMobileUsage(value);
  };

  const updateSoftwareUntil = (value) => {
    setSoftwareUntil(value);
  };

  const handleDropdownSelectionChange = (value) => {
    setSoftwareSelection(value);
  };

  const handleDropdownSelectionTwo = (value) => {
    setTime(value);
  };


  const amountPeople = (value) => {
    setAmountPeopleValue(value);
  };

  const updateTransferData = (value) => {
    setTransferData(value);
  };

  const reasonUntilStep = (value) => {
    setReasonUntil(value);
  };

  const handleApiResponse = (response) => {
    setApiResponse(response);
  };

  const handleApiResponseTwoNew = (response) => {
    setApiResponseTwoNew(response);
};

const reset = () => {
  setCurrentStep(1);
  setMessage("");
  setMobileUsage();
  setSoftwareUntil("");
  setTransferData();
  setReasonUntil("");
  setAmountPeopleValue(30);
  setSoftwareSelection("");
  setTime("");
  setAppleUsage();
  setEmail("");
  setEmailNew("");
  setPhone("");
  setPartner("");
  setCheckOne(false);
  setCheckTwo(false);
  setCheckThree(false);
  setCheckFour(false);
  setHobby("");
  setLife("");
  setChar("");
  setArt("");
  setCat("");
  setPriceFrom("");
  setPriceTo("");
  setType("");
  setApiResponse("");
  setApiResponseTwoNew("");
};



  return (
    <div className="form-container">
     {
  currentStep < 10 && (
    <span className={`text-percentage ${currentStep > 5 ? 'disabled-true' : ''}`}>
     {currentStep*2/1}0%
    </span>
  )
}

      <div className="step-parent-container">

        {currentStep < 4 && (
          <>
            <StepOne
              currentStep={currentStep}
              handleMobileUsage={handleMobileUsage}
              handleSoftwareUntil={handleSoftwareUntil}
              handleTransferData={handleTransferData}
              setSoftwareUntil={setSoftwareUntil}
              updateMobileUsage={updateMobileUsage}
              updateSoftwareUntil={updateSoftwareUntil}
              updateTransferData={updateTransferData}
              reasonUntilStep={reasonUntilStep}
              setReasonUntil={setReasonUntil}
              setType={setType}
              handleDropdownSelection={handleDropdownSelectionChange}
              serverSelection={handleServerSelection}
              handleNext={handleNext}
              mobilState={mobileUsage}
              softwareUntil={softwareUntil}
              transferData={transferData}
              softwareSelection={softwareSelection}
              reasonUntil={reasonUntil}
              type={type}

            />
          </>
        )}
        {currentStep > 3 && currentStep < 6 && (
          <>
            <StepTwo
              currentStep={currentStep}
              handleMobileUsage={handleMobileUsage}
              handleSoftwareUntil={handleSoftwareUntil}
              handleTransferData={handleTransferData}
              setSoftwareUntil={setSoftwareUntil}
              updateMobileUsage={updateMobileUsage}
              updateSoftwareUntil={updateSoftwareUntil}
              updateTransferData={updateTransferData}
              reasonUntil={reasonUntil}
              setReasonUntil={setReasonUntil}
              amountPeople={amountPeople}
              amountPeopleValue={amountPeopleValue}
              appleUsage={handleAppleUsage}
              apple={appleUsage}
              softwareUntil={softwareUntil}
              setHobby={setHobby}
              hobby={hobby}
              life={life}
              setLife={setLife}
              char={char}
              setChar={setChar}
              setPriceFrom={setPriceFrom}
              priceFrom={priceFrom}
              setPriceTo={setPriceTo}
              priceTo={priceTo}
              nextStep={handleNext}


            />
          </>
        )}

        {currentStep > 5 && currentStep < 10 && (
          <>
            <StepFour
              currentStep={currentStep}
              mobileUsage={mobileUsage}
              softwareUntil={softwareUntil}
              transferData={transferData}
              reasonUntil={reasonUntil}
              appleUsage={appleUsage}
              amountPeopleValue={amountPeopleValue}
              softwareSelection={softwareSelection}
              time={time}
              message={message}
              serverSelection={softwareSelection}
              handleTextareaChange={handleTextareaChange}
              handleMobileUsage={handleMobileUsage}
              handleSoftwareUntil={handleSoftwareUntil}
              handleTransferData={handleTransferData}
              setSoftwareUntil={setSoftwareUntil}
              updateMobileUsage={updateMobileUsage}
              updateSoftwareUntil={updateSoftwareUntil}
              updateTransferData={updateTransferData}
              reasonUntilStep={reasonUntilStep}
              amountPeople={amountPeople}
              handleDropdownSelection={handleDropdownSelectionChange}
              timeSelection={handleTimeSelection}
              handleDropdownSelectionTwo={handleDropdownSelectionTwo}
              email={email}
              setEmail={setEmail}
              setEmailNew={setEmailNew}
              emailNew={emailNew}
              phone={phone}
              setPhone={setPhone}
              partner={partner}
              setPartner={setPartner}
              checkOne={checkOne}
              setCheckOne={setCheckOne}
              checkTwo={checkTwo}
              setCheckTwo={setCheckTwo}
              checkThree={checkThree}
              setCheckThree={setCheckThree}
              checkFour={checkFour}
              setCheckFour={setCheckFour}
              hobby={hobby}
              life={life}
              char={char}
              art={art}
              cat={cat}
              priceFrom={priceFrom}
              priceTo={priceTo}
              type={type}
              apiResponse={apiResponse}
              apiResponseTwoNew={apiResponseTwoNew}
              data={apiResponse.data}
              age={softwareUntil}
              gender={softwareSelection}
              issue={reasonUntil}
              handleApiResponse={handleApiResponse}
              handleApiResponseTwoNew={handleApiResponseTwoNew}
              nextStep={handleNext}
              setErrorForPage={setErrorForPage}
            />
          </>
        )}
      </div>
      <div className="button-container-main">
        <div className="button-container-child">
          
          
          {currentStep >= 0 && currentStep < 7 && (
            <button type="button" className={`btn btn-primary  ${currentStep < 2 ? 'disabled-true' : ''}`} onClick={handlePrevious}>
              Zurück
            </button>
          )}
    {
    errorForPage !== "" && currentStep > 5 && (
        <button type="button" className="btn btn-primary" onClick={reset}>
            Konfigurator neu starten
        </button>
    )
}


        </div>
      </div>
    </div>
  );
}
