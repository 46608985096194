import React from 'react'
import Page from './Page'
// import { useForm, ValidationError } from '@formspree/react';

export default function Form({ step, setStep}) {
  // const [state, handleSubmit] = useForm("mvonyplv"); 
  
  // if(state.succeeded) {
  //   return <p>Vielen Dank für Ihre Angaben. Wir haben analysiert, welche Software am besten zu Ihrer Kanzlei passt. Der Anbieter wird unverbindlich innerhalb von 24 Stunden Kontakt zu Ihnen aufnehmen.</p>
  // }
  return (
    <div className='main-div'>
      <form 
      //  onSubmit={handleSubmit}
        >
        <Page/>
        {/* state={state} step={step} setStep={setStep} */}
      </form>
    </div>
  )
}
