import React, { useState } from "react";

export default function StepTwo({
    currentStep,
    handleSoftwareUntil,
    setHobby, 
    hobby,
    setPriceFrom, 
    setPriceTo, 
    priceFrom,
    priceTo,
    nextStep,
}) {

    const [initialMessage, setInitialMessage] = useState('z.B. Fussball, Kochen...');
    const [initialMessageLife, setInitialMessageLife] = useState('Anlass eingeben...');
    const [initialMessageChar, setInitialMessageChar] = useState('Charakter eingeben...');

    const handleTextareaChangeInput = (event) => {
        setHobby(event.target.value);
    };

    const handleTextareaFocus = () => {
        if (hobby === initialMessage) {
            setHobby('');
        }
    };

    const handleTextareaBlur = () => {
        if (hobby.trim() === '') {
            setHobby(initialMessage);
        }
    };
        
    const handlePriceFromChange = (event) => {
        setPriceFrom(event.target.value);
    };

    const handlePriceToChange = (event) => {
        setPriceTo(event.target.value);
    };



    return (
        <>
            <div className={`step-container four ${currentStep === 4 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Was sind die Hobbys oder Interessen der Person?</h6>
                    <div className="image-container">
                        {/* <img src={change} alt="Page" /> */}
                    </div>
                    <div className="input-container">
                        <input
                            type="textarea"
                            id="fname"
                            name="fname"
                            placeholder={initialMessage}
                            className="form-input"
                            value={hobby}
                            onChange={handleTextareaChangeInput}
                            onFocus={handleTextareaFocus}
                            onBlur={handleTextareaBlur}
                        ></input>
                    </div>
                    <div className="button-container confirm">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSoftwareUntil}
                            disabled={hobby === "" || hobby.length < 3}
                        >
                            Bestätigen
                        </button>
                    </div>
                </div>
            </div>
            {/* <div className={`step-container five ${currentStep === 5 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Hat die Person kürzlich eine größere Veränderung in ihrem Leben durchgemacht, die das Geschenk berücksichtigen sollte? </h6>
                    <div className="image-container">
                       
                    </div>
                    <div className="input-container">
                        <input
                            type="textarea"
                            id="fname"
                            name="fname"
                            placeholder={initialMessageLife}
                            className="form-input"
                            value={life}
                            onChange={handleTextareaChangeInputLife}
                            onFocus={handleTextareaFocusLife}
                            onBlur={handleTextareaBlurLife}
                        ></input>
                    </div>
                    <div className="button-container confirm">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSoftwareUntil}
                        >
                            Bestätigen
                        </button>
                    </div>
                </div>
            </div> */}
            <div className={`step-container five ${currentStep === 5 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Bitte geben Sie den gewünschten Preisbereich für das Geschenk an (von-bis in Euro):</h6>
                    <div className="input-container">
                        <label htmlFor="price-from">Von</label>
                        
                        <input
                            type="number"
                            id="price-from"
                            name="price-from"
                            className="form-input"
                            value={priceFrom}
                            placeholder=" . . . 35 €"
                            onChange={handlePriceFromChange}
                            min="0"
                        ></input>
                    </div>
                    <div className="input-container">
                        <label htmlFor="price-to">Bis</label>
                        <input
                            type="number"
                            id="price-to"
                            name="price-to"
                            className="form-input"
                            value={priceTo}
                            placeholder=" . . . 77 €"
                            onChange={handlePriceToChange}
                            min="0"
                        ></input>
                    </div>
                    <div className="button-container confirm">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={nextStep}
                            disabled={priceFrom === "" || priceTo === "" }
                        >
                            Bestätigen
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
