import React, { useState } from "react";
import mobil from "../mobil.png";
import usage from "../actual-usage.png";
import datenmitnahme from "../daten-mitnahme.png";
import cloud from "../cloud.png";
import time from "../time.png";

export default function StepOne({
    currentStep,
    handleSoftwareUntil,
    setSoftwareUntil,
    setType,
    type,
    setReasonUntil,
    reasonUntil,
    softwareUntil,
    handleNext,
}) {

    const [initialMessage, setInitialMessage] = useState('Alter der Person...');
    const [initialMessageTwo, setInitialMessageTwo] = useState('Für wen genau...');
    const [initialMessageThree, setInitialMessageThree] = useState('Anlass eingeben...');


    const handleTextareaChangeInput = (event) => {
        setSoftwareUntil(event.target.value);
    };

    const handleTextareaFocus = () => {
        if (softwareUntil === initialMessage) {
            setSoftwareUntil('');
        }
    };

    const handleTextareaBlur = () => {
        if (softwareUntil.trim() === '') {
            setSoftwareUntil();
        }
    };



    const handleTextareaChangeInputReason = (event) => {
        setReasonUntil(event.target.value);
    };

    const handleTextareaFocusReason = () => {
        if (reasonUntil === initialMessageThree) {
            setReasonUntil('');
        }
    };

    const handleTextareaBlurReason = () => {
        if (reasonUntil.trim() === '') {
            setReasonUntil();
        }
    };


    const handleTextareaChangeInputType = (event) => {
        setType(event.target.value);
    };

    const handleTextareaFocusType = () => {
        if (type === initialMessageTwo) {
            setType('');
        }
    };

    const handleTextareaBlurType = () => {
        if (type.trim() === '') {
          
        }
    };

    return (
        <div className="crit-container">
            <div className={`step-container one ${currentStep === 1 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Für wen genau suchst du ein Geschenk? (z.B. Partner, Freundin, Onkel, Kind)</h6>

                    <div className="input-container">
                        <input
                            required
                            type="text"
                            id="ftype"
                            name="ftype"
                            placeholder={initialMessageTwo}
                            className="form-input"
                            value={type}
                            onChange={handleTextareaChangeInputType}
                            onFocus={handleTextareaFocusType}
                            onBlur={handleTextareaBlurType}
                        ></input>
                    </div>
                    <div className="button-container confirm">
                        <button
                            type="button"
                            className={`btn btn-primary`}
                            onClick={handleNext}
                            disabled={type === "" || type.length < 3}
                        >
                            Bestätigen
                        </button>
                    </div>
                </div>
            </div>
            <div className={`step-container two ${currentStep === 2 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Wie alt ist die Person, für die du ein Geschenk suchst?</h6>

                    <div className="input-container">
                        <input
                            required
                            type="number"
                            id="fname"
                            name="fname"
                            placeholder={initialMessage}
                            className="form-input"
                            value={softwareUntil}
                            onChange={handleTextareaChangeInput}
                            onFocus={handleTextareaFocus}
                            onBlur={handleTextareaBlur}
                        ></input>
                    </div>
                    <div className="button-container confirm">
                        <button
                            type="button"
                            className={`btn btn-primary`}
                            onClick={handleSoftwareUntil}
                            disabled={softwareUntil === "" }
                        >
                            Bestätigen
                        </button>
                    </div>
                </div>
            </div>
            <div className={`step-container three ${currentStep === 3 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Was ist der Anlass für das Geschenk? (z.B. Geburtstag, Weihnachten, Jubiläum, Hochzeit, Einweihungsparty, Abschluss, etc.)</h6>
                    <div className="image-container">
                        {/* <img src={usage} alt="Page" /> */}
                    </div>
                    <div className="input-container">
                        <input
                            required
                            type="text"
                            id="fname"
                            name="fname"
                            placeholder={initialMessageThree}
                            className="form-input"
                            value={reasonUntil}
                            onChange={handleTextareaChangeInputReason}
                            onFocus={handleTextareaFocusReason}
                            onBlur={handleTextareaBlurReason}
                        ></input>
                    </div>
                    <div className="button-container confirm">
                        <button
                            type="button"
                            className={`btn btn-primary`}
                            onClick={handleNext}
                            disabled={reasonUntil === "" }
                        >
                            Bestätigen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
