import React, { useState } from 'react';
import ProductCard from './ProductCard';

export default function ProductList({ apiResponse }) {
    // Initialisieren des State unabhängig davon, ob apiResponse vorhanden ist
    const [visibleCounts, setVisibleCounts] = useState([]);

    // Überprüfen, ob apiResponse und apiResponse.data definiert sind
    if (!apiResponse || !apiResponse.data) {
        return <div>Lade Daten...</div>;
    }

    // Initialisieren des visibleCounts basierend auf apiResponse.data, aber nur einmal
    if (visibleCounts.length === 0 && apiResponse.data.length > 0) {
        setVisibleCounts(apiResponse.data.map(() => 2));
    }

    const loadMore = (index) => {
        if (visibleCounts[index] < apiResponse.data[index].results.SearchResult.Items.length) {
            setVisibleCounts(visibleCounts.map((count, i) => i === index ? count + 10 : count));
        }
    }

    const handleLoadMore = (event, index) => {
        event.preventDefault();
        loadMore(index);
    }

    return (
        <div>
            {apiResponse.data.map((idea, index) => {
                if (!idea.results || !idea.results.SearchResult || !Array.isArray(idea.results.SearchResult.Items)) {
                    return null; // oder eine alternative Darstellung für fehlende Daten
                }

                return (
                    <div key={index} className="product-container-single">
                        <div className="idea-heading-container">
                            <h2>{idea.idea}</h2>
                        </div>
                        <div className='product-container-child'>
                            {idea.results.SearchResult.Items.slice(0, visibleCounts[index]).map((product, i) => (
                                <ProductCard key={i} product={product} />
                            ))}
                        </div>
                        {idea.results.SearchResult.Items.length > 2 && visibleCounts[index] < idea.results.SearchResult.Items.length && (
                            <div className="product-load-more">
                                <button onClick={(event) => handleLoadMore(event, index)}>Mehr laden</button>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}
