import React, { useState, useEffect } from 'react';
import ProductList from './ProductList';
import API from './API';
import APIV2 from './APIV2';
import ProductListSimple from './ProductListSimple';

export default function StepFour({

    apiResponse,
    data,
    emailNew,
    setEmailNew,
    currentStep,
    hobby,
    life,
    char,
    apiResponseTwoNew,
    art,
    cat,
    priceFrom,
    priceTo,
    type,
    nextStep,
    softwareUntil,
    softwareSelection,
    reasonUntil,
    handleApiResponseTwoNew,
    handleApiResponse,
    setErrorForPage

}) {

    const [initialMessage, setInitialMessage] = useState('Email eingeben...');
    const [progress, setProgress] = useState(0);
    const [selectedApi, setSelectedApi] = useState(null); // Neu
    const [progressInterval, setProgressInterval] = useState(0); // Neu
    const [privacyAccepted, setPrivacyAccepted] = useState(false);
    const [newsletterSelected, setNewsletterSelected] = useState("newsletteroff");
    const [errorMessage, setErrorMessage] = useState("");


    const handleTextareaChangeInputEmail = (event) => {
        setEmailNew(event.target.value);

    };

    const handleCheckboxChangePrivacy = (event) => {
        setPrivacyAccepted(event.target.checked);
    };

    const handleCheckboxChangeNewsletter = (event) => {
        setNewsletterSelected(event.target.checked ? "newsletteron" : "newsletteroff");
    };
    



    useEffect(() => {
        setErrorForPage(errorMessage);
    }, [errorMessage]);
    


    const handleTextareaFocusEmail = () => {
        if (emailNew === initialMessage) {
            setEmailNew('');
        }
    };
    const handleApiV2Click = () => {
        setSelectedApi('apiv2');
        setProgressInterval((30000 / 100) / 1000); // Geändert
    };

    const handleApiClick = () => {
        setSelectedApi('api');
        setProgressInterval((3000 / 100) / 100); // Geändert
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    clearInterval(interval);
                    return 100;
                }
                // Anstelle von 0.67% verwenden wir jetzt das Intervall, das durch den Klick auf die API-Buttons festgelegt wurde.
                return Math.min(oldProgress + progressInterval * Math.random() * 1 + 0.9, 100);
            });
        }, 1000);

        // ...
    }, [apiResponse, progressInterval]);

    useEffect(() => {
        if (apiResponse !== "") {
            setProgress(100);
        }
    }, [apiResponse]);

    return (
        <div className="submit-parent-container">
            {currentStep > 5 && currentStep < 7 && (
                <p>Unser kluges KI-System benötigt nur kurz 2 bis 3 Minuten, um die perfekten Geschenkideen zu finden. Oder noch bequemer: Lassen Sie sich die Vorschläge einfach per E-Mail zusenden, sobald sie fertig sind.</p>
            )}
            {currentStep > 5 && currentStep < 7 && (
                <div className="progress-container">

                    <div className="credentials-email">
                        <p>Geschenkidden- & Produkte bequem per E-Mail senden lassen! <span>(freiwillig)</span></p>
                        {/* <label htmlFor="email">Ihre E-Mail</label> */}
                        <input type="text" id="email" name="E-Mail"
                            onChange={handleTextareaChangeInputEmail}
                            onFocus={handleTextareaFocusEmail}
                            value={emailNew}
                            placeholder={initialMessage} />
                    </div>
                    {emailNew !== "" && (
                        <div className="email-checkbox-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '10px', textAlign: 'left', }}>
                            <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <input
                                    type="checkbox"
                                    checked={privacyAccepted}
                                    onChange={handleCheckboxChangePrivacy}
                                    style={{ width: '20px', height: '20px' }}
                                />
                                <span className="functional-container" style={{ fontSize: '16px' }}>Hiermit bestätige ich, dass ich die <a href="https://www.genius-gifts.com/datenschutz" className='check-email-privacy' target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>Datenschutzerklärung</a> zur Kenntnis genommen habe.</span>
                            </label>
                            <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <input
                                    type="checkbox"
                                    checked={newsletterSelected === "newsletteron"}
                                    onChange={handleCheckboxChangeNewsletter}
                                    style={{ width: '20px', height: '20px' }}
                                />
                                <span style={{ fontSize: '16px' }}>Kostenfreien TOP-Geschenke Newsletter bestellen (exklusiv)</span>
                            </label>
                        </div>
                    )}

                </div>
            )}
            {currentStep > 6 && errorMessage === "" && (
                <div className='progress-bar-container-parent'>
                    <span>"Bitte warten, unsere KI erstellt gerade Top-Geschenkideen. Bei angegebener E-Mail, erhalten Sie diese innerhalb von 5 Minuten.</span>
                    <div className="progress-bar-container">
                        <div className="progress-bar" style={{ width: `${progress}%` }}>
                            <div className="percantage-count">
                                {`${progress.toFixed(1)}%`}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {currentStep > 5 && currentStep < 7 && (
                <div className="button-api-container">
                    <APIV2
                        key="apiv2"
                        version="version_two"
                        hobby={hobby}
                        life={life}
                        email={emailNew}
                        char={char}
                        art={art}
                        cat={cat}
                        priceFrom={priceFrom}
                        priceTo={priceTo}
                        age={softwareUntil}
                        gender={softwareSelection}
                        issue={reasonUntil}
                        type={type}
                        handleApiResponse={handleApiResponse}
                        nextStep={nextStep}
                        privacyAccepted={privacyAccepted}
                        newsletterSelected={newsletterSelected}
                        disabled={!privacyAccepted && emailNew !== ''}
                        onClick={handleApiClick}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}

                    />
                    {/* <API
                        key="api"
                        version="version_one"
                        hobby={hobby}
                        life={life}
                        char={char}
                        email={emailNew}
                        art={art}
                        cat={cat}
                        priceFrom={priceFrom}
                        priceTo={priceTo}
                        age={softwareUntil}
                        gender={softwareSelection}
                        issue={reasonUntil}
                        type={type}
                        privacyAccepted={privacyAccepted}
                        handleApiResponseTwoNew={handleApiResponseTwoNew}
                        onClick={handleApiV2Click}
                        nextStep={nextStep}
                        newsletterSelected={newsletterSelected}
                        disabled={!privacyAccepted && emailNew !== ''}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                    /> */}
                </div>
            )}

            {errorMessage !== "" && (
                <div>
                    <img src="/server_error.png" alt="server-error" style={{ width: '300px' }} />
                    <p>{errorMessage}</p>
                </div>
            )}

            {apiResponse !== "" && (
                <ProductList apiResponse={apiResponse} data={data} />
            )}

            {apiResponseTwoNew !== "" && (
                <ProductListSimple apiResponse={apiResponseTwoNew} />
            )}

        </div>
    );
}
